import { CommonModule, DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReportService } from 'src/app/core/rest-services/report.service';
import { MatFormFieldModule } from '@angular/material/form-field';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import * as XLSX from 'xlsx';
import { NgxPaginationModule, PaginationInstance } from 'ngx-pagination';
import { BranchLoansReportModel } from '@lmsModels';
import { HttpClient } from '@angular/common/http';
import { LmsPaginateComponent } from '@lmsShared';
import { ZARcurrencyPipe } from '@lmsSharedPipes';

@Component({
  selector: 'app-branch-loans-report',
  templateUrl: './branch-loans-report.component.html',
  styleUrls: ['./branch-loans-report.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatExpansionModule,
    NgxPaginationModule,
    DatePipe,
    LmsPaginateComponent,
    ZARcurrencyPipe
  ],
})
export class BranchLoansReportComponent implements OnInit {
  branchLoansForm: FormGroup;
  activeBranches: any[] = [];
  selectedBranch: any;
  printAllBranches: boolean;
  startDate: string;
  endDate: string;
  branchLoansReportModel = new BranchLoansReportModel();
  lstBranchLoans: any[] = [];
  //lstBranchReport: Array<any> = [];
  title = 'export-excel';
  fileName = '';

  @ViewChild('TABLE') table: ElementRef;
  dataSource = this.lstBranchLoans;

  docCount = 1;

  config: PaginationInstance = {
    id: 'pager',
    itemsPerPage: 10,
    currentPage: 1
  };

  constructor(
    private http: HttpClient,
    public reportService: ReportService,
    // public riskScoreService: RiskScoresService,
    // private userService: UserService,
    // private lmsDialog: MatDialog,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.branchLoansForm = this.formBuilder.group({
      branch: [this.branchLoansReportModel.selectedBranch],
      startDate: new Date(this.branchLoansReportModel.startDate),
      endDate: new Date(this.branchLoansReportModel.endDate),
    });

    this.findAllActiveBranches();
  }

  findAllActiveBranches() {
    this.reportService.findActiveBranches().subscribe({
      next: (branches) => {
        console.log('branches' + branches);
        this.activeBranches = branches;
      },
      error: (err) => {},
    });
  }

  // this.findAllLMSBranches();

  changeBranch(branch?: any) {
    // this.hasselectedBranch = false;
    // this.agentLandingForm.reset();

    if (branch) {
      this.selectedBranch = branch;
      // this.headerMessage = this.selectedArea + ', Branch: ' + branch.branchName;
      // this.hasselectedBranch = true;
      // this.global_utility.stopLoading();
    }
  }

  exportToExcel(): void {
    console.log('exportToExcel');
    this.title = 'export-excel';
    this.fileName =
      'Branch Loans: ' + new Date().toLocaleDateString() + '.xlsx';

    /* pass here the table id */
    //const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.lstBranchReport);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table.nativeElement,
    );

    /* generate workbook and add the worksheet */

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */

    XLSX.writeFile(wb, this.fileName);
  }

  search() {
    console.log('search' + this.selectedBranch);
    console.log('printAllBranches' + this.printAllBranches);
    this.branchLoansReportModel.printAllBranches = this.printAllBranches;

    this.reportService
      .pullBranchLoansReport(this.branchLoansReportModel)
      .subscribe({
        next: (loans) => {
          console.log('loans = ' + loans);
          // console.log("quotes = "  + quotes.);
          this.lstBranchLoans = loans;
          //this.lstBranchReport = quotes;
        },
        error: (err) => {},
      });
  }

  //  public setPage(page: number) {
  //    // get new pager object for specified page
  //    this.pager = paginate({ totalItems: this.items.length, currentPage: page, pageSize: this.pageSize, maxPages: this.maxPages });

  //    // get new page of items from items array
  //    const pageOfItems = this.items.slice(this.pager.startIndex, this.pager.endIndex + 1);

  //    // call change page function in parent component
  //    this.changePage.emit(pageOfItems);
  //  }

  // function findAllLMSBranches() {
  //   //this.reportService.findAllLMSBranches
  //   throw new Error('Function not implemented.');
  // }

  // findAllLMSBranches() {
  //     this.reportService.getAllLMSBranches().subscribe({
  //     next: (branches) => {

  //       // this.lstQuotes = quotes;
  //       // //this.selectedBranch = {};
  //       // this.isSearchClient = true;
  //       // this.hasselectedBranch = true;
  //       // this.isStatsData = false;
  //       // this._isSearching = false;
  //     },
  //     error: (err) => {
  //     }
  //   });

  //}

  // findAllLMSBranches() {
  //   this.reportService.findAllLMSBranches.subscribe({
  //     //     next: (branches) => {

  //     //       // this.lstQuotes = quotes;
  //     //       // //this.selectedBranch = {};
  //     //       // this.isSearchClient = true;
  //     //       // this.hasselectedBranch = true;
  //     //       // this.isStatsData = false;
  //     //       // this._isSearching = false;
  //     //     },
  //     //     error: (err) => {
  //     //     }
  //        });

  //   //throw new Error('Function not implemented.');
  // }
  //}
}
