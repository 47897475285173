<app-page-cardhead [PageName]="'Region Details'" [PageHeadWidth]=500></app-page-cardhead>
<div class="container-sm center-container">
  <form [formGroup]="regionForm">
    <div class="card">
      <div class="card-body p-1">
        <table class="table table-striped table-hover table-sm shadow-2-strong p-1">
          <tbody class="field-4column-label">
            <tr>
              <td class="align-items-center text-center w-50">
                Region name: &nbsp;
                <input formControlName="region" [(ngModel)]="regionModel.name" type="text" style="max-width: 200px;">
                <!-- <div *ngIf="areaForm.controls.area.errors?.required && submitted" class="error-label">
                    Area is required
                  </div> -->
              </td>
            </tr>

            <tr>
              <td style="width: 180px;">
                <div>
                  <table class="table table-striped table-hover table-sm">
                    <caption>Manage areas in this region</caption>
                    <thead class="table-center-header" style="background-color: white;" color="primary">
                      <tr>
                        <th>Code</th>
                        <th>Area</th>
                        <th>Edit</th>
                      </tr>
                    </thead>
                    <tbody class="center-fields">
                      <ng-container *ngFor="let areas of regionSelected.areas">
                        <tr>
                          <td>{{areas.id}}</td>
                          <td>{{areas.name}}</td>
                          <td>
                            <button class="btn btn-floating btn-icon fs-5 fas" mdbRipple (click)="selectArea(areas)">
                              <i class="fas fa-xs fa-fw fa-pen-to-square"></i></button>
                          </td>
                        </tr>
                      </ng-container>
                      <tr>
                        <td colspan="3">
                          <button class="btn btn-primary btn-sm button-add-row ms-2 me-4" mdbRipple type="button"
                            (click)="findAllAreas()">Move an area to this
                            region</button>

                          <button type="button" class="btn btn-primary btn-sm" (click)="createNewArea()">Create a new
                            area</button>
                        </td>
                      </tr>

                      <tr *ngIf="lstAreas">
                        <td colspan="3">
                      <tr>
                        <td>
                          Select the area that must be moved to this region.
                        </td>
                        <td>
                          <select class="lmsselect" formControlName="selArea" [(ngModel)]="regionModel.areas"
                            (click)="selectNewArea($event)">
                            <option *ngFor="let area of lstAreas" [value]="area.id">
                              {{ area.name }}
                            </option>
                          </select>
                        </td>

                      </tr>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      </td>

      </tr>



      </tbody>
      </table>
    </div>

    <div class="card-footer center-screen">
      <button class="btn btn-primary me-2" mdbRipple (click)="close()"> Close
      </button>
      <button class="btn btn-primary" mdbRipple (click)="saveRegion()">Save
      </button>
    </div>
</div>
</form>
</div>
