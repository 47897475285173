export class OfferModel {
  term: string;
  loanAmount = 0;
  quoteId: any;
  myBenefits!: boolean;
  firstPaymentDate: string;
  payoutMethod: string;
  interestRate: string | number = 5;
  disbursedAmount = 0;
  settlementAmount = 0;
  creditLife = 'yes';
  journalComment?: string;
  userName?: string;
}
