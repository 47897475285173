<!-- <div class="justify-content-center align-content-center center-screen mt-2 mb-4">
  <div class="d-flex align-content-center justify-content-center my-3"> -->

<ng-container class="d-block mt-3">
  <div fxLayout="row" class="container container-sm" fxLayoutAlign="center center" fxLayoutGap="6px">

    <form [formGroup]="ratesAndFeesForm" class="pt-3">
      <div class="card mat-elevation-z2 rounded-2 shadow-2-soft">
        <div class="card-header p-1">
          <div class="idlight ms-auto me-auto rounded-2 shadow-2-strong center-screen py-3">
            <div class="d-inline-flex justify-content-around align-content-center">
              <div *ngFor="let product of lstProductTypes;" style="flex-wrap: nowrap;
                                  flex-grow: 1 auto;">
                <button type="button" class="btn me-2" mdbRipple rippleColor="dark" color="primary"
                  [ngClass]="ratesAndFeesModel ===product ? 'btn-primary':'btn-outline-primary'"
                  (click)="ratesAndFeesModel=product">{{product.description}}</button>
              </div>
            </div>
          </div>
        </div>

        <div class="card-body mb-2 p-3">
          <div class="container-fluid bd-example-row">
            <table id="productRates"
              class="table table-striped table-sm mb-2 caption-top shadow-1-strong rounded-1 p-1 my-2"
              [style.width.px]="900" style="text-align: left;">
              <caption>Product Parameters</caption>
              <tbody class="field-4column-label">
                <tr>
                  <td>Code</td>
                  <td>
                    <input mat-input type="text" formControlName="code" [(ngModel)]="ratesAndFeesModel.code">
                    <!-- <div *ngIf="validateRatesAndFeesValues.code.errors?.required && submitted" class="error-label">
                            Code is required
                          </div> -->
                  </td>

                  <td>Description</td>
                  <td>
                    <input mat-input type="text" formControlName="description"
                      [(ngModel)]="ratesAndFeesModel.description" required>
                    <div *ngIf="validateRatesAndFeesValues.description.errors?.required && submitted"
                      class="error-label">
                      Description is required
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Minimum Loan Amount</td>
                  <td>
                    <input mat-input type="text" formControlName="minimumLoan"
                      [(ngModel)]="ratesAndFeesModel.minimumLoan" required>
                    <div *ngIf="validateRatesAndFeesValues.minimumLoan.errors?.required && submitted"
                      class="error-label">
                      Minimum loan is required
                    </div>
                  </td>

                  <td>Maximum Loan Amount</td>
                  <td>
                    <input mat-input type="text" formControlName="maximumLoan"
                      [(ngModel)]="ratesAndFeesModel.maximumLoan" required>
                    <div *ngIf="validateRatesAndFeesValues.maximumLoan.errors?.required && submitted"
                      class="error-label">
                      Maximum Loan is required
                    </div>
                  </td>
                </tr>
                <!-- interestRate1st: number;
                      interestRateOther: number; -->
                <tr>
                  <td>Interest rate 1st loan (%)</td>
                  <td>
                    <input mat-input type="text" formControlName="interestRate1st"
                      [(ngModel)]="ratesAndFeesModel.interestRate1st" required>
                    <div *ngIf="validateRatesAndFeesValues.interestRate1st.errors?.required && submitted"
                      class="error-label">
                      Interest rate 1st loan is required
                    </div>
                  </td>
                  <td>Interest rate other loans (%)</td>
                  <td>
                    <input mat-input type="text" formControlName="interestRateOther"
                      [(ngModel)]="ratesAndFeesModel.interestRateOther" required>
                    <div *ngIf="validateRatesAndFeesValues.interestRateOther.errors?.required && submitted"
                      class="error-label">
                      Interest rate other is required
                    </div>
                  </td>
                </tr>
                <!-- initFeeBelow1000: number;
                      initFeeFirst1000: number; -->
                <tr>
                  <td>Initiation fee if cap below 1000 (%)</td>
                  <td>
                    <input mat-input type="text" formControlName="initFeeBelow1000"
                      [(ngModel)]="ratesAndFeesModel.initFeeBelow1000">
                  </td>
                  <td>Initiation fee first 1000</td>
                  <td>
                    <input mat-input type="text" formControlName="initFeeFirst1000"
                      [(ngModel)]="ratesAndFeesModel.initFeeFirst1000">
                  </td>
                </tr>

                <!-- initiationFee: number;
                      initiationCap: number; -->
                <tr>
                  <td>Initiation fee (%)</td>
                  <td>
                    <input mat-input type="text" formControlName="initiationFee"
                      [(ngModel)]="ratesAndFeesModel.initiationFee" required>
                    <div *ngIf="validateRatesAndFeesValues.initiationFee.errors?.required && submitted"
                      class="error-label">
                      Initiation fee is required
                    </div>
                  </td>

                  <td>Initiation fee cap</td>
                  <td>
                    <input mat-input type="text" formControlName="initiationCap"
                      [(ngModel)]="ratesAndFeesModel.initiationCap" required>
                    <div *ngIf="validateRatesAndFeesValues.initiationCap.errors?.required && submitted"
                      class="error-label">
                      Initiation fee cap is required
                    </div>
                  </td>
                </tr>
                <!-- myBenefits: number;
                            term:  number; -->
                <tr>
                  <td>My Benefits (%)</td>
                  <td>
                    <input mat-input type="text" formControlName="myBenefits" [(ngModel)]="ratesAndFeesModel.myBenefits"
                      required>
                    <div *ngIf="validateRatesAndFeesValues.myBenefits.errors?.required && submitted"
                      class="error-label">
                      My Benefits is required
                    </div>
                  </td>
                  <td>Term in months</td>
                  <td>
                    <input mat-input type="text" formControlName="term" [(ngModel)]="ratesAndFeesModel.term" required>
                    <div *ngIf="validateRatesAndFeesValues.term.errors?.required && submitted" class="error-label">
                      Term is required
                    </div>
                  </td>
                </tr>
                <!-- factor1stLoan: number;
                      factorOtherLoan: number; -->
                <tr>
                  <td>Factor 1st loan</td>
                  <td>
                    <input mat-input type="text" formControlName="factor1stLoan"
                      [(ngModel)]="ratesAndFeesModel.factor1stLoan">
                    <!-- <div *ngIf="validateRatesAndFeesValues.factor1stLoan.errors?.required && submitted"
                                  class="error-label">
                                  Factor 1st loan is required
                              </div> -->
                  </td>
                  <td>Factor other loans</td>
                  <td>
                    <input mat-input type="text" formControlName="factorOtherLoan"
                      [(ngModel)]="ratesAndFeesModel.factorOtherLoan">
                    <!-- <div *ngIf="validateRatesAndFeesValues.factorOtherLoan.errors?.required && submitted"
                                  class="error-label">
                                  Factor other loans is required
                              </div> -->
                  </td>
                </tr>
                <!-- factorUpTo850: number;
                      factorUpTo1000: number; -->
                <tr>
                  <td>Factor up to 850</td>
                  <td>
                    <input mat-input type="text" formControlName="factorUpTo850"
                      [(ngModel)]="ratesAndFeesModel.factorUpTo850">
                    <!-- <div *ngIf="validateRatesAndFeesValues.factorUpTo850.errors?.required && submitted"
                                  class="error-label">
                                  Factor up to 850 is required
                              </div> -->
                  </td>
                  <td>Factor up to 1000</td>
                  <td>
                    <input mat-input type="text" formControlName="factorUpTo1000"
                      [(ngModel)]="ratesAndFeesModel.factorUpTo1000">
                    <!-- <div *ngIf="validateRatesAndFeesValues.factorUpTo1000.errors?.required && submitted"
                                  class="error-label">
                                  Factor up to 1000 is required
                              </div> -->
                  </td>
                </tr>
                <!-- monthlyServiceFee: number; initServiceFeeCap: -->
                <tr>
                  <td>Monthly service fee</td>
                  <td>
                    <input mat-input type="text" formControlName="monthlyServiceFee"
                      [(ngModel)]="ratesAndFeesModel.monthlyServiceFee" required>
                    <div *ngIf="validateRatesAndFeesValues.monthlyServiceFee.errors?.required && submitted"
                      class="error-label">
                      Monthly service fee is required
                    </div>
                  </td>
                  <td>Initiation service fee cap (%)</td>
                  <td>
                    <input mat-input type="text" formControlName="initServiceFeeCap"
                      [(ngModel)]="ratesAndFeesModel.initServiceFeeCap" required>
                    <div *ngIf="validateRatesAndFeesValues.initServiceFeeCap.errors?.required && submitted"
                      class="error-label">
                      Initiation service fee cap is required
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Max days before first payment</td>
                  <td>
                    <input formControlName="maxDays" [(ngModel)]="ratesAndFeesModel.maxDays" required>
                    <div *ngIf="validateRatesAndFeesValues.maxDays.errors?.required && submitted" class="error-label">
                      Max days before first payment is required
                    </div>
                  </td>
                  <td>Regulatory type</td>
                  <td>
                    <select class="lmsselect" formControlName="regulatoryType"
                      [(ngModel)]="ratesAndFeesModel.regulatoryType" required>
                      <option *ngFor="let regType of lstRegulatory;" [value]="regType.value">
                        {{regType.description}}
                      </option>
                    </select>
                    <div *ngIf="validateRatesAndFeesValues.regulatoryType.errors?.required && submitted"
                      class="error-label">
                      Regulatory type is required
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>MyBenefits Document tier</td>
                  <td>
                    <select class="lmsselect" formControlName="mybenefitsTier"
                      [(ngModel)]="ratesAndFeesModel.mybenefitsTier" lmsSelectOption constantName="MYBENEFIT_TIER'"
                      required>
                      <!-- <option *ngFor="let regType of lstMyBenefitTier;" [value]="regType.value">
                            {{regType.description}}
                          </option> -->
                    </select>
                    <div *ngIf="validateRatesAndFeesValues.mybenefitsTier.errors?.required && submitted"
                      class="error-label">
                      MyBenefits document tier is required
                    </div>
                  </td>
                  <td colspan="2"></td>
                </tr>
              </tbody>
            </table>

            <!-- Product Fee calculations -->
            <table id="serviceFees"
              class="table table-striped table-sm mb-2 caption-top shadow-1-strong rounded-1 p-1 my-2"
              [style.width.px]=900 style="text-align: left;">
              <caption>Product Fee calculations</caption>
              <tbody class="field-4column-label">
                <tr>
                  <td>Loan Type</td>
                  <td>
                    <input style="width: 250px;" formControlName="type" [(ngModel)]="ratesAndFeesModel.type" required>
                    <div *ngIf="validateRatesAndFeesValues.type.errors?.required && submitted" class="error-label">
                      Loan type is required
                    </div>
                  </td>
                  <td>Regulatory type</td>
                  <td>
                    <input style="width: 250px;" formControlName="regulatoryType"
                      [(ngModel)]="ratesAndFeesModel.regulatoryType">
                    <div *ngIf="validateRatesAndFeesValues.regulatoryType.errors?.required && submitted"
                      class="error-label">
                      Regulatory type is required
                    </div>
                  </td>
                </tr>
                <!-- calculationType: string;
                            maxDays: number; -->
                <tr>
                  <td>Max days before first payment</td>
                  <td>
                    <input formControlName="maxDays" [(ngModel)]="ratesAndFeesModel.maxDays" required>
                    <div *ngIf="validateRatesAndFeesValues.maxDays.errors?.required && submitted" class="error-label">
                      Max days before first payment is required
                    </div>
                  </td>
                  <td>Calculation type</td>
                  <td>
                    <input formControlName="calculationType" [(ngModel)]="ratesAndFeesModel.calculationType">
                    <!-- <div *ngIf="validateRatesAndFeesValues.calculationType.errors?.required && submitted"
                                        class="error-label">
                                        Calculation type is required
                                    </div> -->
                  </td>
                </tr>
                <!-- <tr>
                                <td>Include In Interest Calculation</td>
                                <td>
                                    <select formControlName="includeInInterestCalc"
                                          style="width: 250px;"
                                        [(ngModel)]="ratesAndFeesModel.includeInInterestCalc"
                                        required>
                                        <option *ngFor="let includeInCalc of lstIncludeInCalculation;"
                                            [value]="includeInCalc.value">
                                            {{includeInCalc.includeInCalc}}
                                        </option>
                                    </select>
                                </td>
                                <td>Include In VAT Calculation</td>
                                <td>
                                    <select formControlName="includeVatInCalc"
                                          style="width: 250px;"
                                        [(ngModel)]="ratesAndFeesModel.includeVatInCalc"
                                        required>
                                        <option *ngFor="let includeInCalc of lstIncludeInCalculation;"
                                            [value]="includeInCalc.value">
                                            {{includeInCalc.includeInCalc}}
                                        </option>
                                    </select>
                                </td>
                            </tr> -->
              </tbody>
            </table>

            <!-- INTEREST RATES -->
            <!-- <mat-accordion>
                    <mat-expansion-panel class="sub-panels-container" (opened)="panelOpenState = true"
                        (closed)="panelOpenState = false">
                        <mat-expansion-panel-header class="basic-panel-header" [expandedHeight]="'35px'">
                            <mat-panel-title class="basic-panel-title">
                                Interest Rates
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <br>
                        <table id="interestRates" class="table table-striped table-light table-hover table-sm mb-3 caption-top" style="width: 840px;
                                margin: auto;
                                text-align: left;
                                vertical-align: middle;">
                            <tbody>
                                <tr>
                                    <td>Calculation Type</td>
                                    <td>
                                        <div *ngFor="let lstData of lstLoanTypeData">
                                            <input mat-input type="text" style="width: 250px;" formControlName="calculationType"
                                                [(ngModel)]="lstData.calculationType" required>
                                        </div>
                                        <div *ngIf="validateRatesAndFeesValues.calculationType.errors?.required && submitted"
                                            class="error-label">
                                            Calculation Type is required
                                        </div>
                                    </td>

                                    <td>Calculation Period</td>
                                    <td>
                                        <div *ngFor="let lstData of lstLoanTypeData">
                                            <input mat-input type="text" style="width: 250px;"
                                                formControlName="calculationPeriodType" [(ngModel)]="lstData.calculationPeriodType"
                                                required>
                                        </div>
                                        <div *ngIf="validateRatesAndFeesValues.calculationPeriodType.errors?.required && submitted"
                                            class="error-label">
                                            Calculation Period Type is required
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Include VAT in Calculation</td>
                                    <td>
                                        <select formControlName="includeVatInCalculation" style="width: 250px;"
                                            [(ngModel)]="ratesAndFeesModel.includeVatInCalculation" required>
                                            <option *ngFor="let includeInCalc of lstIncludeInCalculation;"
                                                [value]="includeInCalc.value">
                                                {{includeInCalc.includeInCalc}}
                                            </option>
                                        </select>
                                        <div *ngIf="validateRatesAndFeesValues.includeVatInCalculation.errors?.required && submitted"
                                            class="error-label">
                                            Include Vat In Calculation is required
                                        </div>
                                    </td>

                                    <td>Repo Rate Factor</td>
                                    <td>
                                        <div *ngFor="let lstData of lstLoanTypeData">
                                            <input mat-input type="text" aria-label="String" style="width: 250px;"
                                                formControlName="repoRateFactor" [(ngModel)]="lstData.repoRateFactor" required>
                                        </div>
                                        <div *ngIf="validateRatesAndFeesValues.repoRateFactor.errors?.required && submitted"
                                            class="error-label">
                                            Repo Rate Factor is required
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Minimum</td>
                                    <td>
                                        <div *ngFor="let lstData of lstLoanTypeData">
                                            <input style="width: 250px;" formControlName="minimum" [(ngModel)]="lstData.minimum"
                                                required>
                                        </div>
                                        <div *ngIf="validateRatesAndFeesValues.minimum.errors?.required && submitted"
                                            class="error-label">
                                            Minimum is required
                                        </div>
                                    </td>

                                    <td>Maximum</td>
                                    <td>
                                        <div *ngFor="let lstData of lstLoanTypeData">
                                            <input style="width: 250px;" formControlName="maximum" [(ngModel)]="lstData.maximum"
                                                required>
                                        </div>
                                        <div *ngIf="validateRatesAndFeesValues.maximum.errors?.required && submitted"
                                            class="error-label">
                                            Maximum is required
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Minimum 2nd Loan</td>
                                    <td>
                                        <div *ngFor="let lstData of lstLoanTypeData">
                                            <input style="width: 250px;" formControlName="minimum2ndLoan"
                                                [(ngModel)]="lstData.minimum2ndLoan" required>
                                        </div>
                                        <div *ngIf="validateRatesAndFeesValues.minimum2ndLoan.errors?.required && submitted"
                                            class="error-label">
                                            Minimum 2nd Loan is required
                                        </div>
                                    </td>

                                    <td>Maximum 2nd Loan</td>
                                    <td>
                                        <div *ngFor="let lstData of lstLoanTypeData">
                                            <input style="width: 250px;" formControlName="maximum2ndLoan"
                                                [(ngModel)]="lstData.maximum2ndLoan" required>
                                        </div>
                                        <div *ngIf="validateRatesAndFeesValues.maximum2ndLoan.errors?.required && submitted"
                                            class="error-label">
                                            Maximum 2nd Loan is required
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Actual Min.</td>
                                    <td>
                                        <div *ngFor="let lstData of lstLoanTypeData">
                                            <input style="width: 250px;" formControlName="actualMin" [(ngModel)]="lstData.actualMin"
                                                required>
                                        </div>
                                        <div *ngIf="validateRatesAndFeesValues.actualMin.errors?.required && submitted"
                                            class="error-label">
                                            Actual Min. is required
                                        </div>
                                    </td>

                                    <td>Actual Max.</td>
                                    <td>
                                        <div *ngFor="let lstData of lstLoanTypeData">
                                            <input style="width: 250px;" formControlName="actualMax" [(ngModel)]="lstData.actualMax"
                                                required>
                                        </div>
                                        <div *ngIf="validateRatesAndFeesValues.actualMax.errors?.required && submitted"
                                            class="error-label">
                                            Actual Max. is required
                                        </div>
                                    </td>
                                </tr>

                            </tbody>
                        </table>

                    </mat-expansion-panel>
                </mat-accordion>
                <br> -->


            <!-- CREDIT LIMIT -->
            <!-- <mat-accordion>
                    <mat-expansion-panel class="sub-panels-container" (opened)="panelOpenState = true"
                        (closed)="panelOpenState = false">
                        <mat-expansion-panel-header class="basic-panel-header" [expandedHeight]="'35px'">
                            <mat-panel-title class="basic-panel-title">
                                General & Credit Limit
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <br>
                        <table id="creditLimit" class="table table-striped table-light table-hover table-sm mb-3 caption-top" style="width: 840px;
                            margin: auto;
                            text-align: left;
                            vertical-align: middle;">
                            <tbody>
                                <tr>
                                    <td>Maximum Days In Future For Loan's First Pay Date</td>
                                    <td>
                                        <div *ngFor="let lstData of lstLoanTypeData">
                                            <input style="width: 250px;" formControlName="maxDaysInFutureForPayDate" required
                                                [(ngModel)]="lstData.maxDaysInFutureForPayDate">
                                        </div>
                                        <div *ngIf="validateRatesAndFeesValues.maxDaysInFutureForPayDate.errors?.required && submitted"
                                            class="error-label">
                                            Max Days In Future For Pay Date is required
                                        </div>
                                    </td>

                                    <td>Percent (For creating a new Client)</td>
                                    <td>
                                        <div *ngFor="let lstData of lstLoanTypeData">
                                            <input style="width: 250px;" formControlName="percent" [(ngModel)]="lstData.percent" required>
                                        </div>
                                        <div *ngIf="validateRatesAndFeesValues.percent.errors?.required && submitted"
                                            class="error-label">
                                            Percent is required
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </mat-expansion-panel>
                </mat-accordion> -->

          </div>
        </div><!-- END: card-body -->
        <div class="card-footer flex-container">
          <button class="btn btn-danger me-5" (click)="openDeleteProductDialog(ratesAndFeesModel)">
            <i class="fas fa-trash-can  fa-fw me-1"></i>
            Delete Product
          </button>
          <button class="btn btn-primary" mdbRipple (click)="openAddProductDialog()">
            <i class="fas fa-laptop-medical fa-fw me-1"></i>
            Add New
          </button>
          <button class="btn btn-primary " mdbRipple (click)="saveUpdateLoanType(ratesAndFeesModel)">Save
          </button>
        </div>

      </div><!-- END: card -->
    </form>
  </div>
</ng-container>
