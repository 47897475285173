import { Component, OnInit } from '@angular/core';
import { NgFor, NgIf } from '@angular/common';
import { MdbModalRef } from "mdb-angular-ui-kit/modal";
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { PageCardheadComponent } from '@lmsShared';

@Component({
  selector: 'app-offer-values',
  templateUrl: './offer-values.component.html',
  styleUrls: ['./offer-values.component.css'],
  standalone: true,
  imports: [NgIf, NgFor, PageCardheadComponent, MdbRippleModule]
})
export class OfferValuesComponent implements OnInit {
  compuscanOffers: any;

  constructor(public modalRef: MdbModalRef<OfferValuesComponent>) { }

  ngOnInit(): void { }

  close() {
    this.modalRef.close();
  }
}
