/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ClientsResponse, OfferModel, PreAuthorisationModel, QuoteModel } from '@lmsModels';
import { BehaviorSubject,map, catchError, Observable, of, Subject, Subscription, tap, throwError } from "rxjs";
import { environment } from 'src/environments/environment';
import { DEFAULT_TIMEOUTCTX } from '../interceptors/timeout.interceptor';
import { ErrorhanderService } from "../services/error-handler.service";

@Injectable({
  providedIn:'root'
})
export class QuoteStoreService implements OnDestroy {
  private quote_apiURL = `${environment.apiUrl}/loan/api/quote`;
  private client_apiURL = `${environment.apiUrl}/loan/api/client`;
  private preAuthorisationModel: PreAuthorisationModel;
  private _isQuoteFound = false;
  httpOptions = {
    headers: new HttpHeaders({ 'content-type': 'application/json' })
  };

  quoteData2$ = new BehaviorSubject<any>({});
  quoteData2Subject = new Subject<any>();

  subscriptions: Subscription[] = [];
  verifiedMessage: any;

  public get PreAuthorisationModel() {
    return this.preAuthorisationModel;
  }
  public set PreAuthorisationModel(value) {
    this.preAuthorisationModel = value;
  }

  public get isQuoteFound() {
    return this._isQuoteFound;
  }

  quoteNotes$ = new BehaviorSubject<number>(0);

  constructor(
    private httpClient: HttpClient,
    private errorService: ErrorhanderService) { }

  // ngOnInit(): void {
  // }


  ngOnDestroy(): void {
    this.subscriptions.forEach(x => {
      x.unsubscribe();
    });
  }

  setVerifiedMessage(message: any) {
    this.verifiedMessage = message;
  }

  public get getVerifiedMessage() {
    return this.verifiedMessage;
  }

  get quoteWorkbook() {
    return this.quoteWorkbookValue;
  }
  LoadedQuote(): Observable<any> {
    return this.quoteData2$.asObservable();
  }

  LoadedQuoteid = (id: number) => {
    this.getQuoteDetailsById(id).subscribe(x => {
      this.quoteData2Subject.next(x);
    });
  };

  set quoteWorkbook(value: any) {
    if (value) {
      this._isQuoteFound = true;
      this.quoteData2$.next(value);
    }
  }

  get quoteWorkbookValue() {
    return this.quoteData2$.value;
  }

  setQuoteWorkbook(data: any) {
    this._isQuoteFound = false;
    if (data) {
      this._isQuoteFound = true;
    }
    this.quoteData2$.next(data);
  }

  addQuoteNote(note: any) {
    return this.httpClient
      .post<any>(`${this.quote_apiURL}/addQuoteNote`, note)
      .pipe(
        map((responseData) => {
          this.setQuoteNotes(responseData);
          return responseData;
        })
      );
  }

  getQuoteNotesCount() {
    const ss = this.quoteNotes$.getValue();
    return ss;
  }

  setQuoteNotes(data: any) {
    if (data) {
      this.quoteNotes$.next(data.length);
    }
  }

  getQuoteNotes(id: number) {
    return this.httpClient
      .get(this.quote_apiURL + '/getQuoteNotes/' + id).pipe(
        map((quoteNotes: any) => {
          this.setQuoteNotes(quoteNotes);
          return quoteNotes;
        }
        ));
  }

  //No refernces to this code anywhere
  // setClientData(key: string, value: any) {
  //   this.client_data[key] = value;
  // }

  // getClientData(key: string) {
  //   return this.client_data[key];
  // }

  // getBranchQuotes(data: any): Observable<any> {
  //   return this.http
  //     .post<any>(`${this.quote_apiURL}/branchQuotes', data)
  //     .pipe(
  //       map(landingData => ({
  //         landingData
  //         //this.quoteWorkbook = responseData;
  //         //return responseData;
  //       })),
  //     );
  // }

  //No refernces to this code anywhere
  // getUserQuotes(data: any): Observable<any> {
  //   return this.http
  //     .post<any>(`${this.quote_apiURL}/userQuotes', data)
  //     .pipe(
  //       // filter(landingData => !!landingData),
  //       map(responseData => ({
  //         //  this.quoteWorkbook = responseData;
  //         responseData,
  //       })),
  //     );
  // }

  getClientQuoteDetails(idNumber: any) {
    this._isQuoteFound = false;
    return this.httpClient.post<any>(`${this.quote_apiURL}/idNumber`, idNumber, this.httpOptions);
    // .pipe(
    // .subscribe({
    //   next: (responseData: any) => {
    //     this.setQuoteWorkbook(responseData);
    //     return responseData;
    //   }
    // });
    // );
  }

  getBranchClientQuoteDetails(data: any) {
    this._isQuoteFound = false;
    return this.httpClient.post<any>(`${this.quote_apiURL}/branchidNumber`, data, this.httpOptions)
      .pipe(
        map((responseData: any) => {
          return responseData;
        })
      );
  }

  // getBranchValidQuote(data: any) {
  //   this._isQuoteFound = false;
  //   return this.httpClient.post<any>(`${this.quote_apiURL}/branchidNumber`, data, this.httpOptions);
  // }

  getQuoteByIdNumber(data: any) {
    this._isQuoteFound = false;
    return this.httpClient.post<any>(`${this.quote_apiURL}/idNumber`, data, this.httpOptions)
      .pipe(
        map((qdata) => {
          this.setQuoteWorkbook(qdata);
          return qdata;
        }));
  }

  /*
  Reference: https://angular.io/tutorial/tour-of-heroes/toh-pt6#get-hero-by-id
  */
  getQuoteDetailsById(qId: number): Observable<any> {
    const url = `${this.quote_apiURL}/quoteById`;
    return this.httpClient.get<any>(`${url}/${qId}`, this.httpOptions)
      .pipe(
        tap(_ => console.log(`Quotedetails by Qid: ${qId}`)),
        catchError(this.handleError((`Quotedetails by Qid = ${qId}`)))
      );
  }

  /**
 * Handle Http operation that failed.
 * Let the app continue.
 *
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.errorService.handleString(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  getReadOnlyQuoteDetailsById(data: any) {
    this._isQuoteFound = false;
    return this.httpClient.post<any>(`${this.quote_apiURL}/quoteId`, data, this.httpOptions);
    // .pipe(
    //   map((responseData: any) => {
    //     return responseData;
    //   })
    // );
  }

  // getQuoteDetailsById(data: any) {
  //   return this.httpClient.post<any>(`${ this.quote_apiURL }/quoteId`, data, {headers: this.lmsheaders})
  //   .pipe(
  //       map((responseData: any) => {
  //       this.setQuoteWorkbook(responseData);
  //       return responseData;
  //     })
  //   );
  // }

  getNewClientsList(data: any): Observable<any> {
    return this.httpClient.post<any>(`${this.quote_apiURL}/newClients`, data, this.httpOptions);
  }

  getComplianceList(data: any): Observable<ClientsResponse[]> {
    return this.httpClient.post<ClientsResponse[]>(`${this.client_apiURL}/complianceList`, data, this.httpOptions);
  }

  getBranchQuotesList(data: any): Observable<any> {
    return this.httpClient.post<any>(`${this.quote_apiURL}/branchQuotes`, data, this.httpOptions);
  }


  bankingDetailsFromEmid(data: QuoteModel) {
    return this.httpClient.post<any>(`${this.quote_apiURL}/emidBankTransactions`, data)
      .pipe(
        map((responseData: any) => {
          return responseData;
        })
      );
  }

  saveBankDetails(data: any) {
    return this.httpClient.post<any>(`${this.quote_apiURL}/saveBankDetails`, data)
      .pipe(
        map((responseData: any) => {
          return responseData;
        })
      );
  }

  saveOffer(quote: any) {
    this._isQuoteFound = false;
    return this.httpClient.post<any>(`${this.quote_apiURL}/saveOffer`, quote);
  }

  reCalculateOffer(data: any) {
    return this.httpClient.post<any>(`${this.quote_apiURL}/recalcOffer`, data);
  }

  getAllBanks() {
    return this.httpClient.post<any>(`${this.quote_apiURL}/allBanks`, '')
      .pipe(
        map((responseData: any) => {
          return responseData;
        })
      );
  }

  getDebiCheckBanks() {
    return this.httpClient.post<any>(`${this.quote_apiURL}/debiCheckBanks`, '')
      .pipe(
        map((responseData: any) => {
          return responseData;
        })
      );
  }

  saveNextOfKin(data: any) {
    return this.httpClient.post<any>(`${this.quote_apiURL}/saveNextOfKin`, data);
  }

  saveAddress(data: any) {
    return this.httpClient.post<any>(`${this.quote_apiURL}/saveAddress`, data)
      .pipe(
        map((responseData: any) => {
          return responseData;
        })
      );
  }

  //???
  findAddressesByQuoteId(id: any) {
    return this.httpClient.post<any>(`${this.quote_apiURL}/addressesByQuoteId`, id);
    // .pipe(
    //   map((responseData: any) => {
    //     //this.quoteWorkbook = responseData;
    //     return responseData;
    //   })
    // );
  }

  saveEmployerDetails(data: any) {
    return this.httpClient.post<any>(`${this.quote_apiURL}/saveEmployment`, data);
    // .pipe(
    //   map((res: any) => {
    //     //this.quoteWorkbook = responseData;
    //     return res;
    //   })
    // );
  }

  // findEmploymentByQuoteId(id: any) {
  //   return this.httpClient.post<any>(`${this.quote_apiURL}/addressesByQuoteId`, id)
  //     .pipe(
  //       map((responseData: any) => {
  //         //this.quoteWorkbook = responseData;
  //         return responseData;
  //       })
  //     );
  // }

  findAddressById(id: any): any {
    return this.httpClient.
      get<any>(`${this.quote_apiURL}/getAddress/${id}`);
  }

  saveJournalComment(data: any) {
    return this.httpClient.post<any>(`${this.quote_apiURL}/saveJournalComment`, data);
  }

  printPreAgreement(id: any) {
    const httpOptions = {
      responseType: 'blob' as 'json'
      // 'responseType'  : 'blob' as 'json'        //This also worked
    };

    return this.httpClient.
      get<any>(`${this.quote_apiURL}/printAgreement/${id}`,
        httpOptions);
  }

  printMyBenefits(id: any) {
    const httpOptions = {
      //responseType: 'arraybuffer' as 'json'
      'responseType': 'blob' as 'json'        //This also worked
    };

    return this.httpClient.
      get<any>(`${this.quote_apiURL}/printMyBenefits/${id}`, httpOptions);
  }

  printCLMSLoanSummary(id: any) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };

    return this.httpClient.
    get<any>(`${this.quote_apiURL}/printCLMSLoanSummary/${id}`, httpOptions);
  }




  printMyBenefitsTier(description: any){
    const httpOptions = {
      responseType: 'arraybuffer' as 'json'
      //'responseType': 'blob' as 'json'        //This also worked
    };

    return this.httpClient.
      get<any>(`${this.quote_apiURL}/printMyBenefitsTier/${description}`, httpOptions);
  }

  printCreditLifeTermsConditions() {
    const httpOptions = {
      responseType: 'arraybuffer' as 'json'
    };

    return this.httpClient.
    get<any>(`${this.quote_apiURL}/printCreditLifeTermsConditions`, httpOptions);
  }

  // printMultipleLoanDeclaration(firstLoan: any){
  //   const httpOptions = {
  //     responseType: 'arraybuffer' as 'json'
  //     //'responseType': 'blob' as 'json'        //This also worked
  //   };

  //   return this.httpClient.
  //     get<any>(`${this.quote_apiURL}/printMultipleLoanDeclaration/${firstLoan}`, httpOptions);
  // }

  // sendEmploymentSms(data : any){
  //     return this.http
  //       .post<any>(environment.apiUrl+'/loan/api/quote/employmentsms', data)
  //       .pipe(
  //        map((res: any) => {
  //           return res;
  //        })
  //     );
  //   }


  // sendDebitOrderSms(data: any) {
  //   return this.http
  //     .post<any>(`${this.quote_apiURL}/debitordersms', data)
  //     .pipe(
  //       map((res) => {
  //         return res;

  //       })
  //     );
  // }

  rejectOffer(data: any) {
    return this.httpClient.post<any>(`${this.quote_apiURL}/rejectOffer`, data)
      .pipe(
        map((responseData: any) => {
          //this.quoteWorkbook = responseData;
          return responseData;
        })
      );
  }

  submitClientDetails(quote: QuoteModel) {
    return this.httpClient.post<any>(`${this.quote_apiURL}/submitClientDetails`, quote);
    // .pipe(
    //   map((res: any) => {
    //     return res;
    //   })
    // );
  }

  disburseLoan(quote: OfferModel) {
    return this.httpClient.post<any>(`${this.quote_apiURL}/disburseLoan`, quote);
    // .pipe(
    //   map((res: any) => {
    //     return res;

    //   })
    // );
  }

  searchAuditTrail(id: string) {
    return this.httpClient.post<any>(`${this.quote_apiURL}/auditIdNumber`, id)
      .pipe(
        map((responseData: any) => {
          return responseData;
        })
      );
  }
  searchAuditTrailByQuoteId(id: number): Observable<[]> {
    return this.httpClient.post<any>(`${this.quote_apiURL}/auditQuoteById`, id);
    // .pipe(
    //   map((responseData: any) => {
    //     return responseData;
    //   })
    // );
  }

  compareOffers(id: any) {
    return this.httpClient.post<any>(`${this.quote_apiURL}/auditOffers`, id)
      .pipe(
        map((responseData: any) => {
          return responseData;
        })
      );

  }

  // saveDebitOrderDate(data: any) {
  //   return this.http
  //     .post<any>(`${this.quote_apiURL}/saveDebitOrderDate`, data)
  //     .pipe(
  //       map((responseData: any) => {
  //         console.log(responseData)
  //         return responseData;
  //       })
  //     )

  // }

  emidBankTransactionsTest(data: QuoteModel) {
    this._isQuoteFound = false;
    return this.httpClient.post<any>(`${this.quote_apiURL}/emidBankTransactionsTest`, data)
      .pipe(
        map((responseData: any) => {
          this.setQuoteWorkbook(responseData);
          return responseData;
        })
      );
  }

  rejectCompliance(data: any) {
    this._isQuoteFound = false;
    return this.httpClient.post<any>(environment.apiUrl + '/loan/api/client/rejectCompliance', data)
      .pipe(
        map((responseData: any) => {
          //this.quoteWorkbook = responseData;
          return responseData;
        })
      );
  }

  approveCompliance(data: any) {
    return this.httpClient.post<any>(environment.apiUrl + '/loan/api/client/approveCompliance', data)
      .pipe(
        map((responseData: any) => {
          //this.quoteWorkbook = responseData;
          return responseData;
        })
      );
  }

  doLoanQuote(quoteId: any) {
    //this._isQuoteFound = false;
    return this.httpClient.post<any>(`${this.quote_apiURL}/doLoanQuote`, quoteId).pipe(
      catchError((returnedError) => {
        console.warn('SERVICE>> LMS Loan Quote error: ', returnedError);
        return throwError(() => returnedError);
      })
    );
  }

  doLoanCreate(data: any) {
    //this._isQuoteFound = false;
    return this.httpClient.post<any>(`${this.quote_apiURL}/doLoanCreate`, data,
      { context: new HttpContext().set(DEFAULT_TIMEOUTCTX, 130000) })
    .pipe(
      catchError((returnedError) => {
        console.error('SERVICE >> LMS Loan Create: ', returnedError);
          //Handle the error here
        return throwError(() => returnedError);    //Rethrow it back to component
      })
    );


    // .pipe(
    //   map((responseData: any) => {
    //     // this.quoteWorkbook = responseData;
    //     this.setQuoteWorkbook(responseData);
    //     return responseData;
    //   })
    // );
  }

  UpdateMyBenefitJournal(quoteId: number) {
    return this.httpClient.post<any>(`${this.quote_apiURL}/journalLoan`, quoteId, this.httpOptions).pipe();
  }

  completeLoanCreate(quoteId: any) {
    this._isQuoteFound = false;
    return this.httpClient.post<any>(`${this.quote_apiURL}/completeLoanCreate`, quoteId);
    // .pipe(
    //   map((responseData: any) => {
    //     // this.quoteWorkbook = responseData;
    //     this.setQuoteWorkbook(responseData);
    //     return responseData;
    //   })
    // );
  }


  // setExternalPaidUp(data: any){
  //   return this.http
  //   .post<any>(`${this.quote_apiURL}/setExternalPaidUp', data)
  //   .pipe(
  //       map((responseData: any) => {
  //           return responseData;
  //       })
  //   );
  // }

  //??
  // updateExternalLoan(data: any){
  //   return this.http
  //   .post<any>(`${this.quote_apiURL}/updateExternalLoan', data)
  //   .pipe(
  //       map((responseData: any) => {
  //           return responseData;
  //       })
  //   );

  // }


  // calcOffers(idNumber: any) {
  calcOffers(data: any) {
    return this.httpClient.post<any>(`${this.client_apiURL}/calcOffers`, data)
    .pipe(
      map((responseData: any) => {
        return responseData;
      }),
    );
  }

  reviewOffer(quoteId: any) {
    return this.httpClient.post<any>(`${this.quote_apiURL}/reviewOffer`, quoteId)
      .pipe(
        map((responseData: any) => {
          // this.quoteWorkbook = responseData;
          return responseData;
        })
      );
  }

  saveAllDocuments(data: any) {
    return this.httpClient.post<any>(`${this.quote_apiURL}/saveAllDocuments`, data)
      .pipe(
        map((responseData: any) => {
          //this.quoteWorkbook = responseData;
          return responseData;
        })
      );
  }

  updateAllDocuments(data: any) {
    return this.httpClient.post<any>(`${this.quote_apiURL}/updateAllDocuments`, data)
      .pipe(
        map((responseData: any) => {
          //this.quoteWorkbook = responseData;
          return responseData;
        })
      );
  }


  // private handleError(error: HttpErrorResponse) {
  //   let showdate = new Date().toLocaleString();
  //   if (error.type && error.type.valueOf() === 0) {
  //     // A client-side or network error occurred. Handle it accordingly.
  //     console.error('An error occurred:', error?.error);
  //     this.toastrService.error(`ERROR: ${ error?.message|| error?.error}`, `${ showdate; }: QUOTE - Communication error`, { positionClass: 'toast-top-center', tapToDismiss: true});
  //   } else {
  //     // The backend returned an unsuccessful response code.
  //     // The response body may contain clues as to what went wrong.
  //     this.toastrService.error(`Server ${ error.status; }:${ error.name; }: ${ error.error?.message; } `, `${ showdate; } `, { positionClass: 'toast-top-center', tapToDismiss: true});
  //     console.error(`Backend returned code ${ error.status; }, body was: `, error.error?.message);
  //   }
  //   this.loaderService.stopLoading();
  //   return of(error);
  // }

  saveFingerId(data: any) {
    return this.httpClient.post<any>(`${this.quote_apiURL}/saveFingerId`, data)
      .pipe(
        map((responseData: any) => {
          return responseData;
        })
      );
  }

  auditLogUserFingerVerification(idNum: any, enrollMsg: any) {
    return this.httpClient.post<any>(`${this.quote_apiURL}/auditLogUserFingerVerification`, {
      idNumber: idNum,
      verifyMessage: enrollMsg
    })
      .pipe(
        map((responseData: any) => {
          return responseData;
        })
      );
  }

  auditLogClientFingerVerification(idNum: any, enrollMsg: any) {
    return this.httpClient.post<any>(`${this.quote_apiURL}/auditLogClientFingerVerification`, {
      idNumber: idNum,
      verifyMessage: enrollMsg
    })
      .pipe(
        map((responseData: any) => {
          return responseData;
        })
      );
  }


  //RESET LOAN
  resetLoanApplication(data: any) {
    this._isQuoteFound = false;
    return this.httpClient.post<any>(`${this.quote_apiURL}/resetLoanApplication`, data)
      .pipe(
        map((responseData: any) => {
          this.setQuoteWorkbook(responseData);
          return responseData;
        })
      );
  }

  saveConfirmations(data: any) {
    return this.httpClient.post<any>(`${this.quote_apiURL}/saveConfirmations`, data);
    // .pipe(
    //   map((responseData: any) => {
    //     return responseData;
    //   })
    // );
  }

  saveNewLoanConfirmations(data: any) {
    return this.httpClient.post<any>(`${this.quote_apiURL}/saveNewLoanConfirmations`, data);
    // .pipe(
    //   map((responseData: any) => {
    //     return responseData;
    //   })
    // );
  }

}
