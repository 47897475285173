export class InternalloanModel {
  id: number;
  quoteId: number;
  loanReference: string;
  status: string;
  selected?: boolean;
  capital: number;
  term: number;
  outstandingBalance: number | null;
  canSettle?: boolean;
  installment: number;
  ignore?: boolean | false;
  ignoreReason?: string | null;
  createdOn: Date;
  createdBy?: string;
  updatedOn?: Date;
  updatedBy?: string;
  branchName: string;
  lastPaymentDate: Date;
  firstPaymentDate: Date;

  constructor() { }

}
