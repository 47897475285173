import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClientSearchComponent } from 'src/app/client-search/client-search.component';
import { SassaEditorComponent } from 'src/app/client-search/sassa-editor/sassa-editor.component';
// import { BranchEmployersModule } from "src/app/features/branch-employers/branch-employers.module";
import { BasicEmployersSearchComponent } from "src/app/features/branch-employers/basic-employer-form/basic-employer-form.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatSelectModule } from "@angular/material/select";
import { MatRadioModule } from "@angular/material/radio";
import { SalariedEditorComponent } from "src/app/client-search/salaried-editor/salaried-editor.component";
import { MatButtonModule } from "@angular/material/button";
import { SwitchdataComponent } from "./switchdata/switchdata.component";
import { BranchEmployerService } from '../core/services/branch-employer.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../core/services/lmsuser.service';
import { LoanReasonCodesService } from '../modules/loan-reason-codes/loan-reason-codes.service';
import { QuoteStoreService } from '../core/rest-services/quote-store.service';
import { LMSSelectOptionDirective } from '../shared/directives/lms-selectOption.directive';
import { MdbDropdownModule } from 'mdb-angular-ui-kit/dropdown';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatSelectModule,
    MatRadioModule,
    MatButtonModule,
    //BranchEmployersModule,
    BasicEmployersSearchComponent,
    SassaEditorComponent,
    SalariedEditorComponent,
    SwitchdataComponent,
    ClientSearchComponent,
    LMSSelectOptionDirective,
    MdbDropdownModule
  ],
  providers: [
    DatePipe,
    BranchEmployerService,
    ToastrService,
    UserService,
    LoanReasonCodesService,
    QuoteStoreService
  ]
})
export class ClientSearchModule { }
