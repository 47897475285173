export class PaySlipModel {
  id: number;
  quoteid: number;
  month: number | string;
  date!: string;
  payslipDate!: Date | null;
  grossIncome: number;
  deductions: number;
  nettIncome: number;
  otherIncome: number;
}


export class PaySlipModel1 {
  id?: number;
  quoteId: number;
  month?: number | 0;
  date: string | Date;
  payslipDate!: Date | null;
  wageFrequency: null;
  grossIncome: number;
  nettIncome!: number;
  deductions: number;
  otherIncome: number;
  createdOn!: Date | null;
  createdBy: string;
  updatedOn!: Date | null;
  updatedBy: string;
}
