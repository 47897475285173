/* eslint-disable no-inner-declarations */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-var */
/* eslint-disable no-self-assign */
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { ALLFINGERS, SKIPBIOMETRICSREASONS } from '@lmsConstants';
import { QuoteStoreService } from "src/app/core/rest-services/quote-store.service";
import { UserService } from 'src/app/core/services/lmsuser.service';
import { BiometricsModel } from 'src/app/core/models/biometrics.model';
import { PreAuthorisationModel } from 'src/app/core/models/pre-authorisation.model';
import { BiometricsSerivce } from 'src/app/core/rest-services/biometrics.service';
import { MdbModalModule, MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { AppUtilityService } from 'src/app/app-utility.service';
import { MdbTabComponent, MdbTabsModule } from 'mdb-angular-ui-kit/tabs';
import { LMSSelectOptionDirective } from "src/app/shared/directives/lms-selectOption.directive";
import { tap } from 'rxjs';
import { NgxPaginationModule, PaginationInstance } from 'ngx-pagination';
import { LmsPaginateComponent, PageCardheadComponent } from '@lmsShared';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';

declare let Fingerprint: any;

export interface CustomerEnrollmentEntity {
  id?: number;
  idNumber?: string;
  username?: string;
  fingerName?: FingerNameEnum;
  fmd?: string;
  created?: string;
  enrolledBy?: string;
  disabledAt?: string;
  disabledBy?: string;
}

type FingerNameEnum =
  | "LEFT_PINKY"
  | "LEFT_RING"
  | "LEFT_MIDDLE"
  | "LEFT_INDEX"
  | "LEFT_THUMB"
  | "RIGHT_PINKY"
  | "RIGHT_RING"
  | "RIGHT_MIDDLE"
  | "RIGHT_INDEX"
  | "RIGHT_THUMB";

// export namespace CustomerEnrollmentEntity {
//   const FingerNameEnum = {
//     LeftPinky: 'LEFT_PINKY' as FingerNameEnum,
//     LeftRing: 'LEFT_RING' as FingerNameEnum,
//     LeftMiddle: 'LEFT_MIDDLE' as FingerNameEnum,
//     LeftIndex: 'LEFT_INDEX' as FingerNameEnum,
//     LeftThumb: 'LEFT_THUMB' as FingerNameEnum,
//     RightPinky: 'RIGHT_PINKY' as FingerNameEnum,
//     RightRing: 'RIGHT_RING' as FingerNameEnum,
//     RightMiddle: 'RIGHT_MIDDLE' as FingerNameEnum,
//     RightIndex: 'RIGHT_INDEX' as FingerNameEnum,
//     RightThumb: 'RIGHT_THUMB' as FingerNameEnum
//   };
// }

const NO_FINGER = "NO_FINGER";

export interface SelectItem<T = any> {
  label?: string;
  value: T;
  styleClass?: string;
  icon?: string;
  title?: string;
  disabled?: boolean;
}

@Component({
  selector: 'app-biometrics-dialog',
  templateUrl: './biometrics-dialog.component.html',
  styleUrls: ['./biometrics-dialog.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    PageCardheadComponent,
    MdbModalModule,
    MdbTabsModule,
    MdbRippleModule,
    ReactiveFormsModule,
    LMSSelectOptionDirective,
    NgxPaginationModule,
    LmsPaginateComponent,
  ],
})
export class BiometricsDialogComponent implements OnInit, AfterViewInit {
  @ViewChildren(MdbTabComponent) childrenComponent: QueryList<MdbTabComponent>;
  @ViewChild('allFingersSelect', { read: ElementRef })
  set tabContentElement(element: ElementRef) {
    setTimeout(() => {
      if (element) {
        this.allFinger = element;
        this.getFingersList();
      }
    }, 4000);
  }

  allFinger: ElementRef;

  config: PaginationInstance = {
    id: 'pager',
    itemsPerPage: 5,
    currentPage: 1,
  };
  biometricsModel: BiometricsModel = new BiometricsModel();
  preauthorisationModel = {} as PreAuthorisationModel;
  biometricsDialogForm: FormGroup;

  biometricsInfo: any;
  userBiometricsInfo: any;

  readerOpen = true;
  captureOpen = false;
  verificationOpen = false;
  userVerificationOpen = false;
  disableOpen = false;
  deviceInfo = false;
  signatureOpen = false;

  disabled = true;
  biometrics: any;

  readQuality: string;
  status: string;
  sdk: any;
  numberReadings: number;
  deviceSerial: string;
  currentFormat: any;
  devicesList: string[];
  deviceID: string;
  deviceUIDType: string;
  deviceModality: string;
  deviceTechnology: string;
  sampleData: string[];
  radioFormat: number;
  enrollDisabled: boolean;
  stopAcquisitionDisabled: boolean;
  startAcquisitionDisabled: boolean;
  acquisitionStarted: boolean;
  enrollmentError?: string;
  enrollmentMessage?: string;
  clientEnrolled: CustomerEnrollmentEntity[] | [];
  userEnrolled: CustomerEnrollmentEntity[] | [];
  fingerNames: SelectItem[];
  selectedFingerName: any;
  selectedConsultantFingerName: any;
  verifyDisabled: boolean;

  biometricType: any;

  clientBiometricsFound: boolean;
  userBiometricsFound: boolean;
  continueApplicationDisabled: boolean;

  enableSkip: boolean;
  //reasonSelected = false ;
  //lstBiometricsSkipReasons = SKIPBIOMETRICSREASONS;
  //lstAllFingers = ALLFINGERS;
  lstAllFingers: any[];
  //lstDisableClientFingers = DISABLECLIENTFINGERS;
  lstVerifiedFingers: any;
  //disableReason: any;

  noBiometrics: boolean;
  lstAllFingersDisable: boolean;
  sampleDataVerify: string;
  lstSignedFingerDetails: any[];
  lstConsultantSignedFingerDetails: any[];

  preAgreement: any;
  isNokComplete: boolean;
  verifiedClient = false;
  verifiedUser = false;
  isCellVisible: boolean;
  bmVerified: boolean;
  submitted = false;
  skipBiometrics = false;

  userJobTitle: string = '';

  constructor(
    public biometricsService: BiometricsSerivce,
    public quoteStoreService: QuoteStoreService,
    public modalRef: MdbModalRef<BiometricsDialogComponent>,
    private userService: UserService,
    private global_utility: AppUtilityService
  ) {
    //this.biometricsInfo = this.preauthBiometricsData,
    //this.userBiometricsInfo = this.userBiometricsData,
    //this.biometricType = data.biometricType,
    this.readQuality = '';
    this.status = 'Select a device';
    this.sdk = new Fingerprint.WebApi;
    this.numberReadings = 0;
    this.deviceSerial = 'No Device Selected';
    this.currentFormat = Fingerprint.SampleFormat.Intermediate;
    this.devicesList = [];
    this.deviceUIDType = '';
    this.deviceID = '';
    this.deviceModality = '';
    this.deviceTechnology = '';
    this.sampleData = [];
    this.radioFormat = 2;
    this.enrollDisabled = true;
    this.stopAcquisitionDisabled = true;
    this.startAcquisitionDisabled = true;
    this.continueApplicationDisabled = true;
    this.enrollmentMessage = '';
    this.enrollmentError = '';
    this.clientEnrolled = [];
    this.userEnrolled = [];
    //this.selectedFingerName = this.lstAllFinger;
    this.verifyDisabled = true;

    this.sdk.onDeviceConnected = () => {
      // Detects if the deveice is connected for which acquisition started
      console.debug('Device Connected');
      this.status = 'Device Connected';
      this.readerOpen = true;
      this.getDeviceInfo();
    };
    this.sdk.onDeviceDisconnected = () => {
      this.acquisitionStarted = false;
      // Detects if device gets disconnected - provides deviceUid of disconnected device
      console.debug('Device disconnected');
      this.status = 'Device disconnected';
      this.showMessage("Scan your finger");
    };
    this.sdk.onCommunicationFailed = () => {
      this.acquisitionStarted = false;
      // Detects if there is a failure in communicating with U.R.U web SDK
      console.error('SDK Communinication Failed');
      this.status = 'Communinication Failed';
    };
    this.sdk.onErrorOccurred = () => {
      this.acquisitionStarted = false;
      console.debug('Error Occurred during capture');
      this.status = 'Error Occurred during capture';
    };

    this.sdk.onSamplesAcquired = (s: any) => {
      // Sample acquired event triggers this function
      console.debug('Sample Acquired');
      this.status = 'Sample Acquired';
      this.showMessage('Sample Acquired');
      this.sampleAcquired(s);
    };
    this.sdk.onQualityReported = (e: { quality: any }) => {
      console.group('QualityReported');
      console.debug('onQualityReported :: ');
      // Quality of sample aquired - Function triggered on every sample acquired
      this.readQuality = Fingerprint.QualityCode[e.quality];
      this.status = 'Quality Reported';
      this.showMessage('Quality Reported:'+this.readQuality);
      if (this.readQuality == 'Good') {
        this.numberReadings++;
      } else {
        this.global_utility.error(this.readQuality, 'Finger Quality Status');
        this.stopClientCapture();
      }
      console.debug('onQualityReported :: ', this.readQuality);
      console.groupEnd();
    };
  }

  showMessage(message){
    const state = document.getElementById('content-capture');
    //var _instance = this;
    //var statusWindow = document.getElementById("status");
    const x = state!.querySelectorAll("#status");
    if(x.length != 0){
      x[0].innerHTML = message;
    }
  }
  toggle_visibility(ids) {
    var state1 = document.getElementById('content-capture');
    const box = document.getElementById("qualityInputBox");
    (box! as HTMLInputElement).value = "";
    //onStop();
    for (var i=0;i<ids.length;i++) {
      var e = document.getElementById(ids[i]);
      if(i == 0){
        e!.style.display = 'block';
      }
      else{
        e!.style.display = 'none';
      }
    }
  }


  updateData(index: number, value: any) {
    this.sampleData[index] = value;
    console.debug(`sampleData[${index}] >>>`, this.sampleData[index]);
  }

  ngOnInit(): void {
    console.debug('Biometrics info >>', this.biometricsInfo);
    console.debug('Biometrics info >> ngOninit :: Fmt: ' + this.radioFormat);
    console.debug('Biometrics info >> ngOninit :: Biometric type: ' + this.biometricType);
  }

  public ngAfterViewInit(): void {
    this.childrenComponent.forEach((tab:MdbTabComponent) => {
      console.log('Tab', tab);
        tab.content!.context = { biometricsModel: this.biometricsModel, sdk: this.sdk };

        if(this.biometricType == 'user') {
          if(tab.title == 'User Capture' || tab.title == 'Disable User Fingers' || tab.title == 'Reader') {
          }
        }
        if(this.biometricType == 'client') {
          if(tab.title == 'User Capture' || tab.title == 'Disable User Fingers' || tab.title == 'Reader') {
          }
        }
        if (this.biometricType == 'client-offer') {

        }
      // Now you can access to the child component
    });
  }

  activechanged(event: any) {
    this.selectedFingerName = '';

    console.log('activeChanged index:', event.index, 'tab:',event.tab);
    switch (event.tab.title) {
    case 'Reader':
      this.getDevice();
      break;

    case 'Client Capture':
      this.getClientEnrolledFingers();
      this.getFingersList();
      break;
    case 'Client Verification':
      this.openCapture();
      this.getClientEnrolledFingers();
      this.openVerification();
      break;

    case 'User Verification':
      this.openCapture();
      this.getUserEnrolledFingers();
      this.openVerification();
      break;

    case 'User Capture':
      this.getUserEnrolledFingers();
      this.getFingersList();
      break;

    case 'Disable Client Fingers':
      if (!this.disableOpen && this.captureOpen) {
      }
      break;

    case 'Disable User Fingers':
      if (!this.disableOpen && this.captureOpen) {
      }
      break;
    default:
      break;
    }
  }

  // setPage(page: number) {
  //   // get new pager object for specified page
  //   this.pager = paginate({ totalItems: this.items.length, currentPage: page, pageSize: this.pageSize, maxPages: this.maxPages });

  //   // get new page of items from items array
  //   const pageOfItems = this.items.slice(this.pager.startIndex, this.pager.endIndex + 1);
  //   // call change page function in parent component
  //   this.changePage.emit(pageOfItems);
  // }

  getDevice() {
    setTimeout(() => {
      Promise.all([this.sdk.enumerateDevices()])
        .then((devices) => {
          // @ts-ignore
          this.deviceSerial = devices[0];
          console.debug('deviceSerial >>> ' + this.deviceSerial);
        })
        .catch((reason) => {
          console.error('Device connection failed!');
        });
    });
  }

  getDeviceInfo() {
    // @ts-ignore
    const deviceInfoPromise: Promise<DeviceInfo> = this.sdk.getDeviceInfo(this.deviceSerial);
    this.deviceInfo = true;

    deviceInfoPromise
      .then((successObj) => {
        console.error('Device Info >> ', successObj);
        this.deviceID = successObj.DeviceID;
        this.deviceTechnology = Fingerprint.DeviceTechnology[successObj.eDeviceTech];
        this.deviceUIDType = Fingerprint.DeviceUidType[successObj.eUidType];
        this.deviceModality = Fingerprint.DeviceModality[successObj.eDeviceModality];
      })
      .catch((error) => {
        console.error('Device Info Error');
      });
  }

  startCapture() {
    if(this.acquisitionStarted)
    {
      return;
    }

    this.startAcquisitionDisabled = true;
    this.stopAcquisitionDisabled = false;
    this.continueApplicationDisabled = true; //this.clientEnrolled.length != 4;
    if (this.selectedFingerName == 'NO_FINGER') {
      this.verifyDisabled = true;
    }
    console.debug('Selected radio format >>', this.radioFormat);

    const formats = {
      1: Fingerprint.SampleFormat.Raw,
      2: Fingerprint.SampleFormat.Intermediate,
      3: Fingerprint.SampleFormat.Compressed,
      5: Fingerprint.SampleFormat.PngImage,
    };

    this.currentFormat = formats[2] || Fingerprint.SampleFormat.Intermediate;
    console.debug('Starting capture with format >>', this.currentFormat);

    this.sdk.startAcquisition(this.currentFormat,this.deviceSerial).then(() => {
      this.acquisitionStarted = true;
      console.debug('Scan a finger');
      this.status = 'Scan a finger';
      this.showMessage(this.status);
    }).catch((error: any) => {
      console.error('Error starting capture >>');
      this.status = error.message;
      this.showMessage(this.status);
    });
  }

  stopClientCapture() {
    this.startAcquisitionDisabled = false;
    this.stopAcquisitionDisabled = true;
    this.verifyDisabled = false;
    this.enrollDisabled = false;
    this.enrollmentError = 'UNKNOWN';
    this.continueClientDisabled();
    console.debug('Stop Client Capture ::');

    try {
      this.sdk.stopAcquisition();
      console.debug('CLient Capturing stopped');
      this.status = 'Capturing stopped';
      this.numberReadings = 0;
    } catch (error: any) {
      console.error('Stop Client Capture ERROR');
      this.status = error.message;
    }
  }

  stopUserCapture() {
    this.startAcquisitionDisabled = false;
    this.stopAcquisitionDisabled = true;
    this.verifyDisabled = true;
    this.enrollDisabled = false;
    this.enrollmentError = 'UNKNOWN';
    this.continueUserDisabled();
    console.debug('Stop User Capture');
    this.sdk
      .stopAcquisition()
      .then(() => {
        console.debug('User Capturing stopped');
        this.status = 'Capturing stopped';
        this.numberReadings = 0;
      })
      .catch((error: any) => {
        console.error('User capture stop error');
        this.status = error.message;
      });
  }

  sampleAcquired(s: any) {
    console.group('Sample Acquired');
    console.debug('sampleAcquired:: ' + s);

    var samples = JSON.parse(s.samples);
    const finger = samples[0];

    console.debug('sampleAcquired currentFormat >> ', this.currentFormat);
    if (this.currentFormat == Fingerprint.SampleFormat.PngImage) {
      console.debug('processing png...');

      localStorage.setItem('imageSrc', 'data:image/png;base64,' + Fingerprint.b64UrlTo64(finger));
      const vDiv = document.getElementById('imagediv');
      vDiv!.innerHTML = '';

      const image = document.createElement('img');
      image.id = 'image';
      image.src = localStorage.getItem('imageSrc') || '';

      vDiv!.appendChild(image);
      this.sampleData[this.numberReadings - 1] = Fingerprint.b64UrlTo64(finger);
    } else if (this.currentFormat == Fingerprint.SampleFormat.Raw) {
      console.debug('processing raw...');
      var decodedDataRAW = JSON.parse(Fingerprint.b64UrlToUtf8(finger));

      localStorage.setItem('raw', Fingerprint.b64UrlTo64(decodedDataRAW.Data));
      const vRaw = document.getElementById('imagediv');
      vRaw!.innerHTML =
        '<div id="animateText" >RAW Sample Acquired <br>' + Date() + '</div>';

      this.sampleData[this.numberReadings] = Fingerprint.b64UrlTo64(decodedDataRAW.Data); //JSON.stringify(decodedData);
    } else if (this.currentFormat == Fingerprint.SampleFormat.Compressed) {
      console.debug('processing wsq...');
      var decodedDataWSQ = JSON.parse(Fingerprint.b64UrlToUtf8(finger));

      localStorage.setItem(
        'wsq',
        'data:application/octet-stream;base64,' +
          Fingerprint.b64UrlTo64(decodedDataWSQ.Data),
      );

      const vWsq = document.getElementById('imagediv');
      vWsq!.innerHTML = '<div id="animateText" style="display:none">WSQ Sample Acquired <br>' + Date() + '</div>';
      this.sampleData[this.numberReadings - 1] = Fingerprint.b64UrlTo64(decodedDataWSQ.Data); //JSON.stringify(decodedData);
    } else {
      // If sample acquired format is Intermediate- perform following call on object recieved
      // Get samples from the object - get 0th element of samples and then get Data from it.
      // It returns Base64 encoded feature set
      localStorage.setItem("intermediate", "");
      this.sampleData[this.numberReadings - 1] = finger.Data;
      localStorage.setItem("intermediate", this.sampleData[this.numberReadings - 1]);

      // localStorage.setItem("imageSrc", "");
      // localStorage.setItem("imageSrc", "data:image/png;base64," + Fingerprint.b64UrlTo64(finger));
      // const state = document.getElementById("content-capture");
      // if(state){
      //   state.style.display = 'block';
      //   var vDiv = document.getElementById('imagediv');
      //       vDiv!.innerHTML = "";
      //       var image = document.createElement("img");
      //       image.id = "image";
      //       image.className = "lmsfp"
      //       image.style.maxWidth = "50%";
      //       image.style.maxHeight = "55%";
      //       image.src = localStorage.getItem("imageSrc")!;
      //       vDiv!.appendChild(image);
      // }


      if (this.numberReadings == 4) {
        this.enrollDisabled = false;
        console.debug('Enabling enroll button');
      }

      if (this.numberReadings >= 1) {
        if (this.selectedFingerName === 'NO_FINGER') {
          this.verifyDisabled = true;
        } else {
          this.verifyDisabled = false;
        }
        console.debug('Enabling verify button');
      }
      console.groupEnd();
    }
    console.groupEnd();
  }

  enrollClient() {
    this.biometricsModel.fingerName = this.selectedFingerName;
    this.biometricsModel.fingerImage1 = this.sampleData[0];
    this.biometricsModel.fingerImage2 = this.sampleData[1];
    this.biometricsModel.fingerImage3 = this.sampleData[2];
    this.biometricsModel.fingerImage4 = this.sampleData[3];
    this.biometricsModel.enrolledBy = this.userService.userLoginName;
    this.biometricsModel.idNumber = this.biometricsInfo?.idNumber;
    //  'this.biometricsModel.skipReason' is assigned to itself.
    //  this.biometricsModel.skipReason = this.biometricsModel.skipReason;
    this.biometricsModel.branchName = this.userService.userBranchName;

    console.debug('Biometrics Model :: ', this.biometricsModel);

    // Subscribe to enrollClientFinger method of biometricsService
    this.biometricsService
      .enrollClientFinger(this.biometricsModel)
      .pipe(
        tap(() => {
          console.group('Client Enroll');
        }),
      )
      .subscribe({
        next: (response) => {
          console.debug('Enrollement Response >>>', response);

          // Assign errorCode and errorMessage from response to enrollmentError and enrollmentMessage respectively
          this.enrollmentError = response?.errorCode;
          this.enrollmentMessage = response?.errorMessage;

          if (this.enrollmentError != 'SUCCESS') {
            this.global_utility.error(
              `${this.enrollmentError} : ${this.enrollmentMessage}`,
              'Enrollment Error: ',
            );
            setTimeout(() => {
              this.numberReadings = 0;
              this.global_utility.info(
                `Please try again.`,
                'Enrollment Retry: ',
              );
            }, 2500);
            this.numberReadings = 0;
            this.sampleData = [];
            this.stopClientCapture();
          } else {
            // Reset selectedFingerName, stop client capture, and update enrolled and fingers list
            this.global_utility.success(
              `${this.enrollmentError} : ${this.enrollmentMessage}`,
              'Enrollment Status',
            );
            this.stopClientCapture();
            this.getClientEnrolledFingers();
            this.getFingersList();
            this.sampleData = [];
            this.selectedFingerName = '';

            // Enable/disable UI controls based on the number of enrolled fingers
            if (this.clientEnrolled.length === 3) {
              this.startAcquisitionDisabled = false;
              this.lstAllFingersDisable = true;
              // this.continueApplicationDisabled = false;
            } else {
              this.startAcquisitionDisabled = true;
              this.lstAllFingersDisable = false;
            }
          }
          console.groupEnd();
        },
        complete() {
          console.groupEnd();
        },
      });
  }

  verifyClientIntermediate() {
    // Extract first element of sampleData and convert it to a string
    if (this.sampleData.length == 0) {
      this.global_utility.warning(
        'Finger could not be Verified or no finger provided.',
        '',
      );
      return;
    }

    const sampleDataVerify = this.sampleData[0].toString();

    // Create a new biometricsModel object and assign values to its properties
    const biometricsModel = {
      fingerImage: sampleDataVerify,
      verifiedBy: this.userService.userLoginName,
      branchName:
        this.biometricsInfo?.userBranchName ||
        this.userBiometricsInfo?.branchName,
      idNumber:
        this.biometricsInfo?.idNumber || this.userBiometricsInfo?.idNumber,
    };

    // Pass the biometricsModel to the verifyClientFinger method of biometricsService
    this.biometricsService
      .verifyClientFinger(biometricsModel)
      .pipe(
        tap(() => {
          console.group('Verify Client Intermediate');
        }),
      )
      .subscribe({
        next: (response) => {
          console.debug(`Verify Client finger result >>`, response);
          this.enrollmentError = response?.errorCode;
          this.enrollmentMessage = response?.errorMessage;
          this.enrollDisabled = true;
          this.numberReadings = 0;
          this.sampleData = [];

          const toastString = 'Verify Finger Response: ' + this.enrollmentError;
          this.quoteStoreService.setVerifiedMessage(this.enrollmentMessage);

          if (this.biometricType == 'client-offer') {
            if (!this.enrollmentMessage || this.enrollmentMessage == '') {
              this.verifyDisabled = true;
            }

            this.quoteStoreService
              .auditLogClientFingerVerification(
                this.biometricsModel.idNumber,
                this.enrollmentMessage,
              )
              .subscribe();
          }

          if (this.enrollmentError == 'FAILURE') {
            this.global_utility.warning(this.enrollmentMessage, toastString);
          } else {
            this.global_utility.info(this.enrollmentMessage, toastString);
            this.continueApplicationDisabled = false;
            this.verifiedClient = true;
            this.stopClientCapture();
          }
          console.groupEnd();
        },
        complete() {
          console.groupEnd();
        },
      });
  }

  verifyUserIntermediate() {
    if (this.sampleData.length == 0) {
      this.global_utility.warning(
        'Finger could not be Verified or no finger provided.',
        '',
      );
      return;
    }

    this.sampleDataVerify = this.sampleData.slice(0, 1).toString();
    this.biometricsModel.fingerImage = this.sampleDataVerify;
    this.biometricsModel.verifiedBy = this.userService.userLoginName;

    if (this.biometricType == "client-offer") {
      this.biometricsModel.userName = this.userService.userLoginName;
    } else {
      this.biometricsModel.userName = this.userBiometricsInfo.loginName;
    }

    this.biometricsService
      .verifyUserFinger(this.biometricsModel)
      .pipe(
        tap(() => {
          console.group('Verify user finger');
        }),
      )
      .subscribe({
        next: (response) => {
          console.debug(`Verify User finger result >>`, response);
          this.enrollmentError = response?.errorCode;
          this.enrollmentMessage = response?.errorMessage;
          this.enrollDisabled = true;
          this.numberReadings = 0;
          this.sampleData = [];

          if (this.biometricType == 'client-offer') {
            this.quoteStoreService
              .auditLogUserFingerVerification(
                this.biometricsModel.idNumber,
                this.enrollmentMessage,
              )
              .subscribe();
          }

          const toastString = 'Verify Finger Response: ' + this.enrollmentError;
          if (this.enrollmentError == 'FAILURE') {
            this.global_utility.warning(this.enrollmentMessage, toastString);
          } else {
            this.global_utility.info(this.enrollmentMessage, toastString);
            this.verifiedUser = true;
            this.stopUserCapture();
          }
          console.groupEnd();
        },
        complete() {
          console.groupEnd();
        },
      });
  }

  verifyBranchManager() {
    if (
      this.biometricsModel.bmUserName === '' ||
      this.userBiometricsInfo.bmUserName === null
    ) {
      this.global_utility.warning(
        'Please Enter a valid Branch Manger Username.',
        '',
      );
      this.sampleData = [];
      this.stopUserCapture();
      return;
    }

    if (
      this.biometricsModel.bmUserName === this.userService.userLoginName &&
      this.userService.userJobTitle.jobTitle === 'Branch Consultant'
    ) {
      this.global_utility.warning(
        `Please enter a valid Branch Manager username.`,
        '',
      );
      this.sampleData = [];
      this.stopUserCapture();
      return;
    }

    if (this.sampleData.length == 0) {
      this.global_utility.warning(
        'Finger could not be Verified or no finger provided.',
        '',
      );
      return;
    }

    if (
      this.biometricsInfo.cellNumber == '0' ||
      this.biometricsInfo.hasCell == 'No'
    ) {
      this.biometricsModel.cellNoExist = 'No';
    } else {
      this.biometricsModel.cellNoExist = 'Yes';
    }

    this.sampleDataVerify = this.sampleData.slice(0, 1).toString();
    this.biometricsModel.fingerImage = this.sampleDataVerify;
    this.biometricsModel.fingerName = this.selectedFingerName;
    this.biometricsModel.verifiedBy = this.biometricsModel.bmUserName;
    this.biometricsModel.userName = this.userService.userLoginName;
    this.biometricsModel.branchName = this.userService.userBranchName;

    this.biometricsModel.skipReason = "No Biometrics, BM Override";

    //if(this.userService.userJobTitle.jobTitle === 'Branch Manager' && this.biometricsModel.bmUserName != ''){
    this.biometricsService
      .verifyUserFinger(this.biometricsModel)
      .pipe(
        tap(() => {
          console.group('Verify User Finger');
        }),
      )
      .subscribe({
        next: (response) => {
          console.debug(`Verify User[BM] finger result >>`, response);
          this.enrollmentError = response?.errorCode;
          this.enrollmentMessage = response?.errorMessage;
          this.enrollDisabled = true;
          this.numberReadings = 0;
          this.sampleData = [];

          const toastString =
            'Verify Finger Response: ' + this.enrollmentMessage;
          if (this.enrollmentError == 'FAILURE') {
            this.global_utility.warning(this.enrollmentMessage, toastString);
            this.bmVerified = false;
          } else {
            this.global_utility.info(this.enrollmentMessage, toastString);
            this.bmVerified = true;
            this.skipRegistration(this.biometricsModel.skipReason);
            //this.dialogRef.close(this.biometricsModel);
          }
          this.stopUserCapture();
          console.groupEnd();
        },
        complete() {
          console.groupEnd();
        },
      });
    // }
    // else {
    //   this.global_utility.warning("Branch Username is required " , '');
    // }
  }

  getClientEnrolledFingers() {
    console.log('Retrieving Client Enrolled Fingers...');

    this.biometricsModel.idNumber = this.biometricType == 'client' ? this.biometricsInfo.idNumber : this.biometricsInfo || this.userBiometricsInfo?.idNumber;
    const currentDate = new Date();

    this.biometricsService.enrolledFingersDetails(this.biometricsModel.idNumber)
      .pipe(tap(() => {
        console.group('Get Client Enrolled');
        console.debug('Retrieving Client Enrolled Fingers...');
      })).subscribe({
        next: (result) => {
          console.debug('Enrolled finger Data ($):', result);
          this.clientEnrolled = result;
          this.numberReadings = 0;

          if (this.biometricType == 'client' || this.biometricType == 'client-offer') {
            if (this.clientEnrolled.length == 4) {
              this.noBiometrics = false;
              this.lstAllFingersDisable = false;
              this.startAcquisitionDisabled = false;

              //if (this.startAcquisitionDisabled == false) {
              this.continueApplicationDisabled = true;
              // }
              // else {
              //   this.continueApplicationDisabled = false;
              // }
            } else if (
              this.clientEnrolled.length == 1 &&
              this.selectedFingerName == NO_FINGER
            ) {
              this.noBiometrics = false;
              this.lstAllFingersDisable = false;
              this.startAcquisitionDisabled = false;
              this.continueApplicationDisabled = false;
            } else {
              this.noBiometrics = true;
              this.lstAllFingersDisable = false;
              this.startAcquisitionDisabled = true;
            }
          }
          console.groupEnd();
        },
        complete() {
          console.groupEnd();
        },
      });
  }

  disableClientFinger(id: any) {
    this.biometricsModel.id = id;
    this.biometricsModel.username = this.userService.userLoginName;
    //this.biometricsModel.disableReason = this.biometricsModel.disableReason;

    this.biometricsService
      .disableFinger(this.biometricsModel)
      .subscribe((response) => {
        console.debug('Disable Finger >> Response: ', response);

        this.enrollmentError = response?.errorCode;
        this.enrollmentMessage = response?.errorMessage;

        this.getClientEnrolledFingers();
      });

    // this.biometricsService.updateAuditTrail(this.biometricsModel.idNumber, this.biometricsModel.disableReason, this.userService.userDisplayName).subscribe(
    //   (response) => {
    //     console.log("response ----", response);

    // });
  }

  disableAllClientFingers() {
    this.biometricsModel.idNumber = this.biometricsInfo?.idNumber;
    this.biometricsModel.username = this.userService.userLoginName;

    this.biometricsService
      .disableAllClientFingers(this.biometricsModel)
      .subscribe((responseData) => {
        console.log('Response', responseData);

        this.enrollmentError = responseData?.errorCode;
        this.enrollmentMessage = responseData?.errorMessage;

        this.getClientEnrolledFingers();
      });
  }

  enrollUser() {
    this.biometricsModel.fingerName = this.selectedFingerName;
    this.biometricsModel.fingerImage1 = this.sampleData[0];
    this.biometricsModel.fingerImage2 = this.sampleData[1];
    this.biometricsModel.fingerImage3 = this.sampleData[2];
    this.biometricsModel.fingerImage4 = this.sampleData[3];
    this.biometricsModel.fingerImage5 = this.sampleData[4];
    this.biometricsModel.fingerImage6 = this.sampleData[5];
    this.biometricsModel.fingerImage7 = this.sampleData[6];
    this.biometricsModel.fingerImage8 = this.sampleData[7];
    this.biometricsModel.fingerImage9 = this.sampleData[8];
    this.biometricsModel.fingerImage10 = this.sampleData[9];
    this.biometricsModel.enrolledBy = this.userService.userDisplayName;
    this.biometricsModel.loginName = this.userBiometricsInfo.loginName;
    this.biometricsModel.branchName =
      this.userBiometricsInfo?.branchName || this.biometricsInfo?.branchName;
    this.biometricsService
      .enrollUserFinger(this.biometricsModel)
      .subscribe((response) => {
        this.enrollmentError = response?.errorCode;
        this.enrollmentMessage = response?.errorMessage;
        // this.selectedFingerName = '';

        if (this.enrollmentError != 'SUCCESS') {
          this.global_utility.error(
            `${this.enrollmentError} : ${this.enrollmentMessage}`,
            'Enrollment Error: ',
          );
          setTimeout(() => {
            this.numberReadings = 0;
            this.global_utility.info(`Please try again.`, 'Enrollment Retry: ');
          }, 2500);
          this.numberReadings = 0;
          this.sampleData = [];
          this.stopUserCapture();
        } else {
          // Reset selectedFingerName, stop client capture, and update enrolled and fingers list
          this.global_utility.success(
            `${this.enrollmentError} : ${this.enrollmentMessage}`,
            'Enrollment Status',
          );
          this.stopClientCapture();
          this.getUserEnrolledFingers();
          this.getFingersList();
          this.sampleData = [];
          this.selectedFingerName = '';

          // Enable/disable UI controls based on the number of enrolled fingers
          if (this.userEnrolled.length === 3) {
            this.startAcquisitionDisabled = false;
            this.lstAllFingersDisable = true;
            // this.continueApplicationDisabled = false;
          } else {
            this.startAcquisitionDisabled = true;
            this.lstAllFingersDisable = false;
          }
        }

        this.stopUserCapture();
        this.getUserEnrolledFingers();
        this.getFingersList();
        this.sampleData = [];

        if (this.userEnrolled.length === 9) {
          this.startAcquisitionDisabled = false;
          this.lstAllFingersDisable = true;
        } else {
          this.startAcquisitionDisabled = true;
          this.lstAllFingersDisable = false;
        }
      });
  }

  getUserEnrolledFingers() {
    console.group('Get User Enrolled');
    console.debug('Retrieving User Enrolled Fingers...');

    // Set the userName based on the biometric type
    this.biometricsModel.userName = this.biometricType === 'client-offer' ? this.userService.userLoginName : this.userBiometricsInfo.loginName;
    this.biometricsModel.idNumber = this.userBiometricsInfo.idNumber;
    this.biometricsModel.branchName = this.userBiometricsInfo?.branchName || this.biometricsInfo?.branchName;

    this.biometricsService.enrolledUserFingersDetails(this.biometricsModel).subscribe({
      next:(result) => {
        console.debug('Enrolled Data', result);
        this.userEnrolled = result;
        this.numberReadings = 0;

        if (this.biometricType === 'user') {
        // Check if all fingers have been enrolled
          if (this.userEnrolled.length === 10) {
            this.noBiometrics = false;
            this.lstAllFingersDisable = true;
            this.startAcquisitionDisabled = false;
            this.continueApplicationDisabled = false;
            this.enrollDisabled = true;
            this.disableOpen = true;
            this.verifyDisabled = false;
          } else {
            this.openUserCapture();

            this.lstAllFingersDisable = this.userEnrolled.length == 10;
            this.enrollDisabled = this.userEnrolled.length == 10;
            this.verifyDisabled = this.userEnrolled.length == 0;
            this.noBiometrics = this.userEnrolled.length == 0;

            this.startAcquisitionDisabled = true;
            this.disableOpen = this.userEnrolled.length == 0;
          }
        }
        console.groupEnd();
      },
      complete: () => {
        console.groupEnd();
      }});
  }

  disableUserFinger(id: any) {
    this.biometricsModel.id = id;
    this.biometricsModel.username = this.userService.userLoginName;

    this.biometricsService.disableUserFinger(this.biometricsModel).subscribe((response) => {
      console.debug('disable User finger repsonse >>', response);

      this.enrollmentError = response?.errorCode;
      this.enrollmentMessage = response?.errorMessage;

      this.getUserEnrolledFingers();
    });
  }

  disableAllUserFingers() {
    this.biometricsModel.username = "AsdA";
    this.biometricsModel.disabledBy = this.userService.userLoginName;

    this.biometricsService.disableAllUserFingers(this.biometricsModel).subscribe((response) => {
      console.debug('disable ALL User finger repsonse>>', response);

      this.enrollmentError = response?.errorCode;
      this.enrollmentMessage = response?.errorMessage;

      this.getUserEnrolledFingers();
    });
  }

  openReader() {
    this.readerOpen = true;
    this.captureOpen = false;
    this.disableOpen = false;
    this.verificationOpen = false;
    this.userVerificationOpen = false;
    this.signatureOpen = false;
  }

  openCapture() {
    this.captureOpen = this.biometricType != "client-offer";
    this.readerOpen = false;
    this.disableOpen = false;
    this.verificationOpen = this.biometricType == "client-offer";
    this.signatureOpen = false;
    this.userVerificationOpen = false;
  }

  openUserCapture() {
    this.captureOpen = false;
    this.readerOpen = false;
    this.disableOpen = false;
    this.signatureOpen = false;
    this.verificationOpen = false;
    this.userVerificationOpen = this.biometricType === 'client-offer';
  }

  openDisableFinger() {
    const {
      disableOpen,
      captureOpen,
      readerOpen,
      verificationOpen,
      signatureOpen,
    } = this;

    this.disableOpen = true;
    this.captureOpen = false;
    this.readerOpen = false;
    this.verificationOpen = false;
    this.signatureOpen = false;
  }

  openVerification() {
    this.readerOpen = true;
    this.verificationOpen =
      this.captureOpen =
      this.disableOpen =
      this.startAcquisitionDisabled =
        false;
  }

  backToPreAuth() {
    this.modalRef.close(this.biometricsModel);
  }

  close() {
    this.modalRef.close();
  }

  continueApplication() {
    if (
      this.biometricsInfo.cellNumber == '0' &&
      this.biometricsInfo.hasCell == 'No'
    ) {
      if (this.clientEnrolled.length == 0) {
        this.enableSkip = true;
        this.isCellVisible = false;
        this.biometricsModel.verifiedMessage =
          this.quoteStoreService.verifiedMessage;
      } else if (this.clientEnrolled.length == 1) {
        this.enableSkip = true;
        this.isCellVisible = false;
        this.skipBiometrics = false;
        this.biometricsModel.cellNoExist = 'No';
        this.biometricsModel.verifiedMessage =
          this.quoteStoreService.verifiedMessage;
      } else {
        this.isCellVisible = false;
        this.biometricsModel.cellNoExist = 'No';
        this.biometricsModel.verifiedMessage =
          this.quoteStoreService.verifiedMessage;
        this.closeDialog();
      }
    } else {
      if (this.clientEnrolled.length == 0) {
        this.enableSkip = true;
        this.isCellVisible = false;
        this.biometricsModel.verifiedMessage =
          this.quoteStoreService.verifiedMessage;
      } else if (this.clientEnrolled.length == 1) {
        this.isCellVisible = true;
        this.biometricsModel.cellNoExist = 'Yes';
        this.selectedFingerName = NO_FINGER;
        this.biometricsModel.verifiedMessage =
          this.quoteStoreService.verifiedMessage;
        this.closeDialog();
      } else {
        this.isCellVisible = true;
        this.biometricsModel.cellNoExist = 'Yes';
        this.biometricsModel.verifiedMessage =
          this.quoteStoreService.verifiedMessage;
        this.closeDialog();
      }
    }
  }

  proceed() {
    this.verificationOpen =
      this.userVerificationOpen =
      this.readerOpen =
      this.captureOpen =
      this.disableOpen =
      this.startAcquisitionDisabled =
        false;
    this.signatureOpen = true;

    this.getClientEnrolledFingers();
    this.getUserEnrolledFingers();
  }

  getClientFingersCount() {
    return this.clientEnrolled.length < 4;
  }

  getUserFingersCount() {
    return this.userEnrolled.length < 4;
  }

  // getFingersList() {
  //   this.lstAllFingers.forEach((element: { value: string; }) => {
  //     if (this.biometricsModel.fingerName == element.value) {
  //       this.lstAllFingers = this.lstAllFingers.filter((item: { value: string; }) => item != element);
  //     }
  //   });
  // }

  getFingersList() {
    const allfiungercontrol = (this.allFinger || document.querySelector('allFingersSelect')) as ElementRef<HTMLSelectElement>;

    if (this.biometricsModel!.fingerName) {
      //allfiungercontrol.nativeElement.options && // eslint-disable-line @typescript-eslint/no-unnecessary-condition, @typescript-eslint/strict-boolean-expressions
      const fingerOpti = allfiungercontrol.nativeElement
        .options as HTMLOptionsCollection;
      Array.from(fingerOpti).forEach((element: { value: string }) => {
        if (this.biometricsModel.fingerName == element.value) {
          this.lstAllFingers = Array.from(fingerOpti).filter(
            (item: { value: string }) => item != element,
          );
        }
      });
    }
  }

  continueClientDisabled() {
    this.continueApplicationDisabled = this.clientEnrolled.length < 3;
  }

  continueUserDisabled() {
    this.continueApplicationDisabled = this.userEnrolled.length < 3;
  }

  backToOffers() {
    this.enableSkip = false;
  }

  continueLoan() {
    const hasCellNumber =
      this.biometricsInfo.cellNumber !== '0' ||
      this.biometricsInfo.hasCell == 'Yes';
    const hasNoSelectedFinger = this.selectedFingerName === NO_FINGER;

    this.enableSkip = true;
    this.isCellVisible = hasCellNumber && hasNoSelectedFinger;
  }

  onClientChange() {
    if (this.selectedFingerName === NO_FINGER) {
      this.startAcquisitionDisabled = true;
      this.skipBiometrics = true;
      return;
    }

    if (this.selectedFingerName == 'null' || this.selectedFingerName == '') {
      this.startAcquisitionDisabled = true;
    } else {
      this.startAcquisitionDisabled = false;
    }
  }

  onUserChange(event: any) {
    if (!this.startAcquisitionDisabled) {
      this.startAcquisitionDisabled = this.userEnrolled.length > 3;
    } else {
      this.startAcquisitionDisabled = !this.selectedFingerName;
    }
  }

  skipRegistration(data: any) {
    this.biometricsModel.skipReason = data;

    if (
      this.biometricsInfo.cellNumber != '0' &&
      this.biometricsInfo.hasCell == 'Yes' &&
      this.selectedFingerName == NO_FINGER
    ) {
      if (this.biometricsModel.skipReason != 'N/A') {
        this.submitted = true;
        this.enableSkip = true;
        this.biometricsModel.cellNoExist = "Yes";
        this.biometricsModel.idNumber = this.biometricsInfo?.idNumber;
        this.biometricsModel.fingerName = this.selectedFingerName;
        this.biometricsModel.enrolledBy = this.userService.userLoginName;
        this.biometricsService
          .skipBiometrics(this.biometricsModel)
          .subscribe((response) => {
            console.debug('Skip Registration NO_FINGER repsonse >>', response);
            this.enrollmentError = response?.errorCode;
            this.enrollmentMessage = response?.errorMessage;
            this.modalRef.close(this.biometricsModel);
          });
      } else {
        this.submitted = false;
      }
    } else {
      if (this.biometricsModel.skipReason != 'N/A') {
        this.enableSkip = true;
        this.biometricsModel.cellNoExist = "No";
        this.biometricsModel.idNumber = this.biometricsInfo?.idNumber;
        this.biometricsModel.fingerName = this.selectedFingerName;
        this.biometricsModel.enrolledBy = this.userService.userLoginName;
        this.biometricsService
          .skipBiometrics(this.biometricsModel)
          .subscribe((response) => {
            console.debug('Skip Registratioon reason >>', response);
            this.enrollmentError = response?.errorCode;
            this.enrollmentMessage = response?.errorMessage;
            return this.modalRef.close(this.biometricsModel);
          });
      } else {
        this.enableSkip = false;
      }
    }
  }

  fetchFinger(selectedFingerName: any) {
    this.selectedFingerName = selectedFingerName;

    const selectedFinger = this.clientEnrolled.find(
      (item) => item.fingerName === this.selectedFingerName,
    );

    if (selectedFinger) {
      this.biometricsService
        .enrolledSingleFingersDetails(selectedFinger.id)
        .subscribe((result) => {
          console.debug(`Fetch finger ${selectedFingerName} result >>`, result);
          this.lstSignedFingerDetails = result;
        });
    }
  }

  fetchConsultantFinger(selectedConsultantFingerName: string): void {
    this.selectedConsultantFingerName = selectedConsultantFingerName;
    const data: any = this.findUserEnrolledItem();

    this.biometricsService
      .enrolledConsultantSingleFingersDetails(data?.id)
      .subscribe((result: any) => {
        console.debug(
          `Fetch consultant finger ${selectedConsultantFingerName} result >>`,
          result,
        );
        this.lstConsultantSignedFingerDetails = result;
      });
  }

  findUserEnrolledItem(): any {
    return this.userEnrolled.find(
      (item: any) => item.fingerName === this.selectedConsultantFingerName,
    );
  }

  signDocument(selectedFingerName: any, selectedConsultantFingerName: any) {
    const foundFinger1 = this.lstSignedFingerDetails.find(
      (x) => x.fingerName === selectedFingerName,
    );
    const foundFinger2 = this.lstConsultantSignedFingerDetails.find(
      (x) => x.fingerName === selectedConsultantFingerName,
    );

    if (foundFinger1 || foundFinger2) {
      this.biometricsModel.id = this.quoteStoreService.quoteWorkbook.id;

      if (foundFinger1) {
        this.biometricsModel.fingerId = foundFinger1.id;
        if (selectedFingerName === NO_FINGER) {
          this.biometricsModel.fingerId = 0;
        } else {
          this.biometricsModel.fingerId = foundFinger1.id;
        }
      }

      if (foundFinger2) {
        this.biometricsModel.userFingerId = foundFinger2.id;
      }

      const saveFingerIdPromise = new Promise<boolean>((resolve, reject) => {
        this.quoteStoreService.saveFingerId(this.biometricsModel).subscribe({
          next: (res: any) => {
            console.debug(`Save FingerId result >>`, res);
            if (!res) {
              resolve(false);
              return;
            }

            this.quoteStoreService.quoteWorkbook.fingerId = res.fingerId;
            this.quoteStoreService.quoteWorkbook.userFingerId =
              res.userFingerId;
            resolve(true);
          },
          error: (err) => {
            this.global_utility.error(
              'Error on signature: ' + err,
              `Document signing error`,
            );
            resolve(false);
          },
        });
      });

      console.debug('Biometric Data >>', this.biometricsModel);
      saveFingerIdPromise.then((found: boolean) => {
        if (found) {
          this.closeDialog();
        }
      });
    } else {
      console.info('Finger not Found (Promise saveFinger)');
    }
  }

  doSigning() {
    if (this.isNokComplete) {
      this.closeDialog();
    }
  }

  onSkipReasonChange(event: any) {
    this.submitted = !event || event === "N/A";
  }

  closeDialog() {
    this.modalRef.close(this.biometricsModel);
  }

  closeScanFingers() {
    try {
      this.sdk.stopAcquisition();
      console.debug('Capturing stopped..');
      this.status = 'Capturing stopped';
      this.numberReadings = 0;
      this.modalRef.close();
    } catch (error: any) {
      console.error(error.message);
      this.status = error.message;
    }
  }

  ngOnDestroy(): void {
    this.closeScanFingers();
  }

  // fetchDisableReason(event:any){
  //   if(event.target.value != ''){
  //     this.reasonSelected = true;
  //     this.disableReason = event.target.value;
  //     this.biometricsModel.disableReason = this.disableReason;
  //   }
  //   else{
  //     this.reasonSelected = false;
  //     this.global_utility.error(`No reason selected.`, 'Disable Client Finger');
  //   }

  // }
}
