export class ClientSearchModel {
    idNumber:string;
    cellNumber: string;
 //   otpSms: string;
    orginationsOtp: string;
    employmentOtp: string;
    offerOtp: string;
    debiCheckOtp: string;
    otpOverride: string;
    canUseInformation: string;
    bureauConsent: string;
    employerConsent: string;
    // otp_stored:string;
    quoteId: number;
    debitOrderDate: string;

    username?: string;
}
