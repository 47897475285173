export class LMSNote {
  createdOn: Date;
  createdBy: string;
  noteStr: string;

  constructor(model: any = null) {
    this.createdOn = model.createdOn;
    this.createdBy = model.createdBy;
    this.noteStr = model.noteStr;
  }
}
