<div class="modal-content">
  <div class="modal-header">
    <div class="modal-title">
      <app-page-cardhead style="margin-top: -1.2rem;" [PageName]="'Next Of Kin Details'"
        [PageHeadWidth]="650"></app-page-cardhead>
    </div>
    <button class="btn btn-close" aria-label="close dialog" (click)="dialogRef.close()"></button>
  </div>

  <div class="modal-body">
    <div class="container-fluid">
      <div class="row center-screen">
        <table w650 id="clientHeader" class="table p-1 table-hover table-sm shadow-2-strong rounded-1">
          <caption>Client Details</caption>
          <tbody>
            <tr>
              <td>First names</td>
              <td>{{clientDetails.firstName}}</td>

              <td>Surname</td>
              <td>{{clientDetails.lastName}}</td>
            </tr>

            <tr>
              <td>ID number</td>
              <td>{{clientDetails.idNumber}}</td>

              <td>Cell number</td>
              <td>{{clientDetails.cellNumber}}</td>
            </tr>
          </tbody>
        </table>

        <div class="card">
          <div class="card-body px-1">
            <div class="row center-screen">
              <div *ngIf="hasNextOfKin; else editNextOfKinFrm">
                <ng-container *ngTemplateOutlet="displayNextOfKin"></ng-container>
              </div>
              <ng-template #displayNextOfKin>
                <!-- HAS NextOfKin details-->
                <div>
                  <!-- only One Next Of Kin-->
                  <table class="mt-2 table table-sm table-fixed" [style.width.px]="750">
                    <tbody>
                      <tr>
                        <td *ngFor="let nok of [nextOfKinModel.name1, nextOfKinModel.name2]; let i = index"
                          class="aligndetails" style="border-bottom-width: 0;">
                          <table class="table table-striped table-sm shadow-2-strong rounded-1 p-1">
                            <caption>Next of kin {{ i + 1 }}</caption>
                            <tbody class="field-4column-label">
                              <tr>
                                <td>First names</td>
                                <td>{{ i === 0 ? nextOfKinModel.name1 : nextOfKinModel.name2 }}</td>
                              </tr>
                              <tr>
                                <td>Surname</td>
                                <td>{{ i === 0 ? nextOfKinModel.surname1 : nextOfKinModel.surname2 }}</td>
                              </tr>
                              <tr>
                                <td>Relationship</td>
                                <td>{{ i === 0 ? nextOfKinModel.relationship1 : nextOfKinModel.relationship2 }}</td>
                              </tr>
                              <tr>
                                <td>Contact number</td>
                                <td>{{ i === 0 ? nextOfKinModel.contactNo1 : nextOfKinModel.contactNo2 }}</td>
                              </tr>
                              <tr>
                                <td>Email address</td>
                                <td>{{ i === 0 ? nextOfKinModel.email1 : nextOfKinModel.email2 }}</td>
                              </tr>
                              <tr>
                                <td>Address line 1</td>
                                <td>{{ i === 0 ? nextOfKinModel.addressLine1 : nextOfKinModel.line1 }}</td>
                              </tr>
                              <tr>
                                <td>Address line 2</td>
                                <td>{{ i === 0 ? nextOfKinModel.addressLine2 : nextOfKinModel.line2 }}</td>
                              </tr>
                              <tr>
                                <td>Suburb</td>
                                <td>{{ i === 0 ? nextOfKinModel.suburb1 : nextOfKinModel.suburb2 }}</td>
                              </tr>
                              <tr>
                                <td>Town</td>
                                <td>{{ i === 0 ? nextOfKinModel.town1 : nextOfKinModel.town2 }}</td>
                              </tr>
                              <tr>
                                <td>Area code</td>
                                <td>{{ i === 0 ? nextOfKinModel.areaCode1 : nextOfKinModel.areaCode2 }}</td>
                              </tr>
                              <tr>
                                <td>Province</td>
                                <td>{{ i === 0 ? nextOfKinModel.province1 : nextOfKinModel.province2 }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </ng-template>
              <ng-template #editNextOfKinFrm>
                <!-- NO Next Of Kin details-->
                <div *ngIf="!isReadOnly">
                  <!-- only One Next Of Kin-->
                  <table class="mt-2 mb-0 table table-sm table-fixed" [style.width.px]="850">
                    <tbody>
                      <tr>
                        <td *ngFor="let form of [nextOfKinForm, nextOfKin2Form]; let i = index" class="aligndetails"
                          style="border-bottom-width: 0;">
                          <form [formGroup]="form">
                            <table class="table table-striped table-bordered table-sm shadow-2-strong rounded-1">
                              <caption>Next of kin {{ i + 1 }}</caption>
                              <tbody class="field-4column-label">
                                <tr>
                                  <td>First names</td>
                                  <td>
                                    <input class="form-control" placeholder="First name/s *"
                                      [formControlName]="'name' + (i + 1)" required type="text" lmsNoSpecials>
                                    <div *ngIf="form?.get('name' + (i + 1))?.errors?.required && submitted">
                                      <small class="text-danger">
                                        First names are required
                                      </small>
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td>Surname</td>
                                  <td>
                                    <input class="form-control" placeholder="Surname *"
                                      [formControlName]="'surname' + (i + 1)" required type="text" lmsNoSpecials>
                                    <div *ngIf="form?.get('surname' + (i + 1))?.errors?.required && submitted">
                                      <small class="text-danger">
                                        Surname is required
                                      </small>
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td>Relationship</td>
                                  <td>
                                    <select class="lmsselect" placeholder="Relationship *"
                                      [formControlName]="'relationship' + (i + 1)" lmsSelectOption
                                      [constantName]="'RELATIONSHIP'" [setFirstAsValue]="true" required>
                                    </select>
                                    <div *ngIf="form?.get('relationship' + (i + 1))?.errors?.required && submitted">
                                      <small class="text-danger">
                                        Relationship is required
                                      </small>
                                    </div>
                                  </td>

                                </tr>

                                <tr>
                                  <td>Contact number</td>
                                  <td>
                                    <input class="form-control" placeholder="Contact number *"
                                      [formControlName]="'contactNo' + (i + 1)" required maxlength="10"
                                      (keypress)="($event.charCode >= 48 && $event.charCode < 58)">
                                    <div *ngIf="form?.get('contactNo' + (i + 1))?.errors?.required && submitted">
                                      <small class="text-danger">
                                        Contact number is required
                                      </small>
                                    </div>
                                    <div *ngIf="form?.get('contactNo' + (i + 1))?.errors?.pattern">
                                      <small class="text-danger">
                                        Needs to be 10 digits and must start with a 0.
                                      </small>
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td>Email address</td>
                                  <td>
                                    <input class="form-control" placeholder="Email address"
                                      [formControlName]="'email' + (i + 1)">
                                  </td>
                                </tr>

                                <tr>
                                  <td>Address line 1</td>
                                  <td>
                                    <input class="form-control" placeholder="Address line 1 *"
                                      [formControlName]="(i==0)?'addressLine1':'line1'" required type="text"
                                      lmsNoSpecials>
                                    <div
                                      *ngIf="form?.get((i==0)?'addressLine1':'line1')?.errors?.required && submitted">
                                      <small class="text-danger">
                                        Address line 1 is required
                                      </small>
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td>Address line 2</td>
                                  <td>
                                    <input class="form-control" [formControlName]="(i==0)?'addressLine2':'line2'"
                                      placeholder="Address line 2" type="text" lmsNoSpecials>
                                  </td>
                                </tr>

                                <tr>
                                  <td>Suburb</td>
                                  <td>
                                    <input class="form-control" placeholder="Suburb *"
                                      [formControlName]="'suburb' + (i + 1)" required type="text" lmsNoSpecials>
                                    <div *ngIf="form?.get('suburb' + (i + 1))?.invalid && submitted">
                                      <small *ngIf="form?.get('suburb' + (i + 1))?.errors?.required"
                                        class="text-danger">
                                        Suburb is required
                                      </small>
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td>Town</td>
                                  <td>
                                    <input class="form-control" placeholder="Town/City *"
                                      [formControlName]="'town' + (i + 1)" required type="text" lmsNoSpecials>
                                    <div *ngIf="form?.get('town' + (i + 1))?.errors?.required && submitted">
                                      <small class="text-danger">
                                        Town/City is required
                                      </small>
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td>Area code</td>
                                  <td>
                                    <input class="form-control" placeholder="Area code *"
                                      [formControlName]="'areaCode' + (i + 1)" maxlength="4"
                                      (keypress)="($event.charCode >= 48 && $event.charCode < 58)" required>
                                    <div *ngIf="form?.get('areaCode' + (i + 1))?.errors?.required && submitted">
                                      <small class="text-danger">
                                        Area code is required
                                      </small>
                                    </div>
                                    <div *ngIf="form?.get('areaCode' + (i + 1))?.errors?.pattern">
                                      <small class="text-danger">
                                        Invalid Area Code
                                      </small>
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td>Province</td>
                                  <td>
                                    <select class="lmsselect" [formControlName]="'province' + (i + 1)"
                                      placeholder="Province *" lmsSelectOption [constantName]="'PROVINCES'"
                                      [setFirstAsValue]="true" required></select>
                                    <div *ngIf="form?.get('province' + (i + 1))?.errors?.required && submitted">
                                      <small class="text-danger">
                                        Province is required
                                      </small>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </form>
                        </td>

                      </tr>
                    </tbody>
                  </table>
                  <div class="d-flex justify-content-center">
                    <div class="form-check-reverse form-check-inline mt-2 mb-1">
                      <!-- <input
                        mdbInput
                        #OneNok
                        class="form-check-input"
                        type="checkbox"
                        aria-label="Only ONE next of kin is required"
                        [checked]="!isSassaClient && nextOfKinModel.onlyOneNextOfKin"
                        [disabled]="isSassaClient"
                        (change)="nextOfKinModel.onlyOneNextOfKin = isSassaClient ? false : !nextOfKinModel.onlyOneNextOfKin" />
                    -->
                      <label for="OneNok" class="form-check-label pt-1 me-3">TWO next of kin are required.</label>
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
          <div class="card-footer flex-container">
            <button class="btn btn-primary" (click)="close()"> Close </button>
            <button class="btn btn-primary" *ngIf="!isReadOnly && !hasNextOfKin" (click)="onSaveSubmit()"> Save
            </button>
            <button class="btn btn-primary" *ngIf="hasNextOfKin && !isReadOnly" (click)="editNextOfKin()"> Edit
            </button>
            <!-- clientStatus === 'REPEAT' && -->
            <button *ngIf="!isReadOnly && (hasNextOfKin || hasNextOfKin2)" type="button" class="btn btn-primary ms-4"
              (click)="confirmNextOfKinDetails()"><i class="fas fa-thumbs-up fa-fw me-1"></i> Confirm </button>
          </div>
        </div>
      </div>
    </div>
  </div>