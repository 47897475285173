<app-page-cardhead [PageName]="'Menu Administration'" [PageHeadWidth]=600></app-page-cardhead>
<div class="form-container center-screen">
  <form [formGroup]="menuForm">

    <mat-tab-group class="m-1">
      <mat-tab label="Menu Details">
        <div class="center-screen">
          <table class="table table-striped table-light table-hover  shadow-2-strong m-1 p-1 shadow-inner table-sm"
            style="width:770px; overflow-y: auto;">
            <caption>Menu Details</caption>
            <tbody class="field-4column-label">
              <tr>
                <td>Menu Name</td>
                <td>
                  <input formControlName="menu" [(ngModel)]="menuModel.menu" required>
                  <div *ngIf="menuForm.controls.menu.errors?.required && submitted" class="error-label">
                    Menu name is required.
                  </div>
                </td>
              </tr>
              <tr>
                <td>Component</td>
                <td>
                  <input formControlName="menu_component" [(ngModel)]="menuModel.component" required>
                  <div
                    *ngIf="menuForm.controls.menu_component.errors?.required && submitted && isComponentRequired == true"
                    class="error-label">
                    Component is required.
                  </div>

                </td>
              </tr>
              <tr>
                <td>Submenus</td>
                <td>
                  <mat-checkbox (change)="hasSubmenuOnChangeEvent($event)" class="example-margin"
                    [checked]="menuModel.hasSubmenu" [(ngModel)]="menuModel.hasSubmenu" [color]="color"
                    formControlName="hasSubmenu">
                  </mat-checkbox>
                </td>
              </tr>
              <tr>
                <td><label for="selectedRole">Role</label></td>
                <td>
                  <mat-select style="width: 99%" formControlName="selectedJobTitles" multiple required
                    [(ngModel)]="this.menuModel.selectedJobTitles">
                    <mat-option *ngFor="let title of jobTitles" [value]="title">{{title.jobTitle}}</mat-option>
                  </mat-select>

                  <div *ngIf="menuForm.controls.selectedRole.errors?.required && submitted" class="error-label">
                    Job title is required.
                  </div>
                </td>
              </tr>
              <tr>
                <td>Trigger</td>
                <td>
                  <input formControlName="trigger" [(ngModel)]="menuModel.trigger" required>
                  <div *ngIf="menuForm.controls.trigger.errors?.required && submitted && isTriggerRequired == true"
                    class="error-label">
                    Trigger is required.
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </mat-tab>

      <mat-tab label="Sub Menus">
        <div class="d-flex p-3 justify-content-end">
          <button class="btn btn-primary btn-sm btn-add-row" mdbRipple type="button" (click)="addRow()">Add Sub
            menu</button>
        </div>
        <div class="center-screen p-2" style="min-height: 200px !important; max-height: 350px">
          <table mat-table [dataSource]="dataSource"
            class="shadow-2-strong rounded-1 p-1 shadow-2-strong m-1 p-1 w-100">
            <ng-container [matColumnDef]="col.key" *ngFor="let col of columnsSchema">
              <th mat-header-cell *matHeaderCellDef columnCssClassName="table-center-header" color="primary">
                {{ col.label }}
              </th>
              <td mat-cell *matCellDef="let element">
                <div [ngSwitch]="col.type" *ngIf="!element.isEdit">
                  <div class="btn-edit" *ngSwitchCase="'isEdit'">
                    <button class="btn btn-floating btn-icon fs-5 fas" mdbRipple (click)="editSubmenu(element)">
                      <i class="fas fa-xs fa-fw fa-pencil"></i>
                    </button>
                    <button class="btn btn-floating btn-icon fs-5 fas" mdbRipple (click)="removeRow(element)">
                      <i class="fas fa-xs fa-fw fa-trash-can"></i>
                    </button>
                  </div>
                  <span *ngSwitchDefault>
                    {{element[col.key]}}
                  </span>
                </div>
                <div [ngSwitch]="col.type" *ngIf="element.isEdit">
                  <div *ngSwitchCase="'isEdit'">
                    <button class="btn btn-floating btn-icon fs-5 fas" mdbRipple (click)="modifyMenu(element)">
                      <i class="fas fa-xs fa-fw fa-floppy-disk"></i>
                    </button>
                  </div>

                  <input *ngSwitchDefault (input)="removeTrailingSpaces(element)" style="width: 95%" [type]="col.type"
                    matInput required [(ngModel)]="element[col.key]" value=" {{element[col.key]}}"
                    [formControlName]="col.key" />
                </div>
              </td>
            </ng-container>
            <tr class="table-center-header" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row;  let even = even; columns: displayedColumns" [ngClass]="{gray: even}">
            </tr>
          </table>
        </div>
      </mat-tab>
    </mat-tab-group>
    <div class="center-screen flex-container my-2">
      <button class="btn btn-primary me-2" mdbRipple (click)="close()">
        Close </button>
      <button class="btn btn-primary" mdbRipple (click)="saveChanges()">Save Changes</button>
    </div>
  </form>
</div>
