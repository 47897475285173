import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { RiskScoreModel } from '@lmsModels';
import { PageCardheadComponent } from '@lmsShared';

@Component({
  selector: 'app-add-risk-score',
  templateUrl: './add-risk-score.component.html',
  styleUrls: ['./add-risk-score.component.css'],
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, PageCardheadComponent],
})
export class AddRiskScoreComponent implements OnInit {
  riskScoreModel: RiskScoreModel = new RiskScoreModel();
  userProfile: Keycloak.KeycloakProfile = {};
  addRiskScoreForm: FormGroup;
  submitted: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AddRiskScoreComponent>,
    private formBuilder: FormBuilder,
  ) {}

  public lstParameterTypes: Array<any> = [
    { value: 'COUNTRY', parameterType: 'Country' },
    { value: 'PRODUCT', parameterType: 'Product' },
    { value: 'CUSTOMER_TYPE', parameterType: 'Customer Type' },
    { value: 'RANGE', parameterType: 'Range' },
    { value: 'WEIGHTING', parameterType: 'Weighting' },
  ];

  ngOnInit(): void {
    this.addRiskScoreForm = this.formBuilder.group({
      parameterType: [this.riskScoreModel.parameterType, [Validators.required]],
      description: [this.riskScoreModel.description, [Validators.required]],
      score: [this.riskScoreModel.score, [Validators.required]],
    });
  }

  saveRiskScore() {
    this.submitted = true;
    var isValidData = true;

    if (this.addRiskScoreForm.invalid) {
      isValidData = false;
      console.log('Form is invalid...');
    }
    return this.dialogRef.close(this.addRiskScoreForm.value);
  }

  close() {
    this.dialogRef.close();
  }

  get validateRiskScoreValues() {
    return this.addRiskScoreForm.controls;
  }
}
