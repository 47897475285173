<div class="modal-content">
  <div class="modal-header">
    <div class="modal-title">
      <app-page-cardhead style="margin-top: -1.2rem;" [PageName]="'Client Documents'"
        [PageHeadWidth]=600></app-page-cardhead>
    </div>
    <button class="btn btn-close" color="red" aria-label="close dialog" (click)="dialogRef.close()"></button>
  </div>

  <div class="modal-body">
    <div class="container-fluid">
      <div class="card">
        <div class="card-body p-1">
          <app-client-header #clientHeaderComponent align="end" [clientDetails]="clientShow" [showQuote]="!isReadOnly"
            style="width:800px"></app-client-header>
          <div *ngIf="isDocumentLoading" class="fade show my-1" style="text-align: center; height: 30px">
            <i class="spinner-grow spinner-grow-sm"></i>
            <span class="me-2"> Loading documents, please wait! </span>
          </div>
          <div *ngIf="!isDocumentLoading">
            <table w950 class="table p-1 table-striped table-auto table-hover table-sm shadow-1-strong rounded-1">
              <caption>Document List</caption>
              <thead>
                <tr>
                  <th scope="col">Document</th>
                  <th>File name</th>
                  <th scope="col">Upload date</th>
                  <th scope="col">Uploaded by</th>
                  <th scope="col" style="width: 40px;">View</th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let doc of lstDocuments | paginate: config">
                  <td>{{doc.documentType}}</td>
                  <td style="text-overflow: ellipsis;">{{doc.originalFileName}}</td>
                  <td>{{covertDate(doc.uploadDate)}}</td>
                  <td>{{doc.userName}}</td>
                  <td>
                    <button class="btn btn-floating btn-icon fs-5 fas" mdbRipple (click)="onDownloadPdf(doc.fileName)">
                      <i class="fas fa-sm fa-fw fa-eye"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <lms-paginate [config]="config"></lms-paginate>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
