import { BranchModel } from './branch.model';
export class UserAuditTrailModel{
  id:number;
  userBranch!: any;
  action: string;
  actionDatetime: any;
  previousData:string;
  newData: string;
  branch!: BranchModel;
  changedBy!: any;
}
