<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<div class="modal-content">
  <div class="modal-header">
    <div class="modal-title center-fields">
      <app-page-cardhead style="margin-top: -1.1rem;"
        [PageName]="userVerificationOpen ? 'Verifiy User Biometrics': verificationOpen ? 'Verifiy Client Biometrics':'Enroll Biometrics'"
        [PageHeadWidth]="500"></app-page-cardhead>
    </div>
  </div>

  <div class="modal-body">
    <div class="card">
      <div class="card-body p-1">

        <mdb-tabs (activeTabChange)="activechanged($event)" [pills]="true" [fill]="true">

          <mdb-tab *ngIf="readerOpen" title="Reader">
            <ng-template mdbTabContent title="Reader">
              <div class="text-center justify-content-center">
                <h6 class="mt-2">Active Reader:</h6>
                <input id="device" class="form-control ms-auto me-auto text-center w-75" readonly
                  [(ngModel)]="deviceSerial">
              </div>

              <div class="flex-container">
                <button class="btn btn-sm btn-primary" type="button" mdbRipple [rippleUnbound]="true" rippleColor="info"
                  id="refreshList" (click)="getDevice()">
                  Refresh List</button>
                <button class="btn btn-sm btn-primary" type="button" mdbRipple [rippleUnbound]="true" rippleColor="info"
                  id="capabilities" (click)="getDeviceInfo()"> Reader Info</button>
              </div>
              <div *ngIf="deviceInfo" class="my-2">
                <label>Device ID:</label> {{deviceSerial}}
                <br>
                <label>Uid Type:</label>
                {{deviceUIDType}}
                <br>
                <label>Device Tech:</label>
                {{deviceTechnology}}
                <br>
                <label>Device Modality:</label>
                {{deviceModality}}
              </div>
            </ng-template>
          </mdb-tab>

          <mdb-tab *ngIf="biometricType == 'client-offer'" title="Client Verification">
            <ng-template mdbTabContent title="Client Verification">
              <div id="Scores" class="scores align-items-center justify-content-center my-2">
                <label for="qualityInputBox" class="me-2 fs-5"> Scan Quality </label>
                <input class="form-control fs-6 w-50" type="text" id="qualityInputBox" readonly
                  [(ngModel)]="readQuality" style="background-color:#DCDCDC;">
              </div>
              <div class="flex-container">
                <button type="button" class="btn btn-sm  btn-primary" [disabled]="verifyDisabled"
                  (click)="verifyClientIntermediate()"><small>2.</small> Verify</button>
              </div>
              <div class="content-capture mb-2">
                <div *ngFor="let data of sampleData.slice(0,4); let sampleDataIndex = index;">
                  <label class="my-1" [for]="'sampleData' + sampleDataIndex">Finger Data {{ sampleDataIndex + 1 }}
                  </label>
                  <label sampleDataIndex>: Captured success</label>
                </div>

                <div *ngFor="let totFingers of clientEnrolled.slice(0,4); let totalFingersEnrolled = index;">
                  <table class="table table-striped table-light table-hover table-sm mb-3 w-75">
                    <tbody class="field-4column-label">
                      <tr>
                        <td>Finger Already Enrolled</td>
                        <td>{{totFingers.fingerName}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="mt-1 flex-container">
                  <button class="btn btn-sm  btn-primary" type="button" color="primary"
                    (click)="startCapture()"><small>1.</small> Scan</button>
                  <button class="btn btn-sm  btn-primary" type="button" color="primary" (click)="closeScanFingers()">
                    Close
                  </button>
                </div>
              </div>


              <div *ngIf="captureOpen && biometricType != 'client-offer'">
                <div id="Scores" class="scores align-items-center justify-content-center my-2">
                  <label for="qualityInputBox" class="me-2 fs-5"> Scan Quality </label>
                  <input class="form-control fs-6 w-50" type="text" id="qualityInputBox" readonly
                    [(ngModel)]="readQuality" style="background-color:#DCDCDC;">
                </div>

                <select [disabled]="lstAllFingersDisable" class="mx-4 lmsselect" style="max-width: 150px;"
                  [(ngModel)]="selectedFingerName" (change)="onClientChange()" required>
                  <option value=""> --- Select ---</option>
                  <option *ngFor="let finger of lstAllFingers;let fingerIndex = index;" [value]="finger.value">
                    {{finger.title}}
                  </option>
                </select>
                <button class="btn btn-sm btn-primary mx-2" type="button" color="primary"
                  [disabled]="enrollDisabled || numberReadings < 4 || lstAllFingersDisable || clientEnrolled.length === 4 "
                  (click)="enrollClient()">Enroll
                  Finger</button>
                <button class="btn  btn-sm btn-primary me-2" type="button" [disabled]="verifyDisabled"
                  (click)="verifyClientIntermediate()"><small>2.</small> Verify</button>

                <div *ngIf="!selectedFingerName.includes(this.selectedFingerName) || selectedFingerName === ''"
                  style="margin-left: 19%; color: red" [ngClass]="'error'">
                  Please select a valid option.
                </div>

                <div class="content-capture mx-2">
                  <div *ngFor="let data of sampleData.slice(0,4); let sampleDataIndex = index;" style="margin-left:2%;">
                    <label class="mt-2" [for]="'sampleData' + sampleDataIndex">Finger Data {{ sampleDataIndex + 1 }}
                    </label>
                    <label sampleDataIndex>: Captured success</label>
                  </div>

                  <div *ngFor="let totFingers of clientEnrolled.slice(0,4); let totalFingersEnrolled = index;">
                    <table class="table table-striped table-light table-hover table-sm mb-3 w-75 ">
                      <tbody class="field-4column-label">
                        <tr>
                          <td>Finger Already Enrolled</td>
                          <td>{{totFingers.fingerName}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!-- Enable Skip Begin -->
                  <div *ngIf="enableSkip">
                    <div>
                      <table class="table table-striped table-light table-hover table-sm mb-3 w-75">
                        <tbody class="field-4column-label">
                          <tr>
                            <td>Branch Manager Username:
                            </td>
                            <td class="ms-2">
                              <input placeholder="BM Username" [(ngModel)]="biometricsModel.bmUserName" required>
                              <button class="btn btn-primary mx-2" type="submit" color="primary"
                                (click)="verifyBranchManager()"> Verify
                                Biometrics
                              </button>
                              <!-- <div *ngIf="biometricsModel.bmUserName == ''" class="error-label">
                                Please enter a valid Branch Manager Username.
                              </div> -->
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Verify Finger Response:
                            </td>
                            <td>
                              {{enrollmentMessage}}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <!-- <div *ngIf="isCellVisible ">
                      <select style="max-width: 150px; margin-left: 20%;" [(ngModel)]="biometricsModel.skipReason"
                      (change)="onSkipReasonChange($event)" required>
                        <option value=""> --- Select ---</option>
                        <option *ngFor="let skipReasons of lstBiometricsSkipReasons" [value]="skipReasons.value">
                          {{skipReasons.description}}
                        </option>
                      </select>
                      <button class="btn btn-primary" type="submit" color="primary" style="margin-left: 10px;" *ngIf="enableSkip"
                        (click)="skipRegistration(biometricsModel.skipReason)" > Continue Application
                      </button>
                      <button class="btn btn-primary" type="submit" color="primary" style="margin-left: 10px;" (click)="backToOffers()">
                        Try
                        Again
                      </button>
                      <div style="margin-left: 20%;" *ngIf="biometricsModel.skipReason == '' || !submitted" class="error-label">
                        Please select a valid Skip Reason.
                      </div>
                    </div> -->
                  </div>

                  <div class="flex-container">
                    <button class="btn btn-sm btn-primary" type="button" color="primary"
                      [disabled]="startAcquisitionDisabled" (click)="startCapture()"><small>1.</small> Scan</button>

                    <!-- <button class="btn btn-primary" type="button" color="primary" style="margin-left: 10px;"
                      [disabled]="stopAcquisitionDisabled || getClientFingersCount()" (click)="stopClientCapture()">Stop</button> -->

                    <button [disabled]="continueApplicationDisabled" class="btn btn-sm btn-primary" color="primary"
                      (click)="continueApplication()"> Continue Application
                    </button>

                    <button [disabled]="!skipBiometrics" class="btn btn-sm btn-primary" color="primary"
                      (click)="continueLoan()">
                      Skip Biometrics
                    </button>

                    <button class="btn btn-sm btn-primary" type="button" color="primary" (click)="closeScanFingers()">
                      Close
                    </button>

                    <!-- <button [hidden]="noBiometrics" mat-raised-button type="submit" color="primary" style="margin-left: 10px;"
                      (click)="backToPreAuth()">
                      Back
                    </button> -->
                  </div>
                </div>
              </div>
            </ng-template>
          </mdb-tab>

          <mdb-tab *ngIf="biometricType == 'client-offer'" title="User Verification">
            <ng-template mdbTabContent title="User Verification">
              <div class="my-1">
                <div id="Scores" class="scores align-items-center justify-content-center my-2">
                  <label for="qualityInputBox" class="me-2 fs-5"> Scan Quality </label>
                  <input class="form-control fs-6 w-50" type="text" id="qualityInputBox" readonly
                    [(ngModel)]="readQuality" style="background-color:#DCDCDC;">
                </div>

                <div class="flex-container">
                  <button type="button" class="btn btn-sm  btn-primary" [disabled]="verifyDisabled"
                    (click)="verifyUserIntermediate()"><small>2.</small> Verify</button>
                </div>
                <div class="content-capture mb-2">
                  <div *ngFor="let data of sampleData.slice(0,4); let sampleDataIndex = index;" class="me-2">
                    <label style="margin-top:5px;" [for]="'sampleData' + sampleDataIndex">Finger Data {{ sampleDataIndex
                      + 1 }}
                    </label>
                    <label sampleDataIndex>: Captured success</label>
                  </div>
                </div>

                <div *ngFor="let totFingers of userEnrolled.slice(0,4); let totalFingersEnrolled = index;">
                  <table class="table table-striped table-light table-hover table-sm mb-3 w-75">
                    <tbody class="field-4column-label">
                      <tr>
                        <td>Finger Already Enrolled</td>
                        <td>{{totFingers.fingerName}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="flex-container">
                  <button class="btn btn-sm  btn-primary" type="button" color="primary"
                    (click)="startCapture()"><small>1.</small> Scan</button>
                  <button [disabled]="!verifiedUser" class="btn btn-sm  btn-primary" type="submit" color="primary"
                    (click)="proceed()"><small>3.</small> Proceed
                  </button>
                  <button class="btn btn-sm  btn-primary" type="button" color="primary" (click)="closeScanFingers()">
                    Close
                  </button>
                </div>
              </div>
            </ng-template>
          </mdb-tab>

          <mdb-tab *ngIf="biometricType == 'client'" title="Client Capture">
            <ng-template mdbTabContent title="Client Capture">
              <div id="Scores" class="scores align-items-center justify-content-center my-4">
                <label for="qualityInputBox" class="me-2 fs-5"> Scan Quality </label>
                <input class="form-control fs-5 text-center w-50" type="text" id="qualityInputBox" readonly
                  [(ngModel)]="readQuality" style="background-color:#DCDCDC;">
              </div>

              <div class="my-1">
                <select #allFingersSelect [disabled]="lstAllFingersDisable" style="max-width: 150px; " class="lmsselect"
                  [(ngModel)]="selectedFingerName" (change)="onClientChange()" lmsSelectOption
                  [constantName]="'ALLFINGERS'" [addDefault]="false" [setFisrtasValue]="true" required>
                </select>
                <div *ngIf="!selectedFingerName.includes(this.selectedFingerName) || selectedFingerName === ''">
                  <small class="text-danger"> Please select a valid option. </small>
                </div>
              </div>

              <button class="btn  btn-sm btn-primary mx-2" type="button" color="primary"
                [disabled]="enrollDisabled || numberReadings < 4 || lstAllFingersDisable || clientEnrolled.length == 4 "
                (click)="enrollClient()">Enroll Finger</button>

              <button type="button" class="btn  btn-sm btn-primary" color="primary" [disabled]="verifyDisabled"
                (click)="verifyClientIntermediate()"><small>2.</small> Verify </button>

              <div class="content-capture center-section my-2 ">
                <div *ngFor="let data of sampleData.slice(0,4); let sampleDataIndex = index;" class="mx-2">
                  <label class="mt-2" [for]="'sampleData' + sampleDataIndex">Finger Data {{ sampleDataIndex + 1 }}
                  </label>
                  <label sampleDataIndex>: Captured success</label>
                </div>

                <table class="table table-striped table-hover table-sm my-3 w-75">
                  <tbody>
                    <tr *ngFor="let totFingers of clientEnrolled | paginate: config; let totalFingersEnrolled = index;">
                      <td class="fw-semibold">Finger [{{totalFingersEnrolled}}] Already Enrolled</td>
                      <td>{{totFingers.fingerName}}</td>
                    </tr>
                  </tbody>
                </table>

                <div class="my-2" *ngIf="enableSkip">
                  <div>
                    <table class="table table-striped table-light table-hover table-sm mb-3 caption-top"
                      style="width: 98%;">
                      <tbody class="field-4column-label">
                        <tr>
                          <td>Branch Manager Username:
                          </td>
                          <td style="margin-left: 20%;">
                            <input placeholder="BM Username" [(ngModel)]="biometricsModel.bmUserName" required>
                            <button class="btn btn-sm btn-primary" type="submit" color="primary"
                              (click)="verifyBranchManager()"> Verify
                              Biometrics
                            </button>
                            <!-- <div *ngIf="biometricsModel.bmUserName == ''" class="error-label">
                            Please enter a valid Branch Manager Username.
                          </div> -->
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Verify Finger Response:
                          </td>
                          <td>
                            {{enrollmentMessage}}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <!-- <div *ngIf="isCellVisible ">
                  <select style="max-width: 150px; margin-left: 20%;" [(ngModel)]="biometricsModel.skipReason"
                  (change)="onSkipReasonChange($event)" required>
                    <option value=""> --- Select ---</option>
                    <option *ngFor="let skipReasons of lstBiometricsSkipReasons" [value]="skipReasons.value">
                      {{skipReasons.description}}
                    </option>
                  </select>
                  <button mat-raised-button type="submit" color="primary" style="margin-left: 10px;" *ngIf="enableSkip"
                    (click)="skipRegistration(biometricsModel.skipReason)" > Continue Application
                  </button>
                  <button mat-raised-button type="submit" color="primary" style="margin-left: 10px;" (click)="backToOffers()">
                    Try
                    Again
                  </button>
                  <div style="margin-left: 20%;" *ngIf="biometricsModel.skipReason == '' || !submitted" class="error-label">
                    Please select a valid Skip Reason.
                  </div>
                </div> -->
                </div>

                <div class="flex-container">
                  <button class="btn btn-sm btn-primary" type="button" color="primary"
                    [disabled]="startAcquisitionDisabled" (click)="startCapture()"><small>1.</small> Scan</button>

                  <!-- <button mat-raised-button type="button" color="primary" style="margin-left: 10px;"
                  [disabled]="stopAcquisitionDisabled || getUserFingersCount()" (click)="stopUserCapture()">Stop</button> -->

                  <button [disabled]="continueApplicationDisabled && !skipBiometrics" class="btn btn-sm btn-primary"
                    color="primary" (click)="close()">Continue Application
                  </button>

                  <button class="btn btn-sm btn-primary" type="button" color="primary" (click)="close()">
                    Back
                  </button>
                </div>
              </div>
            </ng-template>
          </mdb-tab>

          <mdb-tab *ngIf="biometricType == 'client' && !disableOpen && captureOpen" title="Disable Fingers">
            <ng-template mdbTabContent title="Disable Client Fingers">
              <div>
                <div *ngFor="let enroll of clientEnrolled">
                  <table class="table table-striped table-light table-hover table-sm mb-3 w-75">
                    <tbody class="field-4column-label">
                      <tr>
                        <td>Finger Id</td>
                        <td>{{enroll.id}}</td>
                      </tr>
                      <tr>
                        <td>Finger Name</td>
                        <td>{{enroll.fingerName}}</td>
                      </tr>
                      <tr>
                        <td>

                          <button class="btn btn-primary me-2" mdbRipple color="primary" class="readerDivButtons"
                            (click)="disableClientFinger(enroll.id)"><small>1.</small> Disable Finger</button>
                          <!-- <div>
                          <button [disabled]="!reasonSelected" mat-raised-button class="btn btn-primary btn-rounded" mdbRipple
                            color="primary" class="readerDivButtons"
                            (click)="disableClientFinger(enroll.id)">Disable
                            Finger</button>
                          <select style="max-width: 150px; margin-left: 5%;" (change)="fetchDisableReason($event)"
                            required>
                            <option value=""> --- Select ---</option>
                            <option *ngFor="let disabledFinger of lstDisableClientFingers;let fingerIndex = index;">
                              {{disabledFinger.description}}
                            </option>
                          </select>
                        </div> -->
                          <div
                            *ngIf="enrollmentError === 'SUCCESS' && enrollmentMessage === 'Finger Disabled' && biometricsModel.id === enroll.id"
                            [ngClass]="'error'" style="color: green;">
                            Finger Disabled.
                          </div>
                          <div
                            *ngIf="enrollmentError === 'FAILURE' && enrollmentMessage !== 'Finger Disabled' && biometricsModel.id === enroll.id"
                            [ngClass]="'error'" style="color: red; float:right; margin-right: 303px;">
                            Finger NOT Disabled.
                          </div>
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="readerDivButtons flex-container">
                  <button [disabled]="getClientFingersCount()" class="btn btn-primary" type="button" color="primary"
                    (click)="close()">Close</button>
                  <button type="button" color="primary" class="btn btn-primary"
                    (click)="disableAllClientFingers()">Disable All Fingers</button>
                </div>
              </div>
            </ng-template>
          </mdb-tab>

          <mdb-tab *ngIf="biometricType == 'user'" title="User Capture">
            <ng-template mdbTabContent title="User Capture">
              <div id="Scores" class="scores align-items-center justify-content-center my-4">
                <label for="qualityInputBox" class="me-2 fs-5"> Scan Quality </label>
                <input class="form-control fs-5 text-center w-50" type="text" id="qualityInputBox" readonly
                  [(ngModel)]="readQuality" style="background-color:#DCDCDC;">
              </div>
              <div id="content-capture" style="display : none;">
                <div id="status"></div>
                <div id="imagediv"></div>
              </div>

              <div class="my-1">
                <select #allFingersSelect [disabled]="lstAllFingersDisable" style="max-width: 150px; " class="lmsselect"
                  [(ngModel)]="selectedFingerName" (change)="onUserChange($event)" lmsSelectOption
                  [constantName]="'ALLFINGERS'" [addDefault]="false" [setFisrtasValue]="true" required>
                </select>
                <div *ngIf="!selectedFingerName.includes(this.selectedFingerName) || selectedFingerName === ''">
                  <small class="text-danger"> Please select a valid option. </small>
                </div>
              </div>

              <button class="btn  btn-sm btn-primary mx-2" type="button" color="primary"
                [disabled]="enrollDisabled || numberReadings < 4 || lstAllFingersDisable || userEnrolled.length == 10"
                (click)="enrollUser()">Enroll Finger</button>
              <button type="button" class="btn  btn-sm btn-primary" color="primary" [disabled]="verifyDisabled"
                (click)="verifyUserIntermediate()"> Verify </button>

              <div class="content-capture mx-2">
                <div *ngFor="let data of sampleData.slice(0,4); let sampleDataIndex = index;" style="margin-left:2%;">
                  <label class="mt-2" [for]="'sampleData' + sampleDataIndex">Finger Data {{ sampleDataIndex + 1 }}
                  </label>
                  <label sampleDataIndex>: Captured success</label>
                </div>

                <table class="table table-striped table-hover table-sm my-3 w-75">
                  <tbody>
                    <tr *ngFor="let totFingers of userEnrolled | paginate: config; let totalFingersEnrolled = index;">
                      <td class="fw-semibold">Finger [{{totalFingersEnrolled}}] Already Enrolled</td>
                      <td>{{totFingers.fingerName}}</td>
                    </tr>
                  </tbody>
                </table>

                <div class="flex-container">
                  <button class="btn btn-sm btn-primary" type="button" color="primary"
                    [disabled]="startAcquisitionDisabled"
                    (click)="startCapture();toggle_visibility(['content-capture']);"><small>1.</small>
                    Scan</button>

                  <!-- <button [disabled]="continueApplicationDisabled" class="btn btn-sm btn-primary" color="primary"
                    (click)="continueApplication()"> Continue Application
                  </button> -->

                  <!-- <button [disabled]="!skipBiometrics" class="btn btn-sm btn-primary" color="primary"
                    (click)="continueLoan()">
                    Skip Biometrics
                  </button> -->

                  <button class="btn btn-sm btn-primary" type="button" color="primary" (click)="closeScanFingers()">
                    Close
                  </button>
                </div>
              </div>
            </ng-template>
          </mdb-tab>

          <mdb-tab *ngIf="biometricType == 'user' && disableOpen && !captureOpen" title="Disable Fingers">
            <ng-template mdbTabContent title="Disable User Fingers">
              <table class="table table-striped table-light table-hover table-sm mb-3 w-75">
                <tbody class="field-4column-label">
                  <div *ngFor="let enroll of userEnrolled">
                    <tr>
                      <td>Finger Id</td>
                      <td>{{enroll.id}}</td>
                    </tr>
                    <tr>
                      <td>Finger Name</td>
                      <td>{{enroll.fingerName}}</td>
                    </tr>
                    <tr>
                      <td>
                        <button class="btn btn-primary me-2" mdbRipple color="primary" class="readerDivButtons"
                          (click)="disableUserFinger(enroll.id)"><small>1.</small> Disable Finger</button>
                        <div
                          *ngIf="enrollmentError === 'SUCCESS' && enrollmentMessage === 'Finger Disabled' && biometricsModel.id === enroll.id"
                          style="color: green;">
                          Finger Disabled.
                        </div>
                        <div
                          *ngIf="enrollmentError === 'FAILURE' && enrollmentMessage !== 'Finger Disabled' && biometricsModel.id === enroll.id"
                          [ngClass]="'error'" style="color: red; float:right; margin-right: 303px;">
                          Finger NOT Disabled.
                        </div>
                      </td>
                    </tr>
                  </div>
                </tbody>
              </table>

              <div class="flex-container">
                <button [disabled]="getUserFingersCount()" class="btn btn-sm btn-primary" type="button" color="primary"
                  (click)="close()">Close</button>
                <button type="button" color="primary" class="btn btn-sm btn-primary"
                  (click)="disableAllUserFingers()">Disable All Fingers</button>
              </div>
            </ng-template>
          </mdb-tab>
        </mdb-tabs>

        <ng-container *ngIf="signatureOpen">
          <div class="col-11 align-items-stretch justify-content-evenly my-2">
            <div class="my-2">
              <label for="selectedFingerName"> Client Signature </label>
              <select class="lmsselect" id="selectedFingerName" [disabled]="lstAllFingersDisable"
                style="max-width: 150px;" [(ngModel)]="selectedFingerName" (change)="fetchFinger(selectedFingerName)"
                required>
                <option value=""> --- Select ---</option>
                <option *ngFor="let finger of clientEnrolled;let fingerIndex = index;">{{finger.fingerName}}
                </option>
              </select>
            </div>
            <div class="my-2 ">
              <label for="selectedConsultantFingerName"> Consultant Signature </label>
              <select class="lmsselect" id="selectedConsultantFingerName" [disabled]="lstAllFingersDisable"
                style="max-width: 150px;" [(ngModel)]="selectedConsultantFingerName"
                (change)="fetchConsultantFinger(selectedConsultantFingerName)" required>
                <option value=""> --- Select ---</option>
                <option *ngFor="let Userfinger of userEnrolled;let fingerIndex = index;">{{Userfinger.fingerName}}
                </option>
              </select>
            </div>

            <button class="btn btn-sm btn-primary" type="button" color="primary"
              (click)="signDocument(selectedFingerName, selectedConsultantFingerName)">Sign Document</button>
          </div>
        </ng-container>

        <ng-container *ngIf="signatureOpen" class="justify-content-center" style="height:200px;">
          <div class="ms-4">
            Client Signature
            <select class="ms-4 lmsselect" [disabled]="lstAllFingersDisable" style="max-width: 150px;"
              [(ngModel)]="selectedFingerName" (change)="fetchFinger(selectedFingerName)" required>
              <option value=""> --- Select ---</option>
              <option *ngFor="let finger of clientEnrolled;let fingerIndex = index;">{{finger.fingerName}}
              </option>
            </select>
          </div>
          <div class="ms-4">
            Consultant Signature
            <select class="ms-2 lmsselect" [disabled]="lstAllFingersDisable" style="max-width: 150px;"
              [(ngModel)]="selectedConsultantFingerName" (change)="fetchConsultantFinger(selectedConsultantFingerName)"
              required>
              <option value=""> --- Select ---</option>
              <option *ngFor="let Userfinger of userEnrolled;let fingerIndex = index;">{{Userfinger.fingerName}}
              </option>
            </select>
          </div>

          <button class="btn btn-sm btn-primary ms-5" type="button" color="primary"
            (click)="signDocument(selectedFingerName, selectedConsultantFingerName)">Sign Document</button>
        </ng-container>
      </div>
    </div>
  </div>