import {
  Component,
  inject,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  EventEmitter,
  Output,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlContainer, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { UpdateEmployerComponent } from 'src/app/features/branch-employers/update-employer/update-employer.component';
import { BranchEmployerService } from 'src/app/core/services/branch-employer.service';
import { BasicEmployersSearchComponent } from 'src/app/features/branch-employers/basic-employer-form/basic-employer-form.component';
import { LMSSelectOptionDirective, ZARcurrencyPipe } from '@lmsSharedUI';
import { AppUtilityService } from 'src/app/app-utility.service';
import { LMSDialogHandlerService } from 'src/app/shared/lmsdialog-handler.service';
import { NumbersOnlyDirective, UniquePayslip } from 'src/app/shared/directives';
import { PaySlipModel1, PreAuthorisationModel } from '@lmsModels';
import { EmployerDetailsModel } from 'src/app/features/branch-employers/models/employer-details-model';
import { MdbDropdownModule } from 'mdb-angular-ui-kit/dropdown';
import { MdbFormsModule } from 'mdb-angular-ui-kit/forms';
import { MdbPopoverModule } from 'mdb-angular-ui-kit/popover';

const PERMANENTDESC = 'Permanent';

@Component({
  selector: 'app-salaried-editor',
  templateUrl: './salaried-editor.component.html',
  styleUrls: [ './salaried-editor.component.css' ],
  standalone: true,
  encapsulation: ViewEncapsulation.Emulated,
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatDatepickerModule,
    BasicEmployersSearchComponent,
    LMSSelectOptionDirective,
    ZARcurrencyPipe,
    NumbersOnlyDirective,
    MdbDropdownModule,
    MdbFormsModule,
    MdbPopoverModule,
  ],
})
export class SalariedEditorComponent implements OnInit, OnDestroy, OnChanges {
  @Input() controlKey = '';
  parentContainer = inject(ControlContainer);
  @Input() sallabel?: string;
  @Input() Submitted = false;
  @Input() preauthModel?: PreAuthorisationModel;
  @Output() paySlipCaptured = new EventEmitter<PaySlipModel1>();
  employerDetailsModel?: EmployerDetailsModel;

  updateEmployment = false;
  // lstIndustryType = INDUSTRYTYPE;
  // lstpayDates = PAYDAYOFWEEK;
  // lstWageFreqPayDates = PAYDATES;
  // lstWageFrequency = WAGEFREQUENCY;
  // lstEmploymentType = EMPLOYMENTTYPE;
  lstAdditionalIncome = [
    { value: 'None', description: 'None' },
    { value: 'Rentals', description: 'Rentals' },
    { value: 'Maintanence', description: 'Maintanence' },
    { value: 'Bonus', description: 'Bonus' },
    { value: 'Dividends', description: 'Dividends' },
    { value: 'Royalties', description: 'Royalties' },
    { value: 'Other', description: 'Other' },
  ];
  originalEmployer: string;
  maxDate = new Date();
  startDate = new Date(2010, 0, 1);
  employerNametxt = '';
  payDateError = '';
  invalidDate = false;
  invalidEndDate = false;
  payslipResult: string;
  showPayslip: any;
  income1 = 0;
  income2 = 0;
  income3 = 0;
  isAINoneSelected = false;
  isAIOtherOptionSelected = false;

  get showContractOrTemporary () {
    const ctype = this.f.employmentType.value;
    return ctype && ctype !== PERMANENTDESC;
  }

  get parentFormGroup () {
    return this.parentContainer.control as FormGroup;
  }

  get f () {
    return (this.parentFormGroup.get(this.controlKey) as FormGroup).controls;
  }

  get totalIncome () {
    return +this.income1 + +this.income2 + +this.income3;
  }

  constructor (
    private branchEmployerService: BranchEmployerService,
    public lmsDialogHandler: LMSDialogHandlerService,
    public global_utility: AppUtilityService,
    private dialog: MatDialog
  ) {}

  get employmentStartDate () {
    return this.f.employmentStartDate;
  }

  get contractStartDate () {
    return this.f.contractStartDate;
  }

  get contractEndDate () {
    return this.f.contractEndDate;
  }

  get employmentType () {
    return this.f.employmentType;
  }

  get wageFrequency () {
    return this.f.wageFrequency;
  }

  get payDate () {
    return this.f.payDate;
  }

  get employer () {
    return this.f.employer;
  }

  get payslips () {
    return this.f.payslips as FormGroup;
  }

  get payslipControls () {
    const control = this.payslips.controls;
    return control;
  }

  ngOnInit () {
    this.parentFormGroup.setControl(
      this.controlKey,
      new FormGroup(
        {
          industryType: new FormControl(''),
          industryTypeOther: new FormControl(''),
          employmentType: new FormControl(''),
          employmentStartDate: new FormControl(new Date()),
          contractStartDate: new FormControl(new Date()),
          contractEndDate: new FormControl(new Date()),
          employer: new FormControl(''),
          employerTNR: new FormControl(''),
          wageFrequency: new FormControl(''),
          payDate: new FormControl(''),
          occupation: new FormControl(''),
          workSaturday: new FormControl(false),
          //additionalIncome: new FormControl([]),
          additionalIncome: new FormControl([], Validators.required),
          additionalIncomeOther: new FormControl(''),
          payslips: new FormGroup(
            {
              payslip1: new FormControl({}),
              payslip2: new FormControl({}),
              payslip3: new FormControl({}),
            },
            { validators: UniquePayslip }
          ),
        },
        { updateOn: 'change' }
      ),
      { emitEvent: false }
    );
    // this.parentContainer.valueChanges?.subscribe(preAuth => {
    //   this.updateProfile(preAuth);
    // });

    // let premodTask = setTimeout(() => {
    //   if (Object.values(this.preauthModel!).length != 0) {
    //     this.updateProfile(this.preauthModel!);
    //     clearInterval(premodTask);
    //   }
    // }, 200);
  }

  ngOnChanges (changes: SimpleChanges): void {
    if (!changes.preauthModel.firstChange && Object.keys(changes.preauthModel.currentValue).length > 0) {
      setTimeout(() => {
        this.preauthModel = changes.preauthModel.currentValue;
        this.updateProfile(changes.preauthModel.currentValue);
      });
    }
  }

  updateProfile (preAuth: PreAuthorisationModel) {
    if (Object.values(preAuth).length == 0) {
      return;
    }

    const employerDetailsModel = preAuth.employment && preAuth.employment.id > 0 ? preAuth.employment : preAuth;
    //Set Defaults if employment changed from SASSA
    if (employerDetailsModel.employmentType == 'SASSA') {
      employerDetailsModel.employmentType = PERMANENTDESC;
      employerDetailsModel.employerTNR = 0;
      employerDetailsModel.wageFrequency = 'Monthly';
      employerDetailsModel.workSaturday = false;
      employerDetailsModel.industryType = '';
      employerDetailsModel.occupation = '';
    }

    console.log('preAuth', preAuth);
    this.originalEmployer = preAuth.employer || preAuth.employment.name || preAuth.employerName || '';

    if (this.originalEmployer && (this.originalEmployer == '' || this.originalEmployer == 'SASSA')) {
      this.updateEmployment = true;
    }

    this.employerNametxt = `${this.originalEmployer},${employerDetailsModel.employerTNR || '0'}`;
    this.employer.setValue(this.employerNametxt, {
      onlySelf: false,
      emitEvent: false,
    });
    this.f.employerTNR.setValue(employerDetailsModel.employerTNR || 0, {
      onlySelf: false,
      emitEvent: false,
    });
    this.f.employmentType.setValue(employerDetailsModel.employmentType || PERMANENTDESC, {
      onlySelf: true,
      emitEvent: true,
    });
    if (employerDetailsModel.employmentType == PERMANENTDESC) {
      this.f.employmentStartDate.setValue(new Date(employerDetailsModel.employmentStartDate), { onlySelf: false });
    } else {
      this.f.contractStartDate.setValue(new Date(employerDetailsModel.contractStartDate), { onlySelf: false });
      this.f.contractEndDate.setValue(new Date(employerDetailsModel.contractEndDate), { onlySelf: false });
    }

    this.f.wageFrequency.setValue(employerDetailsModel.wageFrequency || employerDetailsModel.wageCalendar, {
      onlySelf: false,
      emitEvent: true,
    });
    this.f.workSaturday.setValue(employerDetailsModel.workSaturday, { onlySelf: true, emitEvent: false });
    this.f.occupation.setValue(employerDetailsModel.occupation, { onlySelf: true, emitEvent: false });
    this.f.industryType.setValue(employerDetailsModel.industryType, { onlySelf: true, emitEvent: false });
    this.f.industryTypeOther.setValue(preAuth.industryTypeOther, { onlySelf: true, emitEvent: false });

    if (preAuth.payslips && preAuth.payslips.length > 0) {
      preAuth.payslip1 ||= preAuth.payslips[0] ?? null;

      if (preAuth.payslip1 && preAuth.payslip1.payslipDate) {
        preAuth.payslip1.date = preAuth.payslip1.payslipDate;
        preAuth.payslip1.month = 1;
        this.income1 = +preAuth.payslip1.nettIncome + +preAuth.payslip1.otherIncome;
      }
      preAuth.payslip2 ||= preAuth.payslips[1] ?? null;
      if (preAuth.payslip2 && preAuth.payslip2.payslipDate) {
        preAuth.payslip2.date = preAuth.payslip2.payslipDate;
        preAuth.payslip2.month = 2;
        this.income2 = +preAuth.payslip2.nettIncome + +preAuth.payslip2.otherIncome;
      }
      preAuth.payslip3 ||= preAuth.payslips[2] ?? null;
      if (preAuth.payslip3 && preAuth.payslip3.payslipDate) {
        preAuth.payslip3.date = preAuth.payslip3.payslipDate;
        preAuth.payslip3.month = 3;
        this.income3 = +preAuth.payslip3.nettIncome + +preAuth.payslip3.otherIncome;
      }
    }

    this.toggleEmploymentType();
    preAuth.additionalIncome = preAuth.additionalIncome?.length == 0 ? [] : preAuth.additionalIncome;

    if (preAuth.additionalIncome?.length > 0) {
      const adin: any[] = [];
      const adin1 = this.fromAttribute(preAuth.additionalIncome);
      if (!Array.isArray(adin1)) {
        adin.push(adin1);
      }
      this.f.additionalIncome?.patchValue(adin, {
        onlySelf: true,
        emitEvent: false,
      });
    }
    this.f.additionalIncomeOther.setValue(preAuth.additionalIncomeOther, {
      onlySelf: true,
      emitEvent: false,
    });
    setTimeout(() => {
      this.f.payDate.patchValue(this.employerDetailsModel?.payDate!.toString() || preAuth.payDate.toString(), {
        onlySelf: true,
        emitEvent: false,
      });
    }, 200);
  }

  public paydaySelect (payval?: any) {
    if (payval == null) {
      payval = this.payDate.value || this.employerDetailsModel?.payDate;
    }

    setTimeout(() => {
      this.f.payDate.setValue(payval, { onlySelf: true, emitEvent: true });
    }, 200);
  }

  fromAttribute (newVal) {
    return !newVal
      ? null
      : newVal.length === 0
      ? null
      : !newVal.includes(',')
      ? newVal
      : newVal.split(',').map((x) => x.trim());
  }

  toggleEmploymentType () {
    const employType = this.employmentType.value;

    if (employType == PERMANENTDESC) {
      this.employmentStartDate.enable();
      this.contractStartDate.disable();
      this.contractEndDate.disable();
    } else {
      this.employmentStartDate.disable();
      this.contractStartDate.enable();
      this.contractEndDate.enable();
    }

    const minemploymentStartDate = new Date();
    minemploymentStartDate.setMonth(minemploymentStartDate.getMonth() - 3);
    minemploymentStartDate.setDate(minemploymentStartDate.getDay() - 1);
    this.maxDate = minemploymentStartDate;

    //this.parentFormGroup.updateValueAndValidity();
  }

  togglewageFrequency () {
    // this.lstpayDates = [];
    // this.lstWageFreqPayDates = [];
    if (this.f.payDate.hasValidator(Validators.min(1))) {
      this.f.payDate.removeValidators(Validators.min(1));
    }
    this.f.payDate.setValue(null);

    //const wageFreq = this.f.wageFrequency?.value;
    // if (wageFreq === 'Monthly') {
    //   this.lstWageFreqPayDates = PAYDATES;
    // }

    // if (wageFreq === 'Weekly' || wageFreq === 'Fortnightly') {
    //   this.lstpayDates = PAYDAYOFWEEK;
    // }

    this.f.payDate.addValidators(Validators.min(1));
    this.f.payDate.updateValueAndValidity();
    this.f.payDate.reset();
  }

  PayDateSelected (event: any) {
    const selectedDate = new Date(event.target.value);
    const payMonth = 3;
    const payYear = 0;
    this.payDateError = '';
    this.startDate = selectedDate;
    const employmentStartDate = new Date();

    employmentStartDate.setFullYear(employmentStartDate.getFullYear() - payYear);

    employmentStartDate.setMonth(employmentStartDate.getMonth() - payMonth);

    this.invalidDate = selectedDate > employmentStartDate;
    if (this.invalidDate) {
      this.payDateError = 'WARNING! start date is less than 3 months';
    }
  }

  PayEndDateSelected (event: any) {
    console.log(event.target.value);

    const selectedDate = new Date(event.target.value);
    const employmentStartDate = new Date(this.f.contractStartDate.value || this.f.employmentStartDate.value);

    this.invalidEndDate = selectedDate <= employmentStartDate;
    this.invalidDate = this.invalidEndDate;
    if (this.invalidEndDate) {
      this.payDateError = 'End Date must be after Start Date';
    }
  }

  goforNew () {
    this.branchEmployerService.preppedforCreate = true;

    const dialogRef = this.dialog.open(UpdateEmployerComponent, {
      disableClose: true,
      autoFocus: true,
      maxHeight: '1000px',
      minWidth: '850px',
      data: {
        employer: new EmployerDetailsModel(),
      },
    });

    //const currentEmployment = this.quoteStoreService.quoteWorkbook?.employment;
    dialogRef.afterClosed().subscribe((employer: any) => {
      if (employer) {
        this.doEmployerConfirm(employer);

        //this.isEmploymentComplete = true;
        //this.quoteStoreService.quoteWorkbook.employment = {...currentEmployment, ...employer};
        //this.mapWorkAddress(employer);
        //this.checkEmploymentComplete();
        //this.openEmploymentDialog();
        return true;
      }

      this.global_utility.warning('Employment details not saved', 'Incomplete employment');
      return false;
    });
  }

  doEmployerConfirm (event: any) {
    const employersSearch = {
      employerName: event.name || '',
      name: event.name || '',
      employerTNR: event.tnumber,
      branchSerial: this.global_utility.getGlobalData('branch_serial'),
    };

    const employerpromise = new Promise<boolean>((resolve) => {
      if ((!event.tnumber || event.tnumber == 0) && employersSearch.name === '') {
        resolve(false);
        return;
      }

      this.branchEmployerService.findEmployerBySerialTNR(employersSearch).subscribe((employerList: any) => {
        //hasEmployment will enable the dropdown on Edit
        //this.hasEmployment = false;
        this.employerDetailsModel = employerList;

        this.updateEmployment = false;
        if (this.originalEmployer == '' || this.originalEmployer != this.employerDetailsModel?.name) {
          this.updateEmployment = true;
        }

        //this.quoteStoreService.PreAuthorisationModel.employer = employerList;
        //this.loaderService.stopLoading(emplDlg);
        resolve(employerList && employerList.id > 0);
      });
    });

    employerpromise
      .then((employerselected) => {
        if (employerselected) {
          setTimeout(() => {
            this.employerNametxt = event.name;

            // if (this.preauthModel!.employmentType == 'SASSA') {
            //   // If employment type was previously sassa and is changed to salaried then this will not work
            //   // so we change the value if it was previously sassa
            //   this.preauthModel!.employmentType = PERMANENTDESC;
            // }

            // employerDetailsModel is populated after quicksearch result is selected
            this.f.employmentType.setValue(this.preauthModel!.employmentType || PERMANENTDESC, {
              onlySelf: false,
              emitEvent: true,
            });
            this.f.employer.setValue(
              event.tnumber ||
                this.employerDetailsModel?.name ||
                this.branchEmployerService.employerDetailsModel.employerName,
              { onlySelf: true, emitEvent: false }
            );
            this.f.employerTNR.setValue(event.tnumber, {
              onlySelf: true,
              emitEvent: true,
            });
            if (this.preauthModel!.employmentType === PERMANENTDESC) {
              this.f.employmentStartDate.setValue(this.preauthModel!.employment.employmentStartDate, {
                onlySelf: false,
                emitEvent: true,
              });

              //this.f.contractStartDate.setValue(null);
              //this.f.contractEndDate.setValue(null);
            } else {
              //this.f.employmentStartDate.setValue(null);
              this.f.contractStartDate.setValue(this.preauthModel!.employment.contractStartDate, {
                onlySelf: false,
                emitEvent: true,
              });
              this.f.contractEndDate.setValue(this.preauthModel!.employment.contractEndDate, {
                onlySelf: false,
                emitEvent: true,
              });
            }
            this.f.wageFrequency.setValue(
              this.employerDetailsModel?.luEmpPayFrequency || this.preauthModel!.wageCalendar || '',
              { onlySelf: true, emitEvent: false }
            );
            this.f.industryType.setValue(this.preauthModel!.employment.industryType, {
              onlySelf: true,
              emitEvent: false,
            });
            this.f.industryTypeOther.setValue(this.preauthModel!.employment.industryTypeOther, {
              onlySelf: true,
              emitEvent: false,
            });
            this.f.payDate.setValue(this.employerDetailsModel!.payDate.toString() || '4', {
              onlySelf: true,
              emitEvent: false,
            });
            this.toggleEmploymentType();
          });
        }

        // if (!employerselected) {
        //   //This was done to allow for non created employers to be selected or entered.
        //   //This is a temp workaround
        //   this.preauthModel!.wageFrequency = this.employerDetailsModel?.luEmpPayFrequency || '';
        //   this.preauthModel!.payDate = this.employerDetailsModel!.payDate?.toString() || '4';
        //   this.f.wageFrequency?.setValue(this.preauthModel!.wageFrequency, { onlySelf: true, emitEvent: false });
        //   this.f.payDate?.setValue(this.preauthModel!.payDate, { onlySelf: true, emitEvent: false });
        //   this.employerNametxt = this.preauthModel!.employer = event.employerName;
        //   this.f.employer.setValue(0, { onlySelf: true, emitEvent: false });
        // }
      })
      .catch((reason) => {
        console.error('Salaried employer error:', reason);
      });
  }

  openPaySlip (mnth: string | number) {
    console.log(`Saving payslip: ${mnth}`);
    let pslipModel = {} as PaySlipModel1;
    if (Number(mnth) === 1) {
      pslipModel = this.preauthModel!.payslip1;
    } else if (Number(mnth) === 2) {
      pslipModel = this.preauthModel!.payslip2;
    } else if (Number(mnth) === 3) {
      pslipModel = this.preauthModel!.payslip3;
    }

    this.lmsDialogHandler
      .openPayslipDialog(mnth, pslipModel, this.sallabel)
      ?.onClose.subscribe((pslip: PaySlipModel1) => {
        if (pslip) {
          pslip.otherIncome ||= 0;

          const totIn = +pslip.nettIncome + +pslip.otherIncome;
          this.payslipResult = JSON.stringify(pslip);
          console.log(this.payslipResult, 'payslipR');

          if (mnth == '1') {
            this.preauthModel!.payslip1 = pslip;
            this.payslipControls.payslip1?.patchValue(pslip, { onlySelf: true, emitEvent: true });
            this.income1 = totIn;
          } else if (mnth == '2') {
            this.preauthModel!.payslip2 = pslip;
            this.payslipControls.payslip2?.patchValue(pslip, { onlySelf: true, emitEvent: true });
            this.income2 = totIn;
          } else if (mnth == '3') {
            this.preauthModel!.payslip3 = pslip;
            this.payslipControls.payslip3?.patchValue(pslip, { onlySelf: true, emitEvent: true });
            this.income3 = totIn;
          }
          this.payslips.updateValueAndValidity();

          this.paySlipCaptured.emit(pslip);
        }
      });
  }

  updateAdditionalIncomeOptions (value) {
    this.isAINoneSelected = value.some((option) => option.toLowerCase().includes('none')) || false;
    console.log('isMONoneSelected:', this.isAINoneSelected);
    this.isAIOtherOptionSelected = value.some((option) => option.toLowerCase().includes('other')) || false;

    if (this.isAINoneSelected) {
      this.preauthModel!.selectedadditionalIncome = 'None';
      this.isAIOtherOptionSelected = false;
      return;
    }
    console.log('isAIOtherSelected:', this.isAIOtherOptionSelected);
  }

  ngOnDestroy () {
    this.parentFormGroup.removeControl(this.controlKey);
  }
}
