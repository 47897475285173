/* eslint-disable @angular-eslint/use-lifecycle-interface */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, OnInit, inject, OnDestroy, ViewEncapsulation, Input, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlContainer, FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
// import { GRANTTYPE } from "src/app/core/constants";
import { PreAuthorisationModel } from 'src/app/core/models/pre-authorisation.model';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';

import { LMSDialogHandlerService } from 'src/app/shared/lmsdialog-handler.service';
import { ZARcurrencyPipe, LMSSelectOptionDirective } from '@lmsSharedUI';
import { UniquePayslip } from 'src/app/shared/directives';
import { PaySlipModel, PaySlipModel1 } from '@lmsModels';


@Component({
  selector: 'app-sassa-editor',
  templateUrl: './sassa-editor.component.html',
  styleUrls: ['./sassa-editor.component.css'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatDatepickerModule, MatSelectModule, ZARcurrencyPipe,LMSSelectOptionDirective],
  encapsulation: ViewEncapsulation.Emulated,
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true })
    }
  ]
})
export class SassaEditorComponent implements OnInit, OnDestroy {
  @Input() controlKey = '';
  parentContainer = inject(ControlContainer);
  @Input() sallabel?: string;
  @Input() Submitted: boolean = false;
  @Input() preauthModel!: PreAuthorisationModel;
  @Output() paySlipCaptured = new EventEmitter<PaySlipModel1>();
  // lstGrantType = GRANTTYPE;

  originalEmployer: string;

  maxDate = new Date();
  payDateError = '';
  startDate = new Date(2010, 0, 1);
  invalidDate = false;

  invalidEndDate = false;

  payslipResult: string;
  // month1 = 0;
  // month2 = 0;
  // month3 = 0;

  income1 = 0;
  income2 = 0;
  income3 = 0;

  get showContractOrTemporary() {
    const ctype = this.f.grantCategory?.value;
    return (ctype && ctype !== 'Temporary');
  }

  get parentFormGroup() {
    return this.parentContainer.control as FormGroup;
  }

  get f() {
    return (this.parentFormGroup.get(this.controlKey) as FormGroup).controls;
  }

  get totalIncome() {
    return +this.income1 + +this.income2 + +this.income3;
  }

  get grantCategory() {
    return this.f.grantCategory;
  }

  get grantType() {
    return this.f.grantType;
  }

  get employmentStartDate(){
    return this.f.employmentStartDate;
    //return (this.parentFormGroup.get(this.controlKey) as FormGroup).get('employmentStartDate');
  }
  get contractStartDate() {
    return this.f.contractStartDate;
  }

  get contractEndDate() {
    return this.f.contractEndDate;
  }

  get payslips() {
    return this.f?.payslips as FormGroup;
  }

  get payslipControls() {
    const control = this.payslips?.controls;
    return control;
  }

  constructor( private dialog: MatDialog,
    public lmsDialogHandler: LMSDialogHandlerService,
  ){}

  ngOnInit() {
    this.parentFormGroup.addControl(this.controlKey
      , new FormGroup({
        grantCategory: new FormControl(''),
        grantType: new FormControl(''),
        employmentStartDate:new FormControl(new Date()),
        contractStartDate: new FormControl(new Date()),
        contractEndDate: new FormControl(new Date()),
        payslips: new FormGroup(
          {
            payslip1: new FormControl({}),
            payslip2: new FormControl({}),
            payslip3: new FormControl({}),
          },{ validators: UniquePayslip })
      }, { updateOn: 'change' }),
      { emitEvent: false });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.preauthModel && changes.preauthModel.currentValue) {
      if (!this.Submitted && Object.keys(changes.preauthModel.currentValue).length > 0) {
        setTimeout(() => {
          this.preauthModel = changes.preauthModel.currentValue;
          this.updateProfile(changes.preauthModel.currentValue);
        });
      }
    }
  }

  updateProfile(preAuth: PreAuthorisationModel) {
    if (!preAuth || Object.values(preAuth).length == 0) {
      return;
    }

    const employerDetailsModel = (preAuth.employment && preAuth.employment.id > 0) ? preAuth.employment : preAuth;
    //Set Defaults if employment changed from SASSA
    if (employerDetailsModel.employmentType !== 'SASSA') {
      employerDetailsModel.employmentType = 'Temporary';
      employerDetailsModel.employerTNR = 0;
      employerDetailsModel.wageFrequency = 'Monthly';
      employerDetailsModel.workSaturday = false;
      employerDetailsModel.industryType = '';
      employerDetailsModel.occupation = 'Disability';
      employerDetailsModel.employmentStartDate = null;
    }

    // Keep the original employment
    this.originalEmployer = preAuth.employer || preAuth.employment.name || preAuth.employerName || '';
    const defaultOccupation = employerDetailsModel.occupation || (employerDetailsModel.employmentType === 'Temporary' ? 'Disability':'Old age pension');
    this.f.grantType.setValue(defaultOccupation || 'Disability', { onlySelf: true, emitEvent: false });

    if(employerDetailsModel.employmentStartDate) {
      this.f.grantCategory.setValue('Permanent' , { onlySelf: true, emitEvent: true });
    } else {
      this.f.grantCategory.setValue(employerDetailsModel.employmentType == 'SASSA' ? 'Temporary': 'Permanent' , { onlySelf: true, emitEvent: true });
    }

    if (this.f.grantCategory.value == 'Permanent') {
      this.f.employmentStartDate.setValue(new Date(employerDetailsModel.employmentStartDate), { onlySelf: false });
    }
    else {
      this.f.contractStartDate.setValue(new Date(employerDetailsModel.contractStartDate), { onlySelf: false });
      this.f.contractEndDate.setValue(new Date(employerDetailsModel.contractEndDate), { onlySelf: false });
    }

    if (preAuth.payslips && preAuth.payslips.length > 0) {
      preAuth.payslip1 ||= preAuth.payslips[0] ?? null;
      if (preAuth.payslip1 && preAuth.payslip1.payslipDate) {
        preAuth.payslip1.date = preAuth.payslip1.payslipDate;
        preAuth.payslip1.month = 1;
        this.income1 = +preAuth.payslip1.nettIncome + +preAuth.payslip1.otherIncome;
      }
      preAuth.payslip2 ||= preAuth.payslips[1] ?? null;
      if (preAuth.payslip2 && preAuth.payslip2.payslipDate) {
        preAuth.payslip2.date = preAuth.payslip2.payslipDate;
        preAuth.payslip2.month = 2;
        this.income2 = +preAuth.payslip2.nettIncome + +preAuth.payslip2.otherIncome;
      }
      preAuth.payslip3 ||= preAuth.payslips[2] ?? null;
      if (preAuth.payslip3 && preAuth.payslip3.payslipDate) {
        preAuth.payslip3.date = preAuth.payslip3.payslipDate;
        preAuth.payslip3.month = 3;
        this.income3 = +preAuth.payslip3.nettIncome + +preAuth.payslip3.otherIncome;
      }
    }
    this.toggleGrantType();

    // Aliased as
    // Temporary == Disability
    // Permanent == Old age pension
    if (this.f.grantCategory.value !== 'Temporary') {
      this.f.employmentStartDate.setValue(new Date(employerDetailsModel.employmentStartDate), { onlySelf: false });
    }
    else {
      // if no values for contract dates this will default to current date else populate
      if(employerDetailsModel.contractStartDate){
        this.f.contractStartDate.setValue(new Date(employerDetailsModel.contractStartDate), { onlySelf: false });
      }
      if(employerDetailsModel.contractEndDate) {
        this.f.contractEndDate.setValue(new Date(employerDetailsModel.contractEndDate), { onlySelf: false });
      }
    }
  }

  toggleGrantType() {
    const grantType = this.grantType?.value;

    if (grantType === 'Disability') {
      this.grantCategory?.enable();
      //this.f.grantCategory.setValue(grantType == 'Disability' ? 'Temporary': 'Permanent' , { onlySelf: true, emitEvent: true });
    } else {
      this.grantCategory?.disable();
      this.f.grantCategory.setValue('Permanent' , { onlySelf: true, emitEvent: true });
    }

    console.info('Grant Type >> ',grantType)

    // Aliased as
    // Temporary == Disability
    // Permanent == Old age pension
    this.toggleGrantDates();
    //this.parentFormGroup.updateValueAndValidity();
  }

  toggleGrantDates(){
    if (this.grantCategory?.value !== 'Temporary') {
      this.employmentStartDate?.enable();
      this.contractStartDate?.disable();
      this.contractEndDate?.disable();
    } else {
      this.employmentStartDate?.disable();
      this.contractStartDate?.enable();
      this.contractEndDate?.enable();
    }

    const minemploymentStartDate = new Date();
    minemploymentStartDate.setMonth(minemploymentStartDate.getMonth() - 3);
    minemploymentStartDate.setDate(minemploymentStartDate.getDay() - 1);
    this.maxDate = minemploymentStartDate;
  }

  ngOnDestroy() {
    this.parentFormGroup.removeControl(this.controlKey);
  }

  GrantDateSelected(event: any) {
    const selectedDate = new Date(event.target.value);
    const payMonth = 3;
    const payYear = 0;
    this.payDateError = "";
    this.startDate = selectedDate;
    const _employmentStartDate = new Date();
    _employmentStartDate.setFullYear(_employmentStartDate.getFullYear() - payYear);
    _employmentStartDate.setMonth(_employmentStartDate.getMonth() - payMonth);

    this.invalidDate = (selectedDate > _employmentStartDate);
    if (this.invalidDate) {
      this.payDateError = "WARNING! start date is less than 3 months";
    }
  }

  GrantEndDateSelected(event: any) {
    console.log(event.target.value);

    const selectedDate = new Date(event.target.value);
    const _employmentStartDate = new Date(this.f.contractStartDate.value || this.f.employmentStartDate.value);


    this.invalidEndDate = selectedDate <= _employmentStartDate;
    this.invalidDate = this.invalidEndDate;
    if (this.invalidEndDate) {
      this.payDateError = "End Date must be after Start Date";
    }

  }


  openPaySlip(mnth: string | number) {
    console.log("Saving payslip: " + mnth);
    let pslipModel = {} as PaySlipModel1;
    if (Number(mnth) === 1) {
      pslipModel = this.preauthModel!.payslip1;
    } else if (Number(mnth) === 2) {
      pslipModel = this.preauthModel!.payslip2;
    } else if (Number(mnth) === 3) {
      pslipModel = this.preauthModel!.payslip3;
    }

    this.lmsDialogHandler.openPayslipDialog(mnth, pslipModel, this.sallabel)
      ?.onClose.subscribe((pslip: PaySlipModel1) => {
        if (pslip) {
          pslip.otherIncome ||= 0;

          const totIn = +pslip.nettIncome + +pslip.otherIncome;
          this.payslipResult = JSON.stringify(pslip);
          console.log(this.payslipResult, "payslipR");

          if (mnth == '1') {
            this.preauthModel!.payslip1 = pslip;
            this.payslipControls['payslip1']?.patchValue(pslip,{ onlySelf: true, emitEvent: true });
            this.income1 = totIn;
          } else if (mnth == '2') {
            this.preauthModel!.payslip2 = pslip;
            this.payslipControls['payslip2']?.patchValue(pslip,{ onlySelf: true, emitEvent: true });
            this.income2 = totIn;
          } else if (mnth == '3') {
            this.preauthModel!.payslip3 = pslip;
            this.payslipControls['payslip3']?.patchValue(pslip,{ onlySelf: true, emitEvent: true });
            this.income3 = totIn;
          }
          this.payslips.updateValueAndValidity();

          if (this.paySlipCaptured!!) {
            this.paySlipCaptured.emit(pslip);
          }
        }
      });
  }

  // openPaySlip(mnth: string | number) {
  //   console.log("Saving payslip: " + mnth);

  //   this.dialog.open(PayslipDialogComponent, {
  //     disableClose: true,
  //     autoFocus: true,
  //     height: 'auto',
  //     width: 'auto',
  //     data: { month: mnth,
  //       clientType: this.sallabel
  //      }
  //   }).afterClosed().subscribe({
  //     next: (pslip: PaySlipModel) => {
  //       if (pslip) {
  //         const totIn = +pslip.nettIncome
  //         this.payslipResult = JSON.stringify(pslip);
  //         console.log(this.payslipResult, "payslipR");

  //         if (mnth == '1') {
  //           this.preauthModel!.payslip1 = pslip;
  //           this.income1 = totIn;
  //         } else if (mnth == '2') {
  //           this.preauthModel!.payslip2 = pslip;
  //           this.income2 = totIn;
  //         } else if (mnth == '3') {
  //           this.preauthModel!.payslip3 = pslip;
  //           this.income3 = totIn;
  //         }

  //         if (this.paySlipCaptured!!) {
  //           this.paySlipCaptured.emit(pslip);
  //         }
  //       }
  //     }
  //   });
  // }

}
