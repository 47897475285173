import {
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, throwError, TimeoutError, catchError, tap } from 'rxjs';
import { LoadingSpinService } from 'src/app/shared/loaders/spinner/loadspinner.service';
import { environment } from 'src/environments/environment';
import { ErrorhanderService } from '../services/error-handler.service';

// export function ErrorInterceptorFn(req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
//   const loaderService = inject(LoadingSpinService);
//   const this.errorService = inject(ErrorhanderService);
//   const this.loaderService = inject(this.loaderService);

//   const reqWithHeader = request.clone();

//   return next(reqWithHeader)
// }
export const TRACKREQ_ENABLED = new InjectionToken<boolean>('overridectx');
export const OVERRIDE_CTX = new HttpContextToken<boolean>(() => false);

@Injectable({
  providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor (
    private loaderService: LoadingSpinService,
    private errorService: ErrorhanderService,
    private toastrService: ToastrService,
    @Inject(TRACKREQ_ENABLED) protected track_Request: boolean
  ) {}

  intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const ignoreHndl = req.context.get(OVERRIDE_CTX);
    console.info(`Tracking[${ignoreHndl && this.track_Request}]`, req.url);
    return next.handle(req).pipe(
      tap({
        error: (error) => {
          if (
            (ignoreHndl && req.url?.endsWith('doLoanCreate')) ||
            req.url?.endsWith('submitClientDetails') ||
            req.url?.endsWith('doLoanQuote')
          ) {
            return throwError(() => error);
          }
          return this.handleError(error, req, next, this.track_Request, ignoreHndl);
        },
      })
    );
  }

  private handleError (
    returnedError: HttpErrorResponse,
    request: HttpRequest<any>,
    next: HttpHandler,
    trackReq: boolean,
    ignoreHndl: boolean
  ): any {
    const handled: boolean = false;
    // if (ignoreHndl) {
    //   if (
    //     request.url?.endsWith('doLoanCreate') ||
    //     request.url?.endsWith('submitClientDetails') ||
    //     request.url?.endsWith('doLoanQuote')
    //   ) {
    //     return throwError(() => returnedError);
    //   }
    //   handled = true;
    //   of(returnedError);
    // }

    if (!handled && returnedError instanceof Error && !request.url?.endsWith('doLoanCreate')) {
      console.error('ABNORMAL Error: ', returnedError.message);
      this.errorService.handle(returnedError, `ABNORMAL Error`, true);
      // of(error) this basically handles the error and resumes normal execution
      return throwError(() => returnedError);
    }

    // **  TIMEOUT ERROR - handled by timeout interceptor ** //
    if (!handled && returnedError instanceof TimeoutError) {
      return throwError(() => returnedError);
    }

    if (!handled && returnedError instanceof HttpErrorResponse) {
      let errorMessage = '';
      // **  ERROR EVENT ** //
      if (returnedError.error) {
        errorMessage = `Error: ${returnedError.error.message || returnedError.message}`;
        console.error(errorMessage);
        const newerror = new Error(errorMessage);
        this.errorService.handle(newerror, `Response Error`, true);

        return of(returnedError);
        //of(returnedError);
      }

      // **  HTTP RESPONSE ERROR ** //
      // **  PROGRESS ERROR ** //
      if (returnedError.status == 0 && returnedError.error instanceof ProgressEvent) {
        if (!returnedError.url?.endsWith('doLoanCreate')) {
          const message1 = returnedError.url ?? '';
          const hdrString1 = `Network error`;
          const showdateStr1 = new Date().toLocaleString();
          this.toastrService.error(`${showdateStr1} : ${message1}`, hdrString1, {
            positionClass: 'toast-top-center',
            disableTimeOut: true,
            closeButton: true,
            progressAnimation: 'increasing',
          });
          this.loaderService.stopLoading();
          return of(returnedError);
        }
      }

      const newError = new Error();
      switch (returnedError.status) {
        case 302: // Found REsponse
          newError.message = 'Duplicate Record Found!';
          this.errorService.handle(newError, `LMS Server Duplicate not allowed!!`, true);
          return throwError(() => newError);
          break;
        case 417:
          // NUPAY Error response
          console.error('LMS SERVER ::', returnedError.error.message);
          newError.message = returnedError?.error!.message || returnedError.message;

          this.errorService.handle(newError, `LMS Server [response]`, true);
          return of(returnedError);
          break;
        case 501:
          this.errorService.handleOffline(returnedError?.error!.message || 'Server Not Responding', 'Server OFFLINE');
          return of(returnedError);
          break;
        case 400: // Bad Request
          this.toastrService.error(
            `${new Date().toLocaleString()} Server response:<br><br>${returnedError.error.message ?? ''}`,
            'Bad Request error',
            {
              enableHtml: true,
              positionClass: 'toast-top-center',
              timeOut: 18000,
              newestOnTop: true,
              disableTimeOut: true,
              tapToDismiss: true,
            }
          );
          this.loaderService.stopLoading();
          return of(returnedError);
          //handled = handleBadRequest(returnedError);
          break;
        case 504: // Bad Gateway
          // const message1 = returnedError.error.message ?? '';
          // const hdrString1 = 'Bad Request error';
          // const showdateStr1 = `${new Date().toLocaleString()}`;
          this.toastrService.error(
            `${new Date().toLocaleString()} Server response:<br><br>${returnedError.error.message ?? ''}`,
            'Bad Request error',
            {
              enableHtml: true,
              positionClass: 'toast-top-center',
              timeOut: 18000,
              newestOnTop: true,
              disableTimeOut: true,
              tapToDismiss: true,
            }
          );
          this.loaderService.stopLoading();
          return of(returnedError);
          ///handled = handleBadGateway(returnedError);
          break;
        default:
          if (returnedError.error && returnedError.error == 'Unauthorized' && returnedError.status == 401) {
            this.errorService.handleString('Unauthorized recieved. Require login');
          } else if (
            returnedError.error &&
            returnedError.error.type == 'error' &&
            returnedError.statusText === 'Unknown Error'
          ) {
            //Offline
            this.errorService.handleOffline(returnedError.message || returnedError.error.message, 'Server OFFLINE');
          } else {
            if (returnedError.error && !returnedError.error!.result) {
              this.errorService.handleServerSideError(returnedError);
              break;
            }

            if (
              (returnedError.error?.trace || returnedError.error?.stackTrace) &&
              !environment?.production &&
              !!environment?.showtrace
            ) {
              this.errorService.handleStack(returnedError.error?.trace || returnedError.error?.stackTrace);
            }
          }
          if (
            (returnedError.error?.trace || returnedError.error?.stackTrace) &&
            !environment?.production &&
            !!environment?.showtrace
          ) {
            this.errorService.handleStack(returnedError.error?.trace || returnedError.error?.stackTrace);
          }
          break;
      }
      if (handled) {
        return of(returnedError);
      }
    }

    if (!handled) {
      return throwError(() => returnedError);
    }

    return of(returnedError);
  }

  //   private handleProgressEvent = (error: HttpErrorResponse): boolean {
  //     const message1 = error.url ?? '';
  //     const hdrString1 = `Network error`;
  //     const showdateStr1 = `${new Date().toLocaleString()}`;
  //     this.loaderService.error(`${showdateStr1} : ${message1}`, `${hdrString1}`, { positionClass: 'toast-top-center', disableTimeOut: true, closeButton: true, progressAnimation: 'increasing' });
  //     loaderService.stopLoading();
  //     apiloader.isAPIOffline.next(true);
  //     return true;
  //   }

  //   private handleBadRequest = (error: HttpErrorResponse): boolean {
  //     const message1 = error.error.message ?? '';
  //     const hdrString1 = 'Bad Request error';
  //     const showdateStr1 = `${new Date().toLocaleString()}`;
  //     this.loaderService.error(`${showdateStr1} Server response:<br><br>${message1}`, `${hdrString1}`, { enableHtml:true, positionClass: 'toast-top-center', timeOut: 18000, newestOnTop: true, disableTimeOut: true, tapToDismiss: true });
  //     loaderService.stopLoading();
  //     return true;
  //   }

  //   private handleBadGateway(error: HttpErrorResponse): boolean {
  //     const message1 = error.url ?? '';
  //     const hdrString1 = `Bad Gateway error : ` + error.statusText;
  //     const showdateStr1 = `${new Date().toLocaleString()}`;
  //     this.loaderService.error(`${showdateStr1} : ${message1}`, `${hdrString1}`, { positionClass: 'toast-top-center', timeOut: 18000, newestOnTop: true, disableTimeOut: true, tapToDismiss: true });
  //     loaderService.stopLoading();
  //     apiloader.isAPIOffline.next(true);
  //     return true;
  //   }
}
