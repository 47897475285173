<div class="modal-content">
  <div class="modal-header">
    <div class="modal-title">
    <app-page-cardhead style="margin-top: -1.2rem;" [PageName]="'Banking Details'" [PageHeadWidth]="600"></app-page-cardhead>
    </div>
    <button class="btn btn-close" (click)="modalRef.close()"></button>
  </div>

  <div class="modal-body" >
    <table w650 id="clientHeader" class="table p-1 table-hover table-sm shadow-2-strong rounded-1">
      <caption>Client Details</caption>
      <tbody>
        <tr>
          <td>First names</td>
          <td>{{clientDetails.firstName}}</td>

          <td>Surname</td>
          <td>{{clientDetails.lastName}}</td>
        </tr>

        <tr>
          <td>ID number</td>
          <td>{{clientDetails.idNumber}}</td>

          <td>Cell number</td>
          <td>{{clientDetails.cellNumber}}</td>
        </tr>
      </tbody>
    </table>
    <form [formGroup]="bankDetailsForm" #formDirective="ngForm">
    <div class="card">
      <div class="card-body p-1">
        <div class="container-fluid">
          <ng-container class="container-md align-items-center">
            <table class="mt-2 table table-borderless table-sm table-fixed" [style.width.px]="750">
              <tbody>
                <!-- sameAccount-->
                <tr *ngIf="hasAccount  || isReadOnly">
                  <td class="aligndetails" style="border-bottom-width: 0;">
                    <table class="table table-striped table-sm shadow-2-strong rounded-1">
                      <caption>
                        Collect Account details
                      </caption>

                      <tbody class="field-4column-label">
                        <tr>
                          <td>Account holder</td>
                          <td>{{bankDetailsModel.colAccountHolder}}</td>
                        </tr>
                        <tr>
                          <td>Account number</td>
                          <td>{{bankDetailsModel.colAccountNumber}}</td>
                        </tr>
                        <tr>
                          <td>Account type</td>
                          <td>{{bankDetailsModel.colAccountType}}</td>
                        </tr>
                        <tr>
                          <td>Bank</td>
                          <td>{{bankDetailsModel.colBank}}</td>
                        </tr>
                        <tr>
                          <td>Branch Code</td>
                          <td>{{bankDetailsModel.colBranchCode}}</td>
                        </tr>
                        <tr>
                          <td>Account loan type</td>
                          <td>{{bankDetailsModel.colAccountLoanType}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>

                  <td class="aligndetails" style="border-bottom-width: 0;">
                    <!-- Disburse Account -->
                    <table id="disburseAccount" class="table table-striped table-sm shadow-2-strong rounded-1">
                      <caption>
                        Disburse Account details
                      </caption>

                      <tbody class="field-4column-label">
                        <tr>
                          <td>Account holder</td>
                          <td>{{bankDetailsModel.accountHolder}}</td>
                        </tr>
                        <tr>
                          <td>Account number</td>
                          <td>{{bankDetailsModel.accountNumber}}</td>
                        </tr>
                        <tr>
                          <td>Account type</td>
                          <td>{{bankDetailsModel.accountType}}</td>
                        </tr>
                        <tr>
                          <td>Bank</td>
                          <td>{{bankDetailsModel.bank}}</td>
                        </tr>
                        <tr>
                          <td>Branch Code</td>
                          <td>{{bankDetailsModel.branchCode}}</td>
                        </tr>
                        <tr>
                          <td>Account loan type</td>
                          <td>{{bankDetailsModel.accountLoanType}}</td>
                        </tr>
                        <!-- <tr>
                          <td>Payout Method</td>
                          <td>{{bankDetailsModel.payoutMethod}}</td>
                        </tr> -->
                      </tbody>
                    </table>
                  </td>
                </tr>

                <!-- NO account details (EDITING)-->
                <!-- sameAccount-->
                <tr *ngIf="(!hasAccount && !isReadOnly) && bankDetailsModel.sameAsDisburseAccount">
                <!-- Disburse and Collection Account -->
                  <td [colSpan]="2" class="aligndetails" style="border-bottom-width: 0;">
                    <table class="table p-1 table-striped table-bordered table-sm shadow-2-strong rounded-1 w-50">
                      <caption>
                        Collection and Disburse Account
                      </caption>
                      <tbody class="field-4column-label">
                        <tr>
                          <td>Bank</td>
                          <td>
                            <select class="form-control form-select lmsselect" formControlName="bank" [disableControl]="(editAllowed)" placeholder="Select a bank"
                            required
                              (change)="onDebiCheckBankSelect($event.target)" >
                              <option *ngFor="let bank of debiCheckBanks" [value]="bank.bankName">{{bank.bankName}}
                              </option>
                            </select>
                            <div *ngIf="bank.errors?.required && submitted">
                              <small class="text-danger">
                                Please select bank name
                              </small>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>Branch code</td>
                          <td>{{bankDetailsModel.branchCode}}
                            <!-- <input  style="width: 250px;"
                        placeholder="Branch code"
                        formControlName="branchCode"
                        [(ngModel)]= "bankDetailsModel.branchCode"
                        [disabled]="true">-->
                          </td>
                        </tr>

                        <!-- <tr>
                      <td>Branch name</td>
                      <td>
                        <input placeholder="Branch name" formControlName="branchName"
                          [(ngModel)]="bankDetailsModel.branchName" required type="text"
                          (keypress)="omit_special_char($event)">
                        <div *ngIf="bankDetailsInput.branchName.errors?.required && submitted" class="error-label">
                          Branch name is required
                        </div>
                      </td>
                    </tr> -->

                        <tr>
                          <td>Account holder</td>
                          <td>
                            <input class="form-control" formControlName="accountHolder" [disableControl]="(editAllowed)" placeholder="Account holder" pattern="^[a-zA-Z _-]+$"
                            [value]="bankDetailsModel.accountHolder" type="text" required
                            lmsNoPaste lmsNoSpecials>
                            <div *ngIf="accountHolder.errors?.required && submitted">
                              <small class="text-danger">
                                Account holder is required
                              </small>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>Account number</td>
                          <td>
                            <input class="form-control" placeholder="Account number" [disableControl]="(editAllowed)" formControlName="accountNumber"
                            required
                            [value]="bankDetailsModel.accountNumber" type="numbers" maxlength="19"
                            lmsNumbersOnly [allowDecimals]="false" lmsNoPaste>
                            <div *ngIf="accountNumber.errors?.required && submitted">
                              <small class="text-danger">
                                Account number is required
                              </small>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>Account type</td>
                          <td>
                            <select class="form-control form-select lmsselect" formControlName="accountType" [disableControl]="(editAllowed)" [value]="bankDetailsModel.accountType"
                            required>
                              <option value="CHEQUE">Cheque</option>
                              <option value="SAVINGS">Savings</option>
                            </select>
                            <div *ngIf="accountType.errors?.required && submitted">
                              <small class="text-danger">
                                Account type is required
                              </small>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>

                <!-- NOT sameAccount-->
                <tr *ngIf="(!hasAccount && !isReadOnly) && !bankDetailsModel.sameAsDisburseAccount">
                  <!-- Collection Account -->
                  <td class="aligndetails" style="border-bottom-width: 0;">
                    <table class="table p-1 table-striped table-bordered table-sm shadow-2-strong rounded-1">
                        <caption>
                          Collect Account
                        </caption>

                        <tbody class="field-4column-label">
                          <tr>
                            <td>Bank</td>
                            <td>
                              <select class="form-control form-select lmsselect" formControlName="collect_bank" [disableControl]="(editAllowed)"
                                (change)="onColBankSelect($event.target)" required>
                                <option *ngFor="let bank of debiCheckBanks" [value]="bank.bankName">
                                  {{bank.bankName}}
                                </option>
                              </select>
                              <div *ngIf="collect_bank.errors?.required && submitted">
                                <small class="text-danger">
                                  Please select bank name
                                </small>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>Branch code</td>
                            <td>{{bankDetailsModel.colBranchCode}}
                              <!--  <input
                              placeholder="Branch code"
                              formControlName="collect_branchCode"
                              [(ngModel)]= "bankDetailsModel.colBranchCode"
                              [disabled] = true
                              > -->
                            </td>
                          </tr>

                          <!-- <tr>
                          <td>Branch name</td>
                          <td>
                            <input placeholder="Branch name" formControlName="collect_branchName"
                              [(ngModel)]="bankDetailsModel.colBranchName" required type="text"
                              (keypress)="omit_special_char($event)">
                            <div *ngIf="bankDetailsInput.collect_branchName.errors?.required && submitted == true"
                              [ngClass]="'error-label'">
                              Branch name is required
                            </div>
                          </td>
                        </tr> -->

                          <tr>
                            <td>Account holder</td>
                            <td>
                              <input class="form-control" class="form-control" formControlName="collect_accountHolder" [disableControl]="(editAllowed)"
                              placeholder="Account holder" required type="text"
                                (keypress)="global_utility.omit_special_char($event)">
                              <div *ngIf="collect_accountHolder.errors?.required && submitted">
                                <small class="text-danger">
                                  Account holder is required
                                </small>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>Account number</td>
                            <td>
                              <input class="form-control" placeholder="Account number" formControlName="collect_accountNumber" [disableControl]="(editAllowed)"
                              type="numbers" maxlength="19" required
                                (keypress)="global_utility.numberOnly($event)">
                              <div *ngIf="collect_accountNumber.errors?.required && submitted">
                                <small class="text-danger">
                                  Account number is required
                                </small>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>Account type</td>
                            <td>
                              <select class="form-control form-select lmsselect"  aria-placeholder="Select type" formControlName="collect_accountType" [disableControl]="(editAllowed)"
                              required>
                                <option value="CHEQUE">Cheque</option>
                                <option value="SAVINGS">Savings</option>
                              </select>
                              <div *ngIf="collect_accountType.errors?.required && submitted">
                                <small class="text-danger">
                                  Account type is required
                                </small>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                    </table>
                  </td>

                  <!-- Disburse Account -->
                  <td class="aligndetails" style="border-bottom-width: 0;">
                    <table class="table p-1 table-striped table-bordered table-sm shadow-1-strong rounded-1" >
                      <caption>
                        Disburse account
                      </caption>

                      <tbody class="field-4column-label">
                        <tr>
                          <td>Bank</td>
                          <td>
                            <select class="form-control form-select lmsselect" formControlName="bank" [disableControl]="(!bankDetailsModel.sameAsDisburseAccount && editAllowed)" required
                            (change)="onBankSelect($event.target)">
                              <option *ngFor="let bank of allBanks" [value]="bank.bankName">{{bank.bankName}}
                              </option>
                            </select>
                            <div *ngIf="bank.errors?.required && submitted">
                              <small class="text-danger">
                                Please select bank name
                              </small>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>Branch code</td>
                          <td>
                            {{bankDetailsModel.branchCode}}
                          </td>
                        </tr>

                        <!-- <tr>
                        <td>Branch name</td>
                        <td>
                          <input placeholder="Branch name" formControlName="branchName"
                            [(ngModel)]="bankDetailsModel.branchName" required type="text"
                            (keypress)="omit_special_char($event)">
                          <div *ngIf="bankDetailsInput.branchName.errors?.required && submitted == true"
                            [ngClass]="'error-label'">
                            Branch name is required
                          </div>
                        </td>
                      </tr> -->

                        <tr>
                          <td>Account holder</td>
                          <td style="width: 120px;">
                            <input class="form-control" formControlName="accountHolder" [disableControl]="(editAllowed)"  placeholder="Account holder"
                              type="text" required
                              (keypress)="global_utility.omit_special_char($event)">
                            <div *ngIf="accountHolder.errors?.required && submitted">
                              <small class="text-danger">
                                Account holder is required
                              </small>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>Account number</td>
                          <td>
                            <input class="form-control"  placeholder="Account number" formControlName="accountNumber" [disableControl]="(editAllowed)"
                              type="numbers" maxlength="19" required
                              (keypress)="($event.charCode >= 48 && $event.charCode < 58)">
                            <div *ngIf="accountNumber.errors?.required && submitted">
                              <small class="text-danger">
                                Account number is required
                              </small>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td> Account type</td>
                          <td>
                            <select class="form-control form-select lmsselect" formControlName="accountType" [disableControl]="(editAllowed)" required >
                              <option value="CHEQUE">Cheque</option>
                              <option value="SAVINGS">Savings</option>
                            </select>
                            <div *ngIf="accountType.errors?.required && submitted">
                              <small class="text-danger">
                                Account type is required
                              </small>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
              <!-- NOT sameAccount end-->
            </table>
            <div *ngIf="!isReadOnly" class="mw-100 d-flex  center-screen align-content-center">
              <label class="w-25" for="payoutMethod">Payout Method</label>
              <select class="w-25 pull-right lmsselect"
                formControlName="payoutMethod" required>
                <option value="FINBOND CARD">Finbond Card</option>
                <option value="CASH">Cash</option>
                <option value="BANK TRANSFER">Bank Transfer</option>
                <option value="CBS BANK TRANSFER">CBS Bank Transfer</option>
              </select>
            </div>
            <div *ngIf="!hasAccount && !editAllowed" class="center-screen">
              <div class="d-flex justify-content-center align-content-center">
                <span>
                  <label for="sameAccount" class="form-check-label m-2">Collection account is the same as the Disburse account</label>
                <!-- mdbInput class="form-check form-check-input form-check-inline " -->
                <input #sameAccount class="form-check-input form-check-inline form-check" type="checkbox"
                aria-label="Collection account is the same as the Disburse account"
                [checked]="bankDetailsModel.sameAsDisburseAccount"
                  (change)="onSameAccountChange()"/>

                </span>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="card-footer flex-container">
          <button
          class="btn btn-primary"
          mdbRipple
          (click)="close()" > Close </button>
          <button
          class="btn btn-primary"
          mdbRipple
          *ngIf="!isReadOnly && !hasAccount"
          (click)="saveBankDetails()" > Save </button>
        <button
          class="btn btn-primary"
          mdbRipple
          *ngIf="hasAccount  && !isReadOnly"
          (click)="editBankDetails()" > Edit </button>
          <!-- clientStatus === 'REPEAT' && -->

        <button *ngIf=" !isReadOnly && hasAccount && !isEditing"
        class="btn btn-primary ms-4"
        mdbRipple
        (click)="confirmBankDetailsDetails()"><i class="fas fa-thumbs-up fa-fw me-1"></i> Confirm </button>
      </div>
    </div>
    </form>
  </div>
</div>
<!--  </div>

</div>

<div class="card m-2">
  <div class="card-body p-1">
    <ng-container class="container-md align-items-center">




      <div *ngIf="!hasAccount">

        <div *ngIf="bankDetailsModel.sameAsDisburseAccount">
          <table class="table table-borderless table-sm mb-2  shadow-1-strong rounded-1" style="width: 760px;">
              <tbody>

              </tbody>
          </table>
        </div>


        <div *ngIf="!bankDetailsModel.sameAsDisburseAccount">

            <table class="table table-borderless table-sm" style="width: 760px;">
              <tbody>
                <tr>

                </tr>
              </tbody>
            </table>

        </div>

      </div>
      </form>
    </ng-container>
  </div>

</div> -->
