import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LMSAuthGuardFn } from "./core/guard/auth.guard";
import { HomeLandingComponent } from './home/home.component';
import { RiskScoresComponent } from './modules/risk-scores/risk-scores.component';
import { UnsavedChangesGuard } from './core/guard/unsaved-changes.guard';
import { BranchLoansReportComponent } from './modules/reports/branch-loans-report/branch-loans-report.component';
import { LexisNexisReportComponent } from './modules/reports/lexis-nexis-report/lexis-nexis-report.component';
import { ResetLoanComponent } from './modules/reset-loan/reset-loan.component';
import { PerformanceReportComponent} from './modules/reports/performance-report/performance-report.component';

const routes: Routes = [
  { path: '', loadComponent: () => import('src/app/home/home.component').then(c => c.HomeLandingComponent), pathMatch: 'full', canActivate: [LMSAuthGuardFn] },
  //{ path: '', pathMatch: 'full', redirectTo: 'home' },
  { path: 'login', loadComponent: () => import('src/app/modules/login/login.component').then(c => c.LoginComponent) },
  { path: 'register', loadComponent: () => import('./modules/register/register.component').then(m => m.RegisterComponent), canActivate: [LMSAuthGuardFn], data: { title: 'User Administration' } },

  // ****   BRANCH MANAGER  ***** //
  {
    path: 'branchmanager', loadChildren: () => import('./features/branch-manager/branch-manager-routing.module').then(rou => rou.BM_ROUTES), canActivate: [LMSAuthGuardFn]
  },
  // {
  //   path: 'branchmanager', loadComponent: () => import('./features/branch-manager/branch-manager.component').then(m => m.BranchManagerComponent),
  //   canActivate: [LMSAuthGuardFn],
  //   children: [
  //     { path: '', redirectTo: 'landing', pathMatch: 'prefix' },
  //     { path: 'landing', loadComponent: () => import('./features/branch-manager/branch-manager.component').then(m => m.BranchManagerComponent), pathMatch: 'prefix', data: { title: 'Branch Manager' } },
  //     { path: 'quoteOffer', loadComponent: () => import('./quote-offer/quote-offer.component').then(m => m.QuoteOfferComponent), data: { title: 'BM - Loan Information' } },
  //     { path: 'quote', loadComponent: () => import('./quote/quote.component').then(m => m.QuoteComponent), pathMatch: 'prefix', data: { title: 'BM - Quote Information' } },
  //     { path: 'search', loadComponent: () => import('./client-search/client-search.component').then(m => m.ClientSearchComponent), pathMatch: 'prefix', data: { title: 'Client Information [BM]' } }
  //   ]
  // },
  // ****   AREA MANAGER  ***** //
  {
    path: 'areamanager', loadChildren: () => import('./features/area-manager/area-manager-routing.module').then(rou => rou.AM_ROUTES),
    canActivate: [LMSAuthGuardFn]
  },
  // ****   REGIONAL MANAGER  ***** //
  {
    path: 'regionalmanager',
    loadChildren: () => import('./features/regional-manager/regional-manager-routing.module').then(rou => rou.RM_ROUTES),
    //loadComponent: () => import('./features/regional-manager/regional-manager.component').then(m => m.RegionalManagerComponent),
    canActivate: [LMSAuthGuardFn]
  },
  {
    path: 'itsupport', loadChildren: () => import('./features/it-support/it-support.module').then(m => m.ItSupportModule),
    canActivate: [LMSAuthGuardFn]
  },
  {
    path: 'system-administrator', loadChildren: () => import('./features/system-administrator/system-administrator.module').then(m => m.SystemAdministratorModule),
    canActivate: [LMSAuthGuardFn], data: { title: 'System Administrator' }
  },
  {
    path: 'auditor', loadComponent: () => import('./features/auditors/auditors.component').then(m => m.AuditorsComponent),
    canActivate: [LMSAuthGuardFn], data: { title: 'Auditor' }
  },
  {
    path: 'accountHistory', loadChildren: () => import('./features/client-accounthistory/client-accounthistory.module').then(m => m.ClientAccounthistoryModule),
    canActivate: [LMSAuthGuardFn], data: { title: 'Customer Loan History' }
  },
  { path: 'search', loadComponent: () => import('./client-search/client-search.component').then(m => m.ClientSearchComponent), canActivate: [LMSAuthGuardFn], canDeactivate: [UnsavedChangesGuard], data: { title: 'Client Information' } },

  { path: 'clientDetails/:id', loadComponent: () => import('./client-details/client-details.component').then(m => m.ClientDetailsComponent), canActivate: [LMSAuthGuardFn], canDeactivate: [UnsavedChangesGuard], data: { title: 'Client Affordability Information' } },
  { path: 'preAuth', loadComponent: () => import('./pre-authorisation/pre-authorisation.component').then(m => m.PreAuthorisationComponent), canActivate: [LMSAuthGuardFn], data: { title: 'Pre-Authorisation' } },
  { path: 'quote', loadComponent: () => import('./quote/quote.component').then(m => m.QuoteComponent), canActivate: [LMSAuthGuardFn], data: { title: 'Offer select page' } },
  { path: 'agent', loadComponent: () => import('./agent-landing/agent-landing.component').then(m => m.AgentLandingComponent), canActivate: [LMSAuthGuardFn], data: { title: 'Branch Consultant' } },
  { path: 'docScanning', loadComponent: () => import('./features/document-scanning/document-scanning.component').then(m => m.DocumentScanningComponent), canActivate: [LMSAuthGuardFn], data: { title: 'Document Upload' } },
  { path: 'employmentDetails', loadComponent: () => import('./employment-details/employment-details.component').then(m => m.EmploymentDetailsComponent), canActivate: [LMSAuthGuardFn] },
  { path: 'bankingDetails', loadComponent: () => import('./modules/banking-details/banking-details.component').then(m => m.BankingDetailsComponent), canActivate: [LMSAuthGuardFn], data: { title: 'Banking Details' } },
  { path: 'quoteOffer', loadComponent: () => import('./quote-offer/quote-offer.component').then(m => m.QuoteOfferComponent), canActivate: [LMSAuthGuardFn], data: { title: 'Loan Processing Information' } },
  { path: 'address', loadComponent: () => import('./modules/addresses/addresses.component').then(m => m.AddressesComponent), canActivate: [LMSAuthGuardFn], data: { title: 'Address Details' } },
  { path: 'nextofkin', loadComponent: () => import('./modules/next-of-kin/next-of-kin.component').then(m => m.NextOfKinComponent), canActivate: [LMSAuthGuardFn], data: { title: 'Next Of Kin Details' } },
  { path: 'fbloanAgreement', loadComponent: () => import('./loan-agreement/loan-agreement.component').then(m => m.LoanAgreementComponent), canActivate: [LMSAuthGuardFn], data: { title: 'Confirmation of Agreement' } },
  { path: 'disburseLoan', loadComponent: () => import('./disburse-loan/disburse-loan.component').then(m => m.DisburseLoanComponent), canActivate: [LMSAuthGuardFn], data: { title: 'Disburse loan' } },
  { path: 'registerLoan', loadComponent: () => import('./register-loan/register-loan.component').then(m => m.RegisterLoanComponent), canActivate: [LMSAuthGuardFn], data: { title: 'DebiCheck Confirmation' }},
  // { path: 'noOffers', component: NoOffersComponent, canActivate: [LMSAuthGuardFn], data: { title: 'Client Information - Offers' } },
  { path: 'clientHistory', loadComponent: () => import('./client-history/client-history.component').then(m => m.ClientHistoryComponent), canActivate: [LMSAuthGuardFn], data: { title: 'Client History' } },
  // { path: 'resetLoan', loadComponent: () => import('./modules/reset-loan/reset-loan.component').then(m => m.ResetLoanComponent), canActivate: [LMSAuthGuardFn], data: { title: 'Reset Loan Application' } },
  { path: 'resetLoan', component: ResetLoanComponent, canActivate: [LMSAuthGuardFn], data: { title: 'Reset Loan Application' } },
  // //ADMINISTRATION
  //{ path: 'branchEmployers', component: UpdateEmployerComponent, canActivate: [LMSAuthGuardFn], data: { title: 'Branch Employers' } },
  { path: 'applicationComplete', loadComponent: () => import('./modules/application-complete/application-complete.component').then(m => m.ApplicationCompleteComponent), canActivate: [LMSAuthGuardFn], data: { title: 'Successful loan application' } },
  //{ path: 'riskScores', component: RiskScoresComponent, canActivate: [LMSAuthGuardFn]},

  { path: 'ratesAndFees', loadChildren: () => import('./modules/rates-and-fees/rates-and-fees.module').then(m => m.RatesAndFeesModule), canActivate: [LMSAuthGuardFn] },
  { path: 'reasoncodes', loadComponent: () => import('./modules/loan-reason-codes/loan-reason-codes/loan-reason-codes.component').then(m => m.LoanReasonCodesComponent), canActivate: [LMSAuthGuardFn], data: { title: 'Loan Reason Codes' } },
  { path: 'reasonreject', loadComponent: () => import('./modules/reject-reason-codes/reject-reason-codes.component').then(m => m.RejectReasonCodesComponent), canActivate: [LMSAuthGuardFn], data: { title: 'Reject Reason Codes' } },
  {
    path: 'marketingOptionsParameters',
    loadChildren: () => import('./modules/marketing-options-parameters/marketing-options.module').then(m => m.MarketingOptionsModule),
    canActivate: [LMSAuthGuardFn],
    data: { title: 'Marketing Options' }
  },
  { path: 'userAuditTrail', loadComponent: () => import('./modules/user-audit-trail/user-audit-trail.component').then(m => m.UserAuditTrailComponent), canActivate: [LMSAuthGuardFn], data: { title: 'User Branch Audit Trail' } },
  { path: 'menuAdmin', loadComponent: () => import('./modules/menus/menus-admin/menus-admin.component').then(m => m.MenusAdminComponent), canActivate: [LMSAuthGuardFn], data: { title: 'Menus Administration' } },
  { path: 'riskscores', loadChildren: () => import('./modules/risk-scores/risk-scores.module').then(m => m.RiskScoresModule), canActivate: [LMSAuthGuardFn] },
  {
    path: 'risk-scores', children: [{
      path: '', // child route path
      component: RiskScoresComponent, // child route component that the router renders
    }], canActivate: [LMSAuthGuardFn], data: { title: 'Money Laundering Scorecard' }
  },
  { path: 'riskScores', redirectTo: '/risk-scores', pathMatch: 'full' },
  { path: 'livingExpenses', loadChildren: () => import('./modules/living-expenses/living-expenses.module').then(m => m.LivingExpensesModule), canActivate: [LMSAuthGuardFn] },

  { path: 'affordabilityParams', loadChildren: () => import('./modules/affordability-parameters/affordability-parameters.module').then(m => m.AffordabilityParametersModule), canActivate: [LMSAuthGuardFn], data: { title: 'Affordability Parameters' } },

  { path: 'banks-admin', redirectTo: '/banksAdmin', pathMatch: 'full' },
  { path: 'banksAdmin', loadChildren: () => import('./modules/banks-admin/banks-admin.module').then(m => m.BanksAdminModule), canActivate: [LMSAuthGuardFn] },
  {
    path: 'excludeaction',
    loadChildren: () => import('./modules/loan-exclude-actions/exclude-action.module').then(m => m.ExcludeActionModule)
  },
  { path: 'updateEmployer', loadComponent: () => import('./features/branch-employers/update-employer/update-employer.component').then(m => m.UpdateEmployerComponent), canActivate: [LMSAuthGuardFn], data: { title: 'Maintain employers' } },
  //{
  //  path: 'updateEmployers',
  //  component: BranchEmployersModule,canActivate: [LMSAuthGuardFn],
  // children: [
  //   //{ path: 'dashboard', canActivate: [AuthGuard], loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule) },
  //   //{ path: 'user', canActivate: [AuthGuard], loadChildren: () => import('./features/user/user.module').then(m => m.UserModule) },
  //   { path: '', loadChildren: () => import('./features/branch-employers/branch-employers.module').then(m => m.BranchEmployersModule) }
  // ]
  //},
  { path: 'debicheckDevices', loadComponent: () => import('./modules/debicheck-devices/debicheck-devices.component').then(m => m.DebicheckDevicesComponent), canActivate: [LMSAuthGuardFn], data: { title: 'DebiCheck Devices' } },
  { path: 'scanDebiCheckSlip', loadComponent: () => import('./modules/scan-debicheck-slip/scan-debicheck-slip.component').then(m => m.ScanDebicheckSlipComponent), canActivate: [LMSAuthGuardFn], data: { title: 'Debicheck slip upload' } },
  { path: 'branchesAdmin', loadComponent: () => import('./modules/branch-admin/branch-admin.component').then(m => m.BranchAdminComponent), canActivate: [LMSAuthGuardFn], data: { title: 'Branch Administration' } },
  { path: 'regionAdmin', loadComponent: () => import('./modules/regions-admin/regions-admin.component').then(m => m.RegionsAdminComponent), canActivate: [LMSAuthGuardFn], data: { title: 'Regions and Area Administration' } },
  { path: 'clms', component: HomeLandingComponent, pathMatch: 'full' },
  { path: 'branchLoansReport', component: BranchLoansReportComponent, canActivate: [LMSAuthGuardFn], data: { title: 'Branch Loans Report' } },
  { path: 'lexisNexisReport', component: LexisNexisReportComponent, canActivate: [LMSAuthGuardFn], data: { title: 'Lexis Nexis Report' } },
  { path: 'performanceReport', component: PerformanceReportComponent, canActivate: [LMSAuthGuardFn], data: { title: 'Performance Report' } },

  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { enableTracing: false, anchorScrolling: 'enabled', scrollPositionRestoration: "top", scrollOffset: [0,0] })
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
