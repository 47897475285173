import { HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, map, Observable, of, tap, throwError } from 'rxjs';
import { ApiloaderService } from 'src/app/shared/loaders/apiloader/apiloader.service';


// export const CACHING_ENABLED = new HttpContextToken<boolean>(() => true);
// export function LoaderInterceptorFn(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
//   const loaderService = inject(ApiloaderService);
//   // Clone the request to add the authentication header.
//   return next(loaderService.setRequest(req)).pipe(tap(() => {
//     loaderService.removeRequest(req);
//   }));
// }

@Injectable({
  providedIn: 'root'
})
export class LoggingInterceptor implements HttpInterceptor {
  private loaderService = inject(ApiloaderService);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const loaderService = this.loaderService;
    loaderService.setRequest(req.clone());

    return next.handle(req).pipe(
      tap({
        next:(event: HttpEvent<any>) => {
          if(event instanceof HttpResponse) {
            //this.logResponse(event, req)
          }
          return event;
        },
        error: (error) => {
          // Error Hanling interceptor will handle any errors
          console.error('Logging (ignoring) error >>',error);
          this.logResponse(error,req)
          loaderService.removeRequest(req);
          return throwError(() => error);
        }}),
      map(val => {
        if (val instanceof HttpResponse) {
          loaderService.removeRequest(req);
          //this.logResponse(val, req)
        }
        return val;
      }));
  }
  private logResponse(res: HttpResponse<any>, req: HttpRequest<any>) {
    console.log();
  }
}
