import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppUtilityService } from 'src/app/app-utility.service';
import { LMSDialogHandlerService } from '../../lmsdialog-handler.service';

@Component({
  selector: 'app-maximum-loans-dialog',
  templateUrl: './maximum-loans-dialog.component.html',
  styleUrls: ['./maximum-loans-dialog.component.css']
})
export class MaximumLoansDialogComponent {
   clientType = '';
   wageFrequency = '' //:any;

  constructor(
    public lmsDialogHandler: LMSDialogHandlerService,
    public global_utility: AppUtilityService,
    public dialogRef: MatDialogRef<MaximumLoansDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ){
    this.clientType = data.clientType;
    this.wageFrequency = data.wageFrequency;
  }

  close() {
    this.dialogRef!.close();
    //return this.dialogRef.close(this.employmentDetailsForm.value);
  }
}
