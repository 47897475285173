import { Injectable, Optional } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';

import { DocumentScanningComponent } from '../features/document-scanning/document-scanning.component';
import { AddressesComponent } from '../modules/addresses/addresses.component';
import { AuditTrailComponent } from '../modules/audit-trail/audit-trail.component';
import { BankingDetailsComponent } from '../modules/banking-details/banking-details.component';
import { ClientDocumentsComponent } from '../modules/client-documents/client-documents.component';
import { NextOfKinComponent } from '../modules/next-of-kin/next-of-kin.component';
import { RejectOfferComponent, RejectType } from '../modules/reject-offer/reject-offer.component';
import { OfferAuditComponent } from '../offer-audit/offer-audit.component';
import { OfferValuesComponent } from '../offer-values/offer-values.component';
import { CbsGuideComponent } from '../register-loan/cbs-guide/cbs-guide.component';
import { RiskMatrixScoreComponent } from '../risk-matrix-score/risk-matrix-score.component';
import { LexisnexisDialogComponent } from './dialogs/lexisnexis-dialog/lexisnexis-dialog.component';
import { LMSDialogComponent } from './dialogs/lms-dialog/lms-dialog.component';
import { DocumentViewerComponent } from './ui/document-viewer/document-viewer.component';
import { BiometricsDialogComponent } from '../modules/biometrics-dialog/biometrics-dialog.component';
import { AgentComplianceNotificationDialogComponent } from './dialogs/agent-compliance-notification-dialog/agent-compliance-notification-dialog.component';
import { PayslipDialogComponent } from '../modules/payslip-dialog/payslip-dialog.component';
import { BranchManagerOverrideComponent } from '../modules/biometrics-dialog/branch-manager-override/branch-manager-override.component';
import { ClientShowModel, PaySlipModel1, QuoteModel } from '@lmsModels';
import { BranchLoansReportComponent } from './dialogs/branch-loans-report/branch-loans-report.component';
import { BranchPerformanceReportComponent } from './dialogs/branch-performance-report/branch-performance-report.component';
import { BranchStatsReportComponent } from './dialogs/branch-stats-report/branch-stats-report.component';
import { EmploymentDetailsComponent } from '../employment-details/employment-details.component';
import { UpdateEmployerComponent } from '../features/branch-employers/update-employer/update-employer.component';
import { BankAccListComponent } from '../bank-acc-list/bank-acc-list.component';
import { NotesComponent } from '../modules/notes/notes.component';
import { FingerprintSigninComponent } from '../modules/fingerprint-signin/fingerprint-signin.component';
import { MaximumLoansDialogComponent } from './dialogs/maximum-loans-dialog/maximum-loans-dialog.component';

@Injectable()
export class LMSDialogHandlerService {
  public AuditmodalRef: MdbModalRef<AuditTrailComponent> | null = null;

  constructor (private modalService: MdbModalService, @Optional() public matdialog?: MatDialog) {}

  openClientDocuments (clientModel: ClientShowModel, quoteId?: number | string, readonly = false) {
    this.modalService.open(ClientDocumentsComponent, {
      modalClass: 'modal-xl modal-dialog',
      containerClass: 'container-fluid',
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: true,
      data: {
        quoteId: quoteId || clientModel.id,
        clientShow: clientModel,
        isReadOnly: readonly,
      },
    });
  }

  openEmploymentDialog (employment: any, quoteModel: ClientShowModel, addresses: any, readonly = false) {
    const dialogRef = this.modalService.open(EmploymentDetailsComponent, {
      modalClass: 'modal-xl',
      containerClass: 'modal show container-fluid',
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: true,
      data: {
        employment,
        addresses,
        quoteEmployName: employment.name,
        clientShowModel: quoteModel,
        isReadOnly: readonly,
      },
    });
    return dialogRef;
  }

  openUpdateEmployerDialog (employerModel: any) {
    const dialogRef = this.modalService.open(UpdateEmployerComponent, {
      modalClass: 'modal-lg',
      containerClass: 'd-flex d-flex-lg',
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: true,
      data: {
        employerModel,
      },
    });
    return dialogRef;
  }

  openAddressDialog (quoteModel: QuoteModel, addresses: any, readonly = false) {
    const dialogRef = this.modalService.open(AddressesComponent, {
      modalClass: 'modal-lg modal-dialog',
      containerClass: 'd-flex d-flex-lg',
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: true,
      data: {
        addresses,
        clientDetails: quoteModel,
        isReadOnly: readonly,
      },
    });
    return dialogRef;
  }

  openNextofKinDialog (nextOfKin: any, quoteModel: QuoteModel, addresses: any, readonly = false) {
    const dialogRef = this.modalService.open(NextOfKinComponent, {
      modalClass: 'modal-lg',
      containerClass: 'd-flex d-flex-lg',
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: true,
      data: {
        nextOfKin,
        clientDetails: quoteModel,
        addresses,
        isReadOnly: readonly,
      },
    });
    return dialogRef;
  }

  openBankingDetailsDialog (bankingDetails: any, clientDetails: QuoteModel, readonly = false) {
    const dialogRef = this.modalService.open(BankingDetailsComponent, {
      containerClass: 'd-flex d-flex-lg',
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: true,
      modalClass: 'modal-lg',
      data: {
        bankingDetails,
        clientDetails,
        isReadOnly: readonly,
      },
    });
    return dialogRef;
  }

  openDocScanning (quoteModel: QuoteModel, quoteId?: number | string, readonly = false) {
    const dialogRef = this.modalService.open(DocumentScanningComponent, {
      modalClass: 'modal-xl',
      containerClass: 'container-fluid',
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: true,
      data: {
        quoteId: `${quoteId}`,
        clientDetails: quoteModel,
        isReadOnly: readonly,
      },
    });

    return dialogRef;
  }

  openDocumentViewer (fileSrc: string | Blob | Uint8Array, pfileName: string) {
    const dialogRef = this.modalService.open(DocumentViewerComponent, {
      modalClass: 'modal-xl modal-dialog modal-dialog-scrollable d-flex d-flex-xl',
      backdrop: true,
      ignoreBackdropClick: true,
      // modalClass: 'modal-xl modal-dialog modal-dialog-centered',
      // containerClass: 'd-flex d-flex-xl',
      // backdrop: true,
      // ignoreBackdropClick: true,
      // keyboard: true,
      data: {
        fileSrc,
        pdffileName: pfileName,
      },
    });

    return dialogRef;
  }

  openCompuscanOffersDialog (compuscanOffers: any) {
    this.modalService.open(OfferValuesComponent, {
      modalClass: 'modal modal-lg modal-dialog-centered',
      containerClass: 'd-flex d-flex-lg',
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: true,
      data: {
        compuscanOffers,
      },
    });
  }

  openLexisNexisDialog (message: any, title: string) {
    this.modalService.open(LexisnexisDialogComponent, {
      modalClass: 'modal-lg modal-dialog',
      containerClass: 'modal fade show d-flex',
      data: {
        message,
        title,
      },
    });
  }

  openBiometricsDialog (preauthBiometricsData: any, biometricType: string, userBiometricsData?: any) {
    const dialogRef = this.modalService.open(BiometricsDialogComponent, {
      modalClass: 'modal-md modal-dialog-centered',
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: true,
      data: {
        biometricsInfo: preauthBiometricsData,
        userBiometricsInfo: userBiometricsData,
        biometricType,
      },
    });

    return dialogRef;
  }

  openBMOTPOverrideDialog (preauthBiometricsData: any, biometricType: string, userBiometricsData?: any) {
    const dialogRef = this.modalService?.open(BranchManagerOverrideComponent, {
      modalClass: 'modal-md modal-dialog-centered',
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: true,
      data: {
        biometricsInfo: preauthBiometricsData,
        userBiometricsInfo: userBiometricsData,
        biometricType,
      },
    });

    return dialogRef;
  }

  openLMSDialog (confirmMsg: string, msgtitle: string, withTimer: boolean = false, isError = false) {
    const lmsDialogRef = this.modalService.open(LMSDialogComponent, {
      modalClass: 'modal modal-dialog modal-dialog-centered modal-info',
      containerClass: 'd-flex d-flex-md',
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: true,
      data: {
        title: msgtitle,
        message: !confirmMsg || confirmMsg == '' ? 'Please wait!' : confirmMsg,
        showTimer: withTimer,
        isErrorOccured: isError,
      },
    });
    return lmsDialogRef;
  }

  openMatComplianceNotification () {
    const dialogRef = this.matdialog?.open(AgentComplianceNotificationDialogComponent, {
      disableClose: true,
      minHeight: '550px',
      width: 'auto',
      height: 'auto',
      autoFocus: true,
    });
    return dialogRef!;
  }

  openSigninDialog () {
    const fpsignin = this.modalService.open(FingerprintSigninComponent, {
      ignoreBackdropClick: true,
      keyboard: false,
      modalClass: 'modal-md',
      data: {
        biometricType: 'user',
      },
    });

    return fpsignin;
  }

  openRiskMatrixScoresDialog (riskAnalysis: any) {
    this.modalService.open(RiskMatrixScoreComponent, {
      modalClass: 'modal modal-md modal-dialog-centered',
      containerClass: 'd-flex d-flex-md',
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: true,
      data: {
        riskAnalysis,
      },
    });
  }

  openRejectOfferDialog (currQuote: any, setToCancelOrReject: RejectType = 'Rejection') {
    const dialogRef = this.modalService.open(RejectOfferComponent, {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: true,
      nonInvasive: false,
      animation: false,
      modalClass: 'modal modal-dialog modal-dialog-centered ',
      data: {
        rejectOfferInfo: currQuote,
        cancelOrReject: setToCancelOrReject,
      },
    });
    return dialogRef;
  }

  openPayslipDialog (month: string | number, payslipModel?: PaySlipModel1 | null, clientType?: string) {
    const dialogRef = this.modalService?.open(PayslipDialogComponent, {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: true,
      nonInvasive: false,
      modalClass: 'modal-md modal-dialog-centered',
      data: {
        month,
        payslipModel,
        clientType,
      },
    });

    return dialogRef;
  }

  openAuditTrailDialogByQuoteId (quoteId: number) {
    this.AuditmodalRef = this.modalService.open(AuditTrailComponent, {
      backdrop: true,
      ignoreBackdropClick: false,
      keyboard: true,
      nonInvasive: false,
      containerClass: 'd-flex d-flex-xl',
      modalClass: 'modal-xl modal-dialog',
      data: {
        quoteId,
      },
    });
    return this.AuditmodalRef;
  }

  openAuditTrailDialogbyIdNumber (idNumber: string) {
    const dialogRef = this.modalService.open(AuditTrailComponent, {
      backdrop: true,
      ignoreBackdropClick: false,
      keyboard: true,
      nonInvasive: false,
      containerClass: 'd-flex d-flex-xl',
      modalClass: 'modal-xl modal-dialog',
      data: {
        idNumber,
      },
    });
    return dialogRef;
  }

  openOfferAuditDialog (lstaudit: any) {
    //console.log(this.auditTrails, "Audit trail list");
    this.matdialog?.open(OfferAuditComponent, {
      disableClose: false,
      autoFocus: false,
      minHeight: '800px',
      maxHeight: '950px',
      width: '1300px',
      maxWidth: '1300px',
      data: {
        offersList: lstaudit,
        isReadOnly: true,
      },
    });
  }

  openCBSGuideDialog () {
    this.matdialog?.open(CbsGuideComponent, {
      disableClose: true,
      autoFocus: true,
      height: '240px',
      width: '600px',
    });
  }

  openQuoteNotesDialog (quoteID: number, quotenotes: any, readonly = false) {
    const dialogRef = this.modalService.open(NotesComponent, {
      modalClass: 'modal-lg modal-dialog  d-flex',
      data: {
        quoteid: quoteID,
        existingNotes: quotenotes,
        readOnly: readonly,
      },
    });
    return dialogRef;
  }

  openBankAccountListDialog (bankingDetails: any, clientDetails: QuoteModel, readonly = false) {
    const dialogRef = this.modalService.open(BankAccListComponent, {
      modalClass: 'modal-lg modal-dialog  d-flex',
      data: {
        bankingDetails,
        clientDetails,
        readOnly: readonly,
      },
    });
    return dialogRef;
  }

  openBranchLoansDailog (lstLoans: any) {
    console.log('openBranchLoansDialog');
    this.matdialog?.open(BranchLoansReportComponent, {
      disableClose: false,
      autoFocus: false,
      height: '800px',
      width: '1400px',
      maxWidth: '1500px',

      data: {
        loansList: lstLoans,
        isReadOnly: true,
      },
    });
  }

  openBranchPerformanceDialog (lstPerformanceResults: any[]) {
    this.matdialog?.open(BranchPerformanceReportComponent, {
      disableClose: false,
      autoFocus: false,

      data: {
        performanceList: lstPerformanceResults,
        isReadOnly: true,
      },
    });
  }

  openBranchStatsDialog (lstStats: any[]) {
    this.matdialog?.open(BranchStatsReportComponent, {
      disableClose: false,
      autoFocus: false,
      height: '500px',
      width: '450px',
      data: {
        statsList: lstStats,
        isReadOnly: true,
      },
    });
  }

  openMaxLoansDialog (clientType: any, wageFrequency: any) {
    this.matdialog?.open(MaximumLoansDialogComponent, {
      disableClose: true,
      autoFocus: true,
      height: '350px',
      width: '600px',
      data: {
        clientType,
        wageFrequency,
      },
    });
  }
}
