import { HttpClient, HttpContext, HttpRequest } from '@angular/common/http';
import { HostListener, Injectable, isDevMode } from '@angular/core';
import { BehaviorSubject, fromEvent, map, merge, Observable, Observer, Subject } from 'rxjs';
import { TRACKREQ_ENABLED } from 'src/app/core/interceptors/logging.interceptor';
import { environment } from 'src/environments/environment';
import { LoadingSpinService } from '../spinner/loadspinner.service';

@Injectable()
export class ApiloaderService {
  private requestsDur: number[] = [];
  private requests: HttpRequest<any>[] = [];
  public isLoading = new BehaviorSubject(false);

  public isOffline = new BehaviorSubject(false);
  public isAPIOffline = new BehaviorSubject(false);

  constructor(private http: HttpClient,private loaderService: LoadingSpinService) {
    this.createOnline$().subscribe((isOnline) => {
      this.isOffline.next(!isOnline);
      this.isAPIOffline.next(!isOnline);
    });
  }

  getServerAvailable(){
    return this.http.get(environment.apiUrl + '/loan/api/admin/is-available',{
      context: new HttpContext().set(TRACKREQ_ENABLED, false)
    });
  }

  WaitforOnline(lastreq?: HttpRequest<any>){
    return new Promise<boolean>((resolve, reject) => {
      const waiting = false;
      const ongoing_call_check_interval = setInterval(() => {
        console.error('API/Server ERROR >> Retrying server request');
        this.loaderService.startLoading('Attempting reconnect, please wait!');

        this.getServerAvailable().pipe(
        ).subscribe({
          next: (serverres: any) => {
            if(serverres && (serverres.status as string).startsWith('OK')){
              clearInterval(ongoing_call_check_interval);

              this.isAPIOffline.next(false);
              this.isOffline.next(false);
              this.isLoading.next(false);
              this.requests = [];
              this.requestsDur = [];
              this.loaderService.stopLoading();
            }
          },
          error: (err) =>{
            console.warn('API/Server No response');
          }
        });

      }, 10000);
    });
  }

  setRequest = (req: HttpRequest<unknown>) => {
    this.requests.push(req);
    this.requestsDur.push(performance.now());
    if(isDevMode()){
      console.warn('LoaderSvc SetReq >> ', req.url);
    }
    this.isLoading.next(true);
    return req;
  }

  removeRequest = (req: HttpRequest<any>) => {
    const ireq = this.requests.indexOf(req);
    if (ireq >= 0) {
      this.logRequestTime(this.requestsDur[ireq], req.url, req.method);
      this.requests.splice(ireq, 1);
    }

    const finalreq = this.requests.length == 0;
    this.isLoading.next(!finalreq);
  }

  private logRequestTime(startTime: number, url: string, method: string) {
    const requestDuration = `${performance.now() - startTime}`;
    console.info(`${method} ${url} - ${requestDuration} milliseconds`);
  }

  @HostListener('window:ononline', ['$event'])
  public createOnline$(): Observable<boolean> {
    const onlineobserv = new Observable((observer: Observer<boolean>)=>{
      observer.next(navigator.onLine);
    });

    return merge(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      onlineobserv)
  }
}
