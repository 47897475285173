import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { finalize, Observable, tap } from 'rxjs';
import { ApiloaderService } from 'src/app/shared/loaders/apiloader/apiloader.service';

// export const CACHING_ENABLED = new HttpContextToken<boolean>(() => true);
// export function LoaderInterceptorFn(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
//   const loaderService = inject(ApiloaderService);
//   // Clone the request to add the authentication header.
//   return next(loaderService.setRequest(req)).pipe(tap(() => {
//     loaderService.removeRequest(req);
//   }));
// }


@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];
  constructor(private loaderService: ApiloaderService) { }

  private removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  private logRequestTime(startTime: number, url: string, method: string) {
    const requestDuration = `${performance.now() - startTime}`;
    console.info(`${method} ${url} - ${requestDuration}ms`);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const reqWithtime = req.clone();

    this.requests.push(reqWithtime);
    const begin = performance.now();
    this.loaderService.isLoading.next(true);
    if (isDevMode()) {
      console.warn('Loader STRT >> ', req.url);
    }

    return next.handle(req).pipe(
      //tap(evt => {
      //tap((evt: HttpEvent<any>) => {
      //let showdate = new Date().toLocaleString();
      //this.loaderService.isAPIOffline.next(evt instanceof HttpResponse);
      //     if(evt.body && evt.body.success)
      //     this.errorService.handleString(evt.body.success.message);
      //     //this.toastrService.success(`Msg(evt.body.success.message):${evt.body.success.message}`, `${showdate}: (title) ${evt.body.title}`, { positionClass: 'toast-top-center' });
      //}),
      finalize(() => {
        this.removeRequest(reqWithtime);
        this.logRequestTime(begin, reqWithtime.url, reqWithtime.method);
      })
    );
    // .subscribe(
    //   event => {
    //     // if (event instanceof HttpRequest) {
    //     //   req = req.clone({
    //     //     withCredentials: true,
    //     //   });
    //     // }
    //     if (event instanceof HttpResponse) {
    //       //this.removeRequest(req);
    //       observer.next(event);
    //     }
    //   });
    // remove request from queue when cancelled
    //   return () => {
    //     //this.removeRequest(req);
    //     subscription.unsubscribe();
    //   };
    // });
  }

}
