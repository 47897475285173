export class BiometricsModel {
  //Enroll Finger
  // clientId: number | any;
  idNumber: string;
  fingerName: string;
  fingerImage1: string;
  fingerImage2: string;
  fingerImage3: string;
  fingerImage4: string;
  fingerImage5: string;
  fingerImage6: string;
  fingerImage7: string;
  fingerImage8: string;
  fingerImage9: string;
  fingerImage10: string;
  enrolledBy?: string;
  disabledAt: Date;
  disabledBy?: string;

  //User login name
  loginName?: string;
  branchName: string;
  faisUserName: string;

  //Verify Intermediate
  fingerImage: string;
  verifiedBy?: string;
  fingerVerified: boolean;
  verifiedMessage: string;


  //Disable Finger
  id: number | any;
  username?: string;
  userName: string;

  skipReason: string;
  fingerId: number;
  userFingerId: number;
  cellNoExist: string;
  bmUserName: string;

  fingerImages: any[];
}
