export class LMSExcludeActionsModel{
  id: number;
  excludeCode: string;
  description: string;
  documentRequired: boolean;
  createdOn: Date;
  createdBy: string;
  updatedOn: Date;
  updatedBy: string;
  excludeType:string;
  quoteId : number;
}
