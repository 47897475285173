import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MarketingOptionsParametersService {
  @Input() marketingOptionsItems: any[];

  http: HttpClient;
  marketingOptions: any;
  lstMarketingOptions: any[] = [];

  HttpParamsOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(http: HttpClient) {
    this.http = http;
  }

  getMarketingOptions() {
    return this.marketingOptions;
  }

  setMarketingOptions(data: any) {
    this.marketingOptions = data;
  }

  findAll() {
    return this.http.
      get<any>(environment.apiUrl + '/loan/api/admin/findAllMarketingOptionsParameters');
  }

  saveMarketingOptionsParameters(data: any) {
    return this.http
      .post<any>(environment.apiUrl + '/loan/api/admin/saveMarketingOptionsParameters', data)
      .pipe(
        map((responseData) => {
          return responseData;
        })
      );
  }

  removeMarketingOptionsParameters(marketingId: any): Observable<any> {
    return this.http
      .delete(environment.apiUrl + '/loan/api/admin/removeMarketingOptionsParameters/' + marketingId)
      .pipe(
        map((responseData) => {
          return responseData;
        })
      );
  }
}
