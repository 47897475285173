import { HttpClient, HttpContext, HttpRequest } from '@angular/common/http';
import { HostListener, Injectable, isDevMode } from '@angular/core';
import { BehaviorSubject, fromEvent, map, merge, Observable, Observer } from 'rxjs';
import { OVERRIDE_CTX, TRACKREQ_ENABLED } from 'src/app/core/interceptors/http-error.interceptor';
import { environment } from 'src/environments/environment';
import { LoadingSpinService } from '../spinner/loadspinner.service';

@Injectable()
export class ApiloaderService {
  private requestsDur: number[] = [];
  private requests: {url: string; method:string; dur: number}[] = [];
  private activeRequestsCount = 0; // Counter for active requests
  public isLoading = new BehaviorSubject(false);

  public isOffline = new BehaviorSubject(false);
  public isAPIOffline = new BehaviorSubject(false);

  constructor(private http: HttpClient, private loaderService: LoadingSpinService) {}

  getServerAvailable() {
    return this.http.get(environment.apiUrl + '/loan/api/admin/is-available', {
      context: new HttpContext().set(OVERRIDE_CTX, true)
    });
  }

  WaitforOnline(lastreq?: HttpRequest<any>) {
    return new Promise<boolean>((resolve, reject) => {
      const waiting = false;
      const ongoing_call_check_interval = setInterval(() => {
        console.error('API/Server ERROR >> Retrying server request');
        this.loaderService.startLoading('Attempting reconnect, please wait!');

        this.getServerAvailable().pipe().subscribe({
          next: (serverres: any) => {
            if (serverres && (serverres.status as string).startsWith('OK')) {
              clearInterval(ongoing_call_check_interval);

              this.isAPIOffline.next(false);
              this.isOffline.next(false);
              this.removeRequest(lastreq!);
              this.loaderService.stopLoading();
              resolve(true);
            }
          },
          error: (err) => {
            reject(err);
            console.warn('API/Server No response');
          }
        });
      }, 10000);
    });
  }

  setRequest = (req: HttpRequest<any>) => {
    this.requests.push({url: req.url, method: req.method, dur: performance.now()});
    this.requestsDur.push(performance.now());
    this.activeRequestsCount++; // Increment the counter
    if (isDevMode()) {
      console.warn('LoaderSvc SetReq >> ', req.url);
    }
    this.isLoading.next(true);
    return req;
  }

  removeRequest = (req: HttpRequest<any>) => {
    const ireq = this.requests.findIndex(r => r.url === req.url && r.method === req.method);
    if (ireq >= 0) {
      this.logRequestTime(this.requests[ireq].dur, req.url, req.method);
      this.requests.splice(ireq, 1);
      this.activeRequestsCount--; // Decrement the counter
    }

    this.isLoading.next(this.activeRequestsCount != 0);
  }

  private logRequestTime(startTime: number, url: string, method: string) {
    const requestDuration = `${performance.now() - startTime}`;
    console.info(`[${method}] ${url}: ${requestDuration} msecs`);
  }

  @HostListener('window:onLine', ['$event'])
  public createOnline$(): Observable<boolean> {
    const onlineobserv = new Observable((observer: Observer<boolean>) => {
      observer.next(navigator.onLine);
    });

    return merge(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      onlineobserv
    );
  }
}
