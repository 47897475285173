<div class="modal-content">
  <div class="modal-header">
    <div class="modal-title">
      <app-page-cardhead [PageName]="'Biometric Authentication'" style="margin-top: -1.1rem;"
        [PageHeadWidth]="500"></app-page-cardhead>
    </div>
  </div>

  <div class="modal-body">
    <div class="row center-screen">
      <h5>Please Scan Finger</h5>
      <div id="scores" class="scores py-1">
        <span>
          <h6>Scan Quality</h6>
          <input class="form-control fs-5 text-center" type="text" id="qualityInputBox" readonly
            [(ngModel)]="readQuality" style="background-color:#DCDCDC;">

          <div *ngIf="showTimer" class="count-down-timer">
            <div class="example-section text-center">
              <div class="times">
                <p> {{ display }}</p>
              </div>
            </div>
          </div>
        </span>
      </div>

      <div id="content-capture" [style.display]="showStatus ? 'block' : 'none'">
        <div id="status"></div>
        <div id="imagediv"></div>
      </div>
      <div *ngIf="showTimer">
        <div *ngFor="let data of sampleData.slice(0,4); let sampleDataIndex = index;" class="px-2">
          <label class="mt-1" [for]="'sampleData' + sampleDataIndex"><small>Finger Data {{ sampleDataIndex + 1}}</small>
          </label>
          <label sampleDataIndex><small>: Captured success</small></label>
        </div>
      </div>
    </div>
    <hr class="hr-blurry hr my-1">
    <div class="card-footer flex-container">
      <button type="button" class="btn btn-primary me-3" [disabled]="startAcquisitionDisabled"
        (click)="startCapture();showStatus=true;">Start</button>
      <button type="button" *ngIf="!environmentCheck" class="btn btn-accent ms-4" (click)="close()">Close</button>
    </div>
  </div>
</div>