import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClientDetailsService } from '@lmsrestServices';
import { MdbModalRef } from "mdb-angular-ui-kit/modal";
import { AppUtilityService } from "src/app/app-utility.service";
import { ErrorhanderService } from 'src/app/core/services/error-handler.service';
import { LMSDialogHandlerService } from 'src/app/shared/lmsdialog-handler.service';
import { UserService } from 'src/app/core/services/lmsuser.service';
import { FinbondFileModel } from "src/app/core/models/finbondFile.model";
import { LoanDocModel } from 'src/app/core/models/loanDoc.model';
import { NgxPaginationModule, PaginationInstance } from 'ngx-pagination';
import { MdbFormsModule } from 'mdb-angular-ui-kit/forms';
import { FormsModule } from '@angular/forms';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { MatButtonModule } from '@angular/material/button';
import { LmsPaginateComponent, PageCardheadComponent } from '@lmsShared';

//
//  NOTE TO ALL DEVELOPERS!!! DO NOT CHANGE ANYTHING ON THIS COMPONENT!!!
//


@Component({
  selector: 'app-document-scanning',
  templateUrl: './document-scanning.component.html',
  styleUrls: ['./document-scanning.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    PageCardheadComponent,
    MatButtonModule,
    MdbRippleModule,
    FormsModule,
    MdbFormsModule,
    NgxPaginationModule,
    LmsPaginateComponent,
  ],
})
export class DocumentScanningComponent implements OnInit {
  loanDoc: LoanDocModel = new LoanDocModel();
  curr: any;
  downloadObject: any = {};
  finalFileName: any;
  downloadResults: any = {};
  clientDetails: any;
  quoteId: any;
  requiredDocs: any[] = [];
  lstDocuments: any[] = [];
  selectedDocType: any;

  //PAGINATOR CODE
  config: PaginationInstance = {
    id: 'pager',
    itemsPerPage: 5,
    currentPage: 1,
  };

  @Input() items: any[];
  @Output() changePage = new EventEmitter<any>(true);
  @Input() initialPage = 1;
  @Input() pageSize = 5;
  @Input() maxPages = 1;

  docCount = 1;

  isReadOnly: boolean;
  clientStatus: string;
  constructor(
    private clientDetailsService: ClientDetailsService,
    private userService: UserService,
    private errorHandler: ErrorhanderService,
    private lmsDialogHandler: LMSDialogHandlerService,

    public global_utility: AppUtilityService,
    public dialogRef: MdbModalRef<DocumentScanningComponent>,
  ) {}

  ngOnInit(): void {
    this.loanDoc = new LoanDocModel();
    this.clientStatus = this.clientDetails.clientStatus;

    this.listClientDocs();
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   // reset page if items array has changed
  //   if (changes.currentValue !== changes.previousValue) {
  //     this.setPage(this.initialPage);
  //   }
  // }

  onReqDocSelect($event: any) {
    this.selectedDocType = $event;

    this.loanDoc.documentName = this.selectedDocType.documentName;
    this.loanDoc.docId = this.selectedDocType.id;
    this.loanDoc.documentType = this.selectedDocType.documentType;
    this.loanDoc.docCheckListId = this.selectedDocType.id;
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    // this.fileName = file.name;
    const finbondFile = new FinbondFileModel();
    finbondFile.fileName = file.name;
    finbondFile.contentType = file.type;

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader.result) { // eslint-disable-line @typescript-eslint/strict-boolean-expressions
        // eslint-disable-line @typescript-eslint/strict-boolean-expressions
        finbondFile.fileContents = reader.result.toString().split(',')[1];
        // this.loanDoc.file = finbondFile;
        this.loanDoc.contentType = finbondFile.contentType;
        this.loanDoc.originalFileName = finbondFile.fileName;
        this.loanDoc.documentContents = finbondFile.fileContents;
      }
    };
  }

  onDocumentSubmit() {
    if (!this.loanDoc.documentType) {
      // alert('Please select a document from the list');
      this.global_utility.info(
        `Please select *loanDoc.documentType* a document from the list`,
        'Document list',
      );
      return;
    }

    if (!this.loanDoc.originalFileName) {
      //alert('Please choose a file to be submitted');
      this.global_utility.info(
        `Please select a file from the list`,
        'Document list',
      );
      return;
    }

    this.global_utility.startLoading('Submitting document.');
    // this.isLoading = true;
    this.loanDoc.idNumber = this.clientDetails.idNumber;
    this.loanDoc.customerId = this.clientDetails.clientNumber;
    this.loanDoc.loanId = 'Q' + this.quoteId;
    this.loanDoc.userName = this.userService.userLoginName;
    this.loanDoc.applicationId = this.quoteId;
    this.loanDoc.docCheckListId = this.loanDoc.docId;

    this.clientDetailsService.uploadDocument(this.loanDoc).subscribe((res) => {
      if (!res) {
        this.global_utility.error(
          `An upload error has occured. Please check that the document is valid and try again`,
          'Document list',
        );
        return;
      }
      // this.lstDocuments = res;

      // for (let doc of this.lstDocuments) {
      //   doc.documentType = doc.documentType.replaceAll("_", " ");
      // }

      // this.items = this.lstDocuments;
      // if (this.items && this.items.length) {
      //   this.setPage(this.initialPage);
      // }

      // this.getDocChecklist();

      this.global_utility.stopLoading();
      this.listClientDocs();
      this.loanDoc = new LoanDocModel();

      // if (res.status != 'success') {
      //    //alert("An upload error has occured. Please check that the document is valid and try again.")
      //   this.toastrService.error(`An upload error has occured. Please check that the document is valid and try again`, 'Document list',{ positionClass: 'toast-top-center'});
      // } else {
      //   this.listClientDocs();
      // }
    });
  }

  onDownloadPdf(data: any) {
    this.curr = JSON.stringify(data);
    this.finalFileName = this.curr.split('"').join('');
    console.log('Curr ' + this.finalFileName);

    this.downloadObject.fileName = this.finalFileName;
    this.downloadObject.bucketName = '';
    this.downloadObject.userName = '';
    this.downloadObject.originalFileName = '';

    this.clientDetailsService
      .downloadRaw(this.downloadObject)
      .subscribe((res: any) => {
        this.downloadResults = res;
        const base64String = this.downloadResults.documentContent;

        this.openPdfViewer(
          base64String,
          this.downloadResults.originalFilename,
          this.downloadResults.contentType,
        );
      });
  }

  openPdfViewer(base64String: any, fileName: string, contentType: string) {
    const source = `data:` + contentType + `;base64,` + `${base64String}`;
    this.lmsDialogHandler.openDocumentViewer(source, fileName);
  }

  close() {
    this.dialogRef.close('Closed');
  }

  listClientDocs() {
    this.loanDoc.quoteId = 'Q' + this.quoteId;
    this.clientDetailsService.listClientDocs(this.loanDoc).subscribe((res) => {
      if (res) {
        this.lstDocuments = res;

        for (const doc of this.lstDocuments) {
          doc.documentType = doc.documentType.replaceAll('_', ' ');

          if (doc.checklistId?.includes('_')) {
            let counter = doc.checklistId.substring(
              doc.checklistId.indexOf('_') + 1,
            );
            counter = +counter + 1;
            this.docCount = counter > this.docCount ? counter : +this.docCount;
          }
        }

        this.getDocChecklist().then((dropList) => {
          if (dropList) {
            this.requiredDocs.push({
              id: `${this.quoteId}_${this.docCount}`,
              documentName: 'Proof of Residence',
              documentType: 'PROOF_OF_RESIDENCE',
            });
            this.requiredDocs.push({
              id: `${this.quoteId}_${this.docCount}`,
              documentName: 'Other',
              documentType: 'OTHER',
            });
          }
        });
      }
    });
  }

  getDocChecklist() {
    const qid: number = this.quoteId;

    return new Promise((resolve, reject) => {
      this.clientDetailsService.getDocChecklist(qid).subscribe({
        next: (dbChecklist) => {
          if (dbChecklist) {
            this.requiredDocs = dbChecklist;
          }
          resolve(this.requiredDocs);
        },
        error: (err) => {
          this.errorHandler.handle(err);
          reject();
        },
      });
    });
  }

  deleteDocument(doc: any) {
    this.global_utility.startLoading('Submitting document..');
    doc.quoteId = this.quoteId;
    doc.idNumber = this.clientDetails.idNumber;
    doc.userName = this.userService.userLoginName;

    if (
      doc.documentType == 'RISK ANALYSIS' ||
      doc.documentType == 'COMPUSCAN OFFER'
    ) {
      this.global_utility.error(
        `Document ${doc.documentType} cannot be deleted`,
        'Document list',
      );
    } else {
      this.clientDetailsService.deleteDocument(doc).subscribe((res) => {
        if (res) {
          this.requiredDocs = res;
          this.listClientDocs();
        }
        this.global_utility.stopLoading();
      });
    }
  }

  confirmDocumentScanningDetails() {
    this.loanDoc.isConfirmedDocScanningDetails = true;

    this.dialogRef.close('Confirmed');
  }

  covertDate(date: any) {
    if (date != null) {
      let str = date;
      str = str.substring(0, 10);
      date = str;
      return date;
    } else {
      return date;
    }
  }
}

