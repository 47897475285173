import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
  FormGroup,
} from '@angular/forms';
//import { ClientDetailsService } from '../rest-services/client-details.service';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { AppUtilityService } from 'src/app/app-utility.service';
//import { NextOfKinV2 } from "./nok-editor/nok.model";
import { PageCardheadComponent } from '../../shared/ui/pageheader-card/pageheader-card.component';
import { LMSSelectOptionDirective } from 'src/app/shared/directives/lms-selectOption.directive';
import { NextOfKinModel } from '@lmsModels';

// enum CheckBoxType {
//   APPLY_FOR_JOB,
//   MODIFY_A_JOB,
//   NONE,
// }

const ADDRESS_TYPE_NEXTOFKIN = 'NEXTOFKIN';
const CLIENT_TYPE_SASSA = 'SASSA';
const PHONE_NUMBER_PATTERN = '(0)[0-9]{9}$';
const AREA_CODE_PATTERN = '[0-9][0-9]{3}';

@Component({
  selector: 'app-next-of-kin',
  templateUrl: './next-of-kin.component.html',
  styleUrls: ['./next-of-kin.component.css'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush, // Use OnPush strategy
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LMSSelectOptionDirective,
    PageCardheadComponent,
  ],
})
export class NextOfKinComponent implements OnInit {
  nextOfKin: any | null;
  labelPosition: 'before' | 'after' = 'after';

  clientDetails: any;
  nextOfKinForm: FormGroup;
  nextOfKin2Form: FormGroup;
  nextOfKinModel = new NextOfKinModel();
  private _hasNextOfKin?: boolean;
  private _hasNextOfKin2?: boolean;
  submitted?: boolean = false;
  isValidForm?: boolean = false;
  addresses?: any;
  displayWidth = 390;
  lmsUserProfile: any;
  isReadOnly = false;
  clientStatus: string | '';

  isSassaClient?: boolean = false;

  constructor(
    public fb: FormBuilder,
    public global_utility: AppUtilityService,
    public dialogRef: MdbModalRef<NextOfKinComponent>,
    private cdr: ChangeDetectorRef // Inject ChangeDetectorRef
  ) {}

  get hasNextOfKin() {
    return this._hasNextOfKin || false;
  }

  get hasNextOfKin2() {
    return this._hasNextOfKin2 || false;
  }

  private createNextOfKinForm(prefix: string): FormGroup {
    const addline1 = (prefix=='1')?'addressLine1':'line1';
    const addline2 = (prefix=='1')?'addressLine2':'line2';

    return this.fb.group({
      [`name${prefix}`]: ['', [Validators.required]],
      [`surname${prefix}`]: ['', [Validators.required]],
      [`${addline1}`]: ['', [Validators.required]],
      [`${addline2}`]: [''],
      [`suburb${prefix}`]: ['', [Validators.required]],
      [`town${prefix}`]: ['', [Validators.required]],
      [`areaCode${prefix}`]: [
        '',
        [Validators.required, Validators.pattern(AREA_CODE_PATTERN)],
      ],
      [`email${prefix}`]: [''],
      [`contactNo${prefix}`]: [
        '',
        [Validators.required, Validators.pattern(PHONE_NUMBER_PATTERN)],
      ],
      [`relationship${prefix}`]: [[], [Validators.required]],
      [`province${prefix}`]: [[], [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.nextOfKinForm = this.createNextOfKinForm('1');
    this.nextOfKin2Form = this.createNextOfKinForm('2');
    this._hasNextOfKin = false;

    this.checkClientType();
    this.clientStatus = this.clientDetails.clientStatus;
    this.validateNextOfKin();
  }

  checkClientType() {
    if (this.clientDetails.clientType === CLIENT_TYPE_SASSA) {
      this.isSassaClient = true;
    }
    this.nextOfKinModel.onlyOneNextOfKin = false;
  }

  onSaveSubmit(): void {
    this.submitted = true;
    this.isValidForm = true;

    if (this.nextOfKinForm?.invalid || this.nextOfKin2Form?.invalid) {
      this.isValidForm = false;
      //let invControls = this.findInvalidControls();
      this.global_utility.warning(
        `Please ensure that all the information needed on this page is entered`,
        'Information required',
      );
      return;
    }

    if (this.isSassaClient && this.nextOfKinModel.onlyOneNextOfKin) {
      if (this.nextOfKin2Form?.invalid) {
        this.isValidForm = false;
        this.global_utility.warning(
          `SASSA clients must provide details for two Next of Kin`,
          'Information required',
        );
        return;
      }
    }

    if (this.isValidForm) {
      // this.saveNextOfKin();
      // this.updateAddress();
      this.nextOfKinModel = {
        ...this.nextOfKinModel,
        ...Object.fromEntries(Object.entries(this.nextOfKinForm?.value || {}).map(([key, value]) => [key, value ?? ''])),
        ...Object.fromEntries(Object.entries(this.nextOfKin2Form?.value || {}).map(([key, value]) => [key, value ?? '']))
      }
      this.dialogRef.close(this.nextOfKinModel);
    }
  }

  updateAddress() {
    if (this.nextOfKin) {
      const nokSize = this.nextOfKin.length;
      const hasNOK = this.nextOfKin[0];

      if (hasNOK) {
        this._hasNextOfKin = true;
        this._hasNextOfKin2 = false;
      } else {
        if (nokSize == 2) {
          const hasNOK2 = this.nextOfKin[1];
          if (hasNOK2) {
            this._hasNextOfKin2 = true;
          }
        }
      }
    }
  }

  validateNextOfKin(): void {
    this.isValidForm = false;
    if (this.nextOfKin) {
      const nokSize = this.nextOfKin.length;
      this._hasNextOfKin2 = nokSize > 1;

      this.populateNextOfKinDetails(this.nextOfKin[0], this.addresses);
      if (this._hasNextOfKin2) {
        this.populateNextOfKinDetails(this.nextOfKin[1], this.addresses, true);
        this.isValidForm = true;
      }
    }
  }

  private populateNextOfKinDetails(nok: any, addresses: any[], isSecond: boolean = false): void {
    const prefix = isSecond ? '2' : '1';
    if (nok) {
      this[`_hasNextOfKin${isSecond ? '2' : ''}`] = true;

      this.nextOfKinModel[`name${prefix}`] = nok.firstName;
      this.nextOfKinModel[`relationship${prefix}`] = nok.relationship;
      this.nextOfKinModel[`surname${prefix}`] = nok.lastName;
      this.nextOfKinModel[`email${prefix}`] = nok.email;
      this.nextOfKinModel[`contactNo${prefix}`] = nok.contactNo1;
      this.nextOfKinModel[`address${prefix}id`] = nok.address1id;

      const address = addresses.find(
        (addr) => addr.addressType === ADDRESS_TYPE_NEXTOFKIN && addr.id === nok.homeAddressId
      );

      const addline1 = (!isSecond)?'addressLine1':'line1';
      const addline2 = (!isSecond)?'addressLine2':'line2';
      if (address) {
        this.nextOfKinModel[`${addline1}`] = address.line1;
        this.nextOfKinModel[`${addline2}`] = address.line2;

        this.nextOfKinModel[`suburb${prefix}`] = address.suburb;
        this.nextOfKinModel[`province${prefix}`] = address.province;
        this.nextOfKinModel[`town${prefix}`] = address.town;
        this.nextOfKinModel[`areaCode${prefix}`] = address.code;
      }
    }

  }

  onReset() {
    this.submitted = false;
    this.nextOfKinForm?.reset();
  }

  close() {
    this.dialogRef.close();
  }

  editNextOfKin() {
    this.nextOfKinForm.patchValue(this.nextOfKinModel,{onlySelf: false});
    this.nextOfKin2Form.patchValue(this.nextOfKinModel,{onlySelf: false});


    this._hasNextOfKin = false;
    setTimeout(() => {
      this.relationship2.reset();
      this.nextOfKin2Form?.get('province2')?.reset();

      if(this.nextOfKinModel.relationship2){
        this.nextOfKin2Form?.get('relationship2')?.setValue(this.nextOfKinModel.relationship2);
      }
      if(this.nextOfKinModel.relationship1){
        this.nextOfKinForm?.get('relationship1')?.setValue(this.nextOfKinModel.relationship1);
      }
      if(this.nextOfKinModel.province2){
        this.nextOfKin2Form?.get('province2')?.setValue(this.nextOfKinModel.province2);
      }
      if(this.nextOfKinModel.province1){
        this.nextOfKinForm?.get('province1')?.setValue(this.nextOfKinModel.province1);
      }

       // Explicitly trigger change detection after updating values
      this.cdr.markForCheck();
    }, 50);
  }

  get validateNok() {
    const ctrl = this.nextOfKinForm?.controls;
    return ctrl;
  }

  get validateNok2() {
    return this.nextOfKin2Form?.controls;
  }

  get contactNo1() {
    return (
      this.nextOfKinModel.contactNo1 &&
      this.nextOfKinModel.contactNo1.length == 10
    );
  }

  get contactNo2() {
    if (this.nextOfKinModel.onlyOneNextOfKin) {
      return true;
    }

    return (
      this.nextOfKinModel.contactNo2 &&
      this.nextOfKinModel.contactNo2.length == 10
    );
  }

  get relationship2() {
    return this.nextOfKin2Form?.controls?.relationship2;
  }

  validateForm() {
    this.isValidForm = true;
    //var control = this.nextOfKinForm?.controls;

    if (
      this.nextOfKinForm?.invalid ||
      (!this.nextOfKinModel.onlyOneNextOfKin && this.nextOfKin2Form?.invalid)
    ) {
      this.isValidForm = false;
    }
  }

  confirmNextOfKinDetails() {
    if(!this.isValidForm) {
      this.global_utility.warning(
        `Please ensure that all the information needed on this page is entered`,
        'Information required',
      );
      return;
    }

    this.nextOfKinModel.isConfirmedNokDetails = true;
    this.dialogRef.close('Confirmed');
  }
}
