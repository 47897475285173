import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RiskScoreModel } from '@lmsModels';
import { PageCardheadComponent } from '@lmsShared';
import { RiskScoresService } from '../risk-scores.service';

@Component({
    selector: 'app-risk-scores-edit-dialog',
    templateUrl: './risk-scores-edit-dialog.component.html',
    styleUrls: ['./risk-scores-edit-dialog.component.css'],
    standalone: true,
    imports: [CommonModule, FormsModule, ReactiveFormsModule, PageCardheadComponent]
})
export class RiskScoresEditDialogComponent implements OnInit {
  riskScoreModel: RiskScoreModel = new RiskScoreModel();
  editRiskScoreForm: FormGroup;
  submitted: boolean = false;
  riskScorecardInfo: any;

  constructor(
    public dialogRef: MatDialogRef<RiskScoresEditDialogComponent>,
    private formBuilder: FormBuilder,
    public riskScoreService: RiskScoresService,
    @Inject(MAT_DIALOG_DATA) data: any
  ) { this.riskScorecardInfo = data.riskScorecard }

  ngOnInit(): void {
    // this.keycloak.loadUserProfile().then((user: any) => {
    //   this.userProfile = user;
    // });

    this.editRiskScoreForm = this.formBuilder.group({
      // 'manageEngineId': [this.riskScoreModel.manageEngineId, [Validators.required]],
      'parameterType': [this.riskScoreModel.parameterType, [Validators.required]],
      'description': [this.riskScoreModel.description, [Validators.required]],
      'score': [this.riskScoreModel.score, [Validators.required]]
    });

    this.riskScoreModel.parameterType = this.riskScorecardInfo.parameterType;
    this.riskScoreModel.description = this.riskScorecardInfo.description;
    this.riskScoreModel.score = this.riskScorecardInfo.score;
  }

  saveRiskScore() {
    this.submitted = true;
    var isValidData = true;

    console.log(this.riskScorecardInfo, "..... riskScorecard...");
    this.submitted = true;
    var isValidData = true;

    if (this.editRiskScoreForm.invalid) {
      isValidData = false;
      console.log("Form not valid...");
    }

    if (isValidData) {
      return this.dialogRef.close(this.riskScoreModel);
    }

    // if (this.editRiskScoreForm.invalid) {
    //   isValidData = false;
    //   console.log("From data is invalid...");
    // }

    // if (this.riskScoreModel.parameterType == null || this.riskScoreModel.parameterType == '' || this.riskScoreModel.description == null || this.riskScoreModel.description == '' || this.riskScoreModel.score == null) {
    //   isValidData = false;
    //   console.log("From data is invalid...");
    // }

    // if (isValidData) {
    //   return this.dialogRef.close(this.riskScoreModel);
    // }

  }

  close() {
    this.dialogRef.close()
  }

  get validateRiskScoreValues() {
    return this.editRiskScoreForm.controls;
  }

  omit_special_char(event: any) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }
}
