import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppUtilityService } from 'src/app/app-utility.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxExtendedPdfViewerComponent, NgxExtendedPdfViewerModule, NgxExtendedPdfViewerService, OutlineLoadedEvent, pdfDefaultOptions, PdfLoadedEvent,
  PdfSidebarView, ProgressBarEvent, ScrollModeType, XfaLayerRenderedEvent } from 'ngx-extended-pdf-viewer';
import { Observable, Subject } from 'rxjs';
import { UserService } from 'src/app/core/services/lmsuser.service';
import { MdbModalModule, MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { MdbFormsModule } from 'mdb-angular-ui-kit/forms';

@Component({
  selector: 'app-document-viewer',
  standalone: true,
  providers:[
    MdbModalService,
    NgxExtendedPdfViewerService
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MdbModalModule,
    MdbFormsModule,
    NgxExtendedPdfViewerModule
  ],
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.css']
})
export class DocumentViewerComponent implements OnInit, AfterViewInit {
  @ViewChild(NgxExtendedPdfViewerComponent, {static: false})
  private pdfViewer!: NgxExtendedPdfViewerComponent;

  @Input() pdfSrc!: string | Uint8Array | Blob;

  PdfLoading$: Observable<boolean>;

  public base64 = new Subject<string>();

  error: any;
  fileSrc: any;
  pdffileName!: string;

  public printPercentage = 0;
  public totalPages = 0;
  public currentPageRendered = 0;
  public showProgress = true;
  public showCompleted = false;
  public hideBuiltInProgress = false;
  public page =0;
  public minZoom = 0.33;
  public maxZoom = 1.5;
  public zoomLevels = ['auto', 'page-actual', 'page-fit', 'page-width', 0.33, 0.75, 1, 1.25, 1.5];
  public zoomSetting: number | string | undefined = 'page-actual';
  private currentZoomFactor: number = 0;
  public handTool = true;
  public pageLabel!:string;
  public sidebarVisible = false;
  public activeSidebarView: PdfSidebarView = PdfSidebarView.THUMBS;
  public showContextMenu = false;
  public showBorders = true;
  public bearerToken: string | undefined = undefined;

  public set pdftheme(themecolor: string |'dark' | 'light') {
    // this demo stores the theme in the local storage
    // because it take on reload only
    localStorage.setItem('ngx-extended-pdf-viewer.theme', themecolor);
  }
  public get pdftheme(): string |'dark' | 'light' {
    return localStorage.getItem('ngx-extended-pdf-viewer.theme') || 'dark';
  }

  constructor(public global_utility: AppUtilityService,
    private pdfService: NgxExtendedPdfViewerService,
    private userService : UserService,
    public modalRef: MdbModalRef<DocumentViewerComponent>) {
    pdfDefaultOptions.ignoreDestinationZoom = true;
    pdfDefaultOptions.enableScripting = false;
    pdfDefaultOptions.enablePermissions = true;
    pdfDefaultOptions.defaultCacheSize = 5;

    pdfDefaultOptions.defaultZoomValue = 0.58;
    this.bearerToken = 'Bearer ' + this.userService.accessToken;

    modalRef.onClose.subscribe((result)=>{
      this.pdfService.removePrintRange();
      this.pdfViewer.ngOnDestroy();
    })
  }
  ngAfterViewInit(): void {
    setTimeout(() => this.pdfSrc = this.fileSrc, 200);
  }

  ngOnInit() { }

  blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const dataUrl = reader.result as string;
        const base64 = dataUrl.split(',')[1];
        resolve(base64);
      };
      reader.readAsDataURL(blob);
    });
  }

  downloadPdfFile(): void {
    //this.pdfData.save();
    //const source = `data:` + contentType + `;base64,` + `${base64String}`;
    const link = document.createElement("a");
    this.pdfService.getCurrentDocumentAsBlob().then((fdata)=>{
      this.blobToBase64(fdata).then(val => {
        link.href = val
        link.download = this.pdffileName;
        link.click();
      }
      );
    })
  }


  public async downloadPdfasBlob(): Promise<void> {
    const blob = await this.pdfService.getCurrentDocumentAsBlob();
    // if (blob) {
    //   //this.downloaded = 'The BLOB contains ' + blob.size + ' byte.';
    // } else {
    //   //this.downloaded = 'download failed';
    // }
    if (blob) { // eslint-disable-line @typescript-eslint/strict-boolean-expressions, @typescript-eslint/no-unnecessary-condition
      //this.blobToBase64(blob).then((fdata)=>{
      const link = document.createElement("a");
      link.href = await blob.text();
      link.download = this.pdffileName;
      link.click();
      //});
    } else {
      // this.downloaded = 'download failed';
    }
  }

  close() {
    this.modalRef.close("Closed");
  }

  /**
 * Handle error callback
 *
 * @param error error message
 */

  onError(error: any) {
    this.error = error; // set error

    if (error.name === 'PasswordException') {
      const password = prompt(
        'This document is password protected. Enter the password:'
      );

      if (password) {
        this.error = null;
      //this.setPassword(password);
      }
    }
  }

  public onPageRendered(): void {
    if (!this.pdfService.isRenderQueueEmpty()) {
    // try again later when the pages requested by the pdf.js core or the user have been rendered
      setTimeout(() => this.onPageRendered(), 100);
    }

    const pagesBefore = 2;
    const pagesAfter = 2;
    const startPage = Math.max(this.page - pagesBefore, 1);
    const endPage = Math.min(this.page + pagesAfter, this.pdfService.numberOfPages());

    const renderedPages = this.pdfService.currentlyRenderedPages();

    for (let page = startPage; page <= endPage; page++) {
      const pageIndex = page - 1;
      if (!this.pdfService.hasPageBeenRendered(pageIndex)) {
        this.pdfService.addPageToRenderQueue(pageIndex);
        break; // break because you can request only one page at a time
      }
    }
  }

  // setPassword(password: string) {
  //   let newSrc: PDFSource;

  //   if (this.pdfSrc instanceof ArrayBuffer) {
  //     newSrc = { data: this.pdfSrc as any };
  //     // newSrc = { data: this.pdfSrc };
  //   } else if (typeof this.pdfSrc === 'string') {
  //     newSrc = { url: this.pdfSrc };
  //   } else {
  //     newSrc = { ...this.pdfSrc };
  //   }

  //   newSrc.password = password;

  //   newSrc.password = password;

  //     this.pdfSrc = newSrc;
  // }

  // public onBeforePrint() {
  //   if (this.hideBuiltInProgress) {
  //     const node = document.querySelector('.pdf-wrapper #printServiceDialog') as Element;
  //     node.setAttribute('style', 'display:none!important');
  //   }
  //   this.showCompleted = false;
  //   this.showProgress = true;
  // }

  // public onAfterPrint() {
  //   const node = document.querySelector('.pdf-wrapper #printServiceDialog') as Element;
  //   node.removeAttribute('style');
  //   this.showCompleted = true;
  //   this.showProgress = false;
  // }


  public onBeforePrint() {
    if (this.hideBuiltInProgress) {
      const node = document.querySelector('.pdf-wrapper #printServiceDialog') as Element;
      node.setAttribute('style', 'display:none!important');
    }
    this.showCompleted = false;
    this.showProgress = true;
  }

  public onAfterPrint() {
    const node = document.querySelector('.pdf-wrapper #printServiceDialog') as Element;
    node.removeAttribute('style');
    this.showCompleted = true;
    this.showProgress = false;
  }

  public print() {
    this.pdfService.print();
  }

  public cancel() {
    document.getElementById('printCancel')?.click();
  }

  get isPrintCancelled(): boolean {
    return this.totalPages !== this.currentPageRendered;
  }

  public onProgress(event: ProgressBarEvent): void {
    if (this.showProgress) {
      if(event.type=='load' && event.percent == 100){
        this.showCompleted = true;
        this.showProgress = false;
      }

      if(event.type=='print'){
        this.totalPages = event.total;
        this.printPercentage = event.percent;
        this.currentPageRendered = event.page ?? 0;
      }
    }
  }

  public updateZoomFactor(zoom: number): void {
    this.currentZoomFactor = zoom;
  }

}
