export class BanksModel {
  id: number;
  code: string;
  description: string;
  bankName: string;
  branchCode: string;
  accountNumber: string;
  blocked: boolean;
  debiCheckBank: boolean;
  debiCheckSlipRequired: boolean;
}
