export class ProcessReasonModel{

    //REJECTED REASONS
    id:number;
    reasonType: string;
    reasonCode: string;
    reasonValue: string;
    value: string;
    description: string;

    createdOn: Date;
    createdBy: string;
    updateOn: Date;
    updateBy: string;

    isActive: boolean;
}
