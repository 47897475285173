/* eslint-disable @typescript-eslint/no-unused-expressions */
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { NgxPaginationModule, PaginationInstance } from 'ngx-pagination';
import { Subscription } from 'rxjs';
import { LmsPaginateComponent, LMS_PaginateDirective } from '../lms-paginate/lms-paginate.component';

@Component({
  selector: 'lms-gridview',
  styleUrls: ['./lms-gridview.component.css'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <table
        mat-table
        [dataSource]="table_data_source!" w1000
        class="table table-auto table-borderless table-striped table-hover table-sm shadow-1-strong rounded-1 p-1">
        <!-- class="table table-auto table-bordered table-light table-hover text-nowrap table-striped table-sm mb-2 p-1 ms-auto me-auto rounded-1 "> -->
        <ng-container *ngFor="let column of table_config.columns; index as i" [matColumnDef]="column.key">
          <th mat-header-cell *matHeaderCellDef [ngClass]="{ 'numeric-col': !!column.numeric }">
            {{ column.heading }}
          </th>
          <td
            mat-cell
            *matCellDef="let table_row"
            [ngClass]="{ 'numeric-col': !!column.numeric }"
            [ngClass]="{ 'date-col': !!column.datecol }"
          >
            <ng-container *ngIf="!!column.datecol">
              <span class="date-col">{{ table_row[column.key] | date: 'yyyy-MM-dd HH:mm:ss' }}</span>
            </ng-container>

          <ng-container *ngIf="!!column.status">
            <span
              *ngIf="table_row[column.key] === 'Active'"
              class="badge badge-primary rounded-pill d-inline"
              >{{ table_row[column.key] }}</span
            >
            <span
              *ngIf="
                table_row[column.key] === 'New' ||
                table_row[column.key] === 'Pending'
              "
              class="badge badge-info rounded-pill d-inline"
              >{{ table_row[column.key] }}</span
            >
            <span
              *ngIf="table_row[column.key] === 'Submitted'"
              class="badge badge-success rounded-pill d-inline"
              >{{ table_row[column.key] }}</span
            >
            <span
              *ngIf="
                table_row[column.key] === 'Cancelled' ||
                table_row[column.key] === 'Rejected'
              "
              class="badge badge-dark text-bg-warning rounded-pill d-inline"
              >{{ table_row[column.key] }}</span
            >
            <span
              *ngIf="table_row[column.key] === 'Disbursed'"
              class="badge badge-secondary rounded-pill d-inline"
              >{{ table_row[column.key] }}</span
            >
            <span
              *ngIf="
                table_row[column.key] === 'NuPay' ||
                table_row[column.key] === 'Journal'
              "
              class="badge text-emphasis badge-secondary rounded-pill d-inline"
              >{{ table_row[column.key] }}</span
            >
          </ng-container>
          <ng-container *ngIf="!column.datecol && !column.status">
            <span>{{ table_row[column.key] }}</span>
          </ng-container>
        </td>
      </ng-container>
      <ng-container *ngIf="!!table_config?.ediTable?.edit" matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef>View</th>
        <td
          mat-cell
          *matCellDef="let table_row"
          class="row-action-column"
          style="width: 75px;">
          <a type="button"
            class="btn btn-block btn-primary"
            data-mdb-ripple-color="#ffffff"
            style="background-color:#3f51b5; color: #fafafa;"
            aria-label="View"
            [textContent]="table_row.status === 'Submitted' ? 'View' : 'Continue'"
            (click)="viewSelectedItem(table_row)"></a>
        </td>
      </ng-container>
      <ng-container
        *ngIf="!!table_config?.ediTable?.add"
        matColumnDef="add"
        class="lms-table__add">
        <th mat-header-cell *matHeaderCellDef>View</th>
        <td
          mat-cell
          *matCellDef="let table_row"
          class="row-action-column"
          style="white-space: nowrap">
          <button
            class="lms-table__action-button"
            matTooltip="Add New Row"
            matTooltipPosition="left"
            aria-label="Add New Row"
            (click)="addRow()">
            <span class="material-icons"> playlist_add </span>
          </button>
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="displayed_columns"
        class="table-primary bg-primary bg-gradient table-center-header text-wrap"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayed_columns; let i = index"
        class="text-wrap"
      ></tr>
    </table>
    <lms-paginate [config]="config" (changePaged)="setPage($event)"></lms-paginate>
    <mat-paginator #paginator [pageSize]="config.itemsPerPage" style="display: none;">
    </mat-paginator>
    <ul style="display: none;">
    <li *ngFor="let item of table_data_source.data | paginate: config">
    </li>
    </ul>`,
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    NgxPaginationModule,
    LmsPaginateComponent
  ],
  standalone: true,
})
export class LmsGridviewComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('paginator') paginator: MatPaginator;

  @ViewChild(LmsPaginateComponent)
  set lmspaginate(v: LmsPaginateComponent) {
    this._lmsPaginate = v;
  }
  _lmsPaginate: LmsPaginateComponent;
  config: PaginationInstance = {
    id: 'pager1',
    itemsPerPage: 10,
    currentPage: 1,
  };

  @Input() id: string;
  @Input() table_config: any;
  @Output() onRowSelect = new EventEmitter<any>();
  @Output() onRowAdd = new EventEmitter<any>();
  @Output() onRowEdit = new EventEmitter<any>();
  @Output() onLoaded = new EventEmitter<number>();

  displayed_columns!: string[];
  private data_change_sub!: Subscription;
  table_data_source = new MatTableDataSource<any>([]);
  updated_row_index = -1;

  ngOnInit(): void {
    this.setDisplayedColumns(this.table_config.columns);
    // this.data$ = this.table_config.table_data_changer.pipe(
    //   filter(tabledata => !!tabledata),
    //   map((new_data) => { this.trackDataChange() })
    // );
    if (this.table_config.table_data_changer) {
      // if there is a scope to update data
      this.trackDataChange();
    }
  }

  ngAfterViewInit(): void {
    this.config.id = 'pgr_'+this.id;
    if (this.table_data_source) {
      this.table_data_source.paginator = this.paginator;
    }
  }

  trackDataChange() {
    this.data_change_sub = this.table_config.table_data_changer.subscribe({
      next: (new_data: any) => {
        if (new_data) {
          // && (this.table_data_source?.length !== new_data.length)
          setTimeout(() => {
            //this.table_data_source = new_data;
            this.table_data_source.data = new_data;
            this.table_data_source.paginator = this.paginator;
            if (new_data.highlight) {
              //if it is needed to highlight the updated/new row
              this.goToUpdatedPage(new_data.highlight, new_data);
            }

            this.onLoaded.emit(new_data.length);
            //this.setPage(this.initialPage);
          }, 100);
        }
      },
    });
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   // reset page if items array has changed
  //   if (changes.table_config.currentValue != changes.table_config.previousValue) {
  //     this.lmsPaginate.config.currentPage = 1;
  //     this.setPage(1);
  //   }
  // }

  goToUpdatedPage(updated_row: any, data: any[]) {
    //get the index of the updated row
    const updated_index = data.findIndex((row) => {
      let is_matching = true;
      const primary_key_count = this.table_config.primary_key_set.length;
      for (let i = 0; i < primary_key_count; i++) {
        const column = this.table_config.primary_key_set[i];
        if (row[column] != updated_row[column]) {
          is_matching = false;
          break;
        }
      }
      return is_matching;
    });
    //get the page the updated row is and navigate to it after 1sec
    setTimeout(() => {
      if (updated_index >= 0) {
        // const page_size = this._lmsPaginate.config.itemsPerPage;
        // const current_page_index = this._lmsPaginate.config.currentPage;
        // const calculated_page_index =
        //   Math.ceil((updated_index + 1) / page_size) - 1;
        // if (calculated_page_index != current_page_index) {
        //   if (calculated_page_index == 0) {
        //     //if the first page is to be navigated to
        //     //this.pager.pageIndex = 1;
        //     //this.setPage(1);
        //     this;
        //   } else {
        //     //this.pager.pageIndex = calculated_page_index - 1;
        //     this.setPage(calculated_page_index - 1);
        //     this._lmsPaginate.config.currentPage = calculated_page_index - 1;
        //     //this.paginator.nextPage();
        //   }
        // }

        // this.updated_row_index =
        //   updated_index - page_size * calculated_page_index;
        setTimeout(() => {
          this.updated_row_index = -1;
        }, 1000);
      }
    }, 100);
  }

  onTableDataChange(event: any) {
    this._lmsPaginate.items = event;
  }

  onTableSizeChange(event: any): void {
    this._lmsPaginate.config.totalItems = event.target.value;
    this._lmsPaginate.config.currentPage = 1;
  }

  setDisplayedColumns(column_config: any[]) {
    const col_count = column_config.length;
    const columns_to_display: any[] = [];
    for (let i = 0; i < col_count; i++) {
      const col_config = column_config[i];
      columns_to_display.push(col_config.key);
    }

    if (this.table_config?.ediTable?.edit) {
      columns_to_display.push('edit');
    }

    this.displayed_columns = columns_to_display;
  }

  setPage(page: number) {
    this.paginator.pageIndex = page-1;
    if(this._lmsPaginate.config.currentPage != page) {
      this._lmsPaginate.config.currentPage = page;
    }
    this.paginator['page'].emit();
  }

  addRow() {
    this.onRowAdd.emit();
  }

  viewSelectedItem(row: any) {
    this.onRowSelect.emit(row);
  }

  ngOnDestroy(): void {
    this.data_change_sub!.unsubscribe();
  }
}
