export class LivingExpenseModel{
  id: number;
  manageEngineId: number;
  reasonType: string;
  reasonCode: string;
  description: string;
  createdOn: Date;
  createdBy: string;
  updatedOn: Date;
  updatedBy: string;
}

