<div class="modal-content">
  <div class="modal-header">
    <div class="modal-title">
      <app-page-cardhead style="margin-top: -1.2rem;" [PageName]="'Employment details'"
        [PageHeadWidth]="700"></app-page-cardhead>
    </div>
    <button class="btn btn-close" aria-label="close dialog" (click)="modalRef.close()"></button>
  </div>

  <div class="modal-body">
    <div class="container-fluid">
      <div class="row center-screen">

        <!-- <div class="row center-screen pt-1"> -->
        <!-- Has employment details (EDITING)-->
        <form [formGroup]="employmentDetailsForm" #formDirective="ngForm" autocomplete="off">
          <div class="card">
            <div class="card-body p-1">
              <ng-container class="row center-screen align-items-center">
                <table id="offerDetails" w900 [style.width.px]="850"
                  class="table table-striped table-borderless table-auto table-sm mt-2">
                  <caption>Employment Details</caption>
                  <tbody>
                    <tr>
                      <!-- Employer details-->
                      <td class="aligndetails">
                        <table *ngIf="hasEmployment" w450 id="employmentDetails"
                          class="table table-striped table-sm table-fixed shadow-2-strong rounded-1">
                          <caption>Employer Details</caption>
                          <tbody class="field-4column-label">
                            <tr>
                              <td>Employer name</td>
                              <td class="text-wrap">
                                {{employment.name}}
                              </td>
                            </tr>

                            <tr>
                              <td>Employee number</td>
                              <td>
                                {{employment.employeeNumber}}
                              </td>
                            </tr>

                            <tr>
                              <td>Department</td>
                              <td>
                                {{employment.department}}
                              </td>
                            </tr>
                            <tr>
                              <td>Placement</td>
                              <td>
                                {{employment.placement}}
                              </td>
                            </tr>
                            <tr>
                              <td>Occupation</td>
                              <td>
                                {{employment.occupation}}
                              </td>
                            </tr>


                          </tbody>
                        </table>
                        <table *ngIf="!hasEmployment || isReadOnly" w450 [style.width.px]="420"
                          class="table table-striped table-auto table-sm shadow-2-strong rounded-1">
                          <caption>Employment Details</caption>

                          <tbody class="field-4column-label">
                            <tr>
                              <td><label> Employer name </label>
                              </td>
                              <td class="text-wrap">
                                {{employmentDetailsModel.employerName}}
                                <!--  <input
                      placeholder="Employer name"
                      formControlName="employerName"
                      [(ngModel)]= "employmentDetailsModel.employerName"
                      required
                      maxlength="45"
                      type="text"
                      (keypress)="omit_special_char($event)"> -->
                                <!-- <div
                      *ngIf="!refreshEmployment; then showemployemnt else employerLookup"
                    ></div> -->
                                <!-- <ng-template #showemployemnt>
                      <div class=" d-flex align-items-center justify-content-between">
                      {{employmentDetailsModel.employerName}}
                      <span style="cursor: pointer; font-size: 1.1em; color: #3F51B6;">
                      <i class="fas fa-repeat  fa-pull-right fa-border" (click)="refreshEmployment = !refreshEmployment"></i>
                      </span>
                      </div>
                    </ng-template>
                    <ng-template #employerLookup>
                      <app-branch-employers-search
                      [dontAdd]=true
                      [searchValue]="employerNametxt" [debounceTime]="200"
                      (empSelected)="onEmploymentSelect($event)"
                      >

                    </app-branch-employers-search>
                    </ng-template> -->
                              </td>
                            </tr>

                            <tr>
                              <td>Employee number</td>
                              <td>
                                <input class="form-control" placeholder="Employee number"
                                  formControlName="employeeNumber" lmsNoPaste lmsNoSpecials
                                  [(ngModel)]="employmentDetailsModel.employeeNumber" required maxlength="50"
                                  type="text">
                                <div
                                  *ngIf="employmentDetailsForm.controls!.employeeNumber.errors?.required && submitted"
                                  class="error-label">
                                  Employee number is required
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>Department</td>
                              <td>
                                <input class="form-control" placeholder="Department" formControlName="department"
                                  [(ngModel)]="employmentDetailsModel.department" maxlength="50" type="text"
                                  lmsNoSpecials>
                                <!-- <div *ngIf="employmentDetailsForm.controls!.department.errors?.required && submitted" class="error-label">
                      Department is required
                    </div> -->
                              </td>
                            </tr>

                            <tr>
                              <td>Placement</td>
                              <td>
                                <input class="form-control" placeholder="Placement" formControlName="placement"
                                  [(ngModel)]="employmentDetailsModel.placement" maxlength="100" type="text"
                                  lmsNoSpecials>
                                <!-- <div *ngIf="employmentDetailsForm.controls!.placement.errors?.required && submitted" class="error-label">
                        Placement is required
                      </div> -->
                              </td>
                            </tr>
                            <tr>
                              <td>Occupation</td>
                              <td>
                                <input class="form-control" placeholder="Occupation *" formControlName="occupation"
                                  [(ngModel)]="employmentDetailsModel.occupation" maxlength="50" required type="text"
                                  lmsNoSpecials>
                                <div *ngIf="employmentDetailsForm.controls!.occupation.errors?.required && submitted"
                                  class="error-label">
                                  Occupation is required
                                </div>
                              </td>
                            </tr>

                          </tbody>
                        </table>
                      </td>

                      <!-- Line manager-->
                      <td class="aligndetails">
                        <table w450 *ngIf="hasEmployment || isReadOnly" id="contactPerson"
                          class="table table-striped  table-fixed table-sm shadow-2-strong rounded-1">
                          <caption>Contact Person Details</caption>

                          <tbody class="field-4column-label">
                            <tr>
                              <td>First name</td>
                              <td>
                                {{employment.lineManagerFirstName}}
                              </td>
                            </tr>

                            <tr>
                              <td>Surname</td>
                              <td>
                                {{employment.lineManagerLastName}}
                              </td>
                            </tr>

                            <tr>
                              <td>Contact number</td>
                              <td>
                                {{employment.lineManagerContactNumber}}
                              </td>
                            </tr>

                            <tr>
                              <td> Email address</td>
                              <td>
                                {{employment.lineManagerEmail}}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table *ngIf="!hasEmployment && !isReadOnly" w450 [style.width.px]="420"
                          class="table table-striped table-sm  table-auto  shadow-2-strong rounded-1">
                          <caption>Contact Person Details</caption>

                          <tbody class="field-4column-label">
                            <tr>
                              <td>First name</td>
                              <td>
                                <input class="form-control" placeholder="First name *"
                                  formControlName="lineManagerFirstName"
                                  [(ngModel)]="employmentDetailsModel.lineManagerFirstName" required type="text"
                                  lmsNoSpecials>
                                <div
                                  *ngIf="employmentDetailsForm.controls!.lineManagerFirstName.errors?.required && submitted"
                                  class="error-label">
                                  First name is required
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>Surname</td>
                              <td>
                                <input class="form-control" placeholder="Surname *"
                                  formControlName="lineManagerLastName"
                                  [(ngModel)]="employmentDetailsModel.lineManagerLastName" required type="text"
                                  lmsNoSpecials>
                                <div
                                  *ngIf="employmentDetailsForm.controls!.lineManagerLastName.errors?.required && submitted"
                                  class="error-label">
                                  Surname is required
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>Contact number</td>
                              <td>
                                <input class="form-control" placeholder="Contact number *"
                                  formControlName="lineManagerContactNumber"
                                  [(ngModel)]="employmentDetailsModel.lineManagerContactNumber" required maxlength="10"
                                  (keypress)="($event.charCode >= 48 && $event.charCode < 58)">
                                <div
                                  *ngIf="employmentDetailsForm.controls!.lineManagerContactNumber.errors?.required && submitted"
                                  class="error-label">
                                  Contact number is required
                                </div>

                                <div
                                  *ngIf="employmentDetailsForm.controls!.lineManagerContactNumber.errors?.minLength && submitted"
                                  class="error-label">
                                  Contact number is less that 10 numbers
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>Email address</td>
                              <td>
                                <input class="form-control" placeholder="Email" formControlName="lineManagerEmail"
                                  maxlength="45" [(ngModel)]="employmentDetailsModel.lineManagerEmail">
                                <div
                                  *ngIf="employmentDetailsForm.controls!.lineManagerEmail.errors?.maxLength && submitted"
                                  class="error-label">
                                  Email can not be longer that 45 characters
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <!-- Employer Details -->
                      <td class="aligndetails">
                        <table w450 *ngIf="hasEmployment || isReadOnly" id="employerDetails"
                          class="table table-striped  table-fixed table-sm shadow-2-strong rounded-1">
                          <caption>Employer Details</caption>

                          <tbody class="field-4column-label">
                            <tr>
                              <td>Employment type</td>
                              <td>
                                {{employment.employmentType}}
                              </td>
                            </tr>

                            <tr>
                              <td>Wage type</td>
                              <td>
                                {{employment.wageCalendar}}
                              </td>
                            </tr>

                            <!-- <tr>
                <td>Employment start date</td>
                <td>
                  {{covertDate(employmentDetailsModel.employmentStartDate)}}
                </td>
              </tr>


              <tr>
                <td>Contract start date</td>
                <td>
                  {{covertDate(employmentDetailsModel.contractStartDate)}}
                </td>
              </tr>

              <tr>
                <td>Contract end date</td>
                <td>
                  {{covertDate(employmentDetailsModel.contractEndDate)}}
                </td>
              </tr> -->
                            <!-- TODO paydate-->

                            <tr>
                              <td>Salary pay day</td>
                              <td>
                                {{employment.payDate}}
                              </td>
                            </tr>

                            <tr>
                              <td>Shifting Rule</td>
                              <td>
                                {{employment.shiftingRule}}
                              </td>
                            </tr>

                            <tr>
                              <td>Pay Method</td>
                              <td>
                                {{employment.paymentMethod}}
                              </td>
                            </tr>


                          </tbody>
                        </table>

                        <table *ngIf="!hasEmployment && !isReadOnly" w450 [style.width.px]="420" id="employerDetails"
                          class="table table-striped table-sm  table-auto  shadow-2-strong rounded-1">
                          <caption>Employer Details</caption>

                          <tbody class="field-4column-label">
                            <tr>
                              <td>Employment type</td>
                              <td>
                                {{employmentDetailsModel.employmentType}}
                              </td>
                            </tr>

                            <tr>
                              <td>Pay frequency</td>
                              <td>
                                {{employmentDetailsModel.wageCalendar}}
                              </td>
                            </tr>

                            <tr>
                              <td>Salary pay day</td>
                              <td>
                                {{employmentDetailsModel.payDate}}
                              </td>
                            </tr>

                            <tr>
                              <td>Shifting Rule</td>
                              <td>
                                {{employmentDetailsModel.shiftingRule}}
                              </td>
                            </tr>

                            <tr>
                              <td>Pay Method</td>
                              <td>
                                {{employmentDetailsModel.paymentMethod}}
                              </td>
                            </tr>

                          </tbody>
                        </table>
                      </td>

                      <!--  work address-->
                      <td class="aligndetails">
                        <table w450 *ngIf="hasEmployment || isReadOnly" id="workAddress"
                          class="table table-striped table-sm  table-fixed shadow-2-strong rounded-1">
                          <caption>Work Address Details</caption>
                          <tbody class="field-4column-label">
                            <tr>
                              <td>Address line 1</td>
                              <td>
                                {{employmentDetailsModel.workAdressLine1}}
                              </td>
                            </tr>

                            <tr>
                              <td>Address line 2</td>
                              <td>
                                {{employmentDetailsModel.workAdressLine2}}
                              </td>
                            </tr>

                            <tr>
                              <td>Suburb</td>
                              <td>
                                {{employmentDetailsModel.workAdressSuburb}}
                              </td>
                            </tr>

                            <tr>
                              <td>Town / City</td>
                              <td>
                                {{employmentDetailsModel.workAdressTown}}
                              </td>
                            </tr>

                            <tr>
                              <td>Area code</td>
                              <td>
                                {{employmentDetailsModel.workAdressAreaCode}}
                              </td>
                            </tr>

                            <!-- <tr>
                <td>Province</td>
                <td>
                  {{employment.workAdressProvince}}
                </td>
              </tr> -->
                          </tbody>
                        </table>
                        <table *ngIf="!hasEmployment && !isReadOnly" w450 [style.width.px]="420"
                          class="table table-striped table-sm  table-auto  shadow-2-strong rounded-1">
                          <caption>Work Address</caption>

                          <tbody class="field-4column-label">
                            <tr>
                              <td>Address Line 1</td>
                              <td>
                                <input class="form-control" formControlName="workAdressLine1"
                                  [(ngModel)]="employmentDetailsModel.workAdressLine1" required type="text"
                                  maxlength="100" lmsNoSpecials>
                                <div
                                  *ngIf="employmentDetailsForm.controls!.workAdressLine1.errors?.required && submitted"
                                  class="error-label">
                                  Address Line 1 is required
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>Address Line 2</td>
                              <td>
                                <input class="form-control" formControlName="workAdressLine2"
                                  [(ngModel)]="employmentDetailsModel.workAdressLine2" type="text" maxlength="50"
                                  lmsNoSpecials>
                              </td>
                            </tr>

                            <tr>
                              <td>Suburb</td>
                              <td>
                                <input class="form-control" formControlName="workAdressSuburb"
                                  [(ngModel)]="employmentDetailsModel.workAdressSuburb" required type="text"
                                  maxlength="50" lmsNoSpecials>
                                <div
                                  *ngIf="employmentDetailsForm.controls!.workAdressSuburb.errors?.required && submitted"
                                  class="error-label">
                                  Suburb is required
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>Town / City</td>
                              <td>
                                <input class="form-control" formControlName="workAdressTown"
                                  [(ngModel)]="employmentDetailsModel.workAdressTown" required type="text"
                                  maxlength="100" lmsNoSpecials>
                                <div
                                  *ngIf="employmentDetailsForm.controls!.workAdressTown.errors?.required && submitted"
                                  class="error-label">
                                  Town / City is required
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>Area code</td>
                              <td>
                                <input class="form-control" formControlName="workAdressAreaCode" maxlength="4"
                                  [(ngModel)]="employmentDetailsModel.workAdressAreaCode" required type="text"
                                  (keypress)="($event.charCode >= 48 && $event.charCode < 58)">
                                <div
                                  *ngIf="employmentDetailsForm.controls!.workAdressAreaCode.errors?.required && submitted"
                                  class="error-label">
                                  Area code is required
                                </div>
                              </td>
                            </tr>

                            <!-- <tr>
                  <td>Province</td>
                  <td>
                    {{employment.workAdressProvince}}
                  </td>
                </tr> -->
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-container>
            </div>
            <div class="card-footer flex-container">
              <div *ngIf="hasEmployment || isReadOnly">
                <button class="btn btn-primary" (click)="close()"> Close </button>
                <button *ngIf="!isReadOnly" class="btn btn-primary" (click)="editEmploymentTNR()"> Edit
                </button>
                <!-- clientStatus === 'REPEAT' && -->
                <button
                  *ngIf="!isReadOnly && (employmentDetailsModel.employerTNR && employmentDetailsModel.employeeNumber)"
                  class="btn btn-primary ms-5" (click)="confirmEmploymentDetails()">
                  <i class="fas fa-thumbs-up fa-fw me-1"></i> Confirm </button>
              </div>

              <div *ngIf="!hasEmployment && !isReadOnly">
                <button type="button" class="btn btn-primary" (click)="cancelEdit()"> Cancel Edit </button>
                <button type="button" class="btn btn-primary ms-4" (click)="saveEmployeeDetails()"> Save
                </button>
              </div>
            </div>

          </div>
          <!-- </div> -->
        </form>
      </div>
    </div>
  </div>
</div>
<!-- <div class="center-screen" *ngIf="confirmEmployment && !mustConfirm">
  <table class="table table-bordered table-hover table-sm mb-2">
    <thead class="table-center-header" color="primary">
      <tr>
        <th>Employment Confirmation</th>
      </tr>
   </thead>

    <tbody class="center-fields">
      <tr>
        <td>
          <b>Employment details have been confirmed.</b>
        </td>
      </tr>
    </tbody>
  </table>
</div> -->
