<!-- eslint-disable @angular-eslint/template/eqeqeq -->

<head>
  <meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
  <meta http-equiv="Pragma" content="no-cache" />
  <meta http-equiv="Expires" content="0" />
</head>
<ng-container>
  <div class="d-flex center-screen">
    <form [formGroup]="searchForm" novalidate>
      <table w900 id="clientinfo" class="table table-striped table-auto table-sm shadow-1-strong rounded-1 p-1">
        <caption>
          Client and Employment details
        </caption>
        <tbody class="field-4column-label">
          <tr>
            <td>
              <label for="CLientStatus">Client Status</label>
            </td>
            <td>{{clientShowModel.clientStatus}}</td>
            <td>
              <label for="loanStatus">Loan Status</label>
            </td>
            <td>{{clientShowModel.loanStatus}}</td>
          </tr>
          <tr>
            <td>
              <label for="fullNames">Full Names</label>
            </td>
            <td>{{clientShowModel.fullNames}}</td>
            <td>
              <label for="surname">Surname</label>
            </td>
            <td>{{clientShowModel.surname}}</td>
          </tr>
          <tr>
            <td>
              <label for="idNumber">ID Number</label>
            </td>
            <td>{{clientShowModel.idNumber}}</td>
            <td>
              <label for="cellNumber">Cell Number</label>
            </td>
            <td>{{clientShowModel.cellNumber}}</td>
          </tr>
          <tr>
            <td>
              <label for="clientType">Client Type/Source of Funds</label>
            </td>
            <td>
              <span [ngClass]="preAuthorisationModel!.doUpdateEmployment ? 'badge-warning':'badge-success'"
                class="badge p-1 rounded-4 w-70 flex-wrap" style="cursor: pointer;">
                <select class="lmsselect" [formControlName]="'clientType'" lmsSelectOption
                  [constantName]="'CLIENTTYPES'" required>
                </select>
                <i class="text-emphasis">{{!preAuthorisationModel!.doUpdateEmployment ? 'existing':'new'}}</i>
              </span>
              <div *ngIf="clientType?.invalid">
                <div *ngIf="clientType?.errors?.required && submitted">
                  <small class="text-danger">Client type is required.</small>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <label for="maritalStatus">Marital Status</label>
            </td>
            <td>
              <select class="lmsselect" formControlName="maritalStatus" placeholder="Marital status *" lmsSelectOption
                [constantName]="'MARITALSTATUS'" required>
                <!-- <option
                										*ngFor="let marriedstatus of lstMaritalStatus"
                										[value]="marriedstatus.value"
                									>
                										{{marriedstatus.description}}
                									</option> -->
              </select>
              <div *ngIf="maritalStatus?.invalid">
                <div *ngIf="maritalStatus?.errors?.required && submitted">
                  <small class="text-danger">Marital status is required.</small>
                </div>
              </div>
            </td>
            <td>
              <label for="nationality">Nationality</label>
            </td>
            <td>
              <select class="lmsselect" formControlName="nationality" (change)="onNationalitySelect($event)"
                lmsSelectOption [constantName]="'NATIONALITY'"
                [ngClass]="{ 'is-invalid': nationality?.value == 'OTHER' }" required>
              </select>
              <div *ngIf="nationality?.invalid">
                <div *ngIf="nationality?.errors?.required && submitted">
                  <small class="text-danger">
                    Only South African nationality is acccepted
                  </small>
                </div>
                <div *ngIf="nationality?.value == 'OTHER' && submitted">
                  <small class="text-danger">
                    Only South African nationality is acccepted
                  </small>
                </div>
              </div>
            </td>
          </tr>
          <!-- Loan Amount -->
          <tr>
            <td>
              <label for="loanAmount">Loan Amount</label>
            </td>
            <td>
              <input class="form-control" formControlName="loanAmount" placeholder="Loan amount *" required
                lmsNumbersOnly [allowDecimals]="false" />
              <div *ngIf="loanAmount?.invalid">
                <div *ngIf="loanAmount?.errors?.required && submitted">
                  <small class="text-danger">Loan amount required.</small>
                </div>
              </div>
            </td>
            <td>
              <label for="loanReason">Loan Reason</label>
            </td>
            <td>
              <select class="lmsselect" formControlName="loanReason" required>
                <option *ngFor="let reason of lstAllLoanReasonCodes" [value]="reason.reasonCode">
                  {{reason.description}}
                </option>
              </select>
              <div *ngIf="loanReason?.invalid">
                <div *ngIf="loanReason?.errors?.required && submitted">
                  <small class="text-danger">Loan reason is required.</small>
                </div>
              </div>
            </td>
          </tr>
          <!-- Source of Wealth -->
          <tr>
            <td>
              <label for="sourceOfWealth">Source of Wealth</label>
            </td>
            <td>
              <select class="lmsselect" formControlName="sourceOfWealth" lmsSelectOption
                [constantName]="'SOURCEOFWEALTH'" required>
              </select>
              <div *ngIf="sourceOfWealth?.invalid">
                <div *ngIf="sourceOfWealth?.errors?.required && submitted">
                  <small class="text-danger">Source of wealth is required.</small>
                </div>
              </div>
            </td>
            <td *ngIf="sourceOfWealth?.value == 'Other'">
              <label for="sourceOfWealthOther">Source of Wealth Other</label>
            </td>
            <td *ngIf="sourceOfWealth?.value == 'Other'">
              <div>
                <input class="form-control" formControlName="sourceOfWealthOther" placeholder="Source of Wealth *"
                  lmsNoSpecials [required]="sourceOfWealth?.value == 'Other'" />
              </div>
            </td>
          </tr>
          <!-- Additional Income -->
          <!-- Marketing Options -->
          <tr>
            <td>Marketing Options</td>
            <td colspan="3">
              <mat-select class="lmsselect" [formControlName]="'marketingOptions'" multiple required
                style="max-width: 215px; padding-left: .3rem;" (valueChange)="updateMarketingOptions($event)">
                <mat-option *ngFor="let marketingOption of lstMarketingOptions" [value]="marketingOption.description"
                  [disabled]="(marketingOption.description !== 'None' && isMONoneSelected) || (marketingOption.description === 'None' && isMAOtherOptionSelected)">
                  {{marketingOption.description}}
                </mat-option>
              </mat-select>
            </td>
          </tr>
          <tr>
            <td>
              <label>Contact Preference</label>
            </td>
            <td>
              <select class="lmsselect" [formControl]="contactPreference" placeholder="Contact Preference"
                (value)="preAuthorisationModel!.contactPreference" lmsSelectOption [constantName]="'CONTACTPREFERENCES'"
                required>
              </select>
              <div *ngIf="contactPreference?.invalid && submitted">
                <div *ngIf="contactPreference?.errors?.required">
                  <small class="text-danger">Contact preference is required.</small>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="4">
              <div *ngIf="ClientTypeSASSA; then showsassa else showsalaried"></div>
              <ng-template #showsassa>
                <!-- Type of grant -->
                <app-sassa-editor [sallabel]="preAuthorisationModel.clientType" controlKey="sassaTypeControls"
                  [Submitted]="submitted" [preauthModel]="preAuthorisationModel"></app-sassa-editor>
              </ng-template>
              <ng-template #showsalaried>
                <app-salaried-editor [sallabel]="preAuthorisationModel.clientType" controlKey="salariedTypeControls"
                  [Submitted]="submitted" [preauthModel]="preAuthorisationModel"></app-salaried-editor>
              </ng-template>
            </td>
          </tr>
        </tbody>
      </table>

      <ng-container *ngIf="compuscanOffersEvery">
        <div class="note note-warning d-flex py-3">
          <span>
            <div class="fw-bold">
              No valid Compuscan offers available
            </div>
            No valid Compuscan offers were found for this customer.
            Please note that NO offers will be available for
            selection, if you continue.
          </span>
        </div>
      </ng-container>
      <!-- ==   =============   Listing Questions ============= -->
      <table w800 id="questions"
        class="table table-auto table-striped table-bordered table-hover table-sm  shadow-1-strong rounded-1 p-1">
        <caption>
          Listing Questions
        </caption>
        <tbody class="field-4column-label">
          <tr>
            <td style="width: 95%">
              Are you subject to an administration order?
              <div *ngIf="underAdministration?.invalid && submitted">
                <div *ngIf="underAdministration?.errors?.required">
                  <small class="text-danger">
                    Under administration option is required.
                  </small>
                </div>
              </div>
              <div *ngIf="underAdministration?.value == 'yes'">
                <small class="text-danger">
                  The client will not qualify for a loan, if subject to an
                  administration order.
                </small>
              </div>
            </td>
            <td class="text-center">
              <mat-radio-group formControlName="underAdministration" class="form-control" required>
                <mat-radio-button class="radio-button" value="yes">Yes
                </mat-radio-button>
                <mat-radio-button class="radio-button" value="no" color="primary"> No </mat-radio-button>
              </mat-radio-group>
            </td>
          </tr>
          <tr>
            <td style="width: 95%">
              Are you currently under sequestration?
              <div *ngIf="underSequestration?.invalid && submitted">
                <div *ngIf="underSequestration?.errors?.required">
                  <small class="text-danger">Under sequestration option is required.</small>
                </div>
              </div>
              <div *ngIf="underSequestration?.value == 'yes'">
                <small class="text-danger">
                  The client will not qualify for a loan, if currently under
                  sequestration.
                </small>
              </div>
            </td>
            <td class="text-center">
              <mat-radio-group formControlName="underSequestration" class="form-control" required>
                <mat-radio-button class="radio-button" value="yes">Yes
                </mat-radio-button>
                <mat-radio-button class="radio-button" value="no" color="primary"> No </mat-radio-button>
              </mat-radio-group>
            </td>
          </tr>
          <tr>
            <td style="width: 95%">
              Are you under debt review or of the intention to apply for debt review?
              <div *ngIf="underDebtReview?.invalid && submitted">
                <div *ngIf="underDebtReview?.errors?.required">
                  <small class="text-danger">Under debt review option is required.</small>
                </div>
              </div>
              <div *ngIf="underDebtReview?.value=='yes'">
                <small class="text-danger">
                  The client will not qualify for a loan, if currently under
                  debt review.
                </small>
              </div>
            </td>
            <td class="text-center">
              <mat-radio-group formControlName="underDebtReview" class="form-control" required>
                <mat-radio-button class="radio-button" value="yes">Yes
                </mat-radio-button>
                <mat-radio-button class="radio-button" value="no" color="primary"> No </mat-radio-button>
              </mat-radio-group>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="center-screen flex-container flex-nowrap my-2">
        <button class="btn btn-primary" mdbRipple type="button" (click)="openRejectOfferDialog()">
          <i class="fas fa-fw fa-xmarks-lines me-1"></i>
          Reject offer
        </button>
        <button class="btn btn-primary" mdbRipple type="button" (click)="valuesDialog()">
          <i class="fas fa-cart-shopping fa-fw me-1"></i>
          View Codix offers
        </button>
        <button class="btn btn-primary" mdbRipple type="button"
          (click)="lmsDialogHandler.openClientDocuments(clientShowModel)">
          <i class="fas fa-file-invoice fa-fw me-1"></i>
          Client Documents
        </button>
        <button class="btn btn-primary ms-4" mdbRipple type="button"
          (dblclick)="global_utility.findInvalidControls(searchForm)" (click)="submitClientSearch()"> Submit
        </button>
      </div>
    </form>
  </div>
