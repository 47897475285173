import { AreaModel, RegionModel } from "./region.model";

export class RegisterModel {
  areas: any;
  password: string;
  selectedBranch: any;
  firstName: string;
  surname: string;
  loginName: string;
  idNumber: string;
  cellNumber: string;
  email: string;
  cbsAccountNo: string;
  delfinBranchSerial: string;
  branchNumber: string;
  emidUserId: string;
  userdisabled: boolean;
  branchName: string;
  nameSurname: string;
  jobTitle: any;
  disabledReason: string;
  id: number;
  keyclockUuid: any;
  menuActions: any;
  region?: RegionModel = {} as RegionModel;
  managedAreas: AreaModel[];
  selJobtitle: any;
  faisRepresentative: boolean;
}

