<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<div class="modal-content">
  <div class="modal-header">
    <div class="modal-title">
      <app-page-cardhead style="margin-top: -1.1rem;" [PageName]="dlgTitle" [PageHeadWidth]="320"></app-page-cardhead>
    </div>
    <button class="btn btn-close " color="red" (click)="modalRef.close()"></button>
  </div>

  <div class="modal-body">
    <div class="container-fluid ">
      <div class="row center-screen my-2">
        <div id="content-capture"
          [style.display]="showStatus && (!lmsbioCapture.clientVerified || !lmsbioCapture.userVerified || !lmsbioCapture.faisRepVerified) ? 'block' : 'none'">
          <div id="scores" class="scores center-section py-1">
            <span>
              <h6>Scan Quality</h6>
              <input class="form-control fs-5 text-center" type="text" id="qualityInputBox" readonly
                [ngModel]="lmsbioCapture.readQuality | async" style="background-color:#DCDCDC;">
            </span>
          </div>
          <div id="status"></div>
          <!-- <div id="imagediv"></div> -->
        </div>
        <div class="my-2">
          <div *ngFor="let data of lmsbioCapture.sampleData | async; let sampleDataIndex = index;" class="mx-2">
            <label class="mt-2" [for]="'sampleData' + sampleDataIndex">Finger Data {{ sampleDataIndex + 1 }}
            </label>
            <label sampleDataIndex>: Captured success</label>
          </div>
        </div>

        <mdb-tabs (activeTabChange)="activechanged($event)" [pills]="true" [fill]="true">
          <!-- CLIENT CAPTURE -->
          <mdb-tab *ngIf="biometricType=='client' || biometricType=='client-offer'"
            [title]="biometricType=='client-offer' ? 'Client Verify': 'Client Capture'">
            <ng-template mdbTabContent>
              <div class="card">
                <div class="card-body px-1">
                  <table class="table table-striped table-auto table-sm my-1">
                    <tbody>
                      <tr *ngFor="let totFingers of clientEnrolled; let totalFingersEnrolled = index;">
                        <td class="fw-semibold w-50">Enrolled Finger</td>
                        <td>{{totFingers.fingerName}}</td>
                        <td><button class="btn-sm btn-outline-success" [hidden]="lmsbioCapture.clientVerified"
                            (click)="doClientFingerVerify(totFingers)">Verify</button>
                        </td>
                      </tr>
                      <tr *ngIf="biometricType == 'client-offer' && clientEnrolled.length == 0">
                        <i>** No Fingers **</i>
                      </tr>
                      <tr *ngIf="(lmsbioCapture.canDoEnroll && !noBiometrics) || this.clientEnrolled.length == 0">
                        <td class="fw-semibold w-75">Add Finger</td>
                        <td colspan="2">
                          <div *ngIf="lmsbioCapture.canDoEnroll">
                            <div class="input-group-sm center-section">
                              <select #allFingersSelect id="allFingersSelect" style="max-width: 100px;"
                                [formControl]="selectedFingerNameControl" class="form-control-sm lmsselect"
                                (change)="onfingerChange($event.target)" lmsSelectOption [constantName]="'ALLFINGERS'"
                                [addDefault]="true" [setFirstAsValue]="true" required aria-label="Enroll Client">
                              </select>
                              <button class="btn btn-sm btn-primary" type="button" id="button-enroll-cl"
                                [disabled]="!lmsbioCapture.isValidFingerSelected || ((lmsbioCapture.sampleData | async)?.length ?? 0) < 4"
                                (click)="enrollClient()">Enroll
                                Finger</button>
                            </div>
                            <div *ngIf="!lmsbioCapture.isValidFingerSelected">
                              <small class="text-danger"> Please select a valid option. </small>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div id="content-capture"
                    [style.display]="biometricType == 'client' && skipBiometrics ? 'block' : 'none'">
                    <div class="my-1">
                      <table w350 class="table table-striped table-auto table-sm w-75">
                        <tbody class="field-4column-label">
                          <tr>
                            <td>Branch Manager Username:
                            </td>
                            <td>
                              <div class="center-section">
                                <input placeholder="BM Username" [(ngModel)]="biometricsModel.bmUserName" required>
                                <button class="btn btn-sm btn-primary" type="submit" (click)="verifyBranchManager()">
                                  Verify Biometrics
                                </button>
                              </div>
                              <!-- <div *ngIf="biometricsModel.bmUserName == ''" class="error-label">
                            Please enter a valid Branch Manager Username.
                          </div> -->
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Verify Finger Response:
                            </td>
                            <td>
                              {{enrollmentError == 'FAILURE' ? enrollmentMessage: enrollmentError}}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>

            <!-- <ng-container *mdbTabPortalOutlet="ScoresCapture, context: {$implicit: biometricType}"></ng-container> -->
          </mdb-tab>

          <!-- CLIENT DISABLE -->
          <mdb-tab *ngIf="biometricType == 'client' && isUserBranchManager && disableOpen" title="Disable Fingers">
            <ng-template mdbTabContent title="Disable Client Fingers">
              <div class="row center-screen px-4">
                <table class="table table-borderless table-striped table-auto shadow-2-strong table-sm ">
                  <tbody class="field-4column-label">
                    <div *ngFor="let enroll of clientEnrolled; let i as index">
                      <tr>
                        <td>Finger Id</td>
                        <td>{{enroll.id}}</td>
                      </tr>
                      <tr>
                        <td>Finger Name</td>
                        <td>{{enroll.fingerName}}</td>
                      </tr>
                      <tr class="border-bottom">
                        <td></td>
                        <td>
                          <button class="btn  btn-sm btn-outline-primary readerDivButtons" mdbRipple
                            *ngIf="enrollmentError !== 'SUCCESS' && enrollmentError !== 'FAILURE'"
                            (click)="disableClientFinger(enroll.id)">
                            Disable Finger </button>
                          <div
                            *ngIf="enrollmentError === 'SUCCESS' && enrollmentMessage === 'Finger Disabled' && biometricsModel.id === enroll.id"
                            [ngClass]="'error'" style="color: green;">
                            Finger Disabled.
                          </div>
                          <div
                            *ngIf="enrollmentError === 'FAILURE' && enrollmentMessage !== 'Finger Disabled' && biometricsModel.id === enroll.id"
                            [ngClass]="'error'" style="color: red; float:right; margin-right: 303px;">
                            Finger NOT Disabled.
                          </div>
                        </td>
                      </tr>
                    </div>
                  </tbody>
                </table>
              </div>
              <div class="readerDivButtons flex-container">
                <button [disabled]="getClientFingersCount()" class="btn btn-primary" type="button"
                  (click)="close()">Close</button>
                <button type="button" class="btn btn-primary" (click)="disableAllClientFingers()">Disable
                  All Fingers</button>
              </div>
            </ng-template>
          </mdb-tab>

          <!-- USER CAPTURE -->
          <mdb-tab *ngIf="biometricType == 'user'|| biometricType=='client-offer'"
            [title]="biometricType=='client-offer' ? 'User Verify': 'User Capture'">
            <ng-template mdbTabContent>
              <table class="table table-striped table-hover table-sm my-3 mx-2">
                <tbody>
                  <tr *ngFor="let totFingers of userEnrolled | paginate: config; let totalFingersEnrolled = index;">
                    <td class="fw-semibold">Enrolled Finger</td>
                    <td>{{totFingers.fingerName}}</td>
                    <td><button class="btn-sm btn-outline-success" [hidden]="lmsbioCapture.userVerified"
                        (click)="doUserFingerVerify(totFingers)">Verify</button></td>
                  </tr>
                  <tr *ngIf="biometricType == 'user' && userEnrolled.length < 10">
                    <td class="fw-semibold w-75">Add Finger</td>
                    <td colspan="2">
                      <div *ngIf="lmsbioCapture.canDoEnroll">
                        <div class="input-group-sm center-section">
                          <select #allFingersSelect id="allFingersSelect" style="max-width: 100px;"
                            [formControl]="selectedFingerNameControl" class="form-control-sm lmsselect"
                            (change)="onfingerChange($event.target)" lmsSelectOption [constantName]="'ALLFINGERS'"
                            [addDefault]="true" [setFirstAsValue]="true" required aria-label="Enroll Client">
                          </select>
                          <button class="btn btn-sm btn-primary" type="button" id="button-enroll-cl"
                            [disabled]="!lmsbioCapture.isValidFingerSelected || ((lmsbioCapture.sampleData | async)?.length ?? 0) < 4"
                            (click)="enrollUser()">Enroll
                            Finger</button>
                        </div>
                        <div *ngIf="!lmsbioCapture.isValidFingerSelected">
                          <small class="text-danger"> Please select a valid option. </small>
                        </div>
                      </div>

                      <!-- <div *ngIf="captureOpen || !this.lstAllFingersDisable">
                        <div class="input-group-sm center-section" [hidden]="biometricType == 'client-offer'">
                          <select #allFingersSelect id="allFingersSelect" style="max-width: 100px;"
                            [formControl]="selectedFingerNameControl" class="lmsselect form-control-sm"
                            (change)="onfingerChange($event.target)" lmsSelectOption [constantName]="'ALLFINGERS'"
                            [addDefault]="true" [setFirstAsValue]="true" required>
                          </select>
                          <button class="btn btn-sm btn-primary" type="button"
                            [disabled]="!lmsbioCapture.canDoEnroll && !startAcquisitionDisabled"
                            (click)="doUserFingerVerify()">Enroll
                            Finger</button>
                        </div>
                        <div *ngIf="!lmsbioCapture.isValidFingerSelected">
                          <small class="text-danger"> Please select a valid option. </small>
                        </div>
                      </div> -->
                    </td>
                  </tr>
                </tbody>
              </table>
              <lms-paginate class="center-screen" [config]="config"></lms-paginate>
            </ng-template>
          </mdb-tab>

          <!-- REP USER CAPTURE -->
          <mdb-tab *ngIf="biometricType == 'user' || biometricType=='client-offer'"
            [title]="biometricType=='client-offer' ? 'FAIS Rep Verify': 'FAIS Rep Capture'">
            <ng-template mdbTabContent>
              <select [(ngModel)]="selectedUser" (change)="filterFingerprints()"
                class="form-control-sm lmsselect my-2 w-75">
                <option value="">--- Select Representative ---</option>
                <option *ngFor="let faisRepSelection of uniqueUsernames" [value]="faisRepSelection">
                  {{ faisRepSelection }}
                </option>
              </select>
              <table class="table table-striped table-hover table-sm my-3 mx-2">
                <tbody>
                  <tr *ngFor="let finger of filteredFingers | paginate: config2; let totalFingersEnrolled = index;">
                    <td></td>
                    <td>
                      {{finger.fingerName}}
                    </td>
                    <td><button class="btn-sm btn-outline-success" [hidden]="lmsbioCapture.faisRepVerified"
                        (click)="doRepUserFingerVerify(finger)">Verify</button></td>
                  </tr>
                </tbody>
              </table>
              <lms-paginate class="center-screen" [config]="config2"></lms-paginate>
            </ng-template>
          </mdb-tab>

          <!-- USER DISABLE -->
          <mdb-tab *ngIf="biometricType == 'user' && disableOpen && !captureOpen" title="Disable User Fingers">
            <ng-template mdbTabContent>

              <div class="row center-screen px-4" *ngIf="disableOpen && !captureOpen">
                <table class="table table-borderless table-striped table-auto shadow-2-strong table-sm">
                  <tbody class="field-4column-label">
                    <div *ngFor="let enroll of userEnrolled | paginate: config2; let i as index">
                      <tr>
                        <td>Finger Id</td>
                        <td>{{enroll.id}}</td>
                      </tr>
                      <tr>
                        <td>Finger Name</td>
                        <td>{{enroll.fingerName}}</td>
                      </tr>
                      <tr class="border-bottom">
                        <td></td>
                        <td>
                          <button class="btn btn-sm btn-outline-primary readerDivButtons" mdbRipple
                            (click)="disableUserFinger(enroll.id)">Disable Finger</button>
                          <div
                            *ngIf="enrollmentError === 'SUCCESS' && enrollmentMessage === 'Finger Disabled' && biometricsModel.id === enroll.id"
                            style="color: green;">
                            Finger Disabled.
                          </div>
                          <div
                            *ngIf="enrollmentError === 'FAILURE' && enrollmentMessage !== 'Finger Disabled' && biometricsModel.id === enroll.id"
                            [ngClass]="'error'" style="color: red; float:right; margin-right: 303px;">
                            Finger NOT Disabled.
                          </div>
                        </td>
                      </tr>
                    </div>
                  </tbody>
                </table>
                <lms-paginate [config]="config2"></lms-paginate>
              </div>

              <div class="flex-container">
                <button [disabled]="getUserFingersCount()" class="btn btn-sm btn-primary" type="button"
                  (click)="close()">Close</button>
                <button type="button" class="btn btn-sm btn-primary" (click)="disableAllUserFingers()">Disable
                  All Fingers</button>
              </div>
            </ng-template>
          </mdb-tab>
        </mdb-tabs>

        <ng-container *ngIf="signatureOpen$ | async">
          <div class="col-11 align-items-stretch justify-content-center my-2">
            <table class="table table-striped table-sm m-3 shadow-2-strong rounded-1">
              <caption>Signature Verification</caption>
              <tbody>
                <tr>
                  <td>Client Signature - </td>
                  <td>{{verifiedFingers[0].fingerName}}</td>
                </tr>
                <tr>
                  <td>Consultant Signature - </td>
                  <td>{{verifiedFingers[1].fingerName}}</td>
                </tr>
                <tr>
                  <td>FAIS Rep Signature - </td>
                  <td>{{verifiedRepFingers[0].fingerName}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="flex-container">
      <button class="btn btn-primary" type="button" [style.display]="biometricType == 'client-offer' ? 'none': 'block'"
        [disabled]="startAcquisitionDisabled || !lmsbioCapture.isValidFingerSelected"
        (click)="startCapture(false, true);showStatus = true;">Start Scan</button>

      <button [style.display]="biometricType == 'client' && skipBiometrics ? 'block': 'none'"
        [disabled]="continueApplicationDisabled && !lmsbioCapture.clientVerified" class="btn btn-primary"
        (click)="continueApplication()">Continue Application
      </button>

      <button [style.display]="(signatureOpen$ | async) ? 'block': 'none'" class="btn btn-sm btn-primary" type="button"
        (click)="signDocument()">Sign Document</button>

      <button [style.display]="enableSkip ? 'block': 'none'" class="btn btn-primary" (click)="continueLoan()"> Skip
        Biometrics </button>
    </div>
  </div>
</div>