<div class="modal-content">
    <div>&nbsp;</div>
    <div class="modal-header">
        <div class="modal-title center-fields">
            <app-page-cardhead style="margin-top: -1.1rem;" [PageName]="'Maximum Loans Exceeded'"
                [PageHeadWidth]="450"></app-page-cardhead>
        </div>
    </div>

    <div *ngIf="clientType =='Salaried' && wageFrequency=='Monthly' " class="modal-body myh-300 py-3 px-1 text-center">
        <h6>This client already has 4 active loans. </h6>
        <h6>A {{clientType}} client cannot have more than 4 active loans at a time.</h6>
    </div>

    <div *ngIf="clientType =='SASSA'" class="modal-body myh-300 py-3 px-1 text-center">
        <h6>This client already has 4 active loans. </h6>
        <h6>A {{clientType}} client cannot have more than 4 active loans at a time.</h6>
    </div>


    <div class="modal-body myh-300 py-3 px-1 text-center">
        <h6>Please check on Delfin if any of these loans are paid up.</h6>
        <h6>Then exclude these Internal loans on the list. </h6>
    </div>

    <hr class="hr hr-blurry">
    <div class="center-screen">
        <button class="btn btn-sm btn-primary m-2" mdbRipple color="primary" (click)="close()"> Close </button>
    </div>
</div>