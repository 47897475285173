<div class="modal-content">
  <div class="modal-header">
    <app-page-cardhead
      [PageName]="rejectOfferInfo.loanStatus === 'Submitted' ? 'Cancel Offer Reason' : 'Reject Offer Reason'"
      [PageHeadWidth]="350"></app-page-cardhead>
  </div>

  <div class="modal-body">
    <div class="card">
      <div class="card-body p-1">
        <form [formGroup]="rejectOfferReasonDetailsForm" #formDirective="ngForm">
          <table w450 class="table table-bordered table-striped table-sm shadow-2-strong rounded-1 p-1">
            <caption> {{ rejectOfferInfo.loanStatus === 'Submitted' ? 'Cancel Reason' : 'Reject Reason' }}</caption>
            <thead class="text-center">
              <tr>
                <th class="fw-bold">{{ rejectOfferInfo.loanStatus === 'Submitted' ? 'Please select cancelation reason '
                  : 'Please select rejection reason ' }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span class="d-flex flex-wrap flex-column align-content-center">
                    <select class="lmsselect w-50" formControlName="rejectOfferReason" required>
                      <option *ngFor="let pType of lstReasonCode" [ngValue]="pType.reasonCode">{{pType.description}}
                      </option>
                    </select>
                    <div *ngIf="rejectOfferReasonDetailsForm.invalid && submitted">
                      <div *ngIf="rejectOfferReason?.hasError('required')">
                        <small class="text-danger">
                          A reason is required to reject this offer
                        </small>
                      </div>
                    </div>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
      <div class="card-footer flex-container">
        <button type="button" class="btn btn-primary btn-sm" (click)="close()"> Close </button>
        <button class="btn btn-primary btn-sm" mdbRipple (click)="saveRejectOfferReason()"> Save </button>
      </div>
    </div>
  </div>
</div>
