export class LexisNexisModel {
  idNumber: string;
  fullNames: string;
  cellNumber: string;
  surname: string;
  consentOtp: string;
  userName: any;
  maritalStatus: string;
  nationality: string;
  address: string;
  occupation: string;
  countryBirth:string;
  quoteId: number;
}
