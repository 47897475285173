/* eslint-disable @angular-eslint/use-lifecycle-interface */
/* eslint-disable no-inner-declarations */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-var */
/* eslint-disable no-self-assign */
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuoteStoreService } from "src/app/core/rest-services/quote-store.service";
import { UserService } from 'src/app/core/services/lmsuser.service';
import { BiometricsModel } from 'src/app/core/models/biometrics.model';
import { PreAuthorisationModel } from 'src/app/core/models/pre-authorisation.model';
import { BiometricsSerivce } from 'src/app/core/rest-services/biometrics.service';
import { MdbModalModule, MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { AppUtilityService } from 'src/app/app-utility.service';
import { MdbTabComponent, MdbTabsComponent, MdbTabsModule } from 'mdb-angular-ui-kit/tabs';
import { LMSSelectOptionDirective } from "src/app/shared/directives/lms-selectOption.directive";
import { BehaviorSubject, map, Observable, Subscription, take, tap } from 'rxjs';
import { NgxPaginationModule, PaginationInstance } from 'ngx-pagination';
import { LmsPaginateComponent, PageCardheadComponent } from '@lmsShared';
import { FingerNameEnum } from '@lmsConstants';


// declare let Fingerprint: any;

export interface CustomerEnrollmentEntity {
  id?: number;
  idNumber?: string;
  username?: string;
  fingerName?: FingerNameEnum;
  fmd?: string;
  created?: string;
  enrolledBy?: string;
  disabledAt?: string;
  disabledBy?: string;
}

const NO_FINGER = "NO_FINGER";

export interface SelectItem<T = any> {
  label?: string;
  value: T;
  styleClass?: string;
  icon?: string;
  title?: string;
  disabled?: boolean;
}

export interface LMS_BioCaptureModel {
  canDoEnroll: boolean;
  canDoVerify: boolean;
  clientVerified:boolean;
  userVerified:boolean;
  canDoStartAcquisition: boolean;
  isValidFingerSelected: boolean;
  readQuality: Observable<string>;
  numberReadings: Observable<number>;
  sampleData: Observable<string[]>;
  deviceList: Observable<string[]>;
}

@Component({
  selector: 'app-biometrics-dialog',
  templateUrl: './biometrics-dialog.component.html',
  styleUrls: ['./biometrics-dialog.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PageCardheadComponent,
    MdbModalModule,
    MdbTabsModule,
    LMSSelectOptionDirective,
    NgxPaginationModule,
    LmsPaginateComponent
  ],
})
export class BiometricsDialogComponent implements OnInit, AfterViewInit,OnDestroy {
  @ViewChildren(MdbTabComponent) childrenComponent: QueryList<MdbTabComponent>;
  @ViewChild(MdbTabsComponent) tabsComponent: MdbTabsComponent;
  @ViewChild('allFingersSelect', { read: ElementRef })
  set tabContentElement(element: ElementRef) {
    setTimeout(() => {
      if (element) {
        this.allFinger = element;
        if(this.clientEnrolled.length>0) {
          this.getFingersList(this.clientEnrolled);
        }
        if(this.userEnrolled.length>0) {
          this.getFingersList(this.userEnrolled);
        }
      }
    }, 2000);
  }

  allFinger: ElementRef;
  acquisitionStarted: boolean;
  config: PaginationInstance = {
    id: 'pager',
    itemsPerPage: 5,
    currentPage: 1,
  };
  biometricsModel: BiometricsModel = new BiometricsModel();

  biometricsInfo: any;
  userBiometricsInfo: any;

  lmsbioCapture: LMS_BioCaptureModel;

  captureOpen = false;
  verificationOpen = false;
  userVerificationOpen = false;
  disableOpen = false;
  signatureOpen = false;
  faisrep_verified = false;
  deviceSerial: string;
  devicesList: string[];

  radioFormat: number;
  stopAcquisitionDisabled: boolean;
  startAcquisitionDisabled: boolean;

  fingerdisabled: number;
  enrollmentError?: string;
  enrollmentMessage?: string;
  clientEnrolled: CustomerEnrollmentEntity[] | [];
  userEnrolled: CustomerEnrollmentEntity[] | [];

  verifiedFingers: CustomerEnrollmentEntity[] | [];
  fingerNames: SelectItem[];
  selectedFingerName: any;
  selectedConsultantFingerName: any;
  selectedFingerNameControl = new FormControl([{value: null, disabled: false}]);
  biometricType: any;
  finger_processing = (oneSample: string[]): void => {};
  continueApplicationDisabled: boolean;

  enableSkip: boolean;
  //reasonSelected = false ;
  //lstBiometricsSkipReasons = SKIPBIOMETRICSREASONS;
  //lstAllFingers = ALLFINGERS;
  lstAllFingers: any[];
  //lstDisableClientFingers = DISABLECLIENTFINGERS;
  lstVerifiedFingers: any;
  //disableReason: any;

  noBiometrics: boolean;
  lstAllFingersDisable: boolean;
  sampleDataVerify: string;
  lstSignedFingerDetails: any[];
  lstConsultantSignedFingerDetails: any[];

  isNokComplete: boolean;
  isCellVisible: boolean;
  bmVerified: boolean;
  submitted = false;
  skipBiometrics = false;

  showStatus = false;

  isFingerProcessing$ = new BehaviorSubject<boolean>(false);

  subscriptions: Subscription[] = [];
  constructor(
    private fb: FormBuilder,
    public quoteStoreService: QuoteStoreService,
    private biometricsService: BiometricsSerivce,
    private userService: UserService,
    public global_utility: AppUtilityService,
    public modalRef: MdbModalRef<BiometricsDialogComponent>
  ) {
    this.deviceSerial = 'No Device Selected';
    this.devicesList = [];
    this.radioFormat = 2;
    this.stopAcquisitionDisabled = true;
    this.startAcquisitionDisabled = true;
    this.continueApplicationDisabled = true;

    this.enrollmentMessage = '';
    this.enrollmentError = '';
    this.clientEnrolled = [];
    this.userEnrolled = [];
    this.verifiedFingers = [];

    this.lmsbioCapture = {
      canDoEnroll: false,
      canDoVerify: false,
      clientVerified: false,
      userVerified: false,
      canDoStartAcquisition: false,
      isValidFingerSelected: false,
      readQuality: this.biometricsService.readQuality$,
      numberReadings: this.biometricsService.numberReadings$,
      sampleData: this.biometricsService.sampleData$,
      deviceList: this.biometricsService.deviceList$
    };
    this.userVerificationOpen = this.biometricType == 'client-offer';
  }

  ngOnInit(): void {
    console.debug('Biometrics info >>', this.biometricsInfo);
    console.debug('Biometrics info >> ngOninit :: Fmt: ' + this.radioFormat);
    console.debug('Biometrics info >> ngOninit :: Biometric type: ' + this.biometricType);

    let sub1 =this.lmsbioCapture.sampleData.subscribe((sample) =>{
      if(sample.length > 0 && this.acquisitionStarted && !this.isFingerProcessing$.value) {
        this.finger_processing(sample);
      }
    });
    this.subscriptions.push(sub1);

    sub1 = this.lmsbioCapture.numberReadings.subscribe((readIdx) => {
      if(readIdx <= 0) {
        return;
      }
      //this.lmsbioCapture.canDoEnroll = readIdx >= 4 && (this.biometricType=='user' ? this.userEnrolled.length < 10 : this.clientEnrolled.length < 4);
      //this.lmsbioCapture.canDoVerify = readIdx >= 1 && (this.selectedFingerName !== 'NO_FINGER' || this.selectedFingerName !== '');

      console.log('Number of Readings: ',readIdx);
    });
    this.subscriptions.push(sub1);
    sub1 = this.biometricsService.acquisitionStarted$.subscribe((status) =>{
      this.showMessage(status+', place your finger.');
    });
    this.subscriptions.push(sub1);

    sub1 = this.biometricsService.status$.subscribe((status) =>{
      this.showMessage(status);
    });
    this.subscriptions.push(sub1);

    sub1 = this.lmsbioCapture.deviceList.subscribe((devicelist:string[])=>{
      this.devicesList = devicelist;
      this.deviceSerial = devicelist.join(', ');
    });
    this.subscriptions.push(sub1);

    this.biometricsService.getDevices();
    this.lmsbioCapture.canDoEnroll = false;
    this.lmsbioCapture.canDoVerify = false;
  }

  ngAfterViewInit(): void {
  }

  showMessage(message){
    //const state = document.getElementById('content-capture');
    //var _instance = this;
    var statusWindow = document.getElementById("status");
    if(statusWindow) {
      statusWindow.innerHTML = message;
    }
  }
  // toggle_visibility(ids) {
  //   var state = document.getElementById('content-capture');
  //   const box = document.getElementById("qualityInputBox");
  //   (box! as HTMLInputElement).value = "";
  //   //onStop();
  //   for (var i=0;i<ids.length;i++) {
  //     var e = document.getElementById(ids[i]);
  //     if(i == 0){
  //       e!.style.display = 'block';
  //     }
  //     else{
  //       e!.style.display = 'none';
  //     }
  //   }
  // }


  updateData(index: number, value: any) {
    this.lmsbioCapture.sampleData[index] = value;
    console.debug(`sampleData[${index}] >>>`, this.lmsbioCapture.sampleData[index]);
  }

  resetReaderData(){
    this.biometricsService.resetReadings();
  }

  activechanged(event: { index: number; tab: { title: string } }) {
    this.selectedFingerName = '';

    console.log('activeChanged index:', event.index, 'tab:',event.tab);
    switch ((event.tab.title).toLowerCase()) {
    // case 'reader':
    //   this.biometricsService.getDevices();
    //   break;

    case 'client-offer capture':
    case 'client capture':
      //this.openCapture();
      this.lmsbioCapture.clientVerified = false;
      this.getClientEnrolledFingers();
      break;
    case 'client verify':
      //this.openCapture();
      this.getClientEnrolledFingers();
      break;

    case 'user verify':
      //this.openCapture();
      this.lmsbioCapture.userVerified = false;
      this.getUserEnrolledFingers();
      // this.openVerification();
      break;

    case 'user capture':
      this.getUserEnrolledFingers();
      // this.openVerification();

      break;

    case 'disable client fingers':
      if (!this.disableOpen && this.captureOpen) {
      }
      break;

    case 'disable user fingers':
      if (!this.disableOpen && this.captureOpen) {
      }
      break;
    default:
      break;
    }
  }

  getDevice() {
    this.biometricsService.getDevices();
  }

  startCapture(ignoreNoFinger:boolean=false, withProcessing: boolean = false) {
    if(this.acquisitionStarted)
    {
      console.debug('acquisitionStarted Already true, breaking!');
      return;
    }

    this.startAcquisitionDisabled = true;
    this.stopAcquisitionDisabled = false;

    if (this.selectedFingerName == 'NO_FINGER' && !ignoreNoFinger) {
      this.lmsbioCapture.canDoVerify = false;
      //this.skipBiometrics = this.biometricType != 'client-offer';
      withProcessing = false;
      this.acquisitionStarted = false;
      return;
    }

    if(withProcessing){
      this.finger_processing = (oneSample: string[]) => {
        if(oneSample.length >= 4) {
          this.biometricsModel.fingerImage1 = oneSample[0];
          this.biometricsModel.fingerImage2 = oneSample[1];
          this.biometricsModel.fingerImage3 = oneSample[2];
          this.biometricsModel.fingerImage4 = oneSample[3];
          this.lmsbioCapture.canDoEnroll = true;
        }
        //this.continueApplicationDisabled = !(this.clientEnrolled.length == 4 || (this.clientEnrolled.length == 1 && this.clientEnrolled.some(f => f.fingerName?.includes(NO_FINGER))));
      }
    }

    this.acquisitionStarted = true;
    this.biometricsService.startAcquisition(true)
      .catch((error: any) => {
        this.acquisitionStarted = false;
        console.error('Error starting capture >>', error);
        this.showMessage(error);
      });
  }

  stopClientCapture() {
    this.startAcquisitionDisabled = false;
    this.stopAcquisitionDisabled = true;

    this.lmsbioCapture.canDoEnroll = false;
    this.lmsbioCapture.canDoVerify = false;

    this.enrollmentError = 'UNKNOWN';
    this.acquisitionStarted = false;
    this.biometricsService.stopAcquisition().catch((error: any) => {
      console.error('Error stopping acquisition:', error);
    });
    this.continueClientDisabled();
  }

  stopUserCapture() {
    this.startAcquisitionDisabled = false;
    this.stopAcquisitionDisabled = true;

    this.lmsbioCapture.canDoEnroll = false;
    this.lmsbioCapture.canDoVerify = false;

    this.enrollmentError = 'UNKNOWN';
    this.biometricsService.stopAcquisition().catch((error: any) => {
      console.error('Error stopping acquisition:', error);
    });
    this.continueUserDisabled();
  }

  enrollClient() {
    if(!this.lmsbioCapture.isValidFingerSelected || !this.lmsbioCapture.canDoEnroll) {
      this.global_utility.warning('Please select a valid finger to enroll.','Enrollment Warning');
      return;
    }

    // Sample data read from subscription after 4 successfull fingers
    // this.biometricsModel.fingerImage1 = this.lmsbioCapture.sampleData[0];
    // this.biometricsModel.fingerImage2 = this.lmsbioCapture.sampleData[1];
    // this.biometricsModel.fingerImage3 = this.lmsbioCapture.sampleData[2];
    // this.biometricsModel.fingerImage4 = this.lmsbioCapture.sampleData[3];
    this.biometricsModel.fingerName = this.selectedFingerName;
    this.biometricsModel.enrolledBy = this.userService.userLoginName;
    this.biometricsModel.idNumber = this.biometricsInfo?.idNumber;
    this.biometricsModel.branchName = this.userService.userBranchName;
    this.showStatus = false;
    console.debug('Biometrics Model :: ', this.biometricsModel);

    setTimeout(() => {
      // Subscribe to enrollClientFinger method of biometricsService
      this.biometricsService.enrollClientFinger(this.biometricsModel).subscribe({
        next: (response) => {
          console.debug('Enrollement Response >>>', response);
          // Assign errorCode and errorMessage from response to enrollmentError and enrollmentMessage respectively
          this.enrollmentError = response?.errorCode;
          this.enrollmentMessage = response?.errorMessage;

          if (this.enrollmentError != 'SUCCESS') {
            this.stopClientCapture();
            this.global_utility.error(`${this.enrollmentError} : ${this.enrollmentMessage}`,'Enrollment Error: ');
            this.lmsbioCapture.canDoEnroll = true;
            this.lmsbioCapture.isValidFingerSelected = false;
          } else {
          // Reset selectedFingerName, stop client capture, and update enrolled and fingers list
            this.global_utility.success(`${this.enrollmentError} : ${this.enrollmentMessage}`,'Enrollment Status');
            this.getClientEnrolledFingers(true);
          }
          this.selectedFingerNameControl.reset();
          this.selectedFingerName = '';
          this.resetReaderData();
          console.groupEnd();
        },
        complete() {
          console.groupEnd();
        },
        error: (err) => {
          console.error(err);
          this.global_utility.error(`${err} : ${err}`,'Enrollment Error: ');
        },
      });
    });
  }

  doClientFingerVerify(clientFinger: CustomerEnrollmentEntity ){
    this.selectedFingerName = clientFinger?.fingerName;

    if(clientFinger?.fingerName == NO_FINGER as FingerNameEnum) {
      this.skipBiometrics = false;
      this.enableSkip = true;
      this.selectedFingerName = NO_FINGER;
      (this.verifiedFingers as CustomerEnrollmentEntity[]).unshift(clientFinger);
      this.signatureOpen = this.lmsbioCapture.clientVerified && this.biometricType == 'client-offer';
      return;
    }

    if(!this.lmsbioCapture.clientVerified && !this.acquisitionStarted) {
      this.startCapture();
      this.showStatus = true;
    }

    this.finger_processing = (oneSample: string[]) => {
      if (!this.lmsbioCapture.clientVerified && this.lmsbioCapture.canDoVerify || this.lmsbioCapture.canDoEnroll) {
        this.isFingerProcessing$.next(true);

        const sampleDataVerify = oneSample[0];
        const biometricsModel = {
          fingerImage: sampleDataVerify,
          verifiedBy: this.userService.userLoginName,
          branchName: this.biometricsInfo?.branchName || this.userBiometricsInfo?.branchName,
          idNumber: this.biometricsInfo?.idNumber || this.userBiometricsInfo?.idNumber,
        };

        this.biometricsService.verifyClientFinger(biometricsModel).subscribe({
          next: (response) => {
            this.enrollmentError = response?.errorCode;
            this.enrollmentMessage = response?.errorMessage;

            const toastString = 'Verify Finger Response: ' + this.enrollmentError;
            this.quoteStoreService.setVerifiedMessage(this.enrollmentMessage);

            if (this.enrollmentError == 'FAILURE') {
              this.global_utility.warning(this.enrollmentMessage,toastString);

              if(this.biometricType == 'client-offer'){
                this.signatureOpen = false;
                this.lmsbioCapture.clientVerified = false;
                (this.verifiedFingers as CustomerEnrollmentEntity[]).unshift({});
              }
              this.biometricsService.resetReadings();
              this.isFingerProcessing$.next(false);
            } else {
              this.global_utility.info(this.enrollmentMessage,toastString);
              this.noBiometrics = (this.clientEnrolled.length <= 1 && this.clientEnrolled.some(f => f.fingerName?.includes(NO_FINGER)));
              this.lmsbioCapture.canDoEnroll = this.clientEnrolled.length < 4 && !this.noBiometrics;

              this.continueApplicationDisabled = this.biometricType == 'client' && !this.lmsbioCapture.canDoEnroll;

              (this.verifiedFingers as CustomerEnrollmentEntity[]).unshift(clientFinger);
              this.lmsbioCapture.clientVerified = Array.from(this.verifiedFingers).length == 1 && this.verifiedFingers[0] != null;
              //this.lmsbioCapture.clientVerified = Array.from(this.verifiedFingers).length > 1;//this.verifiedFingers[0]?.id && this.verifiedFingers[1]?.id;

              if(this.biometricType == 'client' && this.clientEnrolled.length == 4) {
                this.stopClientCapture();
                this.biometricsService.resetReadings();
                this.isFingerProcessing$.next(false);
                this.continueApplication();
              }

              if(this.biometricType == 'client-offer'){
                if(!this.enrollmentMessage || this.enrollmentMessage == '') {
                  this.lmsbioCapture.canDoVerify = false;
                }
                this.signatureOpen = Array.from(this.verifiedFingers).length > 1 && this.lmsbioCapture.clientVerified;
                this.quoteStoreService.auditLogClientFingerVerification(this.biometricsModel.idNumber, this.enrollmentMessage).subscribe();
                this.stopClientCapture();
                this.biometricsService.resetReadings();
                this.tabsComponent.setActiveTab(1);
              }
            }
            this.isFingerProcessing$.next(false);
          }
        });
      }
    }
  }

  doUserFingerVerify(userFinger: CustomerEnrollmentEntity){
    this.selectedFingerName = userFinger?.fingerName;

    if(userFinger?.fingerName === NO_FINGER as FingerNameEnum) {
      this.enableSkip = true;
      this.selectedFingerName = NO_FINGER;
      this.verifiedFingers[1] = userFinger;
      this.signatureOpen = true;
      return;
    }

    if(!this.lmsbioCapture.userVerified && !this.acquisitionStarted) {
      this.startCapture();
      this.showStatus = true;
    }

    this.finger_processing = (oneSample: string[]) => {
      if (!this.lmsbioCapture.userVerified && this.lmsbioCapture.canDoVerify || this.lmsbioCapture.canDoEnroll) {
        this.isFingerProcessing$.next(true);

        const sampleDataVerify = oneSample[0];
        const biometricsModel = {
          fingerImage: this.sampleDataVerify,
          verifiedBy: this.userService.userLoginName,
          branchName: this.biometricsInfo?.userBranchName || this.userBiometricsInfo?.branchName,
          idNumber: this.biometricsInfo?.idNumber || this.userBiometricsInfo?.idNumber,
        };

        if (this.biometricType == "client-offer") {
          this.biometricsModel.userName = this.userService.userLoginName;
        } else {
          this.biometricsModel.userName = this.userBiometricsInfo.loginName;
        }

        this.biometricsService.verifyUserFinger(biometricsModel).subscribe({
          next: (response) => {
            this.enrollmentError = response?.errorCode;
            this.enrollmentMessage = response?.errorMessage;
            this.lmsbioCapture.canDoEnroll = false;

            const toastString = 'Verify Finger Response: ' + this.enrollmentError;
            this.quoteStoreService.setVerifiedMessage(this.enrollmentMessage);

            if (this.enrollmentError == 'FAILURE') {
              this.global_utility.warning(this.enrollmentMessage,toastString);
              this.biometricsModel.fingerVerified = false;

              if(this.biometricType == 'client-offer'){
                this.signatureOpen = false;
                this.lmsbioCapture.userVerified = false;
                (this.verifiedFingers as CustomerEnrollmentEntity[]).pop();
              }
              this.biometricsService.resetReadings();
              this.isFingerProcessing$.next(false);
            } else {
              this.global_utility.info(this.enrollmentMessage,toastString);
              this.noBiometrics = (this.userEnrolled.length <= 1 && this.userEnrolled.some(f => f.fingerName?.includes(NO_FINGER)));
              this.lmsbioCapture.canDoEnroll = this.userEnrolled.length < 10 && !this.noBiometrics;

              this.continueApplicationDisabled = this.biometricType == 'client' && !this.lmsbioCapture.canDoEnroll;

              if(this.biometricType == 'user') {
                this.stopClientCapture();
                this.biometricsService.resetReadings();
                this.isFingerProcessing$.next(false);
                return;
              }

              if(this.biometricType == 'client-offer'){
                if(!this.enrollmentMessage || this.enrollmentMessage == '') {
                  this.lmsbioCapture.canDoVerify = false;
                }

                if(Array.from(this.verifiedFingers).length > 1){
                  (this.verifiedFingers as CustomerEnrollmentEntity[]).pop()
                }
                (this.verifiedFingers as CustomerEnrollmentEntity[]).push(userFinger);
                this.lmsbioCapture.userVerified = Array.from(this.verifiedFingers).length > 1 && this.verifiedFingers[1] != null;
                this.signatureOpen = Array.from(this.verifiedFingers).length > 1 && this.lmsbioCapture.clientVerified;
                this.quoteStoreService.auditLogUserFingerVerification(this.biometricsModel.idNumber, this.enrollmentMessage).subscribe();

                this.stopClientCapture();
                this.biometricsService.resetReadings();
              }
            }
            this.isFingerProcessing$.next(false);
          }
        });
      }
    }
  }

  verifyUserIntermediate() {
    this.lmsbioCapture.sampleData.pipe(tap((allSample) =>{
      if(allSample.length == 0){
        this.global_utility.warning(
          'Finger could not be Verified or no finger provided.',
          '',
        );
        return;
      }


      this.sampleDataVerify = allSample[0];
      this.biometricsModel.fingerImage = this.sampleDataVerify;
      this.biometricsModel.verifiedBy = this.userService.userLoginName;

      if (this.biometricType == "client-offer") {
        this.biometricsModel.userName = this.userService.userLoginName;
      } else {
        this.biometricsModel.userName = this.userBiometricsInfo.loginName;
      }

      this.biometricsService
        .verifyUserFinger(this.biometricsModel)
        .pipe(
          tap(() => {
            console.group('Verify user finger');
          }),
        )
        .subscribe({
          next: (response) => {
            console.debug(`Verify User finger result >>`, response);
            this.enrollmentError = response?.errorCode;
            this.enrollmentMessage = response?.errorMessage;
            this.lmsbioCapture.canDoEnroll = false;
            this.biometricsService.resetReadings();

            if (this.biometricType == 'client-offer') {
              this.quoteStoreService
                .auditLogUserFingerVerification(
                  this.biometricsModel.idNumber,
                  this.enrollmentMessage,
                )
                .subscribe();
            }

            const toastString = 'Verify Finger Response: ' + this.enrollmentError;
            if (this.enrollmentError == 'FAILURE') {
              this.global_utility.warning(this.enrollmentMessage, toastString);
            } else {
              this.global_utility.info(this.enrollmentMessage, toastString);
              this.lmsbioCapture.userVerified = true;
              this.stopUserCapture();
            }
            console.groupEnd();
          },
          complete() {
            console.groupEnd();
          },
        });
    }));
  }

  verifyBranchManager(isfaisRep: boolean = false) {
    if (
      this.biometricsModel.bmUserName === '' ||
      this.userBiometricsInfo.bmUserName === null
    ) {
      this.global_utility.warning('Please Enter a valid Branch Manger Username.','');
      this.biometricsService.resetReadings();
      this.stopUserCapture();
      return;
    }

    if (
      this.biometricsModel.bmUserName === this.userService.userLoginName &&
      this.userService.userJobTitle.jobTitle === 'Branch Consultant'
    ) {
      this.global_utility.warning('Please enter a valid Branch Manager username.','');
      this.biometricsService.resetReadings();
      this.stopUserCapture();
      return;
    }

    if(!this.lmsbioCapture.userVerified && !this.acquisitionStarted) {
      this.startCapture(true,false);
      this.showStatus = true;
    }

    this.finger_processing = (oneSample: string[]) => {
      this.sampleDataVerify = oneSample[0];

      if(isfaisRep) {
        this.biometricsModel.fingerImage = this.sampleDataVerify;
        this.biometricsModel.fingerName = this.selectedFingerName;
        this.biometricsModel.verifiedBy = this.biometricsModel.bmUserName;
        this.biometricsModel.userName = this.userService.userLoginName;
        this.biometricsModel.branchName = this.userService.userBranchName;

        this.biometricsModel.skipReason = "No Biometrics, BM Override";

        this.biometricsService.verifyUserFinger(this.biometricsModel).subscribe({
          next: (response) => {
            if (this.enrollmentError == 'FAILURE') {
              this.faisrep_verified = false


              ///
            }
            else {

              ///
              this.faisrep_verified = true
            }
            return;
          },
          error: (err) =>{

            return;
          }
        });
        return;
      }

      this.biometricsModel.fingerImage = this.sampleDataVerify;
      this.biometricsModel.fingerName = this.selectedFingerName;
      this.biometricsModel.verifiedBy = this.biometricsModel.bmUserName;
      this.biometricsModel.userName = this.userService.userLoginName;
      this.biometricsModel.branchName = this.userService.userBranchName;

      this.biometricsModel.skipReason = "No Biometrics, BM Override";

      //if(this.userService.userJobTitle.jobTitle === 'Branch Manager' && this.biometricsModel.bmUserName != ''){
      this.biometricsService.verifyUserFinger(this.biometricsModel)
        .subscribe({
          next: (response) => {
            console.debug(`Verify User[BM] finger result >>`, response);
            this.enrollmentError = response?.errorCode;
            this.enrollmentMessage = response?.errorMessage;

            this.lmsbioCapture.canDoEnroll = false;

            const toastString = 'Verify Finger Response: ' + this.enrollmentMessage;
            if (this.enrollmentError == 'FAILURE') {
              this.global_utility.warning(this.enrollmentMessage, toastString);
              this.bmVerified = false;
              this.enableSkip = false;
            } else {
              this.global_utility.info(this.enrollmentMessage, toastString);
              this.bmVerified = true;
              if(this.clientEnrolled.length < 4){
                this.biometricsModel.fingerName = this.selectedFingerName = NO_FINGER;
                this.skipRegistration();
              } else {
                // Client Finger disable required
                this.global_utility.warning('Client finger to be disavbled to allocate No Finger','Max Finger Enrolled');
                this.bmVerified = true;
                /// TODO: Fix this
              }

            }
            this.stopUserCapture();
            this.biometricsService.resetReadings();
            console.groupEnd();
          },
          complete() {
            console.groupEnd();
          },
        });
    // }
    // else {
    //   this.global_utility.warning("Branch Username is required " , '');
    // }
    };
  }

  getClientEnrolledFingers(force_fetch: boolean = false) {
    console.log('Retrieving Client Enrolled Fingers...');

    if(this.clientEnrolled.length > 0 && this.biometricsInfo?.idNumber == this.biometricsModel.idNumber && !force_fetch) {
      console.debug('User Enrolled details already retrieved');
      return;
    }
    this.disableOpen = false;
    this.biometricsModel.idNumber = this.biometricsInfo?.idNumber || this.biometricsInfo;
    //this.skipBiometrics = this.biometricType == 'client';
    // Values no longer needed
    //this.startAcquisitionDisabled = false;
    //this.lstAllFingersDisable = false;

    this.biometricsService.enrolledFingersDetails(this.biometricsModel.idNumber)
      .pipe(tap(() => {
        console.group('Get Client Enrolled');
        console.debug('Retrieving Client Enrolled Fingers...');
      })).subscribe({
        next: (result) => {
          console.debug('Enrolled finger Data ($):', result);
          this.clientEnrolled = result;
          this.lmsbioCapture.canDoEnroll = false;
          this.lmsbioCapture.canDoVerify = false;
          this.lmsbioCapture.isValidFingerSelected = false;

          this.showStatus = false;
          this.noBiometrics = (!this.clientEnrolled.some(f => f.fingerName?.includes(NO_FINGER)) && this.clientEnrolled.length <= 1) || this.clientEnrolled.length == 0;
          this.continueApplicationDisabled = (this.clientEnrolled.length == 4 || this.noBiometrics) && !this.lmsbioCapture.clientVerified;
          this.enableSkip = !this.noBiometrics;

          if (this.continueApplicationDisabled) {
            this.lstAllFingersDisable = true;
            this.disableOpen = this.clientEnrolled.length > 0;
            this.selectedFingerNameControl.enable();
            this.lmsbioCapture.canDoVerify = !this.lmsbioCapture.clientVerified;
            this.lmsbioCapture.canDoEnroll = this.biometricType != 'client-offer' && this.clientEnrolled.length < 4;
            //this.skipBiometrics = this.clientEnrolled.some(f => f.fingerName?.includes(NO_FINGER));

          } else if (this.noBiometrics && this.selectedFingerName == '') {
            this.disableOpen = true;
            this.selectedFingerNameControl.enable();
            this.selectedFingerName = NO_FINGER;
            //this.skipBiometrics = true;
            this.lmsbioCapture.canDoVerify = true;

          } else {
            this.selectedFingerNameControl.disable();
            this.lmsbioCapture.canDoEnroll = this.biometricType != 'client-offer' && this.clientEnrolled.length < 4;
            this.lmsbioCapture.canDoVerify = true;
            this.disableOpen = true;
            //this.openVerification();
            //const test = this.captureOpen && (!this.lmsbioCapture.canDoEnroll || this.lmsbioCapture.canDoEnroll) && !this.lmsbioCapture.clientVerified;
          }
          this.getFingersList(this.clientEnrolled);
          this.lmsbioCapture.isValidFingerSelected = false;

          console.groupEnd();
        },
        complete() {
          console.groupEnd();
        },
      });
  }

  disableClientFinger(id: any) {
    this.biometricsModel.id = id;
    this.biometricsModel.username = this.userService.userLoginName;
    //this.biometricsModel.disableReason = this.biometricsModel.disableReason;

    this.biometricsService
      .disableFinger(this.biometricsModel)
      .subscribe((response) => {
        console.debug('Disable Finger >> Response: ', response);

        this.fingerdisabled = id;
        this.enrollmentError = response?.errorCode;
        this.enrollmentMessage = response?.errorMessage;

        const newenroll = this.clientEnrolled.filter((ff) => ff.id != id);
        this.clientEnrolled = newenroll;

        this.getFingersList(this.clientEnrolled);
        this.lmsbioCapture.isValidFingerSelected = false;
        //this.getClientEnrolledFingers(true);
      });

    // this.biometricsService.updateAuditTrail(this.biometricsModel.idNumber, this.biometricsModel.disableReason, this.userService.userDisplayName).subscribe(
    //   (response) => {
    //     console.log("response ----", response);

    // });
  }

  disableAllClientFingers() {
    this.biometricsModel.idNumber = this.biometricsInfo?.idNumber;
    this.biometricsModel.username = this.userService.userLoginName;

    this.biometricsService
      .disableAllClientFingers(this.biometricsModel)
      .subscribe((responseData) => {
        console.log('Response', responseData);

        this.enrollmentError = responseData?.errorCode;
        this.enrollmentMessage = responseData?.errorMessage;

        this.getClientEnrolledFingers();
      });
  }

  enrollUser() {
    if(!this.lmsbioCapture.isValidFingerSelected || !this.lmsbioCapture.canDoEnroll) {
      this.global_utility.warning('Please select a valid finger to enroll.','Enrollment Warning');
      return;
    }

    // Sample data read from subscription after 4 successfull fingers
    // this.biometricsModel.fingerImage1 = this.lmsbioCapture.sampleData[0];
    // this.biometricsModel.fingerImage2 = this.lmsbioCapture.sampleData[1];
    // this.biometricsModel.fingerImage3 = this.lmsbioCapture.sampleData[2];
    // this.biometricsModel.fingerImage4 = this.lmsbioCapture.sampleData[3];
    this.biometricsModel.fingerName = this.selectedFingerName;
    this.biometricsModel.enrolledBy = this.userService.userDisplayName;
    this.biometricsModel.loginName = this.userBiometricsInfo.loginName;
    this.biometricsModel.branchName =
      this.userBiometricsInfo?.branchName || this.biometricsInfo?.branchName;
    this.showStatus = false;
    setTimeout(() => {
      this.biometricsService.enrollUserFinger(this.biometricsModel).subscribe({
        next: (response) => {
          this.enrollmentError = response?.errorCode;
          this.enrollmentMessage = response?.errorMessage;

          if (this.enrollmentError != 'SUCCESS') {
            this.stopUserCapture();
            this.global_utility.error(
              `${this.enrollmentError} : ${this.enrollmentMessage}`,
              'Enrollment Error: ',
            );
            setTimeout(() => {
              this.global_utility.info(`Please try again.`, 'Enrollment Retry: ');
            }, 2500);
            this.lmsbioCapture.canDoEnroll = true;
            this.lmsbioCapture.isValidFingerSelected = false;
          } else {
            // Reset selectedFingerName, stop client capture, and update enrolled and fingers list
            this.global_utility.success(
              `${this.enrollmentError} : ${this.enrollmentMessage}`,
              'Enrollment Status',
            );
            this.getUserEnrolledFingers(true);
          }
          this.selectedFingerName = '';
          this.selectedFingerNameControl.reset();
          this.resetReaderData();

          if (this.userEnrolled.length == 9) {
            this.startAcquisitionDisabled = false;
            this.selectedFingerNameControl.disable();
            this.lstAllFingersDisable = true;
          } else {
            this.startAcquisitionDisabled = true;
            this.selectedFingerNameControl.enable();
            this.lstAllFingersDisable = false;
          }
        },
        complete() {
          console.groupEnd();
        },
        error: (err) => {
          console.error(err);
          this.global_utility.error(`${err} : ${err}`,'Enrollment Error: ');
        }
      });
    });
  }

  getUserEnrolledFingers(force_fetch: boolean = false) {
    console.group('Get User Enrolled');
    console.debug('Retrieving User Enrolled Fingers...');

    if(this.userEnrolled.length > 0 && this.lmsbioCapture.isValidFingerSelected && !force_fetch) {
      console.debug('User Enrolled details already retrieved');
      console.groupEnd();
      return;
    }

    this.disableOpen = false;
    // Set the userName based on the biometric type
    this.biometricsModel.userName = this.biometricType === 'client-offer' ? this.userService.userLoginName : this.userBiometricsInfo.loginName;
    this.biometricsModel.branchName = this.userBiometricsInfo?.branchName || this.biometricsInfo?.branchName;

    this.biometricsService.enrolledUserFingersDetails(this.biometricsModel).subscribe({
      next:(result) => {
        console.debug('Enrolled Data', result);
        this.userEnrolled = result;

        this.showStatus = false;
        this.noBiometrics = (!this.clientEnrolled.some(f => f.fingerName?.includes(NO_FINGER)) && this.clientEnrolled.length <= 1) || this.clientEnrolled.length == 0;
        this.continueApplicationDisabled = true;
        this.enableSkip = false;

        if (this.biometricType === 'user') {
        // Check if all fingers have been enrolled
          if (this.userEnrolled.length == 10) {
            this.noBiometrics = false;
            this.lstAllFingersDisable = true;
            this.selectedFingerNameControl.disable();

            this.lmsbioCapture.canDoEnroll = false;
            this.lmsbioCapture.canDoVerify = true;
            this.disableOpen = true;
          } else {
            this.openUserCapture();

            this.lstAllFingersDisable = this.userEnrolled.length == 10;
            if(this.lstAllFingersDisable){
              this.selectedFingerNameControl.disable();
            } else {
              this.selectedFingerNameControl.enable();
            }

            this.lmsbioCapture.canDoEnroll = this.biometricType == 'user';// this.userEnrolled.length < 10 || this.lmsbioCapture.isValidFingerSelected;
            this.lmsbioCapture.canDoVerify = this.userEnrolled.length > 1;

            this.disableOpen = this.userEnrolled.length > 0;
          }
          this.getFingersList(this.userEnrolled);
        }
        console.groupEnd();
      },
      complete: () => {
        console.groupEnd();
      }});
  }

  disableUserFinger(id: any) {
    this.biometricsModel.id = id;
    this.biometricsModel.username = this.userService.userLoginName;

    this.biometricsService.disableUserFinger(this.biometricsModel).subscribe((response) => {
      console.debug('disable User finger repsonse >>', response);

      this.enrollmentError = response?.errorCode;
      this.enrollmentMessage = response?.errorMessage;

      this.getUserEnrolledFingers(true);
    });
  }

  disableAllUserFingers() {
    this.biometricsModel.username = "AsdA";
    this.biometricsModel.disabledBy = this.userService.userLoginName;

    this.biometricsService.disableAllUserFingers(this.biometricsModel).subscribe((response) => {
      console.debug('disable ALL User finger repsonse>>', response);

      this.enrollmentError = response?.errorCode;
      this.enrollmentMessage = response?.errorMessage;

      this.getUserEnrolledFingers(true);
    });
  }

  openReader() {
    this.captureOpen = false;
    this.disableOpen = false;
    this.verificationOpen = false;
    this.userVerificationOpen = false;
    this.signatureOpen = false;
  }

  // openCapture() {
  //   this.captureOpen = this.biometricType != "client-offer";
  //   this.disableOpen = false;
  //   this.verificationOpen = this.biometricType == "client-offer";
  //   this.signatureOpen = false;
  // }

  openUserCapture() {
    this.captureOpen = true;
    this.disableOpen = false;
    this.signatureOpen = false;
  }

  openDisableFinger() {
    const {
      disableOpen,
      captureOpen,
      verificationOpen,
      signatureOpen,
    } = this;

    this.disableOpen = true;
    this.captureOpen = false;
    this.verificationOpen = false;
    this.signatureOpen = false;
  }

  // openVerification() {
  //   this.verificationOpen =
  //     this.captureOpen =
  //     this.disableOpen =
  //     this.startAcquisitionDisabled =
  //       false;
  // }

  backToPreAuth() {
    this.modalRef.close(this.biometricsModel);
  }

  continueApplication() {
    if (
      this.biometricsInfo.cellNumber == '0' &&
      this.biometricsInfo.hasCell == 'No'
    ) {
      if (this.clientEnrolled.length == 0) {
        this.enableSkip = true;
        this.isCellVisible = false;
        this.biometricsModel.verifiedMessage =
          this.quoteStoreService.verifiedMessage;
      } else if (this.clientEnrolled.length == 1) {
        this.enableSkip = true;
        this.isCellVisible = false;
        this.skipBiometrics = false;
        this.biometricsModel.cellNoExist = 'No';
        this.biometricsModel.verifiedMessage =
          this.quoteStoreService.verifiedMessage;
      } else {
        this.isCellVisible = false;
        this.biometricsModel.cellNoExist = 'No';
        this.biometricsModel.verifiedMessage =
          this.quoteStoreService.verifiedMessage;
        this.closeDialog();
      }
    } else {
      if (this.clientEnrolled.length == 0) {
        this.enableSkip = true;
        this.isCellVisible = false;
        this.biometricsModel.verifiedMessage =
          this.quoteStoreService.verifiedMessage;
      } else if (this.clientEnrolled.length == 1 || this.noBiometrics) {
        this.isCellVisible = true;
        this.biometricsModel.cellNoExist = 'Yes';
        this.selectedFingerName = NO_FINGER;
        this.biometricsModel.fingerVerified = true;
        this.biometricsModel.verifiedMessage =
          this.quoteStoreService.verifiedMessage;
        this.closeDialog();
      } else {
        this.isCellVisible = true;
        this.biometricsModel.cellNoExist = 'Yes';
        this.biometricsModel.fingerVerified = this.lmsbioCapture.clientVerified || this.lmsbioCapture.userVerified;
        this.biometricsModel.fingerName = this.selectedFingerName;

        this.biometricsModel.verifiedMessage =
          this.quoteStoreService.verifiedMessage;
        this.closeDialog();
      }
    }
  }

  // proceed() {
  //   this.verificationOpen =
  //     this.userVerificationOpen =
  //     //this.readerOpen =
  //     this.captureOpen =
  //     this.disableOpen =
  //     this.startAcquisitionDisabled =
  //       false;
  //   this.signatureOpen = true;

  //   //this.getClientEnrolledFingers();
  //   //this.getUserEnrolledFingers();
  // }

  getClientFingersCount() {
    return this.clientEnrolled.length <= 4;
  }

  getUserFingersCount() {
    return this.userEnrolled.length <= 4;
  }

  getFingersList(activeFingers: CustomerEnrollmentEntity[] | []) {
    const allfiungercontrol = (this.allFinger || document.querySelector('allFingersSelect')) as ElementRef<HTMLSelectElement>;
    if(!allfiungercontrol || activeFingers.length==0){
      return;
    }
    const fingerOptions = allfiungercontrol.nativeElement.options;

    if(fingerOptions) {
      Array.from(fingerOptions).forEach((fingerOption: { value: string,index: number }) => {
        if (activeFingers.some(a => a.fingerName == fingerOption.value.split(':')[1]?.trim())) {
          fingerOptions.remove(fingerOption.index);
        }
      });
    }
  }

  continueClientDisabled() {
    this.continueApplicationDisabled = this.clientEnrolled.length < 4 || !(this.clientEnrolled.length == 1 && this.clientEnrolled.some(f => f.fingerName?.includes(NO_FINGER)));
  }

  continueUserDisabled() {
    this.continueApplicationDisabled = this.clientEnrolled.length < 4 || !(this.clientEnrolled.length == 1 && this.clientEnrolled.some(f => f.fingerName?.includes(NO_FINGER)));
  }

  backToOffers() {
    this.enableSkip = false;
  }

  continueLoan() {
    const hasCellNumber =
      this.biometricsInfo.cellNumber !== '0' ||
      this.biometricsInfo.hasCell == 'Yes';
    const hasNoSelectedFinger = this.selectedFingerName == NO_FINGER;

    this.enableSkip = false;
    this.skipBiometrics = true;
    this.isCellVisible = hasCellNumber && hasNoSelectedFinger;
  }

  onfingerChange(target: any) {
    if (target.value === NO_FINGER) {
      if ( ((this.biometricType == 'user')) || (this.biometricType == 'client' && this.clientEnrolled.length <= 1)) {
        this.startAcquisitionDisabled = true;
        this.continueApplicationDisabled = true;
        this.enableSkip = true;
        this.skipBiometrics = false;
        this.selectedFingerName = NO_FINGER;
        this.lmsbioCapture.isValidFingerSelected = false;
      }
      return;
    }

    this.enableSkip = false;
    const value: string = target.value;
    this.selectedFingerName = value?.trim() || '';
    //this.lmsbioCapture.canDoEnroll = this.biometricType == 'client' ? this.clientEnrolled.length < 4 : this.userEnrolled.length < 10;
    this.lmsbioCapture.isValidFingerSelected = this.selectedFingerName && (this.selectedFingerName !== 'null' || this.selectedFingerName !== '');
    this.continueApplicationDisabled = true;
    this.startAcquisitionDisabled = !this.lmsbioCapture.isValidFingerSelected;
  }

  skipRegistration() {
    if (
      this.biometricsInfo.cellNumber != '0' &&
      this.biometricsInfo.hasCell == 'Yes' &&
      this.selectedFingerName == NO_FINGER
    ) {
      if (this.biometricsModel.skipReason != 'N/A') {
        this.submitted = true;
        this.enableSkip = true;
        this.biometricsModel.cellNoExist = "Yes";
        this.biometricsModel.idNumber = this.biometricsInfo?.idNumber;
        this.biometricsModel.fingerName = this.selectedFingerName;
        this.biometricsModel.enrolledBy = this.userService.userLoginName;

        this.biometricsService.skipBiometrics(this.biometricsModel).subscribe((response: { errorCode: string; errorMessage: string }) => {
          console.debug('Skip Registration NO_FINGER repsonse >>', response);
          this.enrollmentError = response?.errorCode;
          this.enrollmentMessage = response?.errorMessage;
          this.modalRef.close(this.biometricsModel);
        });
      } else {
        this.submitted = false;
      }
    } else {
      if (this.biometricsModel.skipReason != 'N/A') {
        this.enableSkip = true;
        this.biometricsModel.cellNoExist = "No";
        this.biometricsModel.idNumber = this.biometricsInfo?.idNumber;
        this.biometricsModel.fingerName = this.selectedFingerName;
        this.biometricsModel.enrolledBy = this.userService.userLoginName;
        this.biometricsService.skipBiometrics(this.biometricsModel).subscribe((response) => {
          console.debug('Skip Registratioon reason >>', response);
          this.enrollmentError = response?.errorCode;
          this.enrollmentMessage = response?.errorMessage;
          return this.modalRef.close(this.biometricsModel);
        });
      } else {
        this.enableSkip = false;
      }
    }
  }

  fetchFinger(selectedFingerName: any) {
    this.selectedFingerName = selectedFingerName;

    const selectedFinger = this.clientEnrolled.find(
      (item) => item.fingerName === this.selectedFingerName,
    );

    if (selectedFinger) {
      this.biometricsService
        .enrolledSingleFingersDetails(selectedFinger.id)
        .subscribe((result) => {
          console.debug(`Fetch finger ${selectedFingerName} result >>`, result);
          this.lstSignedFingerDetails = result;
        });
    }
  }

  fetchConsultantFinger(selectedConsultantFingerName: string): void {
    this.selectedConsultantFingerName = selectedConsultantFingerName;
    const data: any = this.findUserEnrolledItem();

    this.biometricsService
      .enrolledConsultantSingleFingersDetails(data?.id)
      .subscribe((result: any) => {
        console.debug(
          `Fetch consultant finger ${selectedConsultantFingerName} result >>`,
          result,
        );
        this.lstConsultantSignedFingerDetails = result;
      });
  }

  findUserEnrolledItem(): any {
    return this.userEnrolled.find(
      (item: any) => item.fingerName === this.selectedConsultantFingerName,
    );
  }

  signDocument() {

    const finger1 = (this.verifiedFingers[0]);
    const finger2 = (this.verifiedFingers[1]);
    if (finger1 && finger2) {
      this.biometricsModel.id = this.quoteStoreService.quoteWorkbook.id;


      this.biometricsModel.fingerId = Number(finger1.id);
      if (finger1.fingerName?.includes(NO_FINGER)) {
        this.biometricsModel.fingerId = 0;
      } else {
        this.biometricsModel.fingerId = Number(finger1.id);
      }

      this.biometricsModel.userFingerId = Number(finger2.id);

      const saveFingerIdPromise = new Promise<boolean>((resolve, reject) => {
        this.quoteStoreService.saveFingerId(this.biometricsModel).subscribe({
          next: (res: any) => {
            console.debug(`Save FingerId result >>`, res);
            if (!res) {
              resolve(false);
              return;
            }

            this.quoteStoreService.quoteWorkbook.fingerId = res.fingerId;
            this.quoteStoreService.quoteWorkbook.userFingerId = res.userFingerId;
            resolve(true);
          },
          error: (err) => {
            this.global_utility.error('Error on signature: ' + err,`Document signing error`);
            reject(new Error('Error on signature: ' + err));
          },
        });
      });

      console.debug('Biometric Data >>', this.biometricsModel);
      saveFingerIdPromise.then((found: boolean) => {
        if (found) {
          this.closeDialog();
        }
      }).catch((error) => {
        console.error(error);
      });

    } else {
      console.info('Finger not Found (Promise saveFinger)');
    }
  }

  doSigning() {
    if (this.isNokComplete) {
      this.closeDialog();
    }
  }

  onSkipReasonChange(event: any) {
    this.submitted = !event || event === "N/A";
  }

  closeDialog() {
    this.modalRef.close(this.biometricsModel);
  }

  close() {
    this.modalRef.close();
  }

  closeScanFingers() {
    this.showMessage('Closing..please wait');
    (this.biometricsService.stopAcquisition()).catch((error: any) => {
      console.error(error.message);
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => {
      x.unsubscribe();
    })
    this.closeScanFingers();
  }

  // fetchDisableReason(event:any){
  //   if(event.target.value != ''){
  //     this.reasonSelected = true;
  //     this.disableReason = event.target.value;
  //     this.biometricsModel.disableReason = this.disableReason;
  //   }
  //   else{
  //     this.reasonSelected = false;
  //     this.global_utility.error(`No reason selected.`, 'Disable Client Finger');
  //   }

  // }
}
