<div class="modal-content document-container">
  <div class="modal-body" >
      <ngx-extended-pdf-viewer
    #pdfViewer
    [(src)]="pdfSrc"
    [theme]="pdftheme"
    [(handTool)]="handTool"
    [contextMenuAllowed]="showContextMenu"
    [showBorders]="showBorders"
    [customToolbar]="additionalButtons"
    (currentZoomFactor)="updateZoomFactor($event)"
    [height]="'84vh'"
    [(zoom)]="zoomSetting"
    [minZoom]="minZoom" [maxZoom]="maxZoom"
    [zoomLevels]="zoomLevels"
    [textLayer]="true"
    [authorization]="bearerToken"
    [filenameForDownload]="pdffileName"
    [(page)]="page"
    [(pageLabel)]="pageLabel"
    (pageRendered)="onPageRendered()"
    (progress)="onProgress($event)"
    [showToolbar]="true"
    [showSidebarButton]="true"
    [showSecondaryToolbarButton]="true"
    [showPagingButtons]="false"
    [showDrawEditor]="false"
    [showStampEditor]="false"
    [showTextEditor]="false"
    [showPresentationModeButton]="false"
    [showOpenFileButton]="false"
    [showRotateButton]="false"
    [showHandToolButton]="false"
    [showScrollingButton]="true"
    [showSpreadButton]="false"
    [showPropertiesButton]="true">
  </ngx-extended-pdf-viewer>
  <ng-template #additionalButtons>
    <div id="toolbarViewer" >
      <div id="toolbarViewerLeft">
        <pdf-toggle-sidebar></pdf-toggle-sidebar>
        <div class="toolbarButtonSpacer"></div>
      </div>
      <pdf-zoom-toolbar [showZoomButtons]="true"></pdf-zoom-toolbar> <!-- toolbar viewer middle -->
      <div id="toolbarViewerRight">
        <div class="verticalToolbarSeparator hiddenSmallView"></div>
        <pdf-download  [showDownloadButton]="false"> Download </pdf-download>
        <div class="verticalToolbarSeparator hiddenSmallView"></div>
        <pdf-print [showPrintButton]="true" class="width: 30px; height:30px;"> Print </pdf-print>
      </div>
    </div>
  </ng-template>
    <!-- <button class="btn btn-sm btn-primary" mdbRipple color="primary"
    (click)="downloadPdfFile()">
    <i class="fas fa-sm fa-fw fa-download"></i>
    Download File</button>

    <button class="btn btn-sm btn-primary my-3" mdbRipple color="primary"
    (click)="printPdfFile()">
    <i class="fas fa-sm fa-fw fa-download"></i>
    Print</button> -->
  </div>
  <div class="modal-footer ">
    <button class="btn btn-primary" mdbRipple (click)="close()"> Close </button>
  </div>
</div>
