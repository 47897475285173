
// export class bankDetails {
//   accountNumber: string;
//   accountType: string;
//   accountName: string;
//   accountHolder: string;
//   bank: string;
//   accountLoanType: string;
//   branchName: string;
//   branchCode: string;
//   quoteId: number;
//   sameAsDisburseAccount: boolean;
// }

export class BankDetailsModel {
  accountNumber: string;
  accountType: string;
  accountHolder: string;
  bank: string;
  accountLoanType: string;
  branchName: string;
  branchCode: string;

  colAccountNumber: string;
  colAccountType: string;
  colAccountHolder: string;
  colBank: string;
  colAccountLoanType: string;
  colBranchName: string;
  colBranchCode: string;

  quoteId: number;
  sameAsDisburseAccount: boolean;
  userName: any;
  payoutMethod!: string;
  isConfirmedBankDetailsDetails: boolean = false;
}
