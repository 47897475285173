import { Injectable } from '@angular/core';
import { map, Observable, tap, throwError, timeout } from 'rxjs';
import { environment } from 'src/environments/environment';
//import { PreAuthorisationModel } from 'src/app/core/models/pre-authorisation.model';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EmploymentDetailsModel, JobTitle, LoanDocModel, PreAuthorisationModel, QuoteModel, SearchClientModel } from '@lmsModels';

@Injectable({
  providedIn: 'root'

})
export class ClientDetailsService {
  userBranch_apiURL = `${environment.apiUrl}/loan/api/user-branch`;
  client_apiURL = `${environment.apiUrl}/loan/api/client`;
  quote_apiURL = `${environment.apiUrl}/loan/api/quote`;
  idNumber: any;
  clientResult: any;
  clientHistoryResult: any;
  clientHistoryResult$!: Observable<any>;
  preAuthorisationModel: PreAuthorisationModel;
  //quoteWorkbook: any;

  accessToken: any;

  HttpParamsOptions = {
    headers: new HttpHeaders({
      'content-type': 'application/json'
    })
  };

  constructor(private httpClient: HttpClient) {

  }

  registerUser(data: any): Observable<any> {
    return this.httpClient
      .post(`${this.userBranch_apiURL}/register`, data)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  userAcccount(data: any) {
    return this.httpClient
      .post(`${this.userBranch_apiURL}/user-acccount`, data)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getAllJobTitles() {
    return this.httpClient
      .get<JobTitle[]>(`${this.userBranch_apiURL}/getAllJobTitles`);
  }

  searchUser(data: any): Observable<any> {
    return this.httpClient
      .post<any>(`${this.userBranch_apiURL}/searchUser`, data)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  resetUserPassword(data: any): Observable<any> {
    return this.httpClient
      .post<any>(`${this.userBranch_apiURL}/reset-password`, data)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  updateUser(data: any) {
    return this.httpClient
      .post<any>(`${this.userBranch_apiURL}/update`, data)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  validateUserName(username: any) {
    return this.httpClient
      .post<any>(`${this.userBranch_apiURL}/validateuser`, username);
    // .pipe(
    //   map((res: any) => {
    //     return res;
    //   })
    // );
  }



  getClientDetails(data: any) {
    return this.httpClient
      .post<any>(`${this.client_apiURL}/search`, data, this.HttpParamsOptions);
    // .pipe(
    //   map((responseData: any) => {
    //     this.clientResult = responseData;
    //     return responseData;
    //   })
    // );
  }

  // this is same as endpoint call on quotestore service - getClientQuoteDetails
  // getClientQuoteDetails(data: any) {
  //   return this.httpClient
  //     .post<any>(`${this.quote_apiURL}/idNumber`, data)
  //     .pipe(
  //       map((responseData: any) => {
  //         return responseData;
  //       })
  //     )
  // }

  getClientHistory(data: any) {
    this.clientHistoryResult$ = this.getClientHistoryRaw(data);

    return this.clientHistoryResult$;
  }
  // return this.getClientHistoryRaw(data).subscribe({
  //   next: (responseData) => {
  //     map((responseData: any) => {
  //       this.clientHistoryResult = responseData;
  //       return responseData;
  //     });
  //   },
  // });


  getClientHistoryRaw(data: any) {
    return this.httpClient
      .post<any>(`${this.client_apiURL}/history`, data);
  }

  getClientInternalHistory(data: any) {
    this.clientHistoryResult$ = this.getClientInternalHistoryRaw(data);
    return this.clientHistoryResult$;
  }

  // private getClientInternalHistoryRaw(data: any) {
  //   return this.httpClient
  //     .post<any>(`${this.client_apiURL}/internalhistory`, data, this.HttpParamsOptions);
  // }


  getClientInternalHistoryRaw(data: any) {
    return this.httpClient
      .post<any>(`${this.client_apiURL}/internalhistory`, data);
  }

  applyForAnotherLoan(data: any) {
    return this.httpClient.post(`${this.client_apiURL}/applyForAnotherLoan`, data).pipe(
      timeout({
        each: 180000,
        with: () => throwError(() => new Error("TimeOut occured")),
      }));
    // .pipe(
    //   map((responseData: any) => {
    //     return responseData;
    //   })
    // );
  }

  getClientByIdNumber(id: string): Observable<SearchClientModel> {
    return this.httpClient
      .get<SearchClientModel>(environment.apiUrl + `${id}`);
  }

  submitClientInformation(data: any) {
    return this.httpClient
      .post<any>(`${this.client_apiURL}/clientInformation`, data);
    // .pipe(
    //   map((responseData: any) => {
    //     return responseData;
    //   })
    // );
  }

  submitAffordability(data: any) {
    return this.httpClient
      .post<any>(`${this.client_apiURL}/affordability`, data);
    // .pipe(
    //   map((responseData: any) => {
    //     return responseData;
    //   })
    // );
  }

  saveChecklist(checklistObject: any) {
    return this.httpClient
      .post<any>(environment.apiUrl + '/document/checklist', checklistObject)
      .pipe(
        map((responseData: any) => {
          console.log(responseData);
          return responseData;
        })
      );
  }

  // getFiles(): Observable<any> {
  //   return this.httpClient.get(`${environment.apiUrl}/files`);
  // }

  // uploadDocument(loanDoc: LoanDocModel): Observable<HttpEvent<any>> {
  //   const source$ = this.httpClient
  //     .post<any>(`${this.quote_apiURL}/saveDocument`, loanDoc)
  //     .pipe(tap({
  //       next(responseData) {
  //         return responseData;
  //       }
  //     }));
  //   return source$;
  // }

  uploadDocument(loanDoc: LoanDocModel) {
    return this.httpClient
      .post<any>(`${this.quote_apiURL}/saveDocument`, loanDoc)
      .pipe(tap((responseData) => {
        return responseData;
      })
      );
  }

  download(data: any) {
    return this.httpClient
      .post<any>(`${this.quote_apiURL}/viewDocument`, data)
      .pipe(tap((responseData) => {
        console.log(responseData);
        return responseData;
      }));
  }

  downloadRaw(data: any) {
    return this.httpClient
      .post<any>(`${this.quote_apiURL}/viewDocument`, data);
  }


  sendSms(data: any) {
    return this.httpClient
      .post<any>(`${this.client_apiURL}/sms`, data)
      .pipe(tap((responseData) => {
        return responseData;
      }));
  }

//   sendSms(data: any) {
//   return this.httpClient
//     .post<any>(`${this.client_apiURL}/sms`, data)
//     .pipe(
//       map((responseData) => responseData.message), // Extract the message directly
//       catchError((error) => {
//         // Handle the error and return an observable with an error message
//         return of(`Error: ${error.error ? error.error.message : 'An error occurred'}`);
//       })
//     );
// }


  saveEmploymentDetails(data: any) {
    return this.httpClient
      .post<any>(`${this.quote_apiURL}/saveEmployment`, data);
    // .pipe(
    //   tap((res: any) => {
    //     return res;
    //   })
    // );
  }


  confirmEmploymentUpdate(data: EmploymentDetailsModel) {
    return this.httpClient
      .post<any>(`${this.quote_apiURL}/confirmEmploymentUpdate`, data);
    // .pipe(
    //   tap((res: any) => {
    //     return res;
    //   })
    // );
  }

  findCountries() {
    return this.httpClient.
      get<any>(`${this.client_apiURL}/countries`);
  }


  findAll() {
    return this.httpClient.
      get<any>(`${this.client_apiURL}/findAll`);
  }

  // findCountryScore(natCountry: any) {
  //   return this.httpClient.
  //   get<any>(`${this.client_apiURL}/countryScore/' + natCountry);
  // }

  findEmployment() {
    return this.httpClient.
      get<any>(`${this.client_apiURL}/employment`);
  }

  findProduct() {
    return this.httpClient.
      get<any>(`${this.client_apiURL}/product`);
  }

  findCustomer() {
    return this.httpClient.
      get<any>(`${this.client_apiURL}/customer`);
  }

  findWeighting() {
    return this.httpClient.
      get<any>(`${this.client_apiURL}/weighting`);
  }

  findNationalCountries() {
    return this.httpClient.
      get<any>(`${this.client_apiURL}/nationalCountries`);
  }

  findAddressById(id: any): any {
    return this.httpClient.
      get<any>(`${this.client_apiURL}/getAddress/${id}`);
  }

  listClientDocs(data: any) {
    return this.httpClient
      .post<any>(`${this.quote_apiURL}/listDocuments`, data)
      .pipe(tap({
        next(value) {
          return value;
        },
      }));
  }

  listClientDocsByLoanReference(data: LoanDocModel | any) {
    return this.httpClient
      .post<any>(`${this.quote_apiURL}/listDocumentsByLoanReference`, data)
      .pipe(tap({
        next(value) {
          return value;
        },
      }));
  }
  getDocChecklist(id: any) {
    return this.httpClient.
      get<any>(`${this.quote_apiURL}/getDocChecklist/${id}`);
  }

  // deleteDocument(id: number): Observable<any> {
  //   return this.httpClient
  //     .delete(environment.apiDocUrl+'/document/api/docs/' + id)
  //    .pipe(
  //     map((responseData: any) => {
  //       return responseData;
  //     })
  //   );
  // }

  deleteDocument(data: any) {
    return this.httpClient
      .post<any>(`${this.quote_apiURL}/deleteDoc`, data)
      .pipe(
        map((responseData: any) => {
          return responseData;
        })
      );
  }


  findLoansToBeApproved() {
    return this.httpClient.
      get<any>(environment.apiUrl + '/loan/approver/loanqueue');
  }

  getCallCentreQuoteDetails(data: any) {
    return this.httpClient
      .post<any>(environment.apiUrl + '/loan/approver/callCentreQuote', data)
      .pipe(
        map((responseData: any) => {
          return responseData;
        })
      );
  }

  emidBankTransactionsTest(quoteModel: QuoteModel) {
    return this.httpClient
      .post<any>(`${this.quote_apiURL}/emidBankTransactions`, quoteModel)
      .pipe(
        map((responseData: any) => {
          return responseData;
        })
      );
  }

  // checkLexisNexis(data: PreAuthorisationModel) {
  //   return this.httpClient
  //     .post<any>(`${this.client_apiURL}/lexisNexisSearch`, data)
  //     .pipe(
  //       map((responseData: any) => {
  //         return responseData;
  //       })
  //     );
  // }

  findEmploymentByName(data: any) {
    return this.httpClient
      .post<any>(`${this.quote_apiURL}/findEmployment`, data);
    // .pipe(
    //   map((responseData: any) => {
    //     return responseData;
    //   })
    // );
  }

  sendToCompliance(data: any) {
    return this.httpClient
      .post<any>(`${this.client_apiURL}/sendToCompliance`, data)
      .pipe(
        map((responseData: any) => {
          return responseData;
        })
      );
  }

  removeScorecard(id: any): Observable<any> {
    return this.httpClient
      .delete(`${this.client_apiURL}/removeScorecard/${id}`)
      .pipe(
        map((responseData: any) => {
          return responseData;
        })
      );
  }

  getAllRegions() {
    return this.httpClient
      .get<any>(`${this.userBranch_apiURL}/getAllRegions`)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getAllBranchesByRegion(region: any) {
    return this.httpClient
      .get<any>(`${this.userBranch_apiURL}/getAllBranchesByRegion/${region}`)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  findExistingComplianceData(data: any) {
    return this.httpClient
      .post<any>(`${this.client_apiURL}/fetchExistingComplianceData/`, data)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }


  validateIdNumber(data: any){
    return this.httpClient
      .post<any>(`${this.client_apiURL}/validateIdNumber`, data)
      .pipe(tap((responseData) => {
        return responseData;
      }));
  }

}

