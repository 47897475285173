/* eslint-disable @typescript-eslint/no-useless-constructor */
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Directive,
  ElementRef,
  EventEmitter,
  Host,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Renderer2,
  Self,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';

import {
  NgxPaginationModule,
  PaginationControlsDirective,
  PaginationInstance,
} from 'ngx-pagination';
import { AfterViewInit } from '@angular/core';

@Directive({
  selector: '[lmsPaginate]',
  standalone: true,
})
export class LMS_PaginateDirective
implements AfterViewInit, OnChanges, OnDestroy
{
  /**
   * custom emitter for parent component
   */

  @Output() lmschangePaged = new EventEmitter<number>();
  private paginateContainerRef!: HTMLElement;
  private lmsPaginateRef: HTMLElement;
  @Input() appCustomLength: number = 0;
  @Input() public config: PaginationInstance;

  constructor(
    @Host()
    @Self()
    @Optional()
    private readonly ngxPage: PaginationControlsDirective,
    private elementRef: ElementRef,
    private ren: Renderer2,
  ) {}

  ngOnDestroy(): void {}
  ngAfterViewInit(): void {
    if(this.appCustomLength == 0){
      this.appCustomLength = this.config.currentPage || 1;
    }
  }

  /**
   * react on parent component changing the appCustomLength - rerender bubbles
   */

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.appCustomLength.firstChange) {
      this.switchPage(changes.appCustomLength.currentValue);
      this.elementRef.nativeElement.scrollIntoView();
    }
  }

  onPaginate(numb: number = 1) {
    this.config.currentPage = numb;
    this.ngxPage['_pageChange'](numb);

    if (this.lmschangePaged) {
      this.lmschangePaged.emit(numb);
    }
  }

  /**
   * Helper function to switch page
   */

  private switchPage(i: number): void {
    if(this.ngxPage.getCurrent() != i) {
      this.ngxPage.setCurrent(i);
    }
    //this.ngxPage['_emitPageEvent'](previousPageIndex);
    this.lmschangePaged.emit(i);
  }
}

@Component({
  selector: 'lms-paginate',
  styleUrls: ['./lms-paginate.component.css'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.Default,
  template: `<div class="lms-pager">
    <pagination-template
      #p="paginationApi"
      lmsPaginate
      [appCustomLength]="config.currentPage"
      [maxSize]="maxSize"
      [id]="config.id || 'pager'"
      (pageBoundsCorrection)="onPageBoundsCorrection($event)"
      (pageChange)="onPaginate($event)">
      <div *ngIf="p.pages.length > 1">
        <button
          type="button"
          class="btn btn-sm btn-primary rounded-0 rounded-start pager-first m-0"
          data-mdb-ripple-color="#ffffff"
          (click)="p.setCurrent(1)"
          [disabled]="p.isFirstPage()">
          <small>First</small>
        </button>
        <button
          type="button"
          class="btn btn-sm btn-primary rounded-0 pager-previous m-0"
          data-mdb-ripple-color="#ffffff"
          (click)="p.previous()"
          [class.disabled]="p.isFirstPage()">
          <small>Previous</small>
        </button>

        <div class="btn-group btn-group-sm" role="group" aria-label="PageNumbers">
          <div *ngFor="let page of p.pages; let i = index">
            <div *ngIf="p.pages.length <= maxSize - 2 || (i > 0 && i + 1 !== p.pages.length)">
              <button
                type="button"
                mdbRipple
                data-mdb-ripple-color="#7a7676"
                (click)="p.setCurrent(page.value)"
                [class.btn-outline-primary]="p.getCurrent() === page.value"
                [class.btn-outline-secondary]="p.getCurrent() !== page.value"
                class="btn btn-sm number-item rounded-0">
                {{ page.value }}
              </button>
            </div>
          </div>
        </div>
        <!-- <div *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
              <a (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                  <span>{{ page.label }}</span>
              </a>
              <div *ngIf="p.getCurrent() === page.value">
                  <span>{{ page.label }}</span>
              </div>  -->
        <button
          type="button"
          class="btn btn-sm btn-primary rounded-0 pager-next  m-0"
          data-mdb-ripple-color="#ffffff"
          style=""
          (click)="p.next()"
          [disabled]="p.isLastPage()"
        >
          <small> Next </small>
        </button>
        <button
          type="button"
          class="btn btn-sm btn-primary rounded-0 rounded-end pager-last  m-0"
          data-mdb-ripple-color="#ffffff"
          (click)="p.setCurrent(p.getLastPage())"
          [disabled]="p.isLastPage()"
        >
          <small>Last</small>
        </button>
      </div>
    </pagination-template>
  </div>`,
  standalone: true,
  imports: [CommonModule, NgxPaginationModule, LMS_PaginateDirective],
})
export class LmsPaginateComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() items: any[];

  @Input() maxPages = 3;
  @Input() maxSize: number = 6;

  @Output() changePaged = new EventEmitter<number>();

  @Input() public config: PaginationInstance = {
    id: 'pager',
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
  };

  showPager: boolean = true;
  public eventLog: string[] = [];
  public labels: any = {
    previousLabel: 'Previous',
    nextLabel: 'Next',
    screenReaderPaginationLabel: 'Pagination',
    screenReaderPageLabel: 'page',
    screenReaderCurrentLabel: `You're on page`,
  };

  constructor() {}

  ngOnInit() {
    //   // set page if items array isn't empty
  }

  ngAfterViewInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.config && !changes.config.firstChange) {
      console.log('config changed: ', changes.config);
      this.showPager = this.config.totalItems! > this.config.itemsPerPage;
    }
  }

  absoluteIndex(indexOnPage: number): number {
    return (
      this.config.itemsPerPage * (this.config.currentPage - 1) + indexOnPage
    );
  }

  itemsonPage(): any[] {
    const startIndex = (this.config.currentPage - 1) * this.config.itemsPerPage;
    const endIndex = Math.min(
      startIndex + this.config.itemsPerPage - 1,
      this.config.totalItems!,
    );
    return this.items.slice(startIndex, endIndex);
  }

  onPaginate(numb: number) {
    const previousPageIndex = this.config.currentPage;
    if(this.config.currentPage != numb) {
      this.config.currentPage = numb;
    }
    this.changePaged.emit(numb);
  }

  onPageBoundsCorrection(numb: number = 1) {
    this.logEvent(`pageBoundsCorrection(${numb})`);
    this.config.currentPage = numb;
  }

  private logEvent(message: string) {
    console.info(`${new Date().toISOString()}: ${message}`);
  }

  // setPage(page: number) {
  //   // get new pager object for specified page
  //   this.pager = paginate({
  //     totalItems: this.items!.length | 0,
  //     currentPage: page,
  //     pageSize: this.pageSize,
  //     maxPages: this.maxPages,
  //   });

  //   // get new page of items from items array
  //   const pageOfItems = this.items?.slice(
  //     this.pager.startIndex,
  //     this.pager.endIndex + 1,
  //   );
  //   // call change page function in parent component
  //   this.changePage.emit(pageOfItems);
  // }
}
