<div class="d-flex align-content-center justify-content-center">
  <div class="center-screen">
    <form [formGroup]="lexisNexisReportForm">

      <table id="lexisNexisSearch" style="width:800px; border: 1px; border-width: 1px;">
        <tbody>
          <tr>
            <td>Lexis Nexis by date range</td>
            <td>
              <mat-form-field class="input-group-sm mb-1">
                <mat-label class="small">Enter a date range</mat-label>
                <mat-date-range-input [rangePicker]="picker1">

                  <input matStartDate placeholder="Start date" formControlName="startDate" [(ngModel)]="startDate">

                  <input matEndDate placeholder="End date" formControlName="endDate" [(ngModel)]="endDate">

                </mat-date-range-input>
                <mat-hint>YYYY-MM-DD – YYYY-MM-DD</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-date-range-picker #picker1></mat-date-range-picker>

              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td colspan="3" align="center">
              <button mat-raised-button class="btn btn-primary btn-rounded me-2" color="primary"
                (click)="search()">Search
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
</div>
<br />

<!-- <section  *ngIf="lstLexisNexis.length > 0">  -->
<div class="d-flex align-content-center justify-content-center">
  <div class="center-screen table-responsive" #TABLE>
    <table mat-table *ngIf="lstLexisNexis.length > 0" #table style="width:1200px; border: 3px;">
      <caption>Search Results</caption>

      <thead>
        <tr style="border: 1px;">
          <th scope="col" style="width: 120px;">Id Number</th>
          <th scope="col" style="width: 120px; text-align: center;">First Name</th>
          <th scope="col" style="width: 100px; text-align: left;">Middle Names</th>
          <th scope="col" style="width: 120px; text-align: left;">Last Name</th>
          <th scope="col" style="width: 100px; text-align: left;">Branch Name</th>
          <th scope="col" style="width: 100px;  text-align: left;">Branch Serial</th>
          <th style="width: 100px;  text-align: left;">Query Date</th>
          <th scope="col">Results found</th>
          <th scope="col">Sanctions List Confirmed</th>
          <th scope="col">Sanctions List Possible</th>
          <th scope="col">Pep or Pip</th>
          <th scope="col" hidden="true">Error Code</th>
          <th scope="col" hidden="true">Error Message</th>
        </tr>
      </thead>
      <tbody>
        <!-- <tr *ngFor="let lexis of lstLexisNexis | paginate: { itemsPerPage: pageSize, currentPage: pager.currentPage };">
          -->
        <tr *ngFor="let lexis of lstLexisNexis">

          <td style="text-align: center;">{{lexis.idNumber}}</td>
          <td style="text-align: left;">{{lexis.firstName}}</td>
          <td style="text-align: left;">{{lexis.middleNames}}</td>
          <td style="text-align: left;">{{lexis.surname}}</td>
          <td style="text-align: left;">{{lexis.branchName}}</td>
          <td style="text-align: left;">{{lexis.branchSerial}}</td>
          <td style="text-align: left;">{{lexis.queryDateTime | date}}</td>
          <td style="text-align: center;">{{lexis.noResultsFound}}</td>
          <td style="text-align: center;">{{lexis.sanctionsListConfirmed}}</td>
          <td style="text-align: center;">{{lexis.sanctionsListPossible}}</td>
          <td style="text-align: center;">{{lexis.pepOrPip}}</td>
          <td style="text-align: center;" hidden="true">{{lexis.errorCode}}</td>
          <td style="text-align: center;" hidden="true">{{lexis.errorMessage}}</td>

        </tr>
      </tbody>
    </table>
    <br />
    <lms-paginate [config]="config"></lms-paginate>
    <!-- <div class="lms-pager my-2">
        <div *ngIf="pager.pages && pager.pages?.length && pager.pages?.length > 1" class="btn-toolbar" role="toolbar" aria-label="LMS Pager group">
          <div class="btn-group btn-group-sm" role="group" aria-label="Move Previous group">

            <button type="button" class="btn btn-primary  rounded-start" data-mdb-ripple-color="#ffffff" style="background-color:#3f51b5; margin: 0;" (click)="setPage(1)" [disabled]="pager.currentPage === 1">First</button>
            <button type="button" class="btn btn-primary  rounded-0" data-mdb-ripple-color="#ffffff" style="background-color:#3f51b5; margin: 0;"(click)="setPage(pager.currentPage - 1)" [disabled]="pager.currentPage === 1">Previous</button>
          </div>
          <div class="btn-group btn-group-sm" role="group" aria-label="PageNumbers">
            <button *ngFor="let page of pager.pages" type="button" data-mdb-ripple-color="#7a7676" style="background-color:#edf2fa; margin: 0; color: #7a7676;" [ngClass]="{active:pager.currentPage === page}" (click)="setPage(page)" class="btn btn-outline-secondary page-item number-item  rounded-0">{{page}}</button>
          </div>
          <div class="btn-group btn-group-sm" role="group" aria-label="Move Next group">
            <button type="button" class="btn btn-primary rounded-0" data-mdb-ripple-color="#ffffff" style="background-color:#3f51b5; margin: 0;" (click)="setPage(pager.currentPage + 1)" [disabled]="pager.currentPage === pager.totalPages">Next</button>
            <button type="button" class="btn btn-primary rounded-end" data-mdb-ripple-color="#ffffff" style="background-color:#3f51b5;margin: 0;" (click)="setPage(pager.totalPages)" [disabled]="pager.currentPage === pager.totalPages">Last</button>
          </div>
        </div>
      </div> -->
  </div>
</div>
<!-- </section> -->

<div *ngIf="lstLexisNexis.length > 0">
  <div class="d-flex align-content-center justify-content-center">
    <button mat-raised-button class="btn btn-primary btn-rounded me-2" color="primary" (click)="exportToExcel()">Export
      to Excel</button>

  </div>