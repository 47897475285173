<div class="d-flex align-content-center justify-content-center">
  <div class="center-screen">
    <form [formGroup]="performanceReportForm">

      <table id="performanceReportSearch" style="width:800px; border: 1px; border-width: 1px;">
        <tbody>
          <tr>
            <td>Region</td>
            <div class="input-group-lg">
              <td>
                <select mdbInput class="form-control form-select ms-auto me-auto lmsselect" formControlName="region"
                  (ngModelChange)="changeRegion($event)">
                  <option *ngFor="let region of lstRegions" [ngValue]="region">
                    {{ region.name }}
                  </option>
                </select>
              </td>
            </div>
          </tr>
          <tr>
            <td>Area</td>
            <div class="input-group-lg">
              <td>
                <select mdbInput class="form-control form-select ms-auto me-auto lmsselect" formControlName="area"
                  (ngModelChange)="changeArea($event)">
                  <option *ngFor="let areas of lstAreas" [ngValue]="areas">
                    {{ areas.name }}
                  </option>
                </select>
              </td>
            </div>
          </tr>


          <tr>
            <td>Performance report by dates</td>
            <td style="width: 50%;">
              <mat-form-field class="my-2 input-group-sm d-inline-flex" appearance="fill">
                <mat-label><small>Enter a date range</small></mat-label>
                <mat-date-range-input [rangePicker]="picker2">
                  <input class="not" placeholder="Start date" matStartDate [(ngModel)]="startDate"
                    formControlName="startDate">


                  <input class="not" placeholder="End date" matEndDate [(ngModel)]="endDate" formControlName="endDate">
                </mat-date-range-input>
                <mat-hint>YYYY-MM-DD | YYYY-MM-DD</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-date-range-picker #picker2></mat-date-range-picker>
              </mat-form-field>
            </td>
          </tr>

          <tr>
            <td colspan="3" align="center">
              <button mat-raised-button class="btn btn-primary btn-rounded me-2" color="primary"
                (click)="performanceReport()">User performance report
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
</div>
<br />

<!-- <section  *ngIf="lstPerformanceReport.length > 0">  -->
<div class="d-flex align-content-center justify-content-center">
  <div class="center-screen table-responsive" #TABLE>
    <table mat-table *ngIf="lstPerformanceReport.length > 0" #table style="width:1600px; border: 3px;">
      <caption>Search Results</caption>

      <thead>
        <tr style="border: 1px;">
          <th scope="col" style="width: 120px;">&nbsp;</th>
          <th scope="col" style="width: 120px;">&nbsp;</th>
          <th scope="col" colspan="2" style="text-align: center;">FIN 500</th>
          <th scope="col" colspan="2" style="text-align: center;">FIN 1000</th>
          <th scope="col" colspan="2" style="text-align: center;">FIN 1</th>
          <th scope="col" colspan="2">FIN 2</th>
          <th scope="col" colspan="2">FIN 3</th>
          <th scope="col" colspan="2">FIN 4</th>
          <th scope="col" colspan="2">FIN 6</th>
          <th scope="col" colspan="2">FIN 12</th>
          <th scope="col" colspan="2">FIN 18</th>
          <th scope="col" colspan="2">FIN 24</th>
          <th scope="col" colspan="1"></th>
          <th scope="col" colspan="1"></th>
        </tr>

        <tr style="border: 1px;">
          <th scope="col">User name</th>
          <th scope="col">Branch name</th>
          <th scope="col" style="width: 50px; text-align: center;">Sum of Capital</th>
          <th scope="col" style="width: 60px; text-align: center;">Count of loans</th>
          <th scope="col" style="width: 50px; text-align: center;">Sum of Capital</th>
          <th scope="col" style="width: 60px; text-align: center;">Count of loans</th>
          <th scope="col" style="width: 50px; text-align: center;">Sum of Capital</th>
          <th scope="col" style="width: 60px; text-align: center;">Count of loans</th>
          <th scope="col" style="width: 50px; text-align: center;">Sum of Capital</th>
          <th scope="col" style="width: 60px; text-align: center;">Count of loans</th>
          <th scope="col" style="width: 50px; text-align: center;">Sum of Capital</th>
          <th scope="col" style="width: 60px; text-align: center;">Count of loans</th>
          <th scope="col" style="width: 50px; text-align: center;">Sum of Capital</th>
          <th scope="col" style="width: 60px; text-align: center;">Count of loans</th>
          <th scope="col" style="width: 50px; text-align: center;">Sum of Capital</th>
          <th scope="col" style="width: 60px; text-align: center;">Count of loans</th>
          <th scope="col" style="width: 50px; text-align: center;">Sum of Capital</th>
          <th scope="col" style="width: 60px; text-align: center;">Count of loans</th>
          <th scope="col" style="width: 50px; text-align: center;">Sum of Capital</th>
          <th scope="col" style="width: 60px; text-align: center;">Count of loans</th>
          <th scope="col" style="width: 50px; text-align: center;">Sum of Capital</th>
          <th scope="col" style="width: 60px; text-align: center;">Count of loans</th>
          <th scope="col" style="width: 80px;">Total Sum of Capital</th>
          <th scope="col" style="width: 80px;">Total Count of loans</th>
        </tr>
      </thead>


      <tbody>
        <tr *ngFor="let loans of lstPerformanceReport">
          <!-- | paginate: { itemsPerPage: pageSize, currentPage: pager.currentPage };"> -->
          <td style="text-align: center;">{{loans.userName}}</td>
          <td style="text-align: center;">{{loans.branchName}}</td>
          <td style="text-align: right;">{{loans.fin500Tot}}</td>
          <td style="text-align: center;">{{loans.fin500Cnt}}</td>
          <td style="text-align: right;">{{loans.fin1000Tot}}</td>
          <td style="text-align: center;">{{loans.fin1000Cnt}}</td>
          <td style="text-align: right;">{{loans.fin1Tot}}</td>
          <td style="text-align: center;">{{loans.fin1Cnt}}</td>
          <td style="text-align: right;">{{loans.fin2Tot}}</td>
          <td style="text-align: center;">{{loans.fin2Cnt}}</td>
          <td style="text-align: right;">{{loans.fin3Tot}}</td>
          <td style="text-align: center;">{{loans.fin3Cnt}}</td>
          <td style="text-align: right;">{{loans.fin4Tot}}</td>
          <td style="text-align: center;">{{loans.fin4Cnt}}</td>
          <td style="text-align: right;">{{loans.fin6Tot}}</td>
          <td style="text-align: center;">{{loans.fin6Cnt}}</td>
          <td style="text-align: right;">{{loans.fin12Tot}}</td>
          <td style="text-align: center;">{{loans.fin12Cnt}}</td>
          <td style="text-align: right;">{{loans.fin18Tot}}</td>
          <td style="text-align: center;">{{loans.fin18Cnt}}</td>
          <td style="text-align: right;">{{loans.fin24Tot}}</td>
          <td style="text-align: center;">{{loans.fin24Cnt}}</td>
          <td style="text-align: right;">{{loans.totCapital | zaRcurrency}}</td>
          <td style="text-align: center;">{{loans.totCnt}}</td>
        </tr>

        <!-- <tr>
              <td style="text-align: center;">Grand total</td>
              <td style="text-align: center;"></td> -->
        <!-- <td style="text-align: right;">{{lstPerformanceReport.fin500GrandTot}}</td>
              <td style="text-align: center;">{{loan.fin500GrandCnt}}</td>
              <td style="text-align: right;">{{loan.fin1000GrandTot}}</td>
              <td style="text-align: center;">{{loan.fin1000GrandCnt}}</td>
              <td style="text-align: right;">{{loan.fin1GrandTot}}</td>
              <td style="text-align: center;">{{loan.fin1GrandCnt}}</td>
              <td style="text-align: right;">{{loan.fin2GrandTot}}</td>
              <td style="text-align: center;">{{loan.fin2GrandCnt}}</td>
              <td style="text-align: right;">{{loan.fin3GrandTot}}</td>
              <td style="text-align: center;">{{loan.fin3GrandCnt}}</td>
              <td style="text-align: right;">{{loan.fin4GrandTot}}</td>
              <td style="text-align: center;">{{loan.fin4GrandCnt}}</td>
              <td style="text-align: right;">{{loan.fin6GrandTot}}</td>
              <td style="text-align: center;">{{loan.fin6GrandCnt}}</td>
              <td style="text-align: right;">{{loan.fin12GrandTot}}</td>
              <td style="text-align: center;">{{loan.fin12GrandCnt}}</td>
              <td style="text-align: right;">{{loan.fin18GrandTot}}</td>
              <td style="text-align: center;">{{loan.fin18GrandCnt}}</td>
              <td style="text-align: right;">{{loan.fin24GrandTot}}</td>
              <td style="text-align: center;">{{loan.fin24GrandCnt}}</td>
              <td style="text-align: right;">{{loan.grandCapital | zaRcurrency}}</td>
              <td style="text-align: center;">{{loan.totGrandCnt}}</td>  -->
        <!-- </tr> -->
      </tbody>
    </table>
    <br />

    <!-- <div class="lms-pager my-2">
          <div *ngIf="pager.pages && pager.pages?.length && pager.pages?.length > 1" class="btn-toolbar" role="toolbar" aria-label="LMS Pager group">
            <div class="btn-group btn-group-sm" role="group" aria-label="Move Previous group">

              <button type="button" class="btn btn-primary  rounded-start" data-mdb-ripple-color="#ffffff" style="background-color:#3f51b5; margin: 0;" (click)="setPage(1)" [disabled]="pager.currentPage === 1">First</button>
              <button type="button" class="btn btn-primary  rounded-0" data-mdb-ripple-color="#ffffff" style="background-color:#3f51b5; margin: 0;"(click)="setPage(pager.currentPage - 1)" [disabled]="pager.currentPage === 1">Previous</button>
            </div>
            <div class="btn-group btn-group-sm" role="group" aria-label="PageNumbers">
              <button *ngFor="let page of pager.pages" type="button" data-mdb-ripple-color="#7a7676" style="background-color:#edf2fa; margin: 0; color: #7a7676;" [ngClass]="{active:pager.currentPage === page}" (click)="setPage(page)" class="btn btn-outline-secondary page-item number-item  rounded-0">{{page}}</button>
            </div>
            <div class="btn-group btn-group-sm" role="group" aria-label="Move Next group">
              <button type="button" class="btn btn-primary rounded-0" data-mdb-ripple-color="#ffffff" style="background-color:#3f51b5; margin: 0;" (click)="setPage(pager.currentPage + 1)" [disabled]="pager.currentPage === pager.totalPages">Next</button>
              <button type="button" class="btn btn-primary rounded-end" data-mdb-ripple-color="#ffffff" style="background-color:#3f51b5;margin: 0;" (click)="setPage(pager.totalPages)" [disabled]="pager.currentPage === pager.totalPages">Last</button>
            </div>
          </div>
        </div>

      </div> -->
  </div>
  <!-- </section> -->

  <div *ngIf="lstPerformanceReport.length > 0">
    <div class="d-flex align-content-center justify-content-center">
      <button mat-raised-button class="btn btn-primary btn-rounded me-2" color="primary"
        (click)="exportToExcel()">Export to Excel</button>

    </div>