import { Directive, Renderer2, ElementRef, forwardRef, HostListener, Input } from '@angular/core';
import { Validator, NG_VALIDATORS, ValidationErrors, AbstractControl } from '@angular/forms';

@Directive({
  selector: 'input[lmsNumbersOnly]',
  // providers: [
  //   {
  //     provide: NG_VALUE_ACCESSOR,
  //     multi: true,
  //     useExisting: forwardRef(() => NumbersOnlyDirective),
  //   }],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => NumbersOnlyDirective),
      multi: true,
    },
  ],
  standalone: true,
})
export class NumbersOnlyDirective implements Validator {
  @Input() validationMessage = 'Only numeric values allowed';
  @Input() allowDecimals: boolean = true;
  @Input() allowSign: boolean = false;
  @Input() decimalSeparator: string = '.';
  @Input() allowPaste: boolean = true;

  previousValue: string = '';

  // --------------------------------------
  //  Regular expressions
  integerUnsigned: string = '^[0-9]*$';
  integerSigned: string = '^-?[0-9]+$';
  decimalUnsigned: string = '^[0-9]+(.[0-9]+)?$';
  decimalSigned: string = '^-?[0-9]+(.[0-9]+)?$';

  /**
   * Class constructor
   * @param hostElement
   */

  constructor (private hostElement: ElementRef, private renderer: Renderer2) {}

  validate (control: AbstractControl | null): ValidationErrors | null {
    if (control && control?.value) {
      const isValid = this.isNumbersOnlyValue(control?.value);
      return isValid ? null : { numberOnly: true };
    }
    return null;
  }

  /**
   * Event handler for host's change event
   * @param e
   */

  @HostListener('change', [ '$event' ]) onChange (e: InputEvent) {
    const target = e.target as HTMLInputElement;
    this.validateValue(target.value);
  }

  // @HostListener('input', ['$event']) input(event: InputEvent) {
  //   const target = event.target as HTMLInputElement;
  //   this.validateValue(target.value);
  // }

  @HostListener('blur', [ '$event' ]) onblur (e: InputEvent) {
    const target = e.target as HTMLInputElement;

    if (this.allowDecimals) {
      // when a numbers begins with a decimal separator,
      // fix it adding a zero in the beginning
      const firstCharacter = target.value.charAt(0);
      if (firstCharacter == this.decimalSeparator) {
        target.value = 0 + target.value;
      }

      // when a numbers ends with a decimal separator,
      // fix it adding a zero in the end
      const lastCharacter = target.value.charAt(target.value.length - 1);
      if (lastCharacter == this.decimalSeparator) {
        target.value = target.value + 0;
      }
    }
  }

  /**
   * Event handler for host's paste event
   * @param e
   */

  @HostListener('paste', [ '$event' ]) onPaste (event: ClipboardEvent) {
    // get and validate data from clipboard
    if (!this.allowPaste) {
      event.preventDefault();
    }

    const value = event?.clipboardData?.getData('text/plain');
    if (!this.isNumbersOnlyValue(value!)) {
      event.preventDefault();
      return { numberOnly: true };
    }

    return null;
  }

  /**
   * Event handler for host's keydown event
   * @param event
   */

  @HostListener('keydown', [ '$event' ])
  onKeyDown (event: KeyboardEvent) {
    const cursorPosition =
      (event.target as HTMLInputElement).selectionStart || (event.target as HTMLInputElement).value.length;
    const originalValue: string = (event.target as HTMLInputElement).value;
    const controlOrCommand = event.ctrlKey === true || event.metaKey === true;
    const signExists = originalValue.includes('-');
    const separatorExists = originalValue.includes(this.decimalSeparator);

    // allowed keys apart from numeric characters
    const allowedKeys = [ 'Backspace', 'ArrowLeft', 'ArrowRight', 'Escape', 'Tab' ];

    // when decimals are allowed, add
    // decimal separator to allowed codes when
    // its position is not close to the the sign (-. and .-)
    const separatorIsCloseToSign = signExists && cursorPosition <= 1;
    if (this.allowDecimals && !separatorIsCloseToSign && !separatorExists) {
      if (this.decimalSeparator == '.') {
        allowedKeys.push('.');
      } else {
        allowedKeys.push(',');
      }
    }

    // when minus sign is allowed, add its
    // key to allowed key only when the
    // cursor is in the first position, and
    // first character is different from
    // decimal separator
    const firstCharacterIsSeparator = originalValue.charAt(0) != this.decimalSeparator;
    if (this.allowSign && !signExists && firstCharacterIsSeparator && cursorPosition == 0) {
      allowedKeys.push('-');
    }

    // allow some non-numeric characters
    if (
      allowedKeys.indexOf(event.key) != -1 ||
      // Allow: Ctrl+A and Command+A
      (event.key == 'a' && controlOrCommand) ||
      // Allow: Ctrl+C and Command+C
      (event.key == 'c' && controlOrCommand) ||
      // Allow: Ctrl+V and Command+V
      (event.key == 'v' && controlOrCommand) ||
      // Allow: Ctrl+X and Command+X
      (event.key == 'x' && controlOrCommand)
    ) {
      // let it happen, don't do anything
      return;
    }

    // save value before keydown event
    this.previousValue = originalValue;

    // allow number characters only
    const isNumber = new RegExp(this.integerUnsigned).test(event.key);
    if (isNumber) {
    } else {
      event.preventDefault();
    }
  }

  private getAppropriateRegex (): string {
    if (!this.allowDecimals && !this.allowSign) {
      return this.integerUnsigned;
    }
    if (!this.allowDecimals && this.allowSign) {
      return this.integerSigned;
    }
    if (this.allowDecimals && !this.allowSign) {
      return this.decimalUnsigned;
    }
    return this.decimalSigned;
  }

  private fixDecimalSeparator (value: string): string {
    if (this.allowDecimals) {
      // when a numbers begins with a decimal separator,
      // fix it adding a zero in the beginning
      const firstCharacter = value.toString().charAt(0);
      if (firstCharacter == this.decimalSeparator) {
        value = 0 + value.toString();
      }
      // when a numbers ends with a decimal separator,
      // fix it adding a zero in the end
      const lastCharacter = value.toString().charAt(value.toString().length - 1);
      if (lastCharacter == this.decimalSeparator) {
        value = value.toString() + 0;
      }
    }
    return value;
  }

  /**
   * Test whether value is a valid number or not
   * @param value
   */

  validateValue (value: string): void {
    // choose the appropriate regular expression
    const regex = this.getAppropriateRegex();
    const valid: boolean = new RegExp(regex).test(value.toString());
    this.renderer.setProperty(this.hostElement.nativeElement, 'value', valid ? value : 0);
  }

  isNumbersOnlyValue (value: string): boolean {
    // choose the appropiate regular expression
    const regex = this.getAppropriateRegex();
    const formattedValue = this.fixDecimalSeparator(value);

    // test number with regular expression, when
    // number is invalid, replace it with a zero
    const valid: boolean = new RegExp(regex).test(formattedValue);
    return valid;
  }

  /**
   * Get key's name
   * @param e
   */

  getName (e: { key: any; keyCode: number }) {
    if (e.key) {
      return e.key;
    }

    // for old browsers
    if (e.keyCode && String.fromCharCode) {
      switch (e.keyCode) {
        case 8:
          return 'Backspace';
        case 9:
          return 'Tab';
        case 27:
          return 'Escape';
        case 37:
          return 'ArrowLeft';
        case 39:
          return 'ArrowRight';
        case 188:
          return ',';
        case 190:
          return '.';
        case 109:
          return '-'; // minus in numbpad
        case 173:
          return '-'; // minus in alphabet keyboard in firefox
        case 189:
          return '-'; // minus in alphabet keyboard in chrome
        default:
          return String.fromCharCode(e.keyCode);
      }
    }
  }
}
