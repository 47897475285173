import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SpinnerModel } from 'src/app/shared/loaders/spinner/spinner.model';
import { PageCardheadComponent } from '../../ui/pageheader-card/pageheader-card.component';
import { NgIf } from '@angular/common';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';

@Component({
  selector: 'lms-dialog',
  templateUrl: './lms-dialog.component.html',
  styleUrls: ['./lms-dialog.component.css'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports: [NgIf, PageCardheadComponent, MdbRippleModule],
})
export class LMSDialogComponent implements OnInit {
  @Input() spinner: SpinnerModel;
  title: string;
  message: string;
  showClose: boolean;
  isErrorOccured: boolean;

  autoConfirm: boolean;
  form: FormGroup;
  dlgData: any;
  description: string;

  constructor(private dialogRef: MdbModalRef<LMSDialogComponent>) {}

  ngOnInit() {}

  onConfirm(): void {
    this.dialogRef?.close(true);
  }

  onDismiss(): void {
    this.dialogRef?.close(false);
  }

  close() {
    this.dialogRef?.close(false);
  }
}

export class ConfirmDialogModel {
  constructor(
    public title: string,
    public message: string,
    public showClose: boolean,
    public isErrorOccured: boolean,
    public autoConfirm: false,
  ) {}
}
