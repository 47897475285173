export class RiskModel {
userName: any;
productType: number;
customerType: number;
countryBirth: number;
entityType: number;
moneyLaunderingScore: number;

countryScore = 0;
employmentScore = 0;
productScore = 0;
customerScore = 0;

totalScore = 0;

constructor(){}

setTotalScore() {
  this.totalScore = 0;

  this.totalScore = +this.countryScore
  + +this.employmentScore
  + +this.productScore
  + +this.customerScore;
}

}
