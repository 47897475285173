import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';
import { BranchLoansReportModel } from '@lmsModels';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  reports_apiURL = `${environment.apiUrl}/loan/api/reports`;
  BranchAdmin_apiURL = environment.apiUrl + '/loan/api/branch';

  constructor(private httpClient: HttpClient) {

   }

   findActiveBranches() {
    return this.httpClient
      .get<any>(`${this.reports_apiURL}/findActiveBranches`);
  }

  getBranchByName(branchName: any) {
    return this.httpClient
      .post<any>(`${this.BranchAdmin_apiURL}/getBranchByName`, branchName)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  pullBranchLoansReport(branchLoansReportModel: BranchLoansReportModel) {
    return this.httpClient
      .post<any>(`${this.reports_apiURL}/pullBranchLoansReport`, branchLoansReportModel)
      .pipe(
        map((res) => {
          return res;
        })
      );


    // return this.httpClient
    //   .get<any>(`${this.reports_apiURL}/pullBranchLoansReport/${branchLoansReportModel}`);

  }

  pullLexisnexisReport(branchLoansReportModel: BranchLoansReportModel) {
    return this.httpClient
      .post<any>(`${this.reports_apiURL}/pullLexisNexisReport`, branchLoansReportModel)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
 
  performanceReport(branchLoansReportModel: BranchLoansReportModel) {
    return this.httpClient
    .post<any>(`${this.reports_apiURL}/performanceReport`, branchLoansReportModel)
    .pipe(
      map((res) => {
        return res;
      })
    );
  }

}
