<head>
  <meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
  <meta http-equiv="Pragma" content="no-cache" />
  <meta http-equiv="Expires" content="0" />
</head>

<div class="row justify-content-center my-2">
  <div *ngIf="!canEdit" class="col-md-12 col-lg-10">
    <div class="idlight ms-auto me-auto rounded-1 shadow-2-strong justify-content-center my-3 p-2"
      style="width: 500px;">
      <form [formGroup]="employerSearchForm">
        <div *ngIf="!canEdit && userBranch == HQ_STAFF">
          <span class="align-items-center mx-4 my-2 d-flex">
            <label for="searchBranch" class="fw-bold">Filter branch name</label>
            <input formControlName="searchBranch" class="form-control ms-auto me-1 w-50 rounded pull-right"
              placeholder="Branch name" autofocus>
            <button mdbBtn class="btn btn-primary me-1" mdbRipple (click)="findBranchesByName()">Search
            </button>
          </span>
          <span *ngIf="(employerSearchForm?.value?.searchBranch !== ''|| userBranch === HQ_STAFF) && selectedBranch"
            class="mx-4 align-items-center my-2 d-flex">
            <label for="brancName" class="fw-bold">Select branch</label>
            <select formControlName="brancName" [(ngModel)]="selectedBranch"
              class="form-control form-select ms-auto me-1 w-50 rounded pull-right lmsselect">
              <option *ngFor="let branch of lstBranches" [ngValue]="branch">{{branch.branchName}}
              </option>
            </select>
          </span>
        </div>

        <span
          *ngIf="!canEdit && (employerSearchForm?.value?.searchBranch !== '' || userBranch !== HQ_STAFF) && selectedBranch"
          class="align-items-center mx-4 my-2 d-md-flex d-flex">
          <label for="employerName" class="fw-bold">Employer name</label>
          <input type="search" class="form-control ms-auto me-1 w-50 rounded" placeholder="Employer name *"
            formControlName="employerName" lmsNoSpecials toUppercase />
          <button mdbBtn class="btn btn-primary" mdbRipple (click)="findEmployer()">Filter
          </button>

        </span>
      </form>
    </div>
  </div>
  <!-- <div *ngIf="!canEdit && userBranch != HQ_STAFF" class="form-container center-screen">
     <table class="table table-striped table-borderless table-light table-sm mb-2" style="width: 400px;
      margin: auto;
      text-align: left;
      vertical-align: middle;">
      <tbody>
        <tr style="white-space: nowrap;">
          <td>Employer name</td>
          <td style="width: 200px;">
            <input formControlName="employerName" placeholder="Employer name *" [(ngModel)]="employerName" required (keypress)="omit_special_char($event)">
            <button mat-raised-button class="btn btn-primary btn-rounded ms-1" mdbRipple color="primary"
            (click)="findEmployer()">Filter</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div> -->

  <ng-container class="row justify-content-center">
    <div class="col-11 center-screen mb-3" *ngIf="lstEmployers.length > 0">
      <table w800 class="table table-striped table-auto table-sm shadow-2-strong rounded-1 p-1 w-75">
        <thead class="table-center-header text-nowrap" color="primary">
          <tr>
            <th scope="col"> Employer Number </th>
            <th scope="col"> Employer Name </th>
            <th scope="col"> Branch Serial </th>
            <th scope="col"> Branch Name </th>
            <th style="width: 40px;"> Edit </th>
            <th style="width: 40px;"> Delete </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let employer of lstEmployers | paginate: config;let i = index;">
            <td>
              {{employer.tnumber}}
            </td>
            <td>
              {{employer.name}}
            </td>

            <td>
              {{employer.serial}}
            </td>
            <td>
              {{employer.branchName}}
            </td>

            <td>
              <button class="btn btn-floating btn-icon fs-5 fas" mdbRipple (click)="editEmployer(employer)">
                <i class="fas fa-sm fa-fw fa-pencil"></i>
              </button>
            </td>
            <td>
              <!--removeScorecard(score.id);-->
              <button class="btn btn-floating btn-icon fs-5 fas" mdbRipple (click)="deleteEmployer(employer.id)">
                <i class="fas fa-xs fa-trash-can"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <lms-paginate [config]="config" [items]="lstEmployers"></lms-paginate>
    </div>
    <div class="center-section">
      <button *ngIf="!canEdit" class="btn btn-primary" mdbRipple (click)="newEmployer()">New employer</button>
    </div>
  </ng-container>

  <div class="form-container center-screen col-10">
    <form [formGroup]="employerDetailsForm" *ngIf="canEdit">
      <div class="card">
        <div class="card-body">
          <table class="table table-striped table-borderless table-sm table-fixed">
            <caption>Employer Details</caption>
            <tbody>
              <tr>
                <td class="aligndetails">
                  <!-- Employer details-->
                  <table class="table table-striped table-bordered table-sm table-auto">
                    <tbody>
                      <tr>
                        <td><label> Employer TNR Number </label></td>
                        <td>
                          {{employerModel.employerTNR}}
                        </td>
                      </tr>
                      <tr>
                        <td><label> Employer Name </label></td>
                        <td>
                          <input class="form-control" formControlName="employerName" placeholder="Employer Name *"
                            toUppercase lmsNoSpecials required />
                          <div *ngIf="employerName?.errors?.required && submitted" class="error-label">
                            Employer name is required
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Parent Company/Group</td>
                        <td>
                          <input class="form-control" formControlName="parentCompany" placeholder="Parent Company"
                            lmsNoSpecials />
                        </td>
                      </tr>

                      <tr>
                        <td>Email</td>
                        <td>
                          <input class="form-control" formControlName="workEmail" placeholder="Employer Email" />
                        </td>
                      </tr>

                      <tr>
                        <td>Telephone 1</td>
                        <td>
                          <input class="form-control" formControlName="tel1" placeholder="Telephone *" required
                            maxlength="10" lmsNumbersOnly [allowDecimals]="false">
                          <div *ngIf="employerDetailsForm?.controls?.tel1!.errors?.required && submitted"
                            class="error-label">
                            Employer telephone is required
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td>Telephone 2</td>
                        <td>
                          <input class="form-control" formControlName="tel2" placeholder="Alternate Telephone"
                            maxlength="10" lmsNumbersOnly [allowDecimals]="false">
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td class="aligndetails">
                  <table class="table table-striped table-bordered table-sm table-auto">
                    <tbody>
                      <tr>
                        <td>Branch Name</td>
                        <td> {{employerModel.branchName}} </td>
                      </tr>

                      <tr>
                        <td>Pay Frequency</td>
                        <td>
                          <select class="lmsselect" formControlName="wageFrequency"
                            (change)="onWageFrequencyChange($event)" [addDefault]="true"
                            [class.ng-invalid]="!wageFrequency?.value || wageFrequency?.value == 'null'" lmsSelectOption
                            [constantName]="'WAGEFREQUENCY'" required>
                            <!--
                            [ngClass]="{'invalid':!wageFrequency.value || wageFrequency.value== 'undefined'}"

                            <option *ngFor="let wage of lstWageFrequency" [value]="wage.value">{{wage.description}}
                            </option> -->
                          </select>
                          <div *ngIf="(wageFrequency!.invalid || wageFrequency!.errors?.required) && submitted"
                            class="error-label">
                            Wage frequency is required.
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Pay Date</td>
                        <td>
                          <select class="lmsselect" formControlName="payDate" style="width: 80px;" lmsSelectOption
                            [class.ng-invalid]="!payDate?.value" [addDefault]="true"
                            [ngClass]="{ 'ng-invalid': !payDate?.value || payDate?.value == 'null'}"
                            [constantName]="wageFrequency?.value == 'Monthly'? 'PAYDATES':'PAYDAYOFWEEK'" required>
                            <!-- <option *ngFor="let pDate of payDates"
                             [value]="pDate.value">{{pDate.description}}
                            </option> -->
                          </select>
                          <div *ngIf="(payDate!.invalid || payDate!.errors?.required) && submitted" class="error-label">
                            Pay date is required
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td>Shifting Rule</td>
                        <td>
                          <select class="lmsselect" formControlName="shiftingRule" [addDefault]="true"
                            placeholder="Shifting rule"
                            [ngClass]="{ 'ng-invalid': !shiftingRule?.value || shiftingRule?.value == 'null'}" required
                            lmsSelectOption [constantName]="'SHIFTINGRULES'">
                            <!-- <option *ngFor="let shifing of lstShiftingRule" [value]="shifing.value">{{shifing.description}}
                            </option> -->
                          </select>
                          <div *ngIf="(shiftingRule!.invalid || shiftingRule!.errors?.required) && submitted"
                            class="error-label">
                            Shifting rule is required
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td>Pay Method</td>
                        <td>
                          <select class="lmsselect" formControlName="paymentMethod" lmsSelectOption
                            [ngClass]="{ 'ng-invalid': !paymentMethod?.value || paymentMethod?.value == 'null'}"
                            [constantName]="'PAYMETHODS'" [addDefault]="true" required>
                            <!-- <option *ngFor="let payMethod of lstPayMethod" [value]="payMethod.value">{{payMethod.description}}
                            </option> -->
                          </select>
                          <div *ngIf="(paymentMethod!.invalid || paymentMethod!.errors?.required) && submitted"
                            class="error-label" class="error-label">
                            Salary pay method is required
                          </div>
                        </td>
                      </tr>


                    </tbody>
                  </table>
                </td>
              </tr>

              <tr>
                <!--  work address-->
                <td class="aligndetails">
                  <table class="table table-striped table-bordered table-sm table-auto">
                    <caption>
                      Work Address Details
                    </caption>

                    <tbody>
                      <tr>
                        <td>Work Address 1</td>
                        <td>
                          <input class="form-control" formControlName="workAddressLine1" placeholder="Address Line 1 *"
                            type="text" required lmsNoSpecials>
                          <div *ngIf="employerDetailsForm?.controls?.workAddressLine1!.errors?.required && submitted"
                            class="error-label">
                            Work Address 1 is required
                          </div>
                        </td>

                      </tr>

                      <tr>
                        <td>Work Address 2</td>
                        <td>
                          <input class="form-control" formControlName="workAddressLine2" placeholder="Address Line 2"
                            type="text" lmsNoSpecials>
                        </td>
                      </tr>

                      <tr>
                        <td>Work Suburb</td>
                        <td>
                          <input class="form-control" formControlName="workAddressSuburb" placeholder="Address Suburb *"
                            type="text" required lmsNoSpecials>
                          <div *ngIf="employerDetailsForm?.controls?.workAddressSuburb!.errors?.required && submitted"
                            class="error-label">
                            Work Suburb is required
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td>Work Town/City</td>
                        <td>
                          <input class="form-control" formControlName="workAddressTown"
                            placeholder="Address Town/City *" type="text" lmsNoSpecials required>
                          <div *ngIf="employerDetailsForm?.controls?.workAddressTown!.errors?.required && submitted"
                            class="error-label">
                            Town / City is required
                          </div>
                        </td>

                      </tr>

                      <tr>
                        <td>Work Area Code</td>
                        <td>
                          <input class="form-control" formControlName="workAddressAreaCode" required
                            placeholder="Address Area Code" required maxlength="4" lmsNumbersOnly
                            [allowDecimals]="false">
                          <!-- <div *ngIf="validateEmployerInput.workAddressAreaCode.errors?.required" class="error-label">
                              Area code is required
                            </div> -->
                        </td>
                      </tr>

                    </tbody>
                  </table>
                </td>

                <!--  postal address-->
                <td class="aligndetails">
                  <table class="table table-striped table-bordered table-sm  table-auto">
                    <caption>
                      Postal Address Details
                    </caption>

                    <tbody>
                      <tr>
                        <td>Postal Address 1</td>
                        <td>
                          <input class="form-control" formControlName="postalAddressLine1"
                            placeholder="Postal Address 1" type="text">
                          <!-- <div *ngIf="validateEmployerInput.postalAddressLine1.errors?.required" class="error-label">
                            Address line 1 is required
                          </div> -->
                        </td>
                      </tr>

                      <tr>
                        <td>Postal Address 2</td>
                        <td>
                          <input class="form-control" formControlName="postalAddressLine2"
                            placeholder="Postal Address 2" type="text">
                        </td>
                      </tr>

                      <tr>
                        <td>Postal Town/City</td>
                        <td>
                          <input class="form-control" formControlName="postalAddressTown"
                            placeholder="Postal Address Town/City" type="text" lmsNoSpecials>
                          <!-- <div *ngIf="validateEmployerInput.postalAddressTown.errors?.required" class="error-label">
                              Town / City is required
                            </div> -->
                        </td>
                      </tr>

                      <tr>
                        <td>Postal Area Code</td>
                        <td>
                          <input class="form-control" formControlName="postalAddressAreaCode"
                            placeholder="Postal Area Code" maxlength="4" lmsNumbersOnly [allowDecimals]="false">
                          <!-- <div *ngIf="validateEmployerInput.postalAddressAreaCode.errors?.required" class="error-label">
                              Area code is required
                            </div> -->
                        </td>
                      </tr>

                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="card-footer flex-container">
          <button *ngIf="!canEdit" class="btn btn-primary me-4" mdbRipple (click)="newEmployer()">New employer</button>
          <button *ngIf="canEdit" class="btn btn-primary" mdbRipple (click)="cancelEdit()">Cancel</button>
          <button *ngIf="canEdit" class="btn btn-primary" mdbRipple (click)="saveEmployer()">Save employer</button>
        </div>
      </div>
    </form>
  </div>
</div>
