import { HttpContextToken, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, isDevMode } from '@angular/core';
import { Observable, of, throwError, TimeoutError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { ApiloaderService } from 'src/app/shared/loaders/apiloader/apiloader.service';
import { LoadingSpinService } from "src/app/shared/loaders/spinner/loadspinner.service";
import { environment } from 'src/environments/environment';
import { ErrorhanderService } from "../services/error-handler.service";

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');
export const DEFAULT_TIMEOUTCTX = new HttpContextToken<number>(() => 120000);

// export function TimeoutInterceptorFn(req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> {
//   const toastrService = inject(ToastrService);
//   const loaderService = inject(LoadingSpinService);
//   const errorService =  inject(ErrorhanderService);
//   const reqWithHeader = req.clone({
//     headers: req.headers.delete('timeout'),
//   })

//   if(reqWithHeader.headers.has('timeout')) {
//     console.error('TIMEOUT FAIL for', req.url);
//   }
//   if(isDevMode()){
//     console.warn('Timeout Handled >> ', req.url);
//   }
//   const timeoutValue = reqWithHeader.context.get(DEFAULT_TIMEOUTCTX);
//   return next(req).pipe(timeout(timeoutValue),
//     catchError((returnedError) => {
//       //if (!returnedError.url?.endsWith('doLoanCreate')) { }
//       if (returnedError instanceof TimeoutError) {
//         toastrService.warning('Timeout occured', `LMS Request Time-Out`, { positionClass: 'toast-top-center', disableTimeOut: true, closeButton: true, progressAnimation: 'increasing' });
//         //this.errorService.handleOffline(returnedError.message, 'Server not responding');
//         loaderService.stopLoading();
//         return throwError(() => {
//            const error = new Error(returnedError.message);
//            return error;
//         });
//       }

//       return of(returnedError);
//     })
// );
// }

@Injectable({
  providedIn: 'root'
})
export class TimeoutInterceptor implements HttpInterceptor {
  constructor(
    public loaderService: LoadingSpinService,
    private apiloader: ApiloaderService,
    public errorService: ErrorhanderService,
    @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const timeoutValue = req.context.get(DEFAULT_TIMEOUTCTX) || this.defaultTimeout;
    // const reqWithHeader = req.clone({
    //   headers: req.headers.delete('timeout'),
    // })

    if(req.headers.has('timeout')) {
      console.error('TIMEOUT FAIL for', req.url);
    }
    if(isDevMode()){
      console.warn('Timeout('+timeoutValue+'ms) STRT >> ', req.url);
    }

    return next.handle(req).pipe(
      timeout(timeoutValue),
      catchError((error) => this.handleTimeOut(error, req))
    );
  }

  private handleTimeOut(error: any,req: HttpRequest<any>): Observable<any> {
    if (error instanceof TimeoutError) {
      this.errorService.handleOffline(error.message, 'Server not responding');
      console.error('TIMEOUT ERROR>> ', error);

        this.apiloader.WaitforOnline(req)
        .then((waited)=>{
          if(waited){

          }
        })
        .catch((err) => {
          return throwError(()=> err);
        });

      console.error('API/Server ERROR >> ', error.message);
      this.errorService.handleOffline(environment.showtrace ? error.stack || error.message : error.message, 'API/Server OFFLINE');

      return of(error);
    }

    if (error instanceof Error) {
      console.error('*** Not TIMEOUT >> IGNORING....', error);
    }

    return throwError(() => error);
  }
}
