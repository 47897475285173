export class PostCodeModel {
  slice(arg0: number, arg1: number): string[] {
    throw new Error('Method not implemented.');
  }
  push(arg0: { AREA: string; SUBURB: string; STRCODE: string; BOXCODE: string; FIELD5: string; }) {
    throw new Error('Method not implemented.');
  }
  SUBURB: string;
  BOXCODE: string;
  STRCODE: string;
  AREA: string;
  FIELD5: string;
}
