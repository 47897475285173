//import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import paginate from '@lmsPaginate';
import { ZARcurrencyPipe } from '@lmsSharedUI';
import { NgxPaginationModule } from 'ngx-pagination';
//import { BranchLoansReportModel } from 'src/app/core/models/branch-loans-report-model.model';
import { BranchLoansReportModel } from '@lmsModels';
import { ReportService } from 'src/app/core/rest-services/report.service';
import * as XLSX from 'xlsx';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-performance-report',
  templateUrl: './performance-report.component.html',
  styleUrls: ['./performance-report.component.css'],
  standalone: true,
  imports:[
    CommonModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatButtonModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    ZARcurrencyPipe
  ]
})


export class PerformanceReportComponent implements OnInit {
  performanceReportForm: FormGroup;
  startDate: string;
  endDate: string;
  selectedBranch: any;
  branchLoansReportModel = new BranchLoansReportModel();
  lstPerformanceReport: Array<any> = [];
  title = 'export-excel';
  fileName = '';

  @ViewChild('TABLE') table: ElementRef;
  dataSource = this.lstPerformanceReport;
  
   //PAGINATOR CODE
   @Input() items: Array<any>;
   @Output() changePage = new EventEmitter<any>(true);
   @Input() initialPage = 1;
   @Input() pageSize = 15;
   @Input() maxPages = 5;
 
   docCount = 1;
   pager: any = {};

  constructor(
    public reportService: ReportService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.performanceReportForm = this.formBuilder.group({
      'branch': [this.selectedBranch],
      'startDate': new Date(this.branchLoansReportModel.startDate),
      'endDate': new Date(this.branchLoansReportModel.endDate)
   });
  }

  performanceReport() {
    this.branchLoansReportModel.startDate = this.startDate;
    this.branchLoansReportModel.endDate = this.endDate;
    this.reportService.performanceReport(this.branchLoansReportModel).subscribe({
      next: (result) => {
        console.log("result = "  + result);
       // console.log("quotes = "  + quotes.);
       this.lstPerformanceReport = result;

        // this.items = this.lstLexisNexis;
        // if (this.items && this.items.length) {
        //   this.setPage(this.initialPage);
        // }
      },
      error: (err) => {
      }
    });
  }


  public setPage(page: number) {
    // get new pager object for specified page
    this.pager = paginate({ totalItems: this.items.length, currentPage: page, pageSize: this.pageSize, maxPages: this.maxPages });

    // get new page of items from items array
    const pageOfItems = this.items.slice(this.pager.startIndex, this.pager.endIndex + 1);

    // call change page function in parent component
    this.changePage.emit(pageOfItems);
  }
  
  exportToExcel():void {
    console.log("exportToExcel"  );
    this.title = 'export-excel';
    this.fileName = 'Performance Report: '+ new Date().toLocaleDateString() + '.xlsx';

    /* pass here the table id */
    //const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.lstLexisNexis);
    const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

}
