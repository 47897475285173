<app-page-cardhead [PageName]="'Area Details'" [PageHeadWidth]="500"></app-page-cardhead>

<div class="form-container center-screen">
  <form [formGroup]="areaForm">
    <div class="card" [style.width.px]="500">
      <div class="card-body py-2">
        <table class="table table-striped table-hover table-sm">
          <caption>Update area name</caption>
          <tbody class="field-4column-label">
            <tr>
              <td>Area</td>
              <td>
                <input formControlName="area" [(ngModel)]="areaModel.name" type="text" required>
                <div *ngIf="areaForm.controls.area.errors?.required && submitted" class="error-label">
                  Area is required
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="card-footer flex-container">
          <button class="btn btn-primary btn-sm me-2" mdbRipple (click)="close()"> Close
          </button>
          <button class="btn btn-primary btn-sm" mdbRipple (click)="saveArea()">Save</button>
        </div>
      </div>
    </div>
  </form>
</div>
