<div class="modal-content">
  <div class="modal-header">
    <app-page-cardhead [PageName]="title || dlgData.title" [PageHeadWidth]="300"></app-page-cardhead>
  </div>

  <div class="modal-body">
    <p class="fs-6">
      <b>{{message || dlgData.message}}</b>
    </p>
    <div class="modal-footer">
      <button *ngIf="!showClose" class="btn btn-primary me-2" mdbRipple (click)="onDismiss()">Cancel</button>
      <button *ngIf="!showClose" class="btn btn-primary  me-2" mdbRipple (click)="onConfirm()">Yes</button>
      <button *ngIf="showClose" class="btn btn-primary  me-2" mdbRipple (click)="onConfirm()">Close</button>
    </div>
  </div>
  <!-- Compulsory Dismiss Button -->
  <!-- <button matRipple [mat-dialog-close]>{{data.dismiss_text || 'OK'}}</button> -->
  <!-- Optional Action -->
  <!-- <button *ngIf="!!data.action" matRipple [mat-dialog-close] (click)="data.action()">{{data.action_text}}</button> -->
</div>
