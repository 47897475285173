/* eslint-disable @typescript-eslint/no-empty-function */
import { Directive, forwardRef, HostListener } from '@angular/core';
import { DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[lmsNoSpecials]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => LMS_NoSpecialsDirective),
    },
  ],
  standalone: true,
})
export class LMS_NoSpecialsDirective extends DefaultValueAccessor {
  @HostListener('paste', ['$event']) onPaste(e: ClipboardEvent) {
    // get and validate data from clipboard
    const clipvalue = e!.clipboardData?.getData('text/plain');
    this.validateValue(clipvalue!);
    e.preventDefault();
  }

  @HostListener('input', ['$event']) onChanges(e: InputEvent) {
    const target = e.target as HTMLInputElement;
    this.validateValue(target.value);
  }

  validateValue(value: string): void {
    const formattedVal = value.replace(/[^a-zA-Z  _-]*/gi, '');
    this.writeValue(formattedVal);
  }
}
