import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { ApiloaderService } from 'src/app/shared/loaders/apiloader/apiloader.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorhanderService {

  constructor(
    private router: Router,
    private toastrService: ToastrService,
    private authService: AuthenticationService,
    private apiloader: ApiloaderService) { }

  handle(e: unknown, hdrString?: string, showdate = true) {
    let message = '';

    if (e instanceof Error) {
      message = e.message;

    } else if (e instanceof HttpErrorResponse) {
      if (e.error) {
        message = e.error.message || e.error.error || '';
      }
      else {
        message = e.message || '';
      }

      hdrString &&= ` ${e.status} ${e.statusText}: ${e.name}`;

    } else if (typeof e == 'string') {
      message = e;

    } else {
      message = "We have experienced an unforeseen issue, we will attend to this.";
      this.apiloader.isAPIOffline.next(true);
    }

    let showdateStr = '';
    if (showdate) { showdateStr = `[${new Date().toLocaleString()}] `; }
    this.toastrService.error(message, `${showdateStr}${hdrString || ''}`, { titleClass:'h5', newestOnTop: true, timeOut: 18000, positionClass: 'toast-top-center' });
    // handle the error message here
  }

  handleString(e: string, hdrString?: string, showdate?: boolean, warningToast?:boolean) {
    let message = "We are experiencing some issues, we will attend to this";
    const showdateStr = new Date().toLocaleString();

    hdrString = hdrString || '';
    if (showdate) {
      hdrString = `${showdateStr}: ${hdrString}`;
    }

    if (typeof e === 'string') {
      message = e;
    }

    if(warningToast){
      this.toastrService.warning(message, hdrString, { newestOnTop: true, positionClass: 'toast-top-center' });
    } else {
      this.toastrService.error(message, hdrString, { newestOnTop: true, disableTimeOut: true, closeButton: true, positionClass: 'toast-top-center' });
    }
  }

  handleStack(e: any, hdrString?: string, showdate?: boolean) {
    const showdateStr = new Date().toLocaleString();

    hdrString = hdrString || '';
    if (showdate) {
      hdrString = `${showdateStr}: ${hdrString}`;
    }

    let estack = '';
    if(Array.isArray(e) ) {
      e.forEach(stack => {
        estack += '['+ stack.className + '] ' + stack.methodName + '\n\r';
      });
    }
    else{
      estack = e;
    }

    this.toastrService.error(estack, hdrString, { newestOnTop: true, enableHtml:true, disableTimeOut: true, closeButton: true, messageClass:'text-start', positionClass: 'toast-top-center' });
  }

  handleOffline(e: string, hdrString?: string) {
    this.apiloader.isAPIOffline.next(true);
    this.apiloader.isLoading.next(false);
    this.toastrService.error(`Server non-responsive!`, `${hdrString}`, { newestOnTop: true, disableTimeOut: true, closeButton: true, positionClass: 'toast-top-center' });

    // handle the error message here
  }

  handleServerSideError(error: HttpErrorResponse): boolean {
    let handled = false;

    switch (error.status) {
    case 304:
    case 404: {
      const message1 = error.message || '';

      const hdrString1 = error.statusText || `NOT Modified/Updated: `;

      const showdateStr1 = new Date().toLocaleString()+' -';
      this.toastrService.error(message1, `${showdateStr1}${hdrString1 || ''}`, { positionClass: 'toast-top-center', extendedTimeOut: 15000, newestOnTop: true });
      handled = false;
      break;
    }
    case 401: {
      //we don't want to redirect people to the login page when they're already on
      //the login page
      if (this.router.url != '/home') {
        //this.alertService.info("Please login again.", { keepAfterRouteChange: false });
        this.toastrService.info('WHO!? Please Login!');
        this.authService.logout();
        handled = true;
      }
      break;
    }
    case 403: {
      //this.alertService.info("Please login again.", { keepAfterRouteChange: false });
      //this.authenticationService.logout();
      this.toastrService.info('BYE!? Log out!');
      handled = true;
      break;
    }
    case 417:{
      handled = false;

      break;
    }
    case 422:{
      const hdrString422 = ` ${error.url}[${error.status}]: `;

      const showdateStr422 = `${new Date().toLocaleString()} -`;
      this.toastrService.error(`${error.error.localizedMessage}`, `${showdateStr422}${hdrString422 || ''}`, { positionClass: 'toast-top-center', extendedTimeOut: 5000, newestOnTop: true , tapToDismiss: true});
      handled = true;
      break;
    }
    // case 500:
    //   let ms = JSON.parse(error.error);
    //   let message2 = JSON.parse(ms, (neskey, nesval) => {
    //     let nested2 = JSON.stringify(nesval);
    //     let errstr = JSON.parse(nested2, (neskey1, nesval1) => {
    //       return nesval1;
    //     });
    //     return errstr;
    //   });
    //   let showdateStr2 = `${new Date().toLocaleString()} -`;

    //   this.toastrService.error(`${message2}`, `${showdateStr2}`, { positionClass: 'toast-top-center', extendedTimeOut: 5000, newestOnTop: true, tapToDismiss: true });
    //   handled = false;
    //   break;
    case 0:
      this.handleOffline(error.message || error.error.message, 'Server OFFLINE');
      this.apiloader.isAPIOffline.next(true);
      handled = true;
      break;
    default: {
      let message = `${error.message || error.error.message}`;
      // if (error.error) {
      //   message = error.error.message;
      // } else {
      //   message = error.message;
      // }
      const hdrString = ` ${error.error.path}[${error.status}]: `;
      if (error.error.path === '/biometric/user-api/verify-finger') {
        message = 'No Finger Provided';
      }
      let showdateStr = '';
      showdateStr = `${new Date().toLocaleString()} -`;
      this.toastrService.error(message, `${showdateStr}${hdrString || ''}`, { positionClass: 'toast-top-center', extendedTimeOut: 5000, newestOnTop: true });
      handled = true;
      // handle the error message here
      break;
    }
    }
    return handled;
  }
}

