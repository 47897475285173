import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserAuditTrailModel } from '@lmsModels';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'

})
export class UserAuditTrailService {
  userAudit_apiURL = `${environment.apiUrl}/loan/api/user-audit-trail`;
  lmsheaders = new HttpHeaders().set('Content-Type', 'application/json')
  .set('Accept', 'application/json');

  userAuditLog$ = this.findLastThirtyEntries();
  // HttpParamsOptions = {
  //   headers: new HttpHeaders({'Content-Type':'application/json; charset=utf-8'})
  // };

  constructor(private httpClient: HttpClient) { }

  findAll() {
    return this.httpClient.get(`${this.userAudit_apiURL}/findAll`);
  }

  findLastThirtyEntries() {
    return this.httpClient.get<UserAuditTrailModel[]>(`${this.userAudit_apiURL}/find-thirty-entries`);
  }

  searchBranchAudit(data: any) {
    return this.httpClient.post(`${this.userAudit_apiURL}/search-allaudit`, data, {headers: this.lmsheaders})
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  searchAuditbyBranchName(data: any) {
    return this.httpClient.post<UserAuditTrailModel[]>(`${this.userAudit_apiURL}/auditBybranchname`, data, { headers: this.lmsheaders });
  }

  searchAudit(data: any) {
    return this.httpClient.post(`${this.userAudit_apiURL}/search-audit`, data, {headers: this.lmsheaders})
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

}

