import { Injectable } from '@angular/core';

const USER_KEY = 'auth-user';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  clean(): void {
    window.sessionStorage.clear();
  }

  public saveUser(user: any): void {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): any | undefined {
    const user = window.sessionStorage.getItem(USER_KEY);
    if (user) {
      const userObj = JSON.parse(user);
      return {...userObj};
    }
  }

  /**
  * last action
  */
  public getLastAction() {
    return window.sessionStorage.getItem('lastAction') || "";
  }

  /**
   * set last action
   * @param value
   */
  lastAction(value: any) {
    window.sessionStorage.setItem('lastAction', JSON.stringify(value));
  }

  public isLoggedIn = () => {
    const user = window.sessionStorage.getItem(USER_KEY);
    console.log('storage loggged call >>',user);
    if (user) {
      return true;
    }

    return false;
  }
}
