<div class="modal-content">

  <section *ngIf="lstLoans.length > 0">
    <div>
      <table
        class="table table-sm table-striped table-borderless table-primary ms-auto me-auto table-center-header shadow-2-strong rounded-1 p-1 w-75">
        <caption>Loans by branch summary</caption>
        <thead>
          <tr class="text-nowrap">
            <th scope="col">First name</th>
            <th scope="col">Surname</th>
            <th scope="col">Id number</th>
            <th scope="col">Loan date</th>
            <th scope="col">Loan status</th>
            <th scope="col">File no</th>
            <th scope="col">Loan no</th>
            <th scope="col">First DO date</th>
            <th scope="col">Capital</th>
            <th scope="col">Term</th>
            <th scope="col">Installment</th>
            <th scope="col">Total repayment</th>
            <th scope="col">User name</th>
          </tr>
        </thead>
        <tbody class="center-fields table-bordered  text-bg-light table-light  table-hover table-group-divider ">
          <!-- <tr *ngFor="let loan of lstLoans | paginate: { itemsPerPage: pageSize, currentPage: pager.currentPage };"
            class="text-nowrap"> -->
          <tr *ngFor="let loan of lstLoans | paginate: config;">
            <td>{{loan.firstName}}</td>
            <td>{{loan.surname}}</td>
            <td>{{loan.idNumber}}</td>
            <td>{{loan.loanDate | date}}</td>
            <td>{{loan.loanStatus}}</td>
            <td>{{loan.fileNr}}</td>
            <td>{{loan.loanNumber}}</td>
            <td>{{loan.firstDebitOrderDate}}</td>
            <td>{{loan.capital | zaRcurrency}}</td>
            <td>{{loan.term}}</td>
            <td>{{loan.installment | zaRcurrency}}</td>
            <td>{{loan.totalRepayment | zaRcurrency}}</td>
            <td>{{loan.userName}}</td>
          </tr>
        </tbody>
      </table>
      <lms-paginate [config]="config"></lms-paginate>
      <br />
    </div>

    <div class="center-screen table-responsive" #TABLE>
      <table mat-table #table hidden="true"
        class="table table-sm table-striped table-borderless table-primary ms-auto me-auto table-center-header shadow-2-strong rounded-1 p-1 w-75">
        <caption>Loans by branch Results</caption>
        <thead>
          <tr class="text-nowrap">
            <th scope="col">Branch serial</th>
            <th scope="col">Area</th>
            <th scope="col">Branch name</th>
            <th scope="col">Client type</th>
            <th scope="col">Wage frequency</th>
            <th scope="col">File no</th>
            <th scope="col">Date of birth</th>
            <th scope="col">Time</th>
            <th scope="col">Payout method</th>
            <th scope="col">Repayment method</th>
            <th scope="col" style="width: 200px;">Id number</th>
            <!-- <th scope="col">Structure</th> -->
            <th scope="col">Bank name</th>
            <th scope="col">Bank account</th>
            <th scope="col">Bank branch number</th>
            <th scope="col">Employer name</th>
            <th scope="col">Insurance</th>
            <th scope="col">Loan date</th>
            <th scope="col">Loan status</th>
            <th scope="col">First name</th>
            <th scope="col">Surname</th>
            <th scope="col">Cellphone number</th>
            <th scope="col">First DO date</th>
            <th scope="col">Capital</th>
            <th scope="col">Term</th>
            <th scope="col">Net income</th>
            <th scope="col">Max affordability</th>
            <th scope="col">Max installment</th>
            <th scope="col">Loan number</th>
            <th scope="col">My benefit</th>
            <th scope="col">Installment</th>
            <th scope="col">Initiation fee</th>
            <th scope="col">Total repayment</th>
            <th scope="col">User name</th>
          </tr>
        </thead>
        <tbody class="center-fields table-bordered  text-bg-light table-light  table-hover table-group-divider ">
          <tr *ngFor="let loan of lstLoans" class="text-nowrap">
            <td>{{loan.branchSerial}}</td>
            <!-- <td>{{loan.region}}</td>-->
            <td>{{loan.area}}</td>
            <td>{{loan.branchName}}</td>
            <td>{{loan.clientType}}</td>
            <td>{{loan.wageFrequency}}</td>
            <td>{{loan.fileNr}}</td>
            <td>{{loan.dateOfBirth | date}}</td>
            <td>{{loan.time}}</td>
            <td>{{loan.payoutMethod}}</td>
            <td>{{loan.repaymentMethod}}</td>
            <td>{{loan.idNumber}}</td>
            <!-- <td>{{loan.structure}}</td> -->
            <td>{{loan.bankName}}</td>
            <td>{{loan.bankAccount}}</td>
            <td>{{loan.bank}}</td>
            <td>{{loan.employerName}}</td>
            <td>{{loan.insurance}}</td>
            <td>{{loan.loanDate | date}}</td>
            <td>{{loan.loanStatus}}</td>
            <td>{{loan.firstName}}</td>
            <td>{{loan.surname}}</td>
            <td>{{loan.cellphoneNumber}}</td>
            <td>{{loan.firstDebitOrderDate}}</td>
            <td>{{loan.capital | zaRcurrency}}</td>
            <td>{{loan.term}}</td>
            <td>{{loan.netIncome | zaRcurrency}}</td>
            <td>{{loan.maxAffordability | zaRcurrency}}</td>
            <td>{{loan.maxInstallment | zaRcurrency}}</td>
            <td>{{loan.loanNumber}}</td>
            <td>{{loan.myBenefit | zaRcurrency}}</td>
            <td>{{loan.installment | zaRcurrency}}</td>
            <td>{{loan.initiationFee | zaRcurrency}}</td>
            <td>{{loan.totalRepayment | zaRcurrency}}</td>
            <td>{{loan.userName}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="my-3">
      <button class="btn btn-primary me-2" (click)="exportLoansToExcel()">Export full report to Excel</button>

      <button class="btn btn-primary me-2" type="button" (click)="close()">Close</button>
    </div>
  </section>

</div>