import { AreaModel } from './region.model';
export class BranchModel {
  id: number;
  branchName: string;
  branchSerial: string;
  cbsBranch: string;
  emidAccount: string;
  company: string;
  email: string;
  area? = {} as AreaModel;
  area_id = this.area?.id || 0;
}
