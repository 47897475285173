import { EmployerDetailsModel } from "src/app/features/branch-employers/models/employer-details-model";


export class EmployerDetailsModel22 implements EmployerDetailsModel {
  name: string;
  branchName: string;
  id: number;
  employerName!: string | "";

  branchSerial: string;
  employerBranch: string;
  status: string;
  tel1: string;
  tel2: string;
  email: string;
  //quoteId: number;
  physicalAddressLine1: string;
  physicalAddressLine2: string;
  physicalAddressSuburb: string;
  physicalAddressAreaCode: string;
  physicalAddressTown: string;

  postalAddressLine1: string;
  postalAddressLine2: string;
  postalAddressTown: string;
  postalAddressAreaCode: string;

  employerTNR: number;
  parentCompany: string;
  payDate: string | number;
  shiftingRule: string;
  wageFrequency: string;
  paymentMethod: string;

  luPaydateShiftDesc: string;
  luSalaryMethodDesc: string;
  luEmpPayFrequency: string;
  //branchName: string;
  workAddressLine1: string;
  workAddressLine2: string;
  workAddressTown: string;
  workAddressSuburb: string;
  workAddressAreaCode: string;
  workEmail: string;
  userName!: string;
  // company: string;
  // userUUID?: string;
}
