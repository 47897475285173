import { HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { finalize, Observable } from 'rxjs';
import { ApiloaderService } from 'src/app/shared/loaders/apiloader/apiloader.service';


// export const CACHING_ENABLED = new HttpContextToken<boolean>(() => true);
// export function LoaderInterceptorFn(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
//   const loaderService = inject(ApiloaderService);
//   // Clone the request to add the authentication header.
//   return next(loaderService.setRequest(req)).pipe(tap(() => {
//     loaderService.removeRequest(req);
//   }));
// }

export const TRACKREQ_ENABLED = new HttpContextToken<boolean>(() => true);

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
  private loaderService = inject(ApiloaderService);

  // private logRequestTime(startTime: number, url: string, method: string) {
  //   const requestDuration = `${performance.now() - startTime}`;
  //   console.info(`${method} ${url} - ${requestDuration}ms`);
  // }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.context.get(TRACKREQ_ENABLED) == false) {
      return next.handle(req);
    }

    const reqWithtime = req.clone();
    const loaderService = this.loaderService;
    loaderService.setRequest(reqWithtime);

    return next.handle(req).pipe(
      finalize(() => {
        loaderService.removeRequest(reqWithtime);
      })
    );

    // .subscribe(
    //   event => {
    //     // if (event instanceof HttpRequest) {
    //     //   req = req.clone({
    //     //     withCredentials: true,
    //     //   });
    //     // }
    //     if (event instanceof HttpResponse) {
    //       //this.removeRequest(req);
    //       observer.next(event);
    //     }
    //   });
    // remove request from queue when cancelled
    //   return () => {
    //     //this.removeRequest(req);
    //     subscription.unsubscribe();
    //   };
    // });
  }

}
