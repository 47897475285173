<app-page-cardhead [PageName]="'Submenu Details'" [PageHeadWidth]=600></app-page-cardhead>
<div class="form-container center-screen">
  <form [formGroup]="submenuForm">
    <div class="center-screen">
      <div class="center-screen">
        <table class="table table-striped table-light table-hover table-sm mb-3 caption-top" [style.width.px]="600">

          <tbody class="field-4column-label">
            <tr>
              <td>Submenu</td>
              <td>
                <input formControlName="submenu" [(ngModel)]="submenuModel.submenu" required>
                <div *ngIf="submenuForm.controls.submenu.errors?.required && submitted" class="error-label">
                  Submenu is required.
                </div>
              </td>
            </tr>
            <tr>
              <td>Component</td>
              <td>
                <input formControlName="component" [(ngModel)]="submenuModel.component" required>
                <div *ngIf="submenuForm.controls.component.errors?.required && submitted" class="error-label">
                  Component is required.
                </div>

              </td>
            </tr>
            <tr>
              <td><label for="selectedRole">Role</label></td>
              <td>
                <mat-select style="width: 99%" formControlName="userRoles" multiple required
                  [(ngModel)]="submenuModel.userRoles">
                  <mat-option *ngFor="let title of jobTitles" [value]="title">{{title.jobTitle}}</mat-option>
                </mat-select>

                <div *ngIf="submenuForm.controls.userRoles.errors?.required && submitted" class="error-label">
                  Job title is required.
                </div>
              </td>
            </tr>

          </tbody>
        </table>
      </div>
      <div class="form-group">
        <button class="btn btn-primary me-1" mdbRipple (click)="close()"> Cancel </button>
        <button class="btn btn-primary" mdbRipple (click)="saveChanges(submenuModel)">Add/Update
          Submenu</button>
      </div>
    </div>
  </form>
</div>
