import { HttpContextToken, HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpInterceptorFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

export const CACHING_ENABLED = new HttpContextToken<boolean>(() => false);
// export const cacheInterceptor: HttpInterceptorFn = (req, next) => {
//   if (req.context.get(CACHING_ENABLED)) {

//     return next(reqWithHeader).pipe(tap(event => {
//       if (event.type === HttpEventType.Response) {
//         console.log(req.url, 'returned a response with status', event.status);
//       }
//     }));
//   } else {
//   // caching has been disabled for this request
//     return next(req);
//   }
// };

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  private cache = new Map<HttpRequest<any>, HttpResponse<any>>();

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.method !== "GET") {
      return next.handle(req);
    }

    if (req.headers.get("reset")) {
      this.cache.delete(req);
    }

    if (req.context.get(CACHING_ENABLED)) {
      const cachedResponse = this.cache.get(req);
      if (cachedResponse) {
        return of(cachedResponse.clone());
      } else {
        return next.handle(req).pipe(
          tap(stateEvent => {
            if (stateEvent instanceof HttpResponse) {
              this.cache.set(req, stateEvent.clone());
            }
          })
        );
      }
    }

    return next.handle(req);
  }
}
