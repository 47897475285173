<div class="modal-header">
  <div class="ms-auto me-auto">
    <div class="card-holder ms-auto me-auto card">
      <div class="card-body page-card rounded-2 shadow-3-strong my-1 mx-2 p-1 text-center" #pageCardHeader>
        {{'Income '+ payslipModel.month}}
        <!-- <app-swing-loader></app-swing-loader> -->
      </div>
    </div>
  </div>
</div>
<div class="modal-body center-screen px-2">
  <div class="card">
    <div class="card-body px-3">
      <form [formGroup]="payslipForm">
        <table class="table table-striped table-sm shadow-1-strong rounded-1 px-3">
          <tbody class="field-4column-label">
            <tr>
              <td style="width: 8%">Pay Date:</td>
              <td>
                <div class="input-group" style="width: 150px;">
                  <input class="form-control" [matDatepicker]="picker" [max]="maxDate" placeholder="YYYY-MM-DD"
                    formControlName="payslipDate" required (click)="picker.open()">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker startView="year"></mat-datepicker>
                </div>
                <div *ngIf="payslipDate!.errors?.required && submitted">
                  <small class="text-danger">Pay date is required</small>
                </div>
              </td>
            </tr>
            <tr>
              <td style="width: 8%">Nett Income:</td>
              <td>
                <div class="input-group" style="width: 180px;">
                  <span class="input-group-text">R</span>
                  <input class="form-control" formControlName="nettIncome" lmsNumbersOnly [allowDecimals]="true"
                    [allowSign]="false" [class.ng-valid]="nettIncome?.valid" [class.ng-invalid]="nettIncome?.invalid"
                    min="0">
                </div>
                <div *ngIf="nettIncome?.invalid">
                  <div *ngIf="nettIncome!.errors?.required">
                    <small class="text-danger">Income amount is required</small>
                  </div>
                </div>
                <!-- <div *ngIf="calculateError"  class="error-label">
                  Payslip values are incorrect
                </div> -->
              </td>
            </tr>
            <tr *ngIf="clientType === 'Salaried' || clientType === 'Pensioner'">
              <td style="width: 8%">Other Income:</td>
              <td>
                <div class="input-group" style="width: 180px;">
                  <span class="input-group-text">R</span>
                  <input class="form-control" formControlName="otherIncome" lmsNumbersOnly [allowDecimals]="true"
                    [allowSign]="false" min="0">
                </div>
                <div *ngIf="otherIncome!.errors?.numberOnly">
                  <small class="text-danger">Only numeric values!</small>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- Submit button -->
        <div class="flex-container">
          <button class="btn btn-sm btn-primary" type="button" (click)="closeDialog()"> Close </button>
          <button class="btn btn-sm btn-primary" type="submit" (click)="save()"
            [disabled]="invalidDate ? 'disabled' : null">
            Save </button>
        </div>
      </form>
    </div>
  </div>
</div>
