<div class="modal-content">
  <div class="modal-body">
    <div *ngIf="!showMessage" class="count-down-timer">
      <p>{{title}}</p>
      <div class="times">
        <p>{{ display }}</p>
      </div>
    </div>
    <div style="font-size: 14px;">
      <p><b *ngIf="showMessage">{{ message }}</b></p>
    </div>
    <div class="flex-container">
      <button mat-button class="btn btn-primary" mdbRipple (click)="onConfirm()">Continue</button>
    </div>
  </div>
</div>
