import { ZARcurrencyPipe } from 'src/app/shared/pipes/zarcurrency.pipe';
import { InternalloanModel } from "./internalloan.model";
import { PaySlipModel1 } from './pay-slip.model';

export interface partialPreAuth {
  idNumber: string;
  fullNames: string;
  surname: string;
  cellNumber: string;
  emailAddress: string;
  title: string;

  entityScore: number | 0;
  productScore: number | 0;
  customerScore: number | 0;
  countryScore: number | 0;

  riskEntityValue: string;
  riskProductValue: string;
  riskCustomerValue: string;
  riskCountryValue: string;

  useInformation?: string | boolean;
  bureauConsent: string;
  employerConsent: string;

  consentOtp: string;
  otpOverride: string;
}
export type ClientType = 'salaried' | 'sassa' | 'pensioner';
export class PreAuthorisationModel implements partialPreAuth {
  constructor(partial?: partialPreAuth) {
    if (partial) {
      this.idNumber = partial.idNumber;
      this.fullNames = partial.fullNames;
      this.surname = partial.surname;
      this.cellNumber = partial.cellNumber;
      this.emailAddress = partial.emailAddress;
      this.title = partial.title;

      this.canUseInformation = partial.useInformation;
      this.bureauConsent = partial.bureauConsent;
      this.employerConsent = partial.employerConsent;

      this.consentOtp = partial.consentOtp;
      this.otpOverride = partial.otpOverride;

      this.riskEntityValue = partial.riskEntityValue;
      this.riskProductValue = partial.riskProductValue;
      this.riskCustomerValue = partial.riskCustomerValue;
      this.riskCountryValue = partial.riskCountryValue;

      this.entityScore = partial.entityScore;
      this.productScore = partial.productScore;
      this.customerScore = partial.customerScore;
      this.countryScore = partial.countryScore;
    }
  }

  id?: number;
  quoteId?: number;
  idNumber: string;
  cellNumber: string;
  fullNames: string;
  firstName: string;
  lastName: string;
  surname: string;
  nationality: string;
  countryBirth: string;
  maritalStatus: string;
  gender: string;
  occupation: string;
  emailAddress: string;
  workSaturday: boolean;
  doUpdateEmployment = false;

  homeAdressLine1: string;
  homeAdressLine2: string;
  homeAdressSuburb: string;
  homeAdressTown: string;
  homeAdressAreaCode: string;
  homeAdressProvince: string;

  //addressModel: AddressModel = new AddressModel();
  externalLoans: any[] = [];
  existingDebtObligations: any[] = [];
  internalLoans: InternalloanModel[] = [];

  finSavings: string; // Possible depricated
  accNumber: string; // Possible depricated
  clientNumber: string;
  clientType = 'Salaried';

  loanReason: string;
  loanPurpose?: string;
  loanAmount: number;
  contactPreference: string;

  sourceOfWealth: string;
  sourceOfWealthOther: string;

  marketingOptions: string[] = [];
  selectedMarketingOptions: string;

  additionalIncome: string[] = [];
  selectedadditionalIncome: string;
  additionalIncomeOther: string;

  industryType: string;
  industryTypeOther: string;
  employer: string;
  employerName?: string;
  employerTNR: number;
  employmentType: string;
  employmentStartDate!: string;
  employmentEndDate: string;
  contractStartDate: string;
  contractEndDate: string;
  wageFrequency!: string;
  grantType: string;
  month1 = 0;
  month2 = 0;
  month3 = 0;
  title: string;
  payslips?: PaySlipModel1[];
  payslip1: any;
  payslip2: any;
  payslip3: any;
  employment: any;
  schoolfees: number;
  //bankCharges: number;
  municipalServices: number;
  groceries: number;
  transport: number;
  housing: number;
  //clothing: number;
  insurancePremiums: number;
  phone: number;
  //pocketMoney: number;
  // microLoans: string;
  // hpInstalments: string;
  // currentLoans: string;

  schoolfeesReason: string;
  //bankChargesReason: string;
  municipalServicesReason: string;
  groceriesReason: string;
  transportReason: string;
  housingReason: string;
  //clothingReason: string;
  insurancePremiumsReason: string;
  phoneReason: string;
  //pocketMoneyReason: string;
  // microLoansInfo: string;
  // hpInstalmentsInfo: string;
  // currentLoansInfo: string;

  referenceNumber: string;
  term: number;
  outstandingBalance: number | ZARcurrencyPipe;
  canSettle: boolean;
  instalment: number | ZARcurrencyPipe;
  thirdPartyName: string;
  paidUp: boolean;

  bureauConsent: string;
  employerConsent: string;
  underDebtReview: string;
  canUseInformation?: string | boolean;
  placedUnderDebtReview!: string | boolean;
  underSequestration: string;
  underAdministration: string;
  placedUnderAdministration!: string | boolean;

  withPhone: boolean;
  otp: string;
  otpSms: string;
  otpOverride: string;
  consentOtp: string;
  employmentOtp: string;

  status: string;
  clientStatus: string;
  loanStatus: string;

  entityScore: number | 0;
  productScore: number | 0;
  customerScore: number | 0;
  countryScore: number | 0;
  riskScore: number;
  riskEntityValue: string;
  riskProductValue: string;
  riskCustomerValue: string;
  riskCountryValue: string;

  firstPaymentDate: string;
  payDate: string;
  complianceStatus: string;

  productType: number;
  customerType: number;
  countryBirthType: number;
  entityType: number;

  moneyLaunderingScore: number;

  userName: any;
  createdBy?: string;
  userBranchSerial: any;
  branchName: string;
  affordability: any;
  excludedAffordability = 0;
  excludedInstallment = 0;
  biometrics: any[] = [];
  cellNoExist: string;
  bmUserName: string;
  selectedFinger: string;
  skipReason: string;
  biometricsVerified: boolean;
  verifiedMessage: string;
  incomeExpanded = false;

  get totalScore(): number {
    return (
      (parseFloat(this.countryScore.toString()) +
        parseFloat(this.entityScore.toString()) +
        parseFloat(this.productScore.toString()) +
        parseFloat(this.customerScore.toString())) |
      0
    );
  }

  set totalScore(val: number) {
    this.totalScore = val;
  }

  setTotalScore() {
    this.totalScore =
      parseFloat(this.countryScore.toString()) +
      parseFloat(this.entityScore.toString()) +
      parseFloat(this.productScore.toString()) +
      parseFloat(this.customerScore.toString());
  }
}
