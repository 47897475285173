/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, inject, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { ApiloaderService } from "./apiloader.service";

@Component({
  selector: "app-apiloader",
  templateUrl: "./apiloader.component.html",
  styleUrls: ["./apiloader.component.css"]
})
export class ApiloaderComponent {
  private loaderService = inject(ApiloaderService);

  loading?: boolean;
  isOffline?: Observable<boolean> = this.loaderService.isOffline;
  apiOffline?: Observable<boolean> = this.loaderService.isAPIOffline;

  constructor() {
    const loaderService = this.loaderService;
    loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
  }
}
