export class LoanQuoteModel {
    serial: string;
    clientIDNo: string;
    capitalAmount: number;
    firstInstalmentDate: string;
    frequency: string;
    isFirstLoan: string;
    isSaturdayAWorkingDay: boolean;
    period: number;
    loanTypeName: string;
    repayMethod: string;
    shiftingRule: string;
    vATPercentage: number;
    payDay: string;
    clientNumber: any;
    quoteId: any;
    userName: any;
}
