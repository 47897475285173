export class AdminManagerSearchModel {
  region?:string;
  area: string;
  branch: string;
  startDate: string;
  endDate: string;
  status: string | null;
  clientNumber: string | null;
  idNumber: string | null;
  branchSerial: string | null;
}
