import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MdbModalRef } from "mdb-angular-ui-kit/modal";
import { ToastrService } from 'ngx-toastr';
import { AppUtilityService } from "src/app/app-utility.service";
import { BankDetailsModel, BanksModel, QuoteModel } from 'src/app/core/models';
import { QuoteStoreService } from 'src/app/core/rest-services/quote-store.service';
import { UserService } from 'src/app/core/services/lmsuser.service';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgFor } from '@angular/common';
import { LMS_NoPasteDirective, NumbersOnlyDirective,PageCardheadComponent } from '@lmsSharedUI';
import { DisableControlDirective, LMS_NoSpecialsDirective } from 'src/app/shared/directives';

@Component({
  selector: 'app-banking-details',
  templateUrl: './banking-details.component.html',
  styleUrls: ['./banking-details.component.css'],
  standalone: true,
  imports: [PageCardheadComponent, FormsModule, ReactiveFormsModule, NgIf, NgFor,
    MatButtonModule, MdbRippleModule,DisableControlDirective,
    LMS_NoPasteDirective,LMS_NoSpecialsDirective, NumbersOnlyDirective]
})
export class BankingDetailsComponent implements OnInit {

  clientDetails: any;
  bankingDetails: any;
  disburseAccount: any;
  collectAccount: any;
  hasAccount = true;
  isEditing = false;

  bankDetailsModel = {} as BankDetailsModel;
  allBanks: BanksModel[];
  debiCheckBanks: any;
  isBankingLoading = false;

  bankDetailsForm = this.fb.group({
    accountNumber: [{ value: '', disabled: true }],
    accountType: [{ value: '', disabled: true }],
    accountHolder: [{ value: '', disabled: true }],
    accountLoanType: [{ value: '', disabled: true }],
    bank: [{ value: '', disable: true }],
    branchCode: [{ value: '', disabled: true }],
    collect_accountNumber: [{ value: '', disabled: true }],
    collect_accountType: [{ value: '', disabled: true }],
    collect_accountHolder: [{ value: '', disabled: true }],
    collect_bank: [{ value: '', disabled: true }],
    collect_accountLoanType: [{ value: '', disabled: true }],
    collect_branchCode: [{ value: '', disabled: true }],
    payoutMethod: ['']
  }, { updateOn: 'change' });

  indeterminate = false;
  labelPosition: 'before' | 'after' = 'after';
  submitted = false;
  quoteModel: QuoteModel;

  isUserNotBranchConsultant = (this.userService.userJobTitle.jobTitle !== 'Branch Consultant');
  isUserBranchManager = (this.userService.userJobTitle.jobTitle === 'Branch Manager') || (this.userService.userJobTitle.jobTitle === 'System Owner');
  isReadOnly = false;
  clientStatus: string;

  constructor(
    public quoteStoreService: QuoteStoreService,
    private userService: UserService,
    private toastrService: ToastrService,
    private fb: FormBuilder,
    public global_utility: AppUtilityService,
    public modalRef: MdbModalRef<BankingDetailsComponent>
  ) { }

  get editAllowed() {
    const isallowed = !(!this.isUserNotBranchConsultant && this.isEditing) || (!this.hasAccount && !this.isEditing);
    return !isallowed;
  }

  get accountNumber() {
    return this.bankDetailsForm.get('accountNumber') as FormControl;
  }
  get accountType() {
    return this.bankDetailsForm.get('accountType') as FormControl;
  }
  get accountHolder() {
    return this.bankDetailsForm.get('accountHolder') as FormControl;
  }
  get accountLoanType() {
    return this.bankDetailsForm.get('accountLoanType') as FormControl;
  }
  get bank() {
    return this.bankDetailsForm.get('bank') as FormControl;
  }
  get branchCode() {
    return this.bankDetailsForm.get('branchCode') as FormControl;
  }
  get collect_accountNumber() {
    return this.bankDetailsForm.get('collect_accountNumber') as FormControl;
  }
  get collect_accountType() {
    return this.bankDetailsForm.get('collect_accountType') as FormControl;
  }
  get collect_accountHolder() {
    return this.bankDetailsForm.get('collect_accountHolder') as FormControl;
  }
  get collect_bank() {
    return this.bankDetailsForm.get('collect_bank') as FormControl;
  }
  get collect_accountLoanType() {
    return this.bankDetailsForm.get('collect_accountLoanType') as FormControl;
  }
  get collect_branchCode() {
    return this.bankDetailsForm.get('collect_branchCode') as FormControl;
  }
  get payoutMethod() {
    return this.bankDetailsForm.get('payoutMethod') as FormControl;
  }

  ngOnInit(): void {
    console.log("Banking details init...");
    //Not branch consultant
    this.hasAccount = false;
    this.isBankingLoading = true;

    this.bankDetailsModel.sameAsDisburseAccount = false;
    this.clientStatus = this.clientDetails.clientStatus;

    // this.checkEmidClient();
    this.validateAccounts();
    if (!this.isReadOnly) {
      this.quoteStoreService.getAllBanks().subscribe(res => this.allBanks = res);
      this.quoteStoreService.getDebiCheckBanks().subscribe(res => this.debiCheckBanks = res);
    }

    this.populateDTO_bankingDetails();
    this.isBankingLoading = false;
  }

  checkEmidClient() {
    console.log("Check emid stuff");

    if (this.clientDetails.clientStatus == "EMID" && this.bankingDetails.bankAccounts.length == 0) {

      this.quoteModel = new QuoteModel();
      this.quoteModel.clientNumber = this.clientDetails.clientNumber;
      //call end[point]
      this.quoteStoreService.bankingDetailsFromEmid(this.quoteModel).subscribe((res) => {
        // this.allBanks = res;
        console.log(res);
      });
    }
  }

  validateAccounts() {
    if (this.bankingDetails.length > 0) {
      const hasAcc = this.bankingDetails[0]; //??
      if (hasAcc) {
        const hasAccNo = this.bankingDetails[0].accountNumber;
        if (hasAccNo) {
          this.hasAccount = true;
          for (const acc of this.bankingDetails) {
            if (acc.loanType == "DISBURSE") {
              this.disburseAccount = acc;
            }
            else if (acc.loanType == "COLLECT") {
              this.collectAccount = acc;
            }
          }
        }
      }
    }
  }

  saveBankDetails() {
    this.submitted = true;

    if (this.bankDetailsForm.invalid) {
      //alert('Please enter all the information needed');
      this.toastrService.warning('Please enter all the banking details needed', 'Banking details', { positionClass: 'toast-top-center' });
      return;
    }

    this.isEditing = false;

    //Only get the PayoutMEthod. Use saved values if the form was diabled
    if (Object.keys(this.bankDetailsForm.value).length < 3) {
      this.populateDTO_bankingDetails();
      this.bankDetailsModel.payoutMethod = this.bankDetailsForm.value.payoutMethod || this.bankDetailsModel.payoutMethod;
    } else {
      this.FormValuestoDTO(this.bankDetailsForm.value);
    }

    return this.modalRef.close(this.bankDetailsModel);
  }

  private populateDTO_bankingDetails() {
    this.bankDetailsModel.payoutMethod = this.quoteStoreService.quoteWorkbook?.payoutMethod || this.clientDetails.payoutMethod;
    this.bankDetailsModel.quoteId = this.quoteStoreService.quoteWorkbook?.id || this.clientDetails.id;

    if (this.disburseAccount) {
      this.bankDetailsModel.accountNumber = this.disburseAccount.accountNumber;
      this.bankDetailsModel.accountType = this.disburseAccount.accountType;
      //this.bankDetailsModel.accountName = this.disburseAccount.accountName;
      this.bankDetailsModel.accountHolder = this.disburseAccount.accountHolder;
      this.bankDetailsModel.bank = this.disburseAccount.bank;
      this.bankDetailsModel.accountLoanType = this.disburseAccount.loanType;
      this.bankDetailsModel.branchCode = this.disburseAccount.branchCode;
    }

    if (this.collectAccount) {
      this.bankDetailsModel.colAccountNumber = this.collectAccount.accountNumber;
      this.bankDetailsModel.colAccountType = this.collectAccount.accountType;
      this.bankDetailsModel.colAccountHolder = this.collectAccount.accountHolder;
      this.bankDetailsModel.colBank = this.collectAccount.bank;
      this.bankDetailsModel.colAccountLoanType = this.collectAccount.loanType;
      this.bankDetailsModel.colBranchCode = this.collectAccount.branchCode;
    }

    this.bankDetailsModel.sameAsDisburseAccount = false;
    if (this.collectAccount && this.disburseAccount) {
      this.bankDetailsModel.sameAsDisburseAccount = (
        this.bankDetailsModel.accountNumber == this.bankDetailsModel.colAccountNumber &&
        this.bankDetailsModel.bank == this.bankDetailsModel.colBank && this.bankDetailsModel.branchCode == this.bankDetailsModel.colBranchCode
      );
    }
  }

  private FormValuestoDTO(frmFields: any) {
    this.bankDetailsModel.accountNumber = frmFields.accountNumber! || this.bankDetailsModel.accountNumber;
    this.bankDetailsModel.accountType = frmFields.accountType! || this.bankDetailsModel.accountType;
    this.bankDetailsModel.accountHolder = frmFields.accountHolder! || this.bankDetailsModel.accountHolder;
    this.bankDetailsModel.bank = frmFields.bank! || this.bankDetailsModel.bank;
    this.bankDetailsModel.accountLoanType ||= frmFields.accountLoanType!;

    /*
      branchCode is assigned when select value changes
      this.bankDetailsModel.branchCode = frmFields.branchCode!;
      this.bankDetailsModel.colBranchCode = this.bankDetailsModel.sameAsDisburseAccount ? frmFields.branchCode! : frmFields.collect_branchCode!;
    */

    if (this.bankDetailsModel.sameAsDisburseAccount) {
      this.collect_accountHolder.setValue(frmFields.accountHolder || this.bankDetailsModel.accountHolder, { onlySelf: true, emitEvent: false });
      this.collect_accountNumber.setValue(frmFields.accountNumber || this.bankDetailsModel.accountNumber, { onlySelf: true, emitEvent: false });
      this.collect_accountType.setValue(frmFields.accountType || this.bankDetailsModel.accountType, { onlySelf: true, emitEvent: false });
      this.collect_bank.setValue(frmFields.bank || this.bankDetailsModel.bank, { onlySelf: true, emitEvent: false });
      this.collect_branchCode.setValue(frmFields.branchCode || this.bankDetailsModel.branchCode, { onlySelf: true, emitEvent: false });
    }

    this.bankDetailsModel.colAccountNumber = this.collect_accountNumber.value;
    this.bankDetailsModel.colAccountType = this.collect_accountType.value;
    this.bankDetailsModel.colAccountHolder = this.collect_accountHolder.value;
    this.bankDetailsModel.colAccountLoanType ||= this.collect_accountLoanType.value;
    this.bankDetailsModel.colBank = this.collect_bank.value;
    this.bankDetailsModel.colBranchCode = this.collect_branchCode.value;

    this.bankDetailsModel.payoutMethod = frmFields.payoutMethod!;
  }

  close() {
    this.modalRef.close();
  }

  onSameAccountChange() {
    this.bankDetailsModel.sameAsDisburseAccount = !this.bankDetailsModel.sameAsDisburseAccount;

    if (this.bankDetailsModel.sameAsDisburseAccount) {
      this.collect_accountHolder.disable();
      this.collect_accountLoanType.disable();
      this.collect_accountNumber.disable();
      this.collect_accountType.disable();
      this.collect_bank.disable();
      this.collect_branchCode.disable();
    } else {
      this.collect_accountHolder.enable();
      this.collect_accountLoanType.enable();
      this.collect_accountNumber.enable();
      this.collect_accountType.enable();
      this.collect_bank.enable();
      this.collect_branchCode.enable();
    }
  }

  onBankSelect(event: any) {
    const selbank = this.allBanks.find(b => b.bankName === event.value);
    if (selbank) {
      this.bankDetailsModel.branchCode = selbank.branchCode!;
      this.bankDetailsModel.bank = selbank.bankName!;
      this.bank.setValue(selbank.bankName!, { onlySelf: true, emitEvent: false });
      this.branchCode.setValue(selbank.branchCode!, { onlySelf: true, emitEvent: false });
      if (this.bankDetailsModel.sameAsDisburseAccount) {
        this.bankDetailsModel.colBank = selbank.bankName!;
        this.bankDetailsModel.colBranchCode = selbank.branchCode!;
        this.collect_bank.setValue(selbank.bankName!, { onlySelf: true, emitEvent: false });
        this.collect_branchCode.setValue(selbank.branchCode!, { onlySelf: true, emitEvent: false });
      }
    }
  }

  onDebiCheckBankSelect(event: any) {
    const selbank = this.debiCheckBanks.find(b => b.bankName === event.value);
    if (selbank) {
      this.bankDetailsModel.branchCode = selbank.branchCode!;
      this.bankDetailsModel.bank = selbank.bankName!;
      this.bank.setValue(selbank.bankName!, { onlySelf: true, emitEvent: false });
      this.branchCode.setValue(selbank.branchCode!, { onlySelf: true, emitEvent: false });

      if (this.bankDetailsModel.sameAsDisburseAccount) {
        this.bankDetailsModel.colBank = selbank.bankName!;
        this.bankDetailsModel.colBranchCode = selbank.branchCode!;
        this.collect_bank.setValue(selbank.bankName!, { onlySelf: true, emitEvent: false });
        this.collect_branchCode.setValue(selbank.branchCode!, { onlySelf: true, emitEvent: false });
      }
    }
  }

  onColBankSelect(event: any) {
    const selbank = this.allBanks.find(b => b.bankName === event.value);

    if (selbank) {
      this.bankDetailsModel.colBank = selbank.bankName!;
      this.bankDetailsModel.colBranchCode = selbank.branchCode!;
      this.collect_bank.setValue(selbank.bankName!, { onlySelf: true, emitEvent: false });
      this.collect_branchCode.setValue(selbank.branchCode!, { onlySelf: true, emitEvent: false });
    }
  }

  editBankDetails() {
    if (this.isReadOnly) {
      this.global_utility.warning("Editing restricted in Read-only view", "Read-only View");
      return;
    }

    this.hasAccount = false;
    this.isEditing = true;

    this.bankDetailsForm.patchValue({
      branchCode: this.disburseAccount.branchCode,
      bank: this.disburseAccount.bank,

      accountNumber: this.disburseAccount.accountNumber,
      accountType: this.disburseAccount.accountType,
      accountHolder: this.disburseAccount.accountHolder,
      accountLoanType: this.disburseAccount.accountLoanType,
      payoutMethod: this.bankDetailsModel.payoutMethod,

      collect_accountNumber: this.collectAccount.accountNumber,
      collect_accountType: this.collectAccount.accountType,
      collect_accountHolder: this.collectAccount.accountHolder,
      collect_bank: this.collectAccount.bank,
      collect_accountLoanType: this.collectAccount.accountLoanType,
      collect_branchCode: this.collectAccount.branchCode
    }, { onlySelf: true, emitEvent: false });
  }

  confirmBankDetailsDetails() {
    if (this.isReadOnly) {
      this.global_utility.warning("Editing restricted in Read-only view", "Read-only View");
      return;
    }


    if (this.bankDetailsForm.get('payoutMethod')?.invalid) {
      this.global_utility.warning("Please confirm if the correct Payout method is selected!", "Payout method required");
      return;
    }

    //this.editBankDetails();
    this.bankDetailsModel.payoutMethod = this.bankDetailsForm.get('payoutMethod')?.value || '';
    this.isEditing = false;
    this.modalRef.close(this.bankDetailsModel);
  }
}
