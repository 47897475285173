import { Injectable, Optional } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { MdbModalRef, MdbModalService } from "mdb-angular-ui-kit/modal";

import { DocumentScanningComponent } from "../features/document-scanning/document-scanning.component";
import { AddressesComponent } from "../modules/addresses/addresses.component";
import { AuditTrailComponent } from "../modules/audit-trail/audit-trail.component";
import { BankingDetailsComponent } from "../modules/banking-details/banking-details.component";
import { ClientDocumentsComponent } from "../modules/client-documents/client-documents.component";
import { NextOfKinComponent } from "../modules/next-of-kin/next-of-kin.component";
import { RejectOfferComponent, RejectType } from "../modules/reject-offer/reject-offer.component";
import { OfferAuditComponent } from "../offer-audit/offer-audit.component";
import { OfferValuesComponent } from "../offer-values/offer-values.component";
import { CbsGuideComponent } from "../register-loan/cbs-guide/cbs-guide.component";
import { RiskMatrixScoreComponent } from "../risk-matrix-score/risk-matrix-score.component";
import { LexisnexisDialogComponent } from "./dialogs/lexisnexis-dialog/lexisnexis-dialog.component";
import { LMSDialogComponent } from "./dialogs/lms-dialog/lms-dialog.component";
import { DocumentViewerComponent } from './ui/document-viewer/document-viewer.component';
import { BiometricsDialogComponent } from '../modules/biometrics-dialog/biometrics-dialog.component';
import { AgentComplianceNotificationDialogComponent } from './dialogs/agent-compliance-notification-dialog/agent-compliance-notification-dialog.component';
import { PayslipDialogComponent } from '../modules/payslip-dialog/payslip-dialog.component';
import { BranchManagerOverrideComponent } from '../modules/biometrics-dialog/branch-manager-override/branch-manager-override.component';
import { ClientShowModel, PaySlipModel, PaySlipModel1, QuoteModel } from '@lmsModels';


@Injectable({
  providedIn: 'root',
})
export class LMSDialogHandlerService {
  public AuditmodalRef: MdbModalRef<AuditTrailComponent> | null = null;

  constructor(
    private modalService: MdbModalService,
    @Optional() public matdialog?: MatDialog,
  ) {}

  openClientDocuments(
    clientModel: ClientShowModel,
    quoteId?: number | string,
    readonly = false,
  ) {
    const clientdocumnetDialogRef = this.modalService.open(ClientDocumentsComponent,{
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: true,
      nonInvasive: false,
      modalClass: 'modal-xl modal-dialog  d-flex d-flex-lg d-flex-xl',
      data: {
        quoteId: quoteId || clientModel.id,
        clientShow: clientModel,
        isReadOnly: readonly,
      },
    },
    );
  }

  openAddressDialog(quoteModel: QuoteModel, addresses: any, readonly = false) {
    const dialogRef = this.modalService.open(AddressesComponent, {
      backdrop: true, ignoreBackdropClick: true, keyboard: true, nonInvasive: false, animation: true,
      //modalClass: 'modal-dialog modal-lg modal-fluid modal-dialog-centered  d-flex d-flex-md',
      modalClass: 'modal-lg modal-dialog d-flex d-flex-md',
      containerClass: 'frame modal fade show',
      //modalClass: 'modal modal-xl modal-frame modal-fluid modal-dialog-centered modal-center',
      //containerClass: 'frame',
      data: {
        addresses: addresses,
        clientDetails: quoteModel,
        isReadOnly: readonly,
      },
    });
    return dialogRef;
  }

  openNextofKinDialog(
    nextOfKin: any,
    quoteModel: QuoteModel,
    addresses: any,
    readonly = false,
  ) {
    const dialogRef = this.modalService.open(NextOfKinComponent, {
      backdrop: true, ignoreBackdropClick: true, keyboard: true, nonInvasive: false, animation: true,
      //modalClass: 'modal-dialog modal-lg modal-fluid modal-dialog-centered  d-flex d-flex-md',
      modalClass: 'modal-lg modal-dialog d-flex d-flex-md',
      containerClass: 'frame modal fade show',
      data: {
        nextOfKin: nextOfKin,
        clientDetails: quoteModel,
        addresses: addresses,
        isReadOnly: readonly,
      },
    });
    return dialogRef;
  }

  openBankingDetailsDialog(
    bankingDetails: any,
    clientDetails: QuoteModel,
    readonly = false,
  ) {
    const dialogRef = this.modalService.open(BankingDetailsComponent, {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: true,
      nonInvasive: false,
      animation: true,
      modalClass: 'modal-lg modal-dialog d-flex d-flex-md',
      //modalClass: 'modal-fluid modal-lg modal-dialog modal-dialog-scrollable d-flex d-flex-lg',
      containerClass: 'modal top fade show',
      data: {
        bankingDetails: bankingDetails,
        clientDetails: clientDetails,
        isReadOnly: readonly,
      },
    });
    return dialogRef;
  }

  openDocScanning(quoteModel: QuoteModel, quoteId?: number | string, readonly = false) {
    const dialogRef = this.modalService.open(DocumentScanningComponent, {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: true,
      nonInvasive: false,
      modalClass: 'modal-xl modal-top modal-dialog-scrollable',
      containerClass: 'modal top fade show',
      data: {
        quoteId: `${quoteId}`,
        clientDetails: quoteModel,
        isReadOnly: readonly,
      },
    });

    return dialogRef;
  }

  openDocumentViewer(fileSrc: string | Blob | Uint8Array, pfileName: string) {
    const dialogRef = this.modalService.open(DocumentViewerComponent, {
      backdrop: true, ignoreBackdropClick: true, keyboard: true, nonInvasive: false,
      modalClass: 'modal-xl modal-dialog modal-dialog-scrollable d-flex d-flex-xl',
      containerClass: 'frame modal fade show',
      data: {
        fileSrc: fileSrc,
        pdffileName: pfileName,
      },
    });

    return dialogRef;
  }

  openCompuscanOffersDialog(compuscanOffers: any) {
    this.modalService.open(OfferValuesComponent, {
      backdrop: true, ignoreBackdropClick: true, keyboard: true, nonInvasive: false,
      modalClass: 'modal-lg modal-dialog-centered d-flex',
      data: {
        compuscanOffers: compuscanOffers,
      },
    });
  }

  openLexisNexisDialog(message: any, title: string) {
    this.modalService.open(LexisnexisDialogComponent, {
      backdrop: true, ignoreBackdropClick: true, keyboard: true, nonInvasive: false, animation: false,
      modalClass: 'modal-dialog modal-dialog-scrollable modal-xl',
      containerClass: 'modal faade show',
      data: {
        message: message,
        title: title,
      },
    });
  }

  openBiometricsDialog(
    preauthBiometricsData: any,
    biometricType: string,
    userBiometricsData?: any,
  ) {
    // data: {
    //   userBiometricsData: this.registerObject.loginName,
    //   biometricType: this.user,
    // }

    // data:{
    //   preauthBiometricsData: preauthBiometricsData,
    //   biometricType: biometricType
    // }
    const dialogRef = this.modalService.open(BiometricsDialogComponent, {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: true,
      nonInvasive: false,
      modalClass: 'modal-md modal-dialog modal-dialog-centered',
      data: {
        biometricsInfo: preauthBiometricsData,
        userBiometricsInfo: userBiometricsData,
        biometricType: biometricType,
      },
    });

    return dialogRef;
  }

  openBMOTPOverrideDialog(data: any) {
    const dialogRef = this.matdialog?.open(BranchManagerOverrideComponent, {
      disableClose: true,
      autoFocus: true,
      height: '330px',
      width: 'auto',
      data: data,
    });

    return dialogRef!;
  }

  openLMSDialog(
    confirmMsg: string,
    msgtitle: string,
    withTimer: boolean = false,
    isError = false,
  ) {
    const lmsDialogRef = this.modalService.open(LMSDialogComponent, {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: true,
      nonInvasive: false,
      animation: true,
      modalClass:
        'modal modal-dialog modal-dialog-centered modal-info d-flex d-flex-md',
      containerClass: 'frame modal fade show',
      data: {
        title: msgtitle,
        message: !confirmMsg || confirmMsg == '' ? 'Please wait!' : confirmMsg,
        showTimer: withTimer,
        isErrorOccured: isError,
      },
    });
    return lmsDialogRef;
  }

  openMatComplianceNotification() {
    const dialogRef = this.matdialog?.open(
      AgentComplianceNotificationDialogComponent,
      {
        disableClose: true,
        minHeight: '550px',
        width: 'auto',
        height: 'auto',
        autoFocus: true,
      },
    );
    return dialogRef!;
  }

  openRiskMatrixScoresDialog(riskAnalysis: any) {
    this.modalService.open(RiskMatrixScoreComponent, {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: true,
      nonInvasive: false,
      modalClass: 'modal-md modal-dialog modal-dialog-centered',
      data: {
        riskAnalysis: riskAnalysis,
      },
    });
  }

  openRejectOfferDialog(
    currQuote: any,
    setToCancelOrReject: RejectType = 'Rejection',
  ) {
    const dialogRef = this.modalService.open(RejectOfferComponent, {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: true,
      nonInvasive: false,
      animation: false,
      modalClass: 'modal modal-dialog modal-dialog-centered ',
      data: {
        rejectOfferInfo: currQuote,
        cancelOrReject: setToCancelOrReject,
      },
    });
    return dialogRef;
  }

  openPayslipDialog(
    month: string | number,
    payslipModel?: PaySlipModel1 | null,
    clientType?: string,
  ) {
    const dialogRef = this.modalService?.open(PayslipDialogComponent, {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: true,
      nonInvasive: false,
      modalClass: 'modal-md modal-dialog-centered',
      data: {
        month: month,
        payslipModel: payslipModel,
        clientType: clientType,
      },
    });

    return dialogRef;
  }

  openAuditTrailDialogByQuoteId(quoteId: number) {
    this.AuditmodalRef = this.modalService.open(AuditTrailComponent, {
      backdrop: true,
      keyboard: true,
      containerClass: 'top',
      modalClass: 'modal-xl modal-frame modal-top modal-dialog-scrollable',
      data: {
        quoteId: quoteId,
      },
    });
    return this.AuditmodalRef;
  }

  openAuditTrailDialogbyIdNumber(idNumber: string) {
    const dialogRef = this.modalService.open(AuditTrailComponent, {
      backdrop: true,
      keyboard: true,
      containerClass: 'top',
      modalClass: 'modal-xl modal-frame modal-top modal-dialog-scrollable',
      data: {
        idNumber: idNumber,
      },
    });
    return dialogRef;
  }

  openOfferAuditDialog(lstaudit: any) {
    //console.log(this.auditTrails, "Audit trail list");
    this.matdialog?.open(OfferAuditComponent, {
      disableClose: false,
      autoFocus: false,
      minHeight: '800px',
      maxHeight: '950px',
      width: '1300px',
      maxWidth: '1300px',
      data: {
        offersList: lstaudit,
        isReadOnly: true,
      },
    });
  }

  openCBSGuideDialog() {
    this.matdialog?.open(CbsGuideComponent, {
      disableClose: true,
      autoFocus: true,
      height: '240px',
      width: '600px',
    });
  }
}
