<div class="form-group lms_add_employer">
  <input class="form-control" type="text" placeholder="Employer name *" id="searchValue" #searchValue required
    [class.ng-invalid]="!selected_branchEmployer || selected_branchEmployer.tnumber === 0"
    [class.ng-valid]="selected_branchEmployer && selected_branchEmployer.tnumber > 0" noSpecial uppercase
    (input)="quickSearch($event.target);">
  <div *ngIf="quickSearchPending$ | async">
    <div class="loading-spinner spinner">
      <div class="rect1"></div>
      <div class="rect2"></div>
      <div class="rect3"></div>
      <div class="rect4"></div>
      <div class="rect5"></div>
    </div>
  </div>

  <div *ngIf="newEmployer && !showAddButton && !dontAdd">
    <!-- <button mat-icon-button matSuffix class="btn btn-primary btn-floating" mdbRipple color="primary" (click)="onAddNewClick()"> -->
    <button class="btn btn-floating btn-icon fs-5 fas" mdbRipple color="primary" (click)="onAddNewClick()">
      <i class="fas fa-xs fa-building-circle-arrow-right"></i>
    </button>
    <!-- <button class="mdc-icon-button mat-mdc-icon-button mat-unthemed mat-mdc-button-base" mat-rasied-button class="btn btn-primary btn-rounded" mdbRipple color="primary" (click)="onAddNewClick()">+</button> -->
  </div>
</div>

<ng-container class="emp-container" *ngIf="(selected_branchEmployer.tnumber === 0) && (employer$ | async ) as empRes">
  <input aria-hidden="true" hidden readonly type="text" id="qemp" [(ngModel)]="selected_branchEmployer.name" #combtItem>
  <ul id="empSelectList" class="list-group shadow-4-strong shadow border-1 rounded">
    <div *ngIf="showAddButton && !dontAdd">
      <li class="list-group-item lms-select-emp-button">
        <button class="btn btn-sm btn-primary m-1" mdbRipple color="primary" style="height: 30px;"
          (click)="onAddNewClick()"> New Employer</button>
      </li>
    </div>

    <div *ngIf="quickSearchPending$ | async; then showload else showlist"></div>
    <ng-template #showload>
      <li class="list-group-item">
        <div class="loading-spinner spinner">
          <div class="rect1"></div>
          <div class="rect2"></div>
          <div class="rect3"></div>
          <div class="rect4"></div>
          <div class="rect5"></div>
        </div>
      </li>
    </ng-template>
    <ng-template #showlist>
      <li class="list-group-item lms-select-emp" *ngFor="let combtItem of empRes" (click)="employerSelected(combtItem)">
        <span filterHighlight [searchedWord]="searchValue.value" [setTitle]="true">{{combtItem.name}}</span>
        <!-- <span class="population">{{ suggestion.population | number }}</span> -->
      </li>
    </ng-template>
  </ul>
</ng-container>
