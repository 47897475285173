import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AutoLogoutService } from 'src/app/core/services/auto-logout.service';
import { UserService } from 'src/app/core/services/lmsuser.service';
import { SpinnerModel } from '../../loaders/spinner/spinner.model';
import { CommonModule } from '@angular/common';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';


@Component({
  selector: 'app-session-expired',
  standalone: true, // Mark the component as standalone
  imports: [CommonModule], // Import required Angular modules here
  templateUrl: './session-expired.component.html',
  styleUrls: ['./session-expired.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class SessionExpiredComponent implements OnInit {
  title: string;
  message: string;
  display: any;
  showMessage: boolean;
  spinnerModel: SpinnerModel;
  login: boolean = false;

  constructor(private router : Router,
    //private autoLogoutService: AutoLogoutService,
    public userService: UserService,
    public dialogRef: MdbModalRef<SessionExpiredComponent>
  ) {
    this.title ||= "This session will expire in:";
    this.timer(1);
  }

  timerInstance: any = null;
  logoutTimeout: any = null;
  warningShown = false;

  timer(minute: number) {
    console.log("Starting countdown for", minute, "minute(s)");


    this.clearTimers();

    let seconds: number = minute * 60;
    let textSec: string = "00";
    let statSec: number = 60;
    const prefix = minute < 10 ? "0" : "";

    this.warningShown = false;

    this.timerInstance = setInterval(() => {
      seconds--;
      statSec = statSec !== 0 ? statSec - 1 : 59;
      textSec = statSec < 10 ? "0" + statSec : statSec.toString();

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;


      if (seconds === 10 && !this.warningShown) {
        this.warningShown = true;
        console.log("Your session is about to expire.");
        this.showMessage = true;


        this.logoutTimeout = setTimeout(() => {
          console.log("Logging out due to inactivity.");
          this.userService.logOutUser();
          this.clearTimers();
        }, 60000);
      }

      if (seconds === 0) {
        this.title = "Session Expired!";
        clearInterval(this.timerInstance);
        this.userService.logOutUser();
      }
    }, 1000);
  }


  clearTimers() {
    if (this.timerInstance) {
      clearInterval(this.timerInstance);
      this.timerInstance = null;
    }
    if (this.logoutTimeout) {
      clearTimeout(this.logoutTimeout);
      this.logoutTimeout = null;
    }
  }


  onConfirm(): void {
    this.showMessage = false;
    this.clearTimers();
    this.dialogRef.close(true);
    //this.timer(2);
  }


  onLogin(): void {
    this.router.navigate(['/']);
    //this.dialogRef.close(false);
    this.userService.logOutUser();
    //this.keycloak.logout();
  }

  ngOnInit(): void {
  }
}


