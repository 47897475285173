import { Inject, Injectable, InjectionToken, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SessionExpiredComponent } from 'src/app/shared/ui/session-expired/session-expired.component';
import { UserService } from 'src/app/core/services/lmsuser.service';
import { StorageService } from './storage.service';
import { MdbModalService } from 'mdb-angular-ui-kit/modal';

const LMS_LastActionKEY = 'lastAction';
export const AUTO_LOGOUT = new InjectionToken<number>('AUTO_LOGOUT');

@Injectable({ providedIn: 'root' })
export class AutoLogoutService {
  private isTimeout = false;
  //log off details
  //isLoggedIn$ = this.userService._isUserLoggedin;
  isLoggedIn: boolean = false;

  constructor(
    private router: Router,
    //private snotifyService: SnotifyService,
    private modalService: MdbModalService,
    private userService: UserService,
    private storageService:StorageService,
    private ngZone: NgZone,
    @Inject(AUTO_LOGOUT) private autoLogoutTime: number
  ) {

    this.lastAction(Date.now());
    this.check();
    this.initListener();
    this.initInterval();

    document.addEventListener("visibilitychange", () => {
      if (!document.hidden) {
        this.check();
      }
    });

    window.addEventListener("storage", (event) => {
      if (event.key === "LMS_LastActionKEY") {
        this.check();
      }
    });
  }

  /**
   * last action
   */

  getLastAction() {
    return window.sessionStorage.getItem(LMS_LastActionKEY) || "";
  }

  /**
   * set last action
   * @param value
   */

  lastAction(value: any) {
    //window.sessionStorage.removeItem(LMS_LastActionKEY);
    window.sessionStorage.setItem(LMS_LastActionKEY, JSON.stringify(value))
  }

  /**
   * start event listener
   */

  initListener() {
    this.ngZone.runOutsideAngular(() => {
      document.body.addEventListener('click', () => this.reset());
      // document.body.addEventListener("mousemove", () => this.reset());
      document.body.addEventListener("keypress", () => this.reset());
      document.body.addEventListener("scroll", () => this.reset());
    });
  }

  /**
   * time interval
   */

  initInterval() {
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {

        this.check();
      }, 20000);
    })
  }

  public async logout(): Promise<void> {
    console.log('Executing logout...');
    try {
      await this.ngZone.runOutsideAngular(async () => {
        this.userService.logOutUser();
        console.log('UserService logOutUser executed.');
        await this.router.navigate(['/login']);
        console.log('Navigated to login page.');
      });

    } catch (error) {
      console.error('Error during logout:', error);
    }
  }


  /**
   * reset timer
   */

  reset() {
    console.log('User interaction detected. Resetting timer.');
    this.lastAction(Date.now());
  }


  /**
   * check timer
   */

  warningTimeout: any = null;
  logoutTimeout: any = null;
  hasWarned: boolean = false;

  check() {
    const now = Date.now();
    const lastAction = parseInt(this.getLastAction()) || now;
    const timeLeft = lastAction + this.autoLogoutTime;
    const diff = timeLeft - now;
    this.isTimeout = diff < 0;

    // console.log(`Time left: ${diff}ms, Timeout: ${this.isTimeout}`);

    if (this.isTimeout) {
      if (!this.hasWarned) {
        this.hasWarned = true;
        console.log("Your session is about to expire.");
        this.showMessage();

        localStorage.setItem("logout_timer", now.toString());

        this.logoutTimeout = setTimeout(() => {
          console.log("Logging out due to inactivity.");
          localStorage.removeItem("user_id");
          localStorage.removeItem("LMS_LastActionKEY");
          this.userService.logOutUser();
          this.clearTimeouts();
        }, 60000);
      }
    } else {
      this.clearTimeouts();
    }
  }


  clearTimeouts() {
    if (this.warningTimeout) {
      clearTimeout(this.warningTimeout);
      this.warningTimeout = null;
    }
    if (this.logoutTimeout) {
      clearTimeout(this.logoutTimeout);
      this.logoutTimeout = null;
    }
    this.hasWarned = false;
  }

  showMessage() {
    console.log('Opening session expired dialog.');
    this.modalService?.open(SessionExpiredComponent, {
      modalClass: 'modal-sm modal-dialog-centered',
      data:  {
        title: "Session Expired!",
        message: "Your session expired due to inactivity. Log in again to continue."
      }
    }).onClose.subscribe(() => {
      console.log('Session expired dialog closed.');
      this.clearTimeouts();
    });
  }


  /**
   *check if a user is logged in
   */

  // isUserLoggedIn(): any {
  //   return this.userService._isUserLoggedin;
  // }
}
