/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild,} from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaySlipModel1 } from 'src/app/core/models/pay-slip.model';

//import { LMSSharedUiModule } from '@lmsSharedUI';
import { NumbersOnlyDirective } from 'src/app/shared/directives/numbersOnly.directive';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AppUtilityService } from 'src/app/app-utility.service';
import { PageCardheadComponent } from 'src/app/shared/ui/pageheader-card/pageheader-card.component';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { MdbFormsModule } from 'mdb-angular-ui-kit/forms';

@Component({
  selector: 'app-payslip-dialog',
  templateUrl: './payslip-dialog.component.html',
  styleUrls: ['./payslip-dialog.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    NumbersOnlyDirective,
    MdbFormsModule
  ],
})
export class PayslipDialogComponent implements OnInit, AfterViewInit {
  PayDatesFilter = (d: Date): boolean => {
    const day = d.getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  };

  payslipModel: PaySlipModel1;
  payslipForm: FormGroup;
  // grossIncome: string;
  // deductions: string;
  submitted = false;
  payDateError: string;
  invalidDate = false;
  //calculateError: boolean;
  maxDate = new Date();
  clientType = '';
  month: number;
  constructor(
    public dialogRef: MdbModalRef<PayslipDialogComponent>,
    public global_utility: AppUtilityService,
    public fb: FormBuilder
  ) {}

  ngOnInit(): void {
    console.log(this.clientType);
    this.payslipModel.month = this.month;
    this.payslipForm = this.fb.group({
      month: [this.payslipModel.month, [Validators.required]],
      payslipDate: [new Date(this.payslipModel.payslipDate || this.payslipModel.date), [Validators.required]],
      nettIncome: [this.payslipModel.nettIncome || null, [Validators.required, Validators.min(0)]],
      otherIncome: [this.payslipModel.otherIncome || 0, [Validators.min(0)]]
    });
  }

  ngAfterViewInit() {}

  closeDialog() {
    this.dialogRef.close();
  }

  save() {
    this.submitted = true;

    if (this.payslipForm.invalid) {
      this.global_utility.warning('Please enter required payslip details','Income details');
      return;
    }

    this.payslipModel.payslipDate = this.payslipModel.date = this.payslipForm.value.payslipDate;
    this.payslipModel.nettIncome = this.payslipForm.value.nettIncome;
    this.payslipModel.otherIncome = this.payslipForm.value.otherIncome;
    return this.dialogRef.close(this.payslipModel);
  }

  get payslipInput() {
    return this.payslipForm!.controls;
  }

  get payslipDate() {
    return this.payslipForm.get('payslipDate');
  }

  get nettIncome() {
    return this.payslipForm.get('nettIncome');
  }

  get otherIncome() {
    return this.payslipForm.get('otherIncome');
  }

  // payDateChange(event :any){
  //   this.nettIncome.nativeElement.focus();
  // }
}
