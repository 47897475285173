export class LMSReasonCodesModel{
  id: number;
  reasonCode: string;
  reasonType: string;
  description: string;
  isEditable = false;

  //AFFORDABILITY PARAMETERS
  code: string;
  value: number;

  manageEngineId: number;
  createdOn: Date | number;
  createdBy: string;
  updatedOn: Date | number;
  updatedBy: string;
  isActive: boolean;
}
