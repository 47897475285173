<div class="modal-body spinner-modal-body">
  <div class="d-flex align-items-center justify-content-center">
    <!-- LOADING SPINNER -->
    <div *ngIf="showTimer == 1" color="primary" mode="indeterminate" style="width: 6rem; height: 6rem;"
      class="spinner-border  text-primary example-section m-3" role="status">
      <div *ngIf="showTimer == 1" color="primary" mode="indeterminate" style="width: 4.5rem; height: 4.5rem;"
        class="spinner-border text-secondary example-section" role="status"></div>
      <!-- <img src="assets/gears.svg"> -->
    </div>

    <!-- LOADING TIMER -->
    <div *ngIf="showTimer == 2" class="count-down-timer">
      <div class="example-section text-center">
        <div class="times">
          <p> {{display}} </p>
        </div>
      </div>
    </div>

    <div *ngIf="showTimer == 3" class="showWarning">
      <img alt="lms_ warning" src="assets/Warning_icon.png" loading="lazy" />
    </div>
  </div>

  <!-- LOADING TEXT -->
  <h5 class="fw-normal text-center">
    {{message || 'Please wait!!'}}
  </h5>
</div>
