export class NextOfKin {
    name:string;
    surname: string;
    line1: string;
    line2: string;
    suburb: string;
    town: string;
    code: string;
    email: string;
    quoteId: number;
    sameAsNextOfKin: boolean;
    address: string;
}
