export interface ClientsResponseObj {
  quoteId: number;
  idNumber: string;
  firstName: string;
  lastName: string;
  cellNumber: string;
  status: string;
  userName:string;
}

export class ClientsResponse {
    quoteId: number;
  idNumber: string;
  firstName: string;
  lastName: string;
  cellNumber: string;
  status: string;
  userName:string;
}
