<app-navbar></app-navbar>

<section class="ms-auto me-auto mt-1 pt-1 ">
  <div class="lms-banner mat-elevation-z3 " role="banner" *ngIf="environmentName !== 'PROD'">
    <div role="alertdialog" aria-live="assertive" [class.env-local]="environmentName === 'LOCAL'"
      [class.env-dev]="environmentName === 'DEV'" [class.env-uat]="environmentName === 'UAT'"
      [class.env-preprod]="environmentName === 'PREPROD'">
      <div class="text-muted text-sm-center" height="150px" style="font-size: medium; text-align: center;">
        This is {{ environmentName }} environment
      </div>
    </div>
  </div>

  <!-- <div class="app-container"> -->
  <div *ngIf="isLoggedIn | async" class="center-screen">
    <app-page-cardhead [PageName]="Page_Title" [PageHeadWidth]="650"></app-page-cardhead>
    <div class="col-12 mt-1 mb-2">
    </div>
  </div>
</section>

<main *ngIf="isLoggedIn | async">
  <div class="container container-lg">
    <!-- Consider Fluid with mx-4 px-4 -->
    <div class="card shadow-1-soft rounded-1 pt-1 pb-2" style="min-height: 75vh;">
      <router-outlet (activate)="global_utilities.onRouteActivation($event)"></router-outlet>
    </div>
  </div>
</main>

<!--Footer-->
<footer class="page-footer center-on-small-only pt-0 mt-3">

  <!--Copyright-->
  <div class="footer-copyright text-center py-1">
    <div class="container-fluid text-small">
      &copy; 2024 Copyright: <a href="https://www.finbondmutualbank.co.za/"> Finbond Mutual Bank · All Rights reserved ·
      </a>
    </div>
  </div>
  <!--/.Copyright-->

</footer>
<!--/.Footer-->
