import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';

import { BranchEmployerService } from "src/app/core/services/branch-employer.service";
import { ErrorhanderService } from "src/app/core/services/error-handler.service";
import { BranchEmployer } from "src/app/features/branch-employers/models/branch-employers";
import { EmployerDetailsModel } from "src/app/features/branch-employers/models/employer-details-model";
import { EmployerDetailsModel22 } from "@lmsModels";
import { UpdateEmployerService } from "src/app/features/branch-employers/update-employer.service";
import { BranchService } from '@lmsrestServices';

import { AppUtilityService } from "src/app/app-utility.service";
import { NgxPaginationModule, PaginationInstance } from 'ngx-pagination';
import { LMSSelectOptionDirective, LMS_NoSpecialsDirective, NumbersOnlyDirective, UpperCaseInputDirective } from '@lmsSharedUI';
import { BranchModel, EmploymentDetailsModel } from '@lmsModels';
import { LmsPaginateComponent } from '@lmsShared';
import { MdbModalModule, MdbModalRef } from 'mdb-angular-ui-kit/modal';

const HQSTAFF = 'HQ Staff';

@Component({
  selector: "app-update-employer",
  templateUrl: "./update-employer.component.html",
  styleUrls: ["./update-employer.component.css"],
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, LMSSelectOptionDirective, UpperCaseInputDirective,
    MdbModalModule, NgxPaginationModule,LmsPaginateComponent, NumbersOnlyDirective, LMS_NoSpecialsDirective]
})
export class UpdateEmployerComponent implements OnInit {
  employerModel = new EmployerDetailsModel();

  employerDetailsForm = this.fb.group({
    id:[0],
    employerTNR: [0,[Validators.required]],
    employerName: ['', [Validators.required]],
    parentCompany: [''],
    branchSerial:['',[Validators.required]],
    branchName:['',[Validators.required]],
    employerBranch:['',[Validators.required]],
    workEmail: [''],
    tel1: ['', [Validators.required]],
    tel2: [''],
    payDate: ['', [Validators.required]],
    shiftingRule: ['',[Validators.required]],
    paymentMethod: ['',[Validators.required]],
    wageFrequency: ['', [Validators.required]],
    workAddressLine1: ['',[Validators.required]],
    workAddressLine2: [''],
    workAddressSuburb: [ '', [Validators.required]],
    workAddressTown: ['',[Validators.required]],
    workAddressAreaCode: ['',[Validators.required]],

    postalAddressLine1: [''],
    postalAddressLine2: [''],
    postalAddressTown: [''],
    postalAddressAreaCode: [''],
  });

  employerSearchForm = this.fb.group({
    employerName: ['', Validators.required],
    searchBranch: ['', Validators.required],
    brancName: [[]],
  });

  submitted = false;
  lstEmployers: any[] = [];
  userProfile: any;
  canEdit = false;
  nameEntered = false;
  dataLoading = false;
  // public lstShiftingRule = SHIFTINGRULES;
  // public lstPayMethod = PAYMETHODS;
  // public lstWageFrequency = WAGEFREQUENCY;
  // public payDates = PAYDATES;
  errorMessages: string[] = [];
  currentStepIndex = 0;
  basicInfoFormSubscription: Subscription;
  formSubmitted = false;
  allFormsValid = false;
  pageTitle = 'Add Branch Employer';
  employers: BranchEmployer[];
  lstBranches: BranchModel[];
  userBranch = '';
  searchInput: any;
  selectedBranch: any;

  config: PaginationInstance = {
    id: 'pager',
    itemsPerPage: 8,
    currentPage: 1,
  };

  employerNametxt = '';
  HQ_STAFF = HQSTAFF;
  constructor(
    public updateEmployerService: UpdateEmployerService,
    public global_utility: AppUtilityService,
    private fb: FormBuilder,
    private branchEmployerService: BranchEmployerService,
    private errorhandlerService: ErrorhanderService,
    private branchService: BranchService ) {

  }

  get branchName() {
    return this.employerSearchForm.get('brancName');
  }

  get selbranch() {
    return this.employerSearchForm.value.brancName;
  }

  get employerName() {
    return this.employerSearchForm.controls.employerName;
  }

  get payDate() {
    return this.employerDetailsForm.controls.payDate;
  }
  get shiftingRule() {
    return this.employerDetailsForm.controls.shiftingRule;
  }

  get paymentMethod() {
    return this.employerDetailsForm.controls.paymentMethod;
  }

  get wageFrequency() {
    return this.employerDetailsForm.controls.wageFrequency;
  }

  setSelectedBranch(newValue: any): void {
    console.log('selected BRanch:', newValue);
  }

  getBranchByName(name: string) {
    const fl = this.lstBranches.filter(
      (projects) => projects.branchSerial === name,
    );

    return fl.length > 0 ? fl[0] : null;
  }

  ngOnInit(): void {
    if (this.global_utility.getGlobalData('branch_Name') == HQSTAFF) {
      this.branchService.getAllFinbondBranchNames().subscribe((branches) => {
        this.lstBranches = branches;

        const homeBranch = this.getBranchByName(
          this.global_utility.getGlobalData('branch_serial'),
        ); // .branches.pipe(map(br => br.filter(fb => fb.branchSerial == )[0] || null));
        this.selectedBranch = homeBranch;
        this.employerSearchForm.controls.searchBranch.patchValue(
          homeBranch!.branchName
        );
      });
    }

    //for HQ staff pull
    this.userBranch = this.global_utility.getGlobalData('branch_Name');

    if (this.userBranch != HQSTAFF) {
      this.employerSearchForm.controls.searchBranch.patchValue(this.userBranch);
      this.findBranchesByName();
    }

    if (this.branchEmployerService.preppedforCreate) {
      this.newEmployer();
    }
  }

  findEmployer(forceEdit = false) {
    if (this.employerSearchForm.valid) {
      this.global_utility.startLoading('Refreshing employers..');

      this.canEdit = false;
      this.submitted = false;
      this.nameEntered = true;

      this.employerModel = new EmployerDetailsModel();
      this.employerModel.employerName = this.employerSearchForm.value.employerName?.toUpperCase() || '';
      this.employerModel.branchSerial =
        (this.selbranch! as BranchModel).branchSerial ||
        this.selectedBranch.branchSerial;
      this.employerModel.branchName =
        (this.selbranch! as BranchModel).branchName ||
        this.selectedBranch.branchName;

      this.updateEmployerService.findEmployersByName(this.employerModel).subscribe({
          next: (result: any) => {

            this.global_utility.stopLoading();
            if(result && Array.from(result).length == 0) {
              this.editEmployer(this.employerModel);
              return;
            }

            if (result && Array.isArray(result)) {
              this.lstEmployers = result;
            }
          },
          error: (err) => {
            this.canEdit = false;
            this.submitted = false;
            this.nameEntered = true;

            this.global_utility.warning(
              'Error finding employer: ' +
                `${err.error.error || err.message || ''}`,
              'Employer search fail',
            );
            this.global_utility.stopLoading();
          },
        });
    }
  }

  onWageFrequencyChange(event: any) {
    let numb = 7;
    if (event.target.value === "Monthly") {
      numb = 28;
    }

    if (this.employerModel.payDate < numb) {
      this.payDate.setValue(this.employerModel.payDate as string,{onlySelf: true, emitEvent: true});
    } else {
      this.payDate.reset();
    }
  }

  editEmployer(employer: any) {
    this.submitted = false;
    this.lstEmployers = [];

    this.employerDetailsForm.reset();
    setTimeout(() => {
      this.employerModel.id = employer.id;
      this.employerName.setValue((employer.name || employer.employerName || '' as string).toUpperCase(), {onlySelf: true, emitEvent: false});
      this.employerNametxt = this.employerName.value || "";

      this.employerModel.parentCompany = employer.parentCompany;
      this.employerModel.workEmail = employer.workEmail || employer.email;
      this.employerModel.email = employer.email;
      this.employerModel.tel1 = employer.tel1;
      this.employerModel.tel2 = employer.tel2;
      this.employerModel.payDate = employer.payDate;
      this.employerModel.shiftingRule = employer.luPaydateShiftDesc;
      this.employerModel.paymentMethod = employer.luSalaryMethodDesc;
      this.employerModel.wageFrequency = employer.luEmpPayFrequency;

      // this.employerDetailsModel.addressId = employer.addressId;
      this.employerModel.physicalAddressLine1 = employer.physicalAddress1;
      this.employerModel.physicalAddressLine2 = employer.physicalAddress2;
      this.employerModel.physicalAddressSuburb = employer.physicalSuburb;
      this.employerModel.physicalAddressTown = employer.physicalTown;
      this.employerModel.physicalAddressAreaCode = employer.physicalCode;
      // this.employerDetailsModel.workAddressProvince = employer.ph;

      this.employerModel.postalAddressLine1 = employer.postalAddress1;
      this.employerModel.postalAddressLine2 = employer.postalAddress2;
      this.employerModel.postalAddressTown = employer.postalTown;
      this.employerModel.postalAddressAreaCode = employer.postalCode;

      this.employerModel.employerTNR = employer.tnumber || employer.employerTNR || 0;
      this.employerModel.branchSerial = employer.serial || employer.branchSerial;
      this.employerModel.branchName = employer.branchName;

      this.employerDetailsForm.patchValue({
        id: this.employerModel.id,
        employerTNR: this.employerModel.employerTNR,
        employerName: this.employerNametxt,
        parentCompany: this.employerModel.parentCompany,
        branchSerial:this.employerModel.branchSerial,
        branchName:this.employerModel.branchName,
        employerBranch:this.employerModel.branchName,
        workEmail: this.employerModel.email || '',
        tel1: this.employerModel.tel1 || '',
        tel2: this.employerModel.tel2 || '',
        shiftingRule: this.employerModel.shiftingRule || '',
        paymentMethod: this.employerModel.paymentMethod || '',
        wageFrequency: this.employerModel.wageFrequency || '',
        workAddressLine1: this.employerModel.physicalAddressLine1 || '',
        workAddressLine2: this.employerModel.physicalAddressLine2 || '',
        workAddressSuburb:  this.employerModel.physicalAddressSuburb || '',
        workAddressTown: this.employerModel.physicalAddressTown || '',
        workAddressAreaCode: this.employerModel.physicalAddressAreaCode || '',

        postalAddressLine1: this.employerModel.postalAddressLine1,
        postalAddressLine2: this.employerModel.postalAddressLine2,
        postalAddressTown: this.employerModel.postalAddressTown || '',
        postalAddressAreaCode: this.employerModel.postalAddressAreaCode
      },{onlySelf: true, emitEvent: true});

      setTimeout(()=>{
        this.payDate.setValue(this.employerModel.payDate as string,{onlySelf: true, emitEvent: false});
      },100);
    },100);

    this.canEdit = true;
  }

  deleteEmployer(employerId: any) {
    // console.log("employer", employer);
    // this.employerDetailsModel.id = employer.id;
    this.updateEmployerService
      .deleteEmployer(employerId)
      .subscribe((result: any) => {
        console.log('result', result);
        this.canEdit = false;
        this.global_utility.success('', result.message);
      });
  }

  saveEmployer() {
    this.submitted = true;
    this.employerDetailsForm.updateValueAndValidity();

    if (!this.employerDetailsForm.invalid) {
      this.global_utility.startLoading('Saving employer.');
      if (!this.employerModel.employerTNR) {
        this.employerModel.employerTNR = 0;
      }

      this.employerModel.userName = this.global_utility.getGlobalData('userDisplay'); // if brancj model rue
      this.employerModel.employerBranch = this.employerModel.branchName;

      this.updateEmployerService.saveEmployer(this.employerDetailsForm.value as EmployerDetailsModel).subscribe({
          next: (result) => {
            this.global_utility.stopLoading();
            if (result) {
              if (result.errorMessage) {
                this.errorhandlerService.handleString(result.errorMessage);
                this.canEdit = true;
                return;
              }

              this.employerName.setValue(result.employerName, {onlySelf: true, emitEvent: false});

              this.canEdit = false;
              this.global_utility.success(
                `Employer ${result.name} saved with TNR = ${result.tnumber}!`,
                'Employer saved!',
              );

              this.employerSearchForm.controls.employerName.reset();
              if (this.userBranch != HQSTAFF) {
                this.employerSearchForm.controls.searchBranch.patchValue(this.userBranch);
              }

              if (
                this.branchEmployerService.preppedforCreate //&&
                //this.dialogRef
              ) {
                this.branchEmployerService.preppedforCreate = false;
                //this.dialogRef!.close(this.mapEmployerToEmployment(result));
                // return this.dialogRef.close(this.employmentDetailsForm.value);
              }
            }
          },
          error: (err) => {
            if (
              (err.error &&
                err.error.type === 'error' &&
                err.statusText === 'Unknown Error') ||
              err.status === 504
            ) {
              // Offline
              this.errorhandlerService.handleOffline(
                'Server Communication to Delfin failed.',
                'Server OFFLINE',
              );
            } else {
              this.global_utility.stopLoading();
              this.editEmployer(err.error);
            }
          },
        });
    }
  }

  cancelEdit() {
    this.canEdit = false;
    if (this.userBranch != HQSTAFF) {
      this.employerSearchForm.controls.employerName.reset();
      this.employerSearchForm.updateValueAndValidity();
    }

    if (this.branchEmployerService.preppedforCreate) {
      //this.dialogRef!.close();
    }
  }

  goforNew() {
    this.global_utility.warning(
      'Something amazing is about to happen',
      'New employer required',
    );
  }

  mapEmployerToEmployment(employer: any): EmploymentDetailsModel {
    const employer2employment = new EmploymentDetailsModel();

    employer2employment.serial = employer.branchSerial || employer.serial;
    employer2employment.employerName = employer.name || employer.employerName;
    employer2employment.parentCompany = employer.parentCompany;
    employer2employment.payDate = employer.payDate;
    employer2employment.shiftingRule = employer.luPaydateShiftDesc;
    employer2employment.branch = employer.employerBranch;
    employer2employment.branchName = employer.branchName;
    employer2employment.company = employer.company;
    employer2employment.wageCalendar = employer.luEmpPayFrequency;
    employer2employment.paymentMethod = employer.luSalaryMethodDesc;
    employer2employment.workAdressLine1 =
      employer.physicalAddressLine1 || employer.physicalAddress1;
    employer2employment.workAdressLine2 =
      employer.physicalAddressLine2 || employer.physicalAddress2;
    employer2employment.workAdressSuburb =
      employer.physicalAddressSuburb || employer.physicalSuburb;
    employer2employment.workAdressTown = employer.physicalTown;
    employer2employment.workAdressAreaCode = employer.physicalCode;
    employer2employment.employerTNR = employer.tnumber;
    employer2employment.lineManagerEmail = employer.email;
    employer2employment.lineManagerContactNumber = employer.tel1;

    return employer2employment;
  }

  newEmployer() {
    this.lstEmployers = [];
    // if (this.branchEmployerService.preppedforCreate) {
    this.employerModel = new EmployerDetailsModel();
      this.employerModel.employerName = this.employerSearchForm.value.employerName?.toUpperCase() || '';
      this.employerModel.branchSerial =
        (this.selbranch! as BranchModel).branchSerial ||
        this.selectedBranch.branchSerial;
      this.employerModel.branchName =
        (this.selbranch! as BranchModel).branchName ||
        this.selectedBranch.branchName;
    this.editEmployer(this.employerModel);
    //}

    // this.employerDetailsModel ||= new EmployerDetailsModel();
    this.canEdit = true;
  }

  findBranchesByName() {
    //if (this.userService.userBranchName === HQSTAFF) {
    console.log('br =', this.employerSearchForm.value.searchBranch);
    // if (this.employerSearchForm.value.searchBranch == '') {
    //   this.lstBranches;
    // }
    // let filteredBranches = this.lstBranches.filter(br => br.branchName.startsWith(this.employerSearchForm.value.searchBranch!));
    this.branchService
      .getBranchByName(this.employerSearchForm.value.searchBranch)
      .subscribe((result) => {
        //this.loadingService.stopLoading();
        if (!result || Object.keys(result).length == 0) {
          this.global_utility.warning(
            'No branches found with name: ' +
              this.employerSearchForm.value.searchBranch,
            'Empty Search result',
          );
        } else {
          this.lstBranches = result;
          this.selectedBranch = this.lstBranches[0];
        }
      });
    //}
  }

  get validateSearchInput() {
    return this.employerSearchForm.controls;
  }
}
