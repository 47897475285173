import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ReportService } from 'src/app/core/rest-services/report.service';
import * as XLSX from 'xlsx';
import { NgxPaginationModule, PaginationInstance } from 'ngx-pagination';
import { MatFormFieldModule } from '@angular/material/form-field';
//import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ZARcurrencyPipe } from '@lmsSharedPipes';
import { BranchLoansReportModel } from '@lmsModels';
import { LmsPaginateComponent } from '@lmsShared';

@Component({
  selector: 'app-lexis-nexis-report',
  templateUrl: './lexis-nexis-report.component.html',
  styleUrls: ['./lexis-nexis-report.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatButtonModule,
    NgxPaginationModule,
    LmsPaginateComponent,
    FormsModule,
    ReactiveFormsModule,
    DecimalPipe, 
    DatePipe, 
    ZARcurrencyPipe
  ]
})
export class LexisNexisReportComponent implements OnInit {
  lexisNexisReportForm: FormGroup;
  startDate: string;
  endDate: string;
  selectedBranch: any;
  branchLoansReportModel = new BranchLoansReportModel();
  lstLexisNexis: any[] = [];
  title = 'export-excel';
  fileName = '';

  @ViewChild('TABLE') table: ElementRef;
  dataSource = this.lstLexisNexis;

  //PAGINATOR CODE
   @Input() items: any[];
   @Output() changePage = new EventEmitter<any>(true);
   @Input() initialPage = 1;
   @Input() pageSize = 15;
   @Input() maxPages = 5;
  config: PaginationInstance = {
    id: 'pager',
    itemsPerPage: 10,
    currentPage: 1
  };
  docCount = 1;

  constructor(
    public reportService: ReportService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.lexisNexisReportForm = this.formBuilder.group({
      branch: [this.selectedBranch],
      startDate: new Date(this.branchLoansReportModel.startDate),
      endDate: new Date(this.branchLoansReportModel.endDate),
    });
  }

  search() {
    console.log('search' + this.selectedBranch);
    this.branchLoansReportModel.startDate = this.startDate;
    this.branchLoansReportModel.endDate = this.endDate;

    this.reportService
      .pullLexisnexisReport(this.branchLoansReportModel)
      .subscribe({
        next: (result) => {
          console.log('result = ' + result);
          // console.log("quotes = "  + quotes.);
          this.lstLexisNexis = result;

          // this.items = this.lstLexisNexis;
          // if (this.items && this.items.length) {
          //   this.setPage(this.initialPage);
          // }
        },
        error: (err) => {},
      });
  }

  // public setPage(page: number) {
  //   // get new pager object for specified page
  //   this.pager = paginate({ totalItems: this.items.length, currentPage: page, pageSize: this.pageSize, maxPages: this.maxPages });

  //   // get new page of items from items array
  //   const pageOfItems = this.items.slice(this.pager.startIndex, this.pager.endIndex + 1);

  //   // call change page function in parent component
  //   this.changePage.emit(pageOfItems);
  // }

  exportToExcel(): void {
    console.log('exportToExcel');
    this.title = 'export-excel';
    this.fileName = 'Lexis Nexis: ' + new Date().toLocaleDateString() + '.xlsx';

    /* pass here the table id */
    //const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.lstLexisNexis);
    const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement);
    /* generate workbook and add the worksheet */

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */

    XLSX.writeFile(wb, this.fileName);
  }


}
