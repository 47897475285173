import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'

})
export class MenuService {

  http: HttpClient;
  menuResult: any;
  data: Observable<any>;

  accessToken: any;

  HttpParamsOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(http: HttpClient) {
    this.http = http;
  }

  getUserMenu(roles: any, uuid: any) {
    return this.http.
      get(environment.apiUrl + '/loan/menu/getUserMenus/' + roles + "/" + uuid);
  }

  findAll() {
    return this.http.
      get(environment.apiUrl + '/loan/menu/findAll');
  }

  delete(menu: any) {
    return this.http.
      post(environment.apiUrl + '/loan/menu/delete', menu);
  }

  save(menu: any) {
    return this.http.
      post(environment.apiUrl + '/loan/menu/save', menu);
  }

  deleteSubmenu(subMenu: any) {
    return this.http.
      post(environment.apiUrl + '/loan/menu/submenu/delete', subMenu);
  }

  saveSubmenu(subMenu: any) {
    return this.http.
      post(environment.apiUrl + '/loan/menu/submenu/save', subMenu);
  }
}

