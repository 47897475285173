/* eslint-disable @typescript-eslint/no-empty-function */
import { MdbUiKitModule } from 'src/app/mdb-ui-kit.module';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';

import { ClientHeaderComponent } from 'src/app/modules/client-header/client-header.component';
//import { SessionExpiredComponent } from './session-expired/session-expired.component';

import { LMSDatePickerModule } from 'src/app/core/constants/datepicker-adapter';
import { LmsGridviewComponent } from 'src/app/shared/ui/lms-gridview/lms-gridview.component';
import { LMSDialogComponent } from './dialogs/lms-dialog/lms-dialog.component';

import { HighlightDirective } from './directives/highlight.directive';
import { LMSSelectOptionDirective } from './directives/lms-selectOption.directive';

import { LMSDialogHandlerService } from './lmsdialog-handler.service';

import { LmsClientSearchComponent, PageCardheadComponent, LmsPaginateComponent } from './ui';
import { LMS_NoSpecialsDirective, LMS_NoPasteDirective, UpperCaseInputDirective, NumbersOnlyDirective, UniquePayslipDirective } from './directives';
import { ZAcurrencyPipe, ZARcurrencyPipe, NoSpecialCharPipe,UpperCaseSearchListPipe } from './pipes';
import { AppUtilityService } from '../app-utility.service';

const EXPORTED_DECLARATIONS = [
  ClientHeaderComponent,
  PageCardheadComponent,
  LmsClientSearchComponent,
  LmsGridviewComponent,
  LmsPaginateComponent,
  LMSDatePickerModule,
  ZAcurrencyPipe,
  ZARcurrencyPipe,
  NoSpecialCharPipe,
  LMSDialogComponent,
  HighlightDirective,
  UpperCaseSearchListPipe,
  NumbersOnlyDirective,
  LMSSelectOptionDirective,
  LMS_NoPasteDirective,
  UpperCaseInputDirective,
  LMS_NoSpecialsDirective
];

@NgModule({
  imports: [
    CommonModule,
    MdbUiKitModule.forRoot(),
    NgxPaginationModule,
    ...EXPORTED_DECLARATIONS
  ],
  providers: [
    LMSDialogHandlerService,
    AppUtilityService,
    HighlightDirective,
    NumbersOnlyDirective,
    LMSSelectOptionDirective,
    LMS_NoSpecialsDirective,
    LMS_NoPasteDirective,
    UniquePayslipDirective,
    UpperCaseSearchListPipe,
    ZARcurrencyPipe,
    ZAcurrencyPipe
  ],
  //exports: [
  //       PageCardheadComponent,
  //LmsPaginateComponent,
  //       LmsGridviewComponent,
  //       LMSDialogComponent,
  //       ZAcurrencyPipe,
  //       ZARcurrencyPipe,
  //       HighlightDirective,
  //       NoSpecialCharPipe,
  //       UpperCaseSearchListPipe,
  //       UpperCaseInputDirective,
  //       ClientHeaderComponent,
  //       LmsClientSearchComponent
  // ]
})
export class LMSSharedUiModule { }
