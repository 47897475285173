<div class="modal-content">
  <div class="modal-header">
    <div class="modal-title">
      <app-page-cardhead style="margin-top: -1.2rem;" [PageName]="'Next Of Kin Details'"
        [PageHeadWidth]="650"></app-page-cardhead>
    </div>
    <button class="btn btn-close" aria-label="close dialog" (click)="dialogRef.close()"></button>
  </div>

  <div class="modal-body">
    <table w650 id="clientHeader" class="table p-1 table-hover table-sm shadow-2-strong rounded-1">
      <caption>Client Details</caption>
      <tbody>
        <tr>
          <td>First names</td>
          <td>{{clientDetails.firstName}}</td>

          <td>Surname</td>
          <td>{{clientDetails.lastName}}</td>
        </tr>

        <tr>
          <td>ID number</td>
          <td>{{clientDetails.idNumber}}</td>

          <td>Cell number</td>
          <td>{{clientDetails.cellNumber}}</td>
        </tr>
      </tbody>
    </table>

    <div class="card">
      <div class="card-body p-1">
        <div class="container-fluid">
          <ng-container class="container-md align-items-center">
            <!-- HAS NextOfKin details-->
            <div *ngIf="hasNextOfKin">
              <!-- only One Next Of Kin-->
              <table class="mt-2 table table-sm table-fixed" [style.width.px]="750">
                <tbody>
                  <tr>
                    <td class="aligndetails" style="border-bottom-width: 0;">
                      <!--  NOK 1 -->
                      <table id="nextOfKinDetails" class="table table-striped table-sm shadow-2-strong rounded-1 p-1">
                        <caption> Next of kin 1</caption>
                        <tbody class="field-4column-label">
                          <tr>
                            <td>First names</td>
                            <td>{{nextOfKinModel.name1}}</td>
                          </tr>

                          <tr>
                            <td>Surname</td>
                            <td>{{nextOfKinModel.surname1}}</td>
                          </tr>

                          <tr>
                            <td>Relationship</td>
                            <td>{{nextOfKinModel.relationship1}}</td>
                          </tr>


                          <tr>
                            <td>Contact number</td>
                            <td>{{nextOfKinModel.contactNo1}}</td>
                          </tr>

                          <tr>
                            <td>Email address</td>
                            <td>{{nextOfKinModel.email1}}</td>
                          </tr>

                          <tr>
                            <td>Address line 1</td>
                            <td>{{nextOfKinModel.addressLine1}}</td>
                          </tr>


                          <tr>
                            <td>Address line 2</td>
                            <td>{{nextOfKinModel.addressLine2}}</td>
                          </tr>

                          <tr>
                            <td>Suburb</td>
                            <td>{{nextOfKinModel.suburb1}}</td>
                          </tr>

                          <tr>
                            <td>Town</td>
                            <td>{{nextOfKinModel.town1}}</td>
                          </tr>

                          <tr>
                            <td>Area code</td>
                            <td>{{nextOfKinModel.areaCode1}}</td>
                          </tr>

                          <tr>
                            <td>Province</td>
                            <td>{{nextOfKinModel.province1}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>

                    <td class="aligndetails" style="border-bottom-width: 0;" *ngIf="!nextOfKinModel.onlyOneNextOfKin">
                      <table id="nextOfKin2Details" class="table table-striped table-sm shadow-2-strong rounded-1 p-1">
                        <caption> Next of kin 2</caption>
                        <tbody class="field-4column-label">
                          <tr>
                            <td>First names</td>
                            <td>{{nextOfKinModel.name2}}</td>
                          </tr>

                          <tr>
                            <td>Surname</td>
                            <td>{{nextOfKinModel.surname2}}</td>
                          </tr>

                          <tr>
                            <td>Relationship</td>
                            <td>{{nextOfKinModel.relationship2}}</td>
                          </tr>

                          <tr>
                            <td>Contact number</td>
                            <td>{{nextOfKinModel.contactNo2}}</td>
                          </tr>

                          <tr>
                            <td>Email address</td>
                            <td>{{nextOfKinModel.email2}}</td>
                          </tr>

                          <tr>
                            <td>Address line 1</td>
                            <td>{{nextOfKinModel.line1}}</td>
                          </tr>

                          <tr>
                            <td> Address line 2</td>
                            <td>{{nextOfKinModel.line2}}</td>
                          </tr>

                          <tr>
                            <td>Suburb</td>
                            <td>{{nextOfKinModel.suburb2}}</td>
                          </tr>

                          <tr>
                            <td>Town</td>
                            <td>{{nextOfKinModel.town2}}</td>
                          </tr>

                          <tr>
                            <td>Area code</td>
                            <td>{{nextOfKinModel.areaCode2}}</td>
                          </tr>

                          <tr>
                            <td>Province</td>
                            <td>{{nextOfKinModel.province2}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- NO Next Of Kin details-->
            <div class="center-screen" *ngIf="!hasNextOfKin && !isReadOnly">
              <!-- only One Next Of Kin-->
              <table class="mt-2 table table-sm table-fixed" [style.width.px]="750">
                <tbody>
                  <tr>
                    <td class="aligndetails" style="border-bottom-width: 0;">
                      <form [formGroup]="nextOfKinForm" #formDirective="ngForm">
                        <table class="table table-striped table-bordered table-sm shadow-2-strong rounded-1"
                          [ngClass]="{'myw-400':nextOfKinModel.onlyOneNextOfKin}">
                          <caption> Next of kin</caption>
                          <tbody class="field-4column-label">

                            <tr>
                              <td>First names</td>
                              <td>
                                <input class="form-control" placeholder="First name/s *" formControlName="name1"
                                  [(ngModel)]="nextOfKinModel.name1" required type="text"
                                  (keypress)="global_utility.omit_special_char($event)">
                                <div *ngIf="validateNok.name1.errors?.required && submitted">
                                  <small class="text-danger">
                                    First names are required
                                  </small>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>Surname</td>
                              <td>
                                <input class="form-control" placeholder="Surname *" formControlName="surname1"
                                  [(ngModel)]="nextOfKinModel.surname1" required type="text"
                                  (keypress)="global_utility.omit_special_char($event)">
                                <div *ngIf="validateNok.surname1.errors?.required && submitted">
                                  <small class="text-danger">
                                    Surname is required
                                  </small>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>Relationship</td>
                              <td>
                                <select class="form-control form-select lmsselect"
                                  [(ngModel)]="nextOfKinModel.relationship1" placeholder="Relationship *"
                                  formControlName="relationship1" lmsSelectOption [constantName]="'RELATIONSHIP'"
                                  required>
                                  <!-- <option *ngFor="let relation of lstRelationships" [value]="relation.value">
                                    {{relation.description}}
                                  </option> -->
                                </select>
                                <div *ngIf="validateNok.relationship1.errors?.required && submitted">
                                  <small class="text-danger">
                                    Relationship is required
                                  </small>
                                </div>
                              </td>

                            </tr>

                            <tr>
                              <td>Contact number</td>
                              <td>
                                <input class="form-control" placeholder="Contact number *" formControlName="contactNo1"
                                  [(ngModel)]="nextOfKinModel.contactNo1" required maxlength="10"
                                  (keypress)="($event.charCode >= 48 && $event.charCode < 58)">
                                <div *ngIf="validateNok.contactNo1.errors?.required && submitted">
                                  <small class="text-danger">
                                    Contact number is required
                                  </small>
                                </div>
                                <div *ngIf="validateNok.contactNo1.errors?.pattern">
                                  <small class="text-danger">
                                    Needs to be 10 digits and must start with a 0.
                                  </small>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>Email address</td>
                              <td>
                                <input class="form-control" placeholder="Email address" formControlName="email1"
                                  [(ngModel)]="nextOfKinModel.email1">
                              </td>
                            </tr>

                            <tr>
                              <td>Address line 1</td>
                              <td>
                                <input class="form-control" placeholder="Address line 1 *"
                                  formControlName="addressLine1" [(ngModel)]="nextOfKinModel.addressLine1" required
                                  type="text" (keypress)="global_utility.omit_special_char($event)">
                                <div *ngIf="validateNok.addressLine1.errors?.required && submitted">
                                  <small class="text-danger">
                                    Address line 1 is required
                                  </small>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>Address line 2</td>
                              <td>
                                <input class="form-control" formControlName="addressLine2" placeholder="Address line 2"
                                  [(ngModel)]="nextOfKinModel.addressLine2" type="text"
                                  (keypress)="global_utility.omit_special_char($event)">
                              </td>
                            </tr>

                            <tr>
                              <td>Suburb</td>
                              <td>
                                <input class="form-control" placeholder="Suburb *" formControlName="suburb1"
                                  [(ngModel)]="nextOfKinModel.suburb1" required type="text"
                                  (keypress)="global_utility.omit_special_char($event)">
                                <div *ngIf="validateNok.suburb1.errors?.required && submitted">
                                  <small *ngIf="validateNok.suburb1.errors?.required" class="text-danger">
                                    Suburb is required
                                  </small>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>Town</td>
                              <td>
                                <input class="form-control" placeholder="Town/City *" formControlName="town1"
                                  [(ngModel)]="nextOfKinModel.town1" required type="text"
                                  (keypress)="global_utility.omit_special_char($event)">
                                <div *ngIf="validateNok.town1.errors?.required && submitted">
                                  <small class="text-danger">
                                    Town/City is required
                                  </small>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>Area code</td>
                              <td>
                                <input class="form-control" placeholder="Area code *" formControlName="areaCode1"
                                  [(ngModel)]="nextOfKinModel.areaCode1" maxlength="4"
                                  (keypress)="($event.charCode >= 48 && $event.charCode < 58)" required>
                                <div *ngIf="validateNok.areaCode1.errors?.required && submitted">
                                  <small class="text-danger">
                                    Area code is required
                                  </small>
                                </div>
                                <div *ngIf="validateNok.areaCode1.errors?.pattern">
                                  <small class="text-danger">
                                    Invalid Area Code
                                  </small>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>Province</td>
                              <td>
                                <select class="form-control form-select lmsselect" formControlName="province1"
                                  placeholder="Province *" [(ngModel)]="nextOfKinModel.province1" lmsSelectOption
                                  [constantName]="'PROVINCES'" required>
                                  <!-- <option *ngFor="let prov of lstProvinces" [value]="prov.value">{{prov.description}}
                                  </option> -->
                                </select>
                                <div *ngIf="validateNok.province1.errors?.required && submitted">
                                  <small class="text-danger">
                                    Province is required
                                  </small>
                                </div>
                              </td>
                            </tr>

                          </tbody>
                        </table>
                      </form>
                    </td>

                    <!-- Next Of Kin2 -->
                    <td class="aligndetails" style="border-bottom-width: 0;" *ngIf="!nextOfKinModel.onlyOneNextOfKin">
                      <form [formGroup]="nextOfKin2Form" #formDirective="ngForm">
                        <table class="table p-1 table-striped table-bordered table-sm shadow-2-strong rounded-1">
                          <caption>Next of kin 2</caption>
                          <tbody class="field-4column-label">
                            <tr>
                              <td>First names</td>
                              <td>
                                <input class="form-control" placeholder="First name/s *" formControlName="name2"
                                  [(ngModel)]="nextOfKinModel.name2" [required]="!nextOfKinModel.onlyOneNextOfKin"
                                  type="text" (keypress)="global_utility.omit_special_char($event)">
                                <div *ngIf="validateNok2.name2.errors?.required && submitted">
                                  <small class="text-danger">
                                    First name/s are required
                                  </small>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>Surname</td>
                              <td>
                                <input class="form-control" placeholder="Surname *" formControlName="surname2"
                                  [(ngModel)]="nextOfKinModel.surname2" [required]="!nextOfKinModel.onlyOneNextOfKin"
                                  type="text" (keypress)="global_utility.omit_special_char($event)">
                                <div *ngIf="validateNok2.surname2.errors?.required && submitted">
                                  <small class="text-danger">
                                    Surname is required
                                  </small>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>Relationship</td>
                              <td>
                                <select class="form-control form-select lmsselect"
                                  [(ngModel)]="nextOfKinModel.relationship2" placeholder="Relationship *"
                                  formControlName="relationship2" [required]="!nextOfKinModel.onlyOneNextOfKin"
                                  lmsSelectOption [constantName]="'RELATIONSHIP'">
                                  <!-- <option *ngFor="let relation of lstRelationships" [value]="relation.value">
                                    {{relation.description}}
                                  </option> -->
                                </select>
                                <div *ngIf="validateNok2.relationship2.errors?.required && submitted">
                                  <small class="text-danger">
                                    Relationship is required
                                  </small>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>Contact number</td>
                              <td>
                                <input class="form-control" placeholder="Contact number *" formControlName="contactNo2"
                                  maxlength="10" [(ngModel)]="nextOfKinModel.contactNo2"
                                  [required]="!nextOfKinModel.onlyOneNextOfKin"
                                  (keypress)="($event.charCode >= 48 && $event.charCode < 58)">
                                <div *ngIf="validateNok2.contactNo2.errors?.required && submitted">
                                  <small class="text-danger">
                                    Contact number is required
                                  </small>
                                </div>
                                <div *ngIf="validateNok2.contactNo2.errors?.pattern">
                                  <small class="text-danger">
                                    Needs to be 10 digits and must start with a 0.
                                  </small>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>Email address</td>
                              <td>
                                <input class="form-control" placeholder="Email address" formControlName="email2"
                                  [(ngModel)]="nextOfKinModel.email2">
                              </td>
                            </tr>

                            <tr>
                              <td>Address line 1</td>
                              <td>
                                <input class="form-control" placeholder="Address line 1 *" formControlName="line1"
                                  [(ngModel)]="nextOfKinModel.line1" [required]="!nextOfKinModel.onlyOneNextOfKin"
                                  type="text" (keypress)="global_utility.omit_special_char($event)">
                                <div *ngIf="validateNok2.line1.errors?.required && submitted">
                                  <small class="text-danger">
                                    Address line 1 is required
                                  </small>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>Address line 2</td>
                              <td>
                                <input class="form-control" class="form-control" formControlName="line2"
                                  placeholder="Address line 2" [(ngModel)]="nextOfKinModel.line2" type="text"
                                  (keypress)="global_utility.omit_special_char($event)">
                              </td>
                            </tr>

                            <tr>
                              <td>Suburb</td>
                              <td>
                                <input class="form-control" placeholder="Suburb *" formControlName="suburb2"
                                  [(ngModel)]="nextOfKinModel.suburb2" [required]="!nextOfKinModel.onlyOneNextOfKin"
                                  type="text" (keypress)="global_utility.omit_special_char($event)">
                                <div *ngIf="validateNok2.suburb2.errors?.required && submitted">
                                  <small class="text-danger">
                                    Suburb is required
                                  </small>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>Town</td>
                              <td>
                                <input class="form-control" placeholder="Town/City *" formControlName="town2"
                                  [(ngModel)]="nextOfKinModel.town2" [required]="!nextOfKinModel.onlyOneNextOfKin"
                                  type="text" (keypress)="global_utility.omit_special_char($event)">
                                <div *ngIf="validateNok2.town2.errors?.required && submitted">
                                  <small class="text-danger">
                                    Town/City is required
                                  </small>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>Area code</td>
                              <td>
                                <input class="form-control" maxlength="4" min="0" placeholder="Area code *"
                                  formControlName="areaCode2" [(ngModel)]="nextOfKinModel.areaCode2"
                                  [required]="!nextOfKinModel.onlyOneNextOfKin"
                                  (keypress)="($event.charCode >= 48 && $event.charCode < 58)">
                                <div *ngIf="validateNok2.areaCode2.errors?.required && submitted">
                                  <small class="text-danger">
                                    Area code is required
                                  </small>
                                </div>
                                <div *ngIf="validateNok2.areaCode2.errors?.pattern  && submitted">
                                  <small class="text-danger">
                                    Invalid Area Code
                                  </small>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>Province</td>
                              <td>
                                <select class="form-control form-select lmsselect" formControlName="province2"
                                  placeholder="Province *" [(ngModel)]="nextOfKinModel.province2"
                                  [required]="!nextOfKinModel.onlyOneNextOfKin" lmsSelectOption
                                  [constantName]="'PROVINCES'">
                                  <!-- <option *ngFor="let prov of lstProvinces" [value]="prov.value">{{prov.description}}
                                  </option> -->
                                </select>
                                <div *ngIf="validateNok2.province2.errors?.required && submitted">
                                  <small class="text-danger">
                                    Province is required
                                  </small>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </form>
                      <!-- Next Of Kin2 end-->
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="center-screen">
                <div class="d-flex justify-content-center align-content-center">
                  <span>
                    <label for="OneNok" class="form-check-label m-2">Only ONE next of kin is required</label>
                    <!-- mdbInput class="form-check form-check-input form-check-inline " -->
                    <input #OneNok class=" form-check-input form-check-inline form-check" type="checkbox"
                      aria-label="Only ONE next of kin is required" [checked]="nextOfKinModel.onlyOneNextOfKin"
                      (change)="nextOfKinModel.onlyOneNextOfKin = !nextOfKinModel.onlyOneNextOfKin" />
                  </span>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="card-footer flex-container">
        <button class="btn btn-primary" (click)="close()"> Close </button>
        <button class="btn btn-primary" *ngIf="!isReadOnly && !hasNextOfKin" (click)="onSaveSubmit()" color="primary">
          Save </button>
        <button class="btn btn-primary" *ngIf="hasNextOfKin && !isReadOnly" (click)="editNextOfKin()" color="primary">
          Edit </button>
        <!-- clientStatus === 'REPEAT' && -->
        <button *ngIf="!isReadOnly && (hasNextOfKin||hasNextOfKin2)" type="button" class="btn btn-primary ms-4"
          (click)="confirmNextOfKinDetails()"><i class="fas fa-thumbs-up fa-fw me-1"></i> Confirm </button>
      </div>
    </div>
  </div>
</div>
