<!-- <div class="center-screen">
<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
    <button mat-icon-button disabled></button>
    <mat-checkbox class="checklist-leaf-node" [checked]="checklistSelection.isSelected(node)"
      (change)="todoLeafItemSelectionToggle(node)">{{node.item}}</mat-checkbox>
  </mat-tree-node>

  <mat-tree-node *matTreeNodeDef="let node; when: hasNoContent" matTreeNodePadding>
    <button mat-icon-button disabled></button>
    <mat-form-field appearance="fill">
      <mat-label>New item...</mat-label>
      <input matInput #itemValue placeholder="Ex. Lettuce">
    </mat-form-field>
    <button mat-button (click)="saveNode(node, itemValue.value)">Save</button>
  </mat-tree-node>

  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.item">
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </button>
    <mat-checkbox [checked]="descendantsAllSelected(node)" [indeterminate]="descendantsPartiallySelected(node)"
      (change)="todoItemSelectionToggle(node)">{{node.item}}</mat-checkbox>
    <button mat-icon-button (click)="addNewItem(node)"><mat-icon>add</mat-icon></button>
  </mat-tree-node>
</mat-tree>
<div> -->

<form class="form-container center-screen" [formGroup]="menusAdminForm">
  <table class="table table-striped table-hover table-sm shadow-2-strong p-2 rounded-1" [style.width.px]="850">
    <thead class="table-center-header">
      <tr>
        <td> Menu </td>
        <td> Component </td>
        <td style="width: 20px;"> Submenus </td>
        <td> Trigger </td>
        <td> Role </td>
        <td style="width: 30px;">
          Edit </td>
        <td style="width: 30px;">
          Delete </td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let menu of menus | paginate: config">
        <td style="width:35vw"> {{menu.menu}} </td>
        <td style="width:35vw"> {{menu.component}} </td>
        <td>
          <button *ngIf="menu.hasSubmenu == true" class="btn btn-floating btn-icon fs-5 fas" mdbRipple
            (click)="editMenu(menu)">
            <i class="fas fa-xs fa-fw fa-square-check"></i>
          </button>
        </td>
        <td style="width:10vw"> {{menu.trigger}} </td>
        <td style="line-break: normal;"> {{menu.jobRoles}} </td>
        <td>
          <button class="btn btn-floating btn-icon fs-5 fas" mdbRipple (click)="editMenu(menu)">
            <i class="fas fa-xs fa-fw fa-pencil"></i>
          </button>
        </td>
        <td>
          <button class="btn btn-floating btn-icon fs-5 fas" mdbRipple (click)="deleteMenu(menu)">
            <i class="fas fa-xs fa-fw fa-trash-can"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <lms-paginate [config]="config"></lms-paginate>

  <button class="btn btn-primary my-3" mdbRipple (click)="addMenu()">Add Menu</button>
</form>
