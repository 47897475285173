import { ZARcurrencyPipe } from 'src/app/shared/pipes/zarcurrency.pipe';

export class ExistingDebtObligationsModel {

  id?: number;
  quoteId?: number;
  referenceNumber: string;
  term: number;
  outstandingBalance: number | ZARcurrencyPipe;
  canSettle: boolean;
  instalment: number | ZARcurrencyPipe;
  thirdPartyName: string;
  paidUp: boolean;
  exclude?: boolean;
  excludeAction?: string;
  excludeDocRequired?: boolean;
}
