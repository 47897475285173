/* eslint-disable no-inner-declarations */
/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BiometricsModel } from '@lmsModels';
import { BiometricsSerivce } from '@lmsrestServices';
import { PageCardheadComponent } from '@lmsShared';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { Subscription, tap } from 'rxjs';
import { AppUtilityService } from 'src/app/app-utility.service';
import { UserService } from 'src/app/core/services/lmsuser.service';
import { environment } from 'src/environments/environment';
import { CustomerEnrollmentEntity } from '../biometrics-dialog/biometrics-dialog.component';

@Component({
  selector: 'app-fingerprint-signin',
  templateUrl: './fingerprint-signin.component.html',
  styleUrls: ['./fingerprint-signin.component.css'],
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MdbRippleModule, PageCardheadComponent ]
})
export class FingerprintSigninComponent implements OnInit,AfterViewInit, OnDestroy {
  biometricsModel = {} as BiometricsModel;
  userEnrolled: CustomerEnrollmentEntity[] | [];

  sampleData: string[] = [];
  readQuality: string;
  startAcquisitionDisabled = false;
  enrollmentError?: string;
  enrollmentMessage?: string;
  environmentCheck = false;
  lmsUserProfile$ = this.userService.LMSProfile$;
  display = '0:06';
  showTimer = false;
  showStatus = true;
  subscriptions: Subscription[] = [];

  constructor(
    private biometricsService: BiometricsSerivce,
    private userService: UserService,
    private global_utility: AppUtilityService,
    private dialogRef: MdbModalRef<FingerprintSigninComponent>
  ) { }

  ngAfterViewInit(): void {
    this.biometricsService.resetReadings();
  }

  ngOnInit(): void {
    this.userEnrolled = [];
    this.enrollmentMessage = "";
    this.enrollmentError = "";

    this.environmentCheck = environment.production;
    let sub1 = this.biometricsService.sampleData$.subscribe((sample) =>{
      this.showStatus = false;
      if (sample && sample.length > 0) {
        this.sampleData = sample;
        this.showTimer = false;
        this.verifyUserIntermediate();
      }
    });
    this.subscriptions.push(sub1);

    // this.biometricsService.numberReadings$.subscribe((readIdx) =>{
    //   console.log('Number of Readings: ',readIdx);
    // });

    sub1 = this.biometricsService.acquisitionStarted$.subscribe((status) =>{
      this.showMessage(status+', place your finger.');
    });
    this.subscriptions.push(sub1);

    sub1 = this.biometricsService.readQuality$.subscribe((quality) =>{
      this.readQuality = quality;
    });
    this.subscriptions.push(sub1);

    sub1 = this.biometricsService.status$.subscribe((status) =>{
      this.showMessage(status);
    });
    this.subscriptions.push(sub1);

    this.biometricsService.getDevices();
    this.biometricsService.deviceList$.pipe(tap((devicelist:string[])=>{
      this.startAcquisitionDisabled = devicelist.length > 0;
    }))

    sub1 = this.userService.LMSProfile$.subscribe((loginChanged) => {
      // eslint-disable-next-line no-empty
      if (loginChanged) {
        console.log("FP Signing(User Event): ", loginChanged);
      }
    });
    this.subscriptions.push(sub1);
  }

  showMessage(message){
    //const state = document.getElementById('content-capture');
    var statusWindow = document.getElementById("status");
    if(statusWindow){
      statusWindow.innerHTML = message;
    }
  }

  updateData(index: number, value: any) {
    this.sampleData[index] = value;
    console.log("sampleData[index]---", this.sampleData[index]);
  }

  startCapture() {
    this.showMessage("Please wait..");
    this.biometricsService.startAcquisition(true).then(() => {
      this.showTimer = true;
      this.timer(6);
      const timerInterval = setInterval(() => {
        if(this.showTimer) {
          this.showTimer = false;
          this.verifyUserIntermediate();
        }
        clearInterval(timerInterval);
      }, 6000);
    }).catch((error) => {
      this.global_utility.error('Failed to start acquisition: ' + error, 'Reader Start Error');
    });
  }

  toggle_visibility(ids) {
    //var state1 = document.getElementById('content-capture');
    const box = document.getElementById("qualityInputBox");
    (box! as HTMLInputElement).value = "";
    for (var i=0;i<ids.length;i++) {
      var e = document.getElementById(ids[i]);
      if(i == 0){
        e!.style.display = 'block';
      }
      else{
        e!.style.display = 'none';
      }
    }
  }

  verifyUserIntermediate() {
    if (!this.sampleData || this.sampleData.length == 0) {
      this.global_utility.warning('Finger could not be Verified or no finger provided.', '');
      return;
    }

    this.biometricsModel.fingerImage = this.sampleData.slice(0, 1).toString();
    this.biometricsModel.userName = this.userService.userLoginName;
    this.biometricsModel.branchName = this.userService.userBranchName;
    this.biometricsModel.verifiedBy = this.userService.userLoginName;

    this.biometricsService.verifyUserFinger(this.biometricsModel).subscribe({
      next: (response: any) => {
        console.log(response);
        this.enrollmentError = response?.errorCode;
        this.enrollmentMessage = response?.errorMessage;
        this.biometricsService.resetReadings();
        this.sampleData = [];
        this.readQuality = '';

        const toastString = 'User Verified - ' + this.enrollmentError;

        this.userService.setFingerValid(this.enrollmentError == 'SUCCESS');
        if (this.enrollmentError === 'FAILURE') {
          this.global_utility.warning(toastString, '');
        } else {
          this.showStatus=false;
          this.global_utility.info(toastString, '');
          this.dialogRef.close(true);
        }
      },
      error: (err) => {
        this.global_utility.error(err, '');
      },
    });
  }

  async stopUserCapture() {
    this.enrollmentError = "UNKNOWN";
    this.biometricsService.resetReadings();
    await this.biometricsService.stopAcquisition();
  }

  timer(secs: any) {
    // let minute = 1;
    let textSec: any = "00";
    let statSec = 6;

    const timerintervall = setInterval(() => {
      secs--;
      if (statSec != 0) statSec--;
      else statSec = 6;

      if(statSec == 0) {
        clearInterval(timerintervall);
        return;
      }

      if (statSec <= 6) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.display = `00:${textSec}`;
    }, 1000);
  }

  close() {
    this.dialogRef?.close(true);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => {
      x.unsubscribe();
    });
    void this.stopUserCapture();
  }
}
