
import { CommonModule } from "@angular/common";
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { MdbModalService, MdbModalModule, MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { BehaviorSubject } from 'rxjs';
import { LoadingSpinService } from "./loadspinner.service";


@Component({
  selector: 'app-lmsspinner',
  templateUrl: 'spinner.component.html',
  styleUrls: ['./spinner.component.css'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  providers: [
    MdbModalService
  ],
  imports: [CommonModule, MdbModalModule],
})
export class SpinnerComponent {
  showTimer: number = 1;

  message: string | null;

  public showClock$ = new BehaviorSubject<number>(this.showTimer);
  display = '03:00';

  public constructor(private modalRef: MdbModalRef<SpinnerComponent>, private loadingEvc: LoadingSpinService) {
    // this.loaderService.isLoading.subscribe((v) => {
    //   console.log(v);
    //   this.loading = v;
    // });
    this.timer(3);

    this.loadingEvc.message$.subscribe((msg) => {
      this.message = msg;
    });

    this.loadingEvc.showspinner$.subscribe((spinner)=>{
      this.showTimer = spinner;
    })
  }

  timer(minute: any) {
    // let minute = 1;
    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec = 60;
    const prefix = minute < 10 ? "0" : "";

    setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      //if (seconds == 0) {
        //this.keycloak.logout();
      //}
    }, 1000);
  }

  public setNewDataMessage(message?: string) {
    this.message = message == '' || message == undefined ? "Please wait!!" : message;
  }
}
